
import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import Select from "react-select";
import listCountry from '../../../common/country.json'
import { CapitalizeEachWord, Emailvalidate, Phonenumber } from '../../../common/validate';
import ImportedURL from '../../../common/api';
import { Success } from '../../../common/swal';
import axios from 'axios';

export default function Demo() {

    const [data, setData] = useState({});
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phonenumberError, setPhonenumberError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [emailValidError, setEmailValidError] = useState(false);
    const [phonenumberValidError, setPhonenumberValidError] = useState(false);
    const [backStatus, setBackStatus] = useState(true);
    const selectRef = useRef(null);

    const countryOption = []
    const countryValue = data.country ? listCountry.find((e) => CapitalizeEachWord(e.name) == data.country) : ''
    if (listCountry && listCountry.length > 0) {
        listCountry.map((item) => {
            countryOption.push({ value: CapitalizeEachWord(item.name), label: CapitalizeEachWord(item.name), name: "country" })
        })
    }

    const onChange = (e) => {
        const { name, value } = (e.target != undefined && e.target) ? e.target : e;
        SaveData(name, value);
        if (name == 'name') {
            setNameError(false);
        }
        if (name == 'email') {
            if (value) setEmailValidError(!Emailvalidate(value)); else setEmailValidError(false);
            setEmailError(false);
        }
        if (name == 'phonenumber') {
            setPhonenumberError(false);
        }
        if (name == 'country') {
            setCountryError(false);
        }
        if (name == 'message') {
            setMessageError(false);
        }
    };

    const SaveData = (name, value) => {
        if (name == 'phonenumber') {
            let s1 = value.replace(/[^a-z\d\s]+/gi, "");
            let s2 = s1.replace(/[- )(]/g, '')
            var s3 = s2.slice(0, 10);
            var val = s3.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
            if (val) setPhonenumberValidError(!Phonenumber(val)); else setPhonenumberValidError(false);
            const getData = {
                ...data,
                [name]: val
            }
            setData(getData);
        } else {
            const getData = {
                ...data,
                [name]: value
            }
            setData(getData);
        }
    }

    const submit = (e) => {
        const newData = { ...data };
        for (let key in newData) {
            if (newData.hasOwnProperty(key) && typeof newData[key] === 'string') {
                newData[key] = newData[key].trim();
            }
        }
        let valid = 1;
        if (!newData.name) {
            valid = 0;
            setNameError(true);
        }
        if (!newData.email) {
            valid = 0;
            setEmailError(true);
        }
        if (!newData.phonenumber) {
            valid = 0;
            setPhonenumberError(true);
        }
        if (!newData.country) {
            valid = 0;
            setCountryError(true);
        }
        if (!newData.message) {
            valid = 0;
            setMessageError(true);
        }
        if (emailValidError) {
            valid = 0;
        }
        if (phonenumberValidError) {
            valid = 0;
        }
        if (valid) {
            newData['type'] = 'Demo'
            axios.post(ImportedURL.API.addLandingPageEnquiry, newData)
                .then((res) => {
                    setBackStatus(false);
                    setData({ name: '', email: '', phonenumber: '', country: '', message: '', })
                }).catch((err) => {
                    console.log(err)
                });
        }
    }
    return (
        <div className='demo_sec section' id='contact'>
            <Container className='container-new-big'>
                <div className='demo'>
                    <h2>Book A Demo</h2>
                    {
                        backStatus
                            ?
                            <>
                                <p>Send us a message to get more information about the card</p>
                                <form>
                                    <Row>

                                        <Col lg={6} md={6} sm={12}>
                                            <div className="mb-3">
                                                <input type="text" id='demoname' value={data.name} onChange={onChange} name='name' className={"form-control " + (nameError ? 'red_bdr' : '')} placeholder="Full Name*" />
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <div className="mb-3">
                                                <input type="email" id='demoemail' value={data.email} onChange={onChange} name='email' className={"form-control " + ((emailError || emailValidError) ? 'red_bdr' : '')} placeholder="Email Address*" />
                                                {emailValidError ? <span className='error-message' style={{ display: 'flex' }}>Enter valid email</span> : ''}
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <div className="mb-3">
                                                <input type="text" id='demophonenumber' value={data.phonenumber} onChange={onChange} name='phonenumber' className={"form-control " + ((phonenumberError || phonenumberValidError) ? 'red_bdr' : '')} placeholder="Phone Number*" />
                                                {phonenumberValidError ? <span className='error-message' style={{ display: 'flex' }}>Phone Number should contain 10 digits</span> : ''}
                                            </div>
                                        </Col >
                                        <Col lg={6} md={6} sm={12}>
                                            <div className="mb-3">
                                                <Select
                                                    id='democountry'
                                                    ref={selectRef}
                                                    className={(countryError ? 'red_bdr' : '')}
                                                    options={countryOption}
                                                    onChange={onChange}
                                                    value={countryValue ? { label: CapitalizeEachWord(countryValue.name), flag: countryValue.flag } : ''}
                                                    styles={{
                                                        menu: provided => ({ ...provided, zIndex: 9999, textAlign: 'left' })
                                                    }}
                                                    placeholder={<div className="select-placeholder-text">Country*</div>}
                                                />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="mb-3">
                                                <textarea id='demomessage' value={data.message} onChange={onChange} name='message' className={"form-control " + (messageError ? 'red_bdr' : '')} rows="5" placeholder='Message*'></textarea>
                                            </div>
                                        </Col>
                                        <div className='demo_btn'>
                                            <Button title='Book a demo' onClick={submit}>Book a demo</Button>
                                        </div>
                                    </Row>
                                </form>
                            </>
                            :
                            <>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
                                    <div className="form-group">
                                        <p style={{ color: '#2f2f2f', fontWeight: '600', fontSize: '35px', textAlign: 'center' }}>Thank you for your submission!</p>
                                        <div className='demo_btn'>
                                            <Button title='Book a demo' onClick={(e) => setBackStatus(true)}>Back</Button>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </Container>
        </div>
    )
}
