import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import axios from 'axios';
import { Link } from "react-router-dom";
import QRCode from 'qrcode.react';
import ImportedURL from "../../common/api";
import { Spinner } from "react-bootstrap";
import { AiOutlineAppstore, GrFormAdd } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import { Error, Success } from "../../common/swal";
import { Redirect } from 'react-router-dom';
import { useParams } from "react-router";
import CountryCodeJson from "../../common/countrycode.json";
import Select from "react-select";
import { Emailvalidate, Imagevalidation, Phonenumber } from "../../common/validate";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { AC_EMPTY_USER, AC_HANDLE_INPUT_CHANGE_PROFILE, AC_USER_SPINNER, AC_VIEW_USER } from "../../actions/profileAction";
import { Buffer } from 'buffer';
import { AC_EMPTY_COMPANY, AC_HANDLE_INPUT_CHANGE_COMPANY, AC_LIST_COMPANY, AC_SPINNER_COMPANY, AC_VIEW_COMPANY } from "../../actions/companyAction";
import { AC_LIST_CATEGORY } from "../../actions/categoryAction";
import { isValidPassword } from "../../common/validate";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class AddCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalType: "Add",
            id: '',
            preview: [],
            username: '',
            email: '',
            password: '',
            nameError: false,
            usernameError: false,
            emailError: false,
            passwordError: false,
            hidepassword: false,
            emailValidError: false,
            barCodeValue: '',
            image: ''
        }
    }
    componentDidMount() {
        this.props.ListCategory();
        this.props.EmptyCompany();
        const { params, path } = this.props.match;
        if (params.id) {
            this.props.SpinnerCompany();
            this.props.ViewCompany(params.id);
            this.setState({ modalType: path === "/view-room/:id" ? "View" : "Edit", id: params.id })
        }

    }
    submit = () => {
        const { companyState } = this.props;
        const getData = companyState.company
        const data = { ...getData };
        for (let key in data) {
            if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
                data[key] = data[key].trim();
            }
        }
        let valid = 1;
        if (!data.category) {
            this.setState({ categoryError: true });
            valid = 0;
        }
        if (!data.name) {
            this.setState({ nameError: true });
            valid = 0;
        }
        if (!data.email) {
            this.setState({ emailError: true });
            valid = 0;
        }
        if (!data.username) {
            this.setState({ usernameError: true });
            valid = 0;
        }
        if (!data.password) {
            this.setState({ passwordError: true });
            valid = 0;
        }
        if (!data.phonenumber) {
            this.setState({ phonenumberError: true });
            valid = 0;
        }
        if (!data.companytype) {
            this.setState({ companytypeError: true });
            valid = 0;
        }
        if (!data.address) {
            this.setState({ addressError: true });
            valid = 0;
        }
        if (!data.city) {
            this.setState({ cityError: true });
            valid = 0;
        }
        if (!data.state) {
            this.setState({ stateError: true });
            valid = 0;
        }

        if (!data.postcode) {
            this.setState({ postcodeError: true });
            valid = 0;
        }
        if (!data.country) {
            this.setState({ countryError: true });
            valid = 0;
        }
        if (!data.image) {
            this.setState({ imageError: true });
            valid = 0;
        }
        if (this.state.emailValidError) {
            valid = 0;
        }
        if (this.state.phonenumberValidError) {
            valid = 0;
        }
        if (this.state.invalidFileError) {
            valid = 0;
        }
        if (this.state.strongPass) {
            valid = false
        }
        if (valid) {
            var formData = new FormData();
            for (let key in data) {
                if (key === 'cc') formData.append(key, JSON.stringify(data[key]));
                else if (key === 'previleges') formData.append(key, JSON.stringify(data[key]));
                else formData.append(key, data[key])
            }
            this.setState({ saving: true })
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addCompany, formData)
                    .then((res) => {
                        this.setState({ saving: false, ListState: true })
                        Success("Company created successfully");
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        this.ErrorResponse(response);
                    });
            } else {
                axios.post(ImportedURL.API.updateCompany + "/" + this.state.id, formData)
                    .then((res) => {
                        this.setState({ saving: false, ListState: true })
                        Success("Company updated successfully");
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        this.ErrorResponse(response);
                    });
            }
        }
    }
    ErrorResponse = (response) => {
        if (response) {
            if (response.status == 401) {
                Error('Something wrong, Retry again!')
            } else if (response.status == 502) {
                Error(response.status + ' Bad Gateway')
            } else if (response.status == 500) {
                Error('Internal Server Error')
            } else if (response.status == 409) {
                Error('Email already exist')
            } else if (response.status == 408) {
                Error('Username already exist')
            } else if (response.status == 406) {
                Error('Company ID already exist')
            } else if (response.status == 405) {
                Error('Email already exist in user')
            } else if (response.status == 400) {
                Error('Bad request')
            }
        } else {
            Error("Bad Gateway")
        }
    }

    onChange = e => {
        const data = this.props.companyState.company
        const { name, value } = e.target;
        const Error = name + "Error";
        const ValidError = name + "ValidError";
        this.setState({ [name]: value, [Error]: false })
        if (name === 'email') {
            this.props.HandleChange(name, value)
            var email = value;
            if (email) {
                if (Emailvalidate(email)) {
                    this.setState({ [ValidError]: false, [Error]: false })
                } else {
                    this.setState({ [ValidError]: true })
                }
            }
            else {
                this.setState({ emailError: true, [ValidError]: false });
            }
        } else if (name == 'phonenumber') {
            let s1 = value.replace(/[^a-z\d\s]+/gi, "");
            let s2 = s1.replace(/[- )(]/g, '')
            var s3 = s2.slice(0, 10);
            var val = s3.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            this.props.HandleChange(name, val);
            if (val) {
                if (Phonenumber(val)) {
                    this.setState({ [ValidError]: false })
                } else {
                    this.setState({ [ValidError]: true })
                }
            } else {
                this.setState({ [ValidError]: false })
            }
        } else if (name == 'companytype') {
            let s1 = value.replace(/[^a-z\d\s]+/gi, "");
            let s2 = s1.replace(/[- )(]/g, '')
            var val = s2.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            this.props.HandleChange(name, val);
        } else if (name == 'image') {
            const imgvalidate = Imagevalidation(e.target.files[0]);
            if (imgvalidate) {
                this.setState({ invalidFileError: false })
                this.props.HandleChange(name, e.target.files[0]);
            } else {
                this.setState({ invalidFileError: true })
                document.getElementById('imageSet').value = '';
                this.props.HandleChange(name, '');
            }
        } else if (name === 'password') {
            this.props.HandleChange(name, value)
            if (value) {
                if (isValidPassword(value)) {
                    this.setState({ strongPass: false })
                } else {
                    this.setState({ strongPass: true })
                }
            } else {
                this.setState({ strongPass: false })
            }
        } else {
            this.props.HandleChange(name, value)
        }
    }
    eyeClick = () => {
        this.setState({ hidepassword: !this.state.hidepassword })
    }
    onSelectFlag = (e) => {
        const { name, value, label } = e;
        let data = {
            name: value,
            value: label,
        };
        this.props.HandleChange("cc", data);
    };
    onChangeSelect = (e) => {
        const { name, value, label, previleges } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false })
        this.props.HandleChange(name, value);
        if (name == 'category') {
            this.props.HandleChange("previleges", JSON.stringify(previleges));
        }
    };
    render() {
        if (this.state.ListState) return <Redirect to={'/admin/list-company'} />

        const { accountState, companyState, CategoryState } = this.props;
        const account = accountState.account
        const listCategories = CategoryState.listcategories;
        const data = companyState.company
        const spinner = companyState.spinner;
        var selectedCategory = '';
        const categoryOptions = [];
        if (listCategories && listCategories.length > 0) {
            listCategories.filter(e => e.status === true).map((item) => {
                if (data.category == item._id) {
                    selectedCategory = { label: `${item.name}`, value: item._id, previleges: item.previleges, name: 'category' }
                }
                categoryOptions.push({ label: `${item.name}`, value: item._id, previleges: item.previleges, name: 'category' });
            })
        }
        return (
            <>
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-12 breadcome_value">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item header_color_breadcome"> <Link to='/admin'>Dasboard</Link></li>
                                    <li class="breadcrumb-item active"><Link to='/admin/list-company'>List Company</Link></li>
                                    <li class="breadcrumb-item active">{this.state.modalType} Company</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="col-md-12">
                        <div class="card card-primary header_border" >
                            <div class="table-title">
                                <div className="card-header">
                                    <h3 className="card-title d-flex "> <div className='rounded_icon'> <i className="fa fa-building-o" /></div><h2 class="card-title header_title">{(this.state.modalType).toUpperCase()} COMPANY</h2> </h3>
                                    <div className="card-options">
                                        <div className="d-flex justify-content-end">
                                            <div className="header-action mr-2">
                                                <Link to='/admin/list-company'><button type="button" className="btn btn-primary button_color" id='Add'> <i className="fa fa-arrow-left mr-2" id='Add' />Back</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <form id="quickForm" autoComplete="off">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Category<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                    <div className="form-group" >
                                                        <Select
                                                            value={selectedCategory}
                                                            onChange={this.onChangeSelect}
                                                            options={categoryOptions}
                                                            styles={{
                                                                menu: provided => ({ ...provided, zIndex: 9999, textAlign: 'left' })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="invalid-feedback" style={{ display: this.state.categoryError ? "block" : 'none' }}>Category is required</div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Company Name<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='name' value={data.name} onChange={this.onChange} placeholder="Company Name" />
                                                    <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Company Name is required</div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Email<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='email' value={data.email} onChange={this.onChange} placeholder="Email" />
                                                    <div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Email is required</div>
                                                    <div className="invalid-feedback" style={{ display: this.state.emailValidError ? 'block' : 'none' }}>Enter valid email</div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Username<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='username' value={data.username} onChange={this.onChange} placeholder="Username" />
                                                    <div className="invalid-feedback" style={{ display: this.state.usernameError ? "block" : 'none' }}>Username is required</div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Password<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                    <input type={this.state.hidepassword ? "text" : "password"} className="form-control" style={{ position: "relative" }} name='password' value={data.password} onChange={this.onChange} placeholder="Password" />
                                                    <div className="invalid-feedback" style={{ display: this.state.passwordError ? "block" : 'none' }}>Password is required</div>
                                                    <div className="invalid-feedback" style={{ display: this.state.strongPass ? "block" : 'none' }}>Password should contain at least 1 uppercase, 1 lowercase,  1 special character, 1 number and atleast 8 characters in total</div>
                                                    {
                                                        (this.state.hidepassword)
                                                            ?
                                                            <BsFillEyeFill className="input_type_password" onClick={this.eyeClick} />
                                                            :
                                                            <BsFillEyeSlashFill className="input_type_password" onClick={this.eyeClick} />
                                                    }
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <label for="exampleInputEmail1">Phone Number<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                <div className="row">
                                                    <div className="col-4">
                                                        <Select
                                                            value={data.cc && data.cc.value != undefined ? { label: data.cc.value, value: data.cc.name, flag: data.cc.name.toLowerCase(), } : ""}
                                                            onChange={this.onSelectFlag}
                                                            options={CountryCodeJson &&
                                                                CountryCodeJson.map((item) => {
                                                                    return {
                                                                        label: item.dial_code,
                                                                        flag: item.code.toLowerCase(),
                                                                        value: item.code,
                                                                        name: "cc",
                                                                    };
                                                                })
                                                            }
                                                            formatOptionLabel={(e) => {
                                                                return (
                                                                    <div style={{ display: "block", alignItems: "center", }} >
                                                                        {e.flag ? (
                                                                            <img src={"../assets/images/flags/" + e.flag + ".svg"}
                                                                                style={{ height: "20px", width: "25px", objectFit: "contain", }}
                                                                                className="contain_image"
                                                                            />
                                                                        ) : ("")}
                                                                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                                    </div>
                                                                );
                                                            }}
                                                            styles={{
                                                                menu: provided => ({ ...provided, zIndex: 9999, textAlign: 'left' })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-8">
                                                        <div class="form-group">
                                                            <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='phonenumber' value={data.phonenumber} onChange={this.onChange} placeholder="Phone Number" />
                                                        </div>
                                                    </div>
                                                    <div className="invalid-feedback" style={{ display: this.state.phonenumberError ? "block" : 'none' }}>Phone Number is required</div>
                                                    <div className="invalid-feedback" style={{ display: this.state.phonenumberValidError ? "block" : 'none' }}>Phone Number should contain 10 digits</div>
                                                </div>

                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Company ID<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='companytype' value={data.companytype} onChange={this.onChange} placeholder="Company ID" />
                                                    <div className="invalid-feedback" style={{ display: this.state.companytypeError ? "block" : 'none' }}>Company ID is required</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">
                                                        Address<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='address' onChange={this.onChange} value={data.address} placeholder="Address" />
                                                    <div className="invalid-feedback" style={{ display: this.state.addressError ? "block" : 'none' }}> Address is required</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">
                                                        City<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='city' onChange={this.onChange} value={data.city} placeholder="City" />
                                                    <div className="invalid-feedback" style={{ display: this.state.cityError ? "block" : 'none' }}> City is required</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">
                                                        State<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='state' onChange={this.onChange} value={data.state} placeholder="State" />
                                                    <div className="invalid-feedback" style={{ display: this.state.stateError ? "block" : 'none' }}> State is required</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">
                                                        Postcode<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='postcode' onChange={this.onChange} value={data.postcode} placeholder="Postcode" />
                                                    <div className="invalid-feedback" style={{ display: this.state.postcodeError ? "block" : 'none' }}> Postcode is required</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">
                                                        Country<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='country' onChange={this.onChange} value={data.country} placeholder="Country" />
                                                    <div className="invalid-feedback" style={{ display: this.state.countryError ? "block" : 'none' }}> Country is required</div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">
                                                        Logo<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input type="file" accept="image/*" className="form-control" placeholder="Image" name='image' onChange={this.onChange} id="imageSet" />
                                                    <div className="invalid-feedback" style={{ display: this.state.imageError ? "block" : 'none' }}>Logo is required</div>
                                                    <div className="invalid-feedback" style={{ display: this.state.invalidFileError ? "block" : 'none' }}>Invalid file extension</div>
                                                </div>
                                                {data.image ?
                                                    <div className="form-group">
                                                        <img src={typeof data.image == 'string' ? ImportedURL.LIVEURL + data.image : window.URL.createObjectURL(data.image)} width="100px" alt='' />
                                                    </div>
                                                    : ''}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="card-footer text-right mandatory">
                                        <label className="text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                        <div className="text-right">
                                            {
                                                this.state.saving ?
                                                    <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>{this.state.modalType == "Edit" ? "Updating" : "Saving"}</button>
                                                    :
                                                    <button type="button" className="btn commor_save" onClick={this.submit}>
                                                        <i className="fe fe-save mr-2"></i>{this.state.modalType == "Edit" ? "Update" : "Save"}
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {spinner ?
                    <div className='common_loader_ag_grid'>
                        <img className='loader_img_style_common_ag_grid' src='/assets/images/logo.jpg' />
                        <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                        </Spinner>
                    </div>
                    : ""}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        accountState: state.account,
        CategoryState: state.category,
        companyState: state.company,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleChange: AC_HANDLE_INPUT_CHANGE_COMPANY,
        ViewCompany: AC_VIEW_COMPANY,
        EmptyCompany: AC_EMPTY_COMPANY,
        SpinnerCompany: AC_SPINNER_COMPANY,
        ListCategory: AC_LIST_CATEGORY,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)((AddCompany));