"use client";
import React from 'react';
import { Container } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export default function testimonials() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div className='testimonials_sec section'>
            <Container className='container-new-big'>
                <div className='testimonials'>
                    <h2>Our Client <br />
                        Testimonials</h2>
                    <Carousel responsive={responsive} infinite={true}>
                        <div className='caro_item'>
                            <p className='para'>“ACW Card has drastically helped me improve my networking.  I can share my profile with people I meet in just a tap.”  </p>
                            <div className='prof_name'>
                                <div className='prof_image'>
                                    <img src='./landing_page/assets/images/man.png' />
                                </div>
                                <div className='prof_address'>
                                    <p className='add_1'>- Wil Smith</p>
                                    {/* <p className='add_1'>man powers</p>
                                    <p className='add_1'>Mumbai</p> */}
                                </div>
                            </div>
                        </div>
                        <div className='caro_item'>
                            <p className='para'>“Each of our employees are now connected through the ACW PVC Cards. These cards have streamlined our company’s network. It is easier to share details about our organization with new people.” </p>
                            <div className='prof_name prof_name2'>
                                <div className='prof_image'>
                                    <img src='./landing_page/assets/images/woman.png' />
                                </div>
                                <div className='prof_address'>
                                    <p className='add_1'>- Kane William
                                    </p>
                                    {/* <p className='add_1'>voluptatem</p>
                                    <p className='add_1'>CEO</p> */}
                                </div>
                            </div>
                        </div>
                        <div className='caro_item'>
                            <p className='para'>“ACW Card has eliminated my need to carry paper contact cards. I can easily share all my details to clients, prospects and the people I meet in just a tap.”  </p>
                            <div className='prof_name'>
                                <div className='prof_image'>
                                    <img src='./landing_page/assets/images/man.png' />
                                </div>
                                <div className='prof_address'>
                                    <p className='add_1'>- Jack Albert
                                    </p>
                                    {/* <p className='add_1'>consequuntur</p>
                                    <p className='add_1'>CEO</p> */}
                                </div>
                            </div>
                        </div>
                        <div className='caro_item'>
                            <p className='para'>“Our company has switched from paper visiting cards to ACW NFC Embedded Cards. It has improved our process of connecting and sharing profiles.” </p>
                            <div className='prof_name'>
                                <div className='prof_image'>
                                    <img src='./landing_page/assets/images/woman.png' />
                                </div>
                                <div className='prof_address'>
                                    <p className='add_1'>- Mario JJ</p>
                                    {/* <p className='add_1'>man powers</p>
                                    <p className='add_1'>Mumbai</p> */}
                                </div>
                            </div>
                        </div>
                        <div className='caro_item'>
                            <p className='para'>“With the help of ACW Card, our firm has successfully onboarded many new clients. It has made it convenient for us to pitch new clients and sharing about our firm in a few seconds.” </p>
                            <div className='prof_name prof_name2'>
                                <div className='prof_image'>
                                    <img src='./landing_page/assets/images/man.png' />
                                </div>
                                <div className='prof_address'>
                                    <p className='add_1'>- Adam Paul
                                    </p>
                                    {/* <p className='add_1'>voluptatem</p>
                                    <p className='add_1'>CEO</p> */}
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
            </Container>
        </div>
    )
}
