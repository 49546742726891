import React from 'react'
import { Row, Tab, Tabs, Col, Container, Button, Card } from 'react-bootstrap';
import { LiaRupeeSignSolid } from 'react-icons/lia';
import Select from 'react-select'
import { Link } from "react-router-dom";

export default function contact() {
    const scrollEnquiry = () => {
        let formEnquiry = document.getElementById("product_link");
        if (formEnquiry) {
            formEnquiry.scrollIntoView()
        }
    }
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    const options1 = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    const options2 = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    return (
        <div className='contact_sec'>
            <div className='order_sec section'>
                <div className='order_section'>
                    <div className='react_videoplayer_innerpage'>
                        {/* <ReactPlayer url='../videos/testvideo.mp4' volume autoPlay playing loop controls /> */}
                        <video autoPlay loop volume muted>
                            {/* Provide the video source */}
                            <source src="../videos/contact.mov" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>

                </div>
            </div>

            <div className='contact'>
                <Container className='container-new-big'>
                    <div className='contact_text1'>
                        <h2>Leave A Mail</h2>
                        <p>If you have chosen a plan or have a query, you can mail us at</p>
                        <a href='mailto:info@acwcard.com '>info@acwcard.com</a>
                    </div>
                    <hr />
                </Container>


                <div className='form_part'>
                    <div className='sub_text_contact'>
                        <h3>Place A Query </h3>
                        <p>You can also write your query to us and we will get back to you. Fill your details here.</p>
                    </div>
                    <div className='enquiry_contact_btn'>
                        <Link to='/details/enquiry-form'>
                            <Button onClick={scrollEnquiry}>Enquiry</Button>
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    )
}
