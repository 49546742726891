const initialState = {
    hotel: {
        cc: {
            name: "US",
            value: "+1",
        },
    },
    listHotel: [],
    spinner: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_INPUT_CHANGE_HOTEL':
            return Object.assign({}, state, {
                hotel: {
                    ...state.hotel,
                    [action.name]: action.value
                }
            })
        case "VIEW_HOTEL":
            return Object.assign({}, state, {
                hotel: action.payload,
                spinner: action.spinner
            });
        case 'LIST_HOTEL':
            return {
                ...state,
                listHotel: action.payload,
                spinner: action.spinner
            }
        case 'EMPTY_HOTEL':
            return Object.assign({}, state, {
                hotel: {
                    cc: {
                        name: "US",
                        value: "+1",
                    },
                }
            })
        case 'SPINNER_HOTEL':
            return Object.assign({}, state, {
                spinner: true
            })

        default:
            return state;
    }
}