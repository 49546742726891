const initialState = {
    bloodgroup: {
        name: '',
    },
    listBloodgroup: [],
    spinner: true,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_INPUT_CHANGE_BLOODGROUP':
            return Object.assign({}, state, {
                bloodgroup: {
                    ...state.bloodgroup,
                    [action.name]: action.value
                }
            })

        case "VIEW_BLOODGROUP":
            return Object.assign({}, state, {
                bloodgroup: action.payload,
                spinner: action.spinner
            });

        case 'LIST_BLOODGROUP':
            return {
                ...state,
                listBloodgroup: action.payload,
                spinner: action.spinner
            }

        case 'EMPTY_BLOODGROUP':
            return Object.assign({}, state, {
                bloodgroup: {
                    name: '',
                },
                spinner: false
            })

        default:
            return state;
    }
}