import { combineReducers } from 'redux';
import settings from './settings';
import account from './account';
import profile from './profile';
import app from './app';
import loginhistory from './loginhistory';
import savecontacthistory from './savecontacthistory';
import enquiry from './enquiry';
import category from './category';
import business from './business';
import swap from './swap';
import bloodgroup from './bloodgroup';
import education from './education';
import company from './company';
import cardtype from './cardtype';
import hotel from './hotel';
import landingpageenquiry from './landingpageenquiries';

export default combineReducers({
    settings,
    account,
    profile,
    app,
    loginhistory,
    savecontacthistory,
    enquiry,
    category,
    business,
    swap,
    bloodgroup,
    education,
    company,
    cardtype,
    hotel,
    landingpageenquiry,
});