import React, { Component } from 'react'
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Container, Col, Spinner } from 'react-bootstrap';
import { BsFillEyeFill, BsFillEyeSlashFill, BsEye } from 'react-icons/bs';
import { AiOutlineMail, AiOutlineUser, AiOutlineCaretRight } from 'react-icons/ai';
import { HiOutlineChevronLeft } from 'react-icons/hi';
import Swal from 'sweetalert2';
import { Link, Redirect } from "react-router-dom";
import { FiLogOut } from 'react-icons/fi';
import { AC_HANDLE_INPUT_CHANGE_BROCHURE, AC_HANDLE_INPUT_CHANGE_PROFILE, AC_LIST_PROFILE, AC_VIEW_PROFILE } from '../../actions/profileAction';
import ImportedURL from '../../common/api';
import { Emailvalidate } from '../../common/validate';
import { Success, Error } from '../../common/swal';
import { AC_LIST_APP } from '../../actions/appAction';
import { BiBlock, BiSitemap } from 'react-icons/bi';
import { BsArrowRightCircle } from "react-icons/bs";
import { FaUserEdit } from "react-icons/fa";

import active_tick from '../images/v2/active_tick.png'
import disable_tick from '../images/v2/disable_tick.png'
import logo from '../images/v2/logo.png'
import log_out from '../images/v2/logout.png'
import swap_img from '../images/v2/swap.png'
import disable_account from '../images/v2/security.png'
import preview from '../images/v2/preview.png'
import cover_dis from '../images/v2/cover_dis.png'
import banner_img from '../images/v2/banner.png'

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bgimage: '',
            profileimg: '',
            skill: '',
            socialmediaSelect: {},
            urlError: false,
            emailValidError: false,
            updateSpinner: false,
            hidepassword: false,
            hidepasswordconfirm: false,
            isEmailShow: false,
            isUserNameShow: false,
            isPassShow: false,
            redirectLogin: false,
            brochure: false,
            isEmailSaveShow: false,
            isUserNameSaveShow: false,
            isPasswordSaveShow: false,
            isPreview: false,
            passwordConfError: false,
            passwordError: false
        }
    }
    componentDidMount() {
        document.title = 'ACW CARD - Settings'
        document.description = 'ACW Card enables you to share your business and personal profiles along with digital uploads of key documents to strengthen your portfolio.'

        this.props.ViewProfile();
        this.props.ListApp();
        this.props.ListUser()
    }
    eyeClick = () => {
        this.setState({ hidepassword: !this.state.hidepassword })
    }
    eyeClickConfirm = () => {
        this.setState({ hidepasswordconfirm: !this.state.hidepasswordconfirm })
    }
    submit = (e) => {
        const { ProfileState } = this.props;
        const dataProfile = ProfileState.profile
        const data = { ...dataProfile };
        for (let key in data) {
            if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
                data[key] = data[key].trim();
            }
        }
        let valid = true
        if (e == 'email') {
            if (this.state.emailValidError) {
                valid = false
            }
            if (!data.email) {
                this.setState({ emailError: true })
                valid = false
            }
        }
        if (e == 'username') {
            if (!data.username) {
                this.setState({ usernameError: true })
                valid = false
            }
        }
        if (e == 'password') {
            if (this.state.passwordValidError) {
                valid = false
            }
            if (!this.state.isPasswordSaveShow) {
                valid = false
            }
            if (!data.password) {
                this.setState({ passwordError: true })
                this.props.HandleInputChange("password", "")
                this.props.HandleInputChange("confirmpassword", "")
                valid = false
            }
        }
        if (valid) {
            this.setState({ updateSpinner: true })
            var formData = { _id: data._id }
            if (e == 'email') formData['email'] = data.email
            if (e == 'username') formData['username'] = data.username
            if (e == 'password') formData['password'] = data.password
            axios.post(ImportedURL.API.updateaccountsetting, formData)
                .then((res) => {
                    this.setState({ updateSpinner: false, isEmailSaveShow: false, isUserNameSaveShow: false })
                    if (res.data.logoutStatus != undefined && res.data.logoutStatus) {
                        if (e == 'email') {
                            localStorage.removeItem('acwtoken');
                            localStorage.removeItem('type');
                            window.location.href = "/login";
                            // this.setState({ redirectLogin: true })
                        }
                    }
                    if (e == 'password') {
                        localStorage.removeItem('acwtoken');
                        localStorage.removeItem('type');
                        window.location.href = "/login";
                        // this.setState({ redirectLogin: true })
                    }
                    Success('Saved');
                }).catch(({ response }) => {
                    this.setState({ updateSpinner: false })
                    if (response) {
                        if (response.status == 401) {
                            Error('Something wrong, Retry again!')
                        } else if (response.status == 510) {
                            Error('Email does not exit')
                        } else if (response.status == 502) {
                            Error(response.status + ' Bad Gateway')
                        } else if (response.status == 500) {
                            Error('Internal Server Error')
                        } else if (response.status == 409) {
                            Error('Username already exist')
                        } else if (response.status == 408) {
                            Error('Email already exist')
                        } else if (response.status == 400) {
                            Error('Bad request')
                        } else {
                            Error('Bad request')
                        }
                    }
                });
        }
    }


    Logout = (e) => {
        // this.setState({ updateSpinner: true })
        Swal.fire({
            title: 'Are you sure you want to logout?',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            // imageUrl: 'assets/images/logout.png',
            customClass: {
                popup: 'swal_pop',
                title: 'swal_title',
                image: 'swal_image',
                actions: 'swal_action',
                confirmButton: 'swal_confirm',
                cancelButton: 'swal_close',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                // this.setState({ updateSpinner: false })
                e.preventDefault();
                localStorage.removeItem('acwtoken');
                localStorage.removeItem('type');
                window.location.href = "/login";
            }
        })
    }
    Myhotelai = (e) => {
        this.props.HandleChangeBroche("settings")
        this.setState({ brochure: true })
    }
    disable = (e) => {
        const data = this.props.ProfileState.profile
        Swal.fire({
            title: 'Are you sure you want to disable your account?',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            // imageUrl: 'assets/images/disable.png',
            customClass: {
                popup: 'swal_pop',
                title: 'swal_title',
                image: 'swal_image',
                actions: 'swal_action',
                confirmButton: 'swal_confirm',
                cancelButton: 'swal_close',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(ImportedURL.API.statusChange, { id: data._id ? data._id : '', status: false, model: 'users' })
                    .then((data) => {
                        Success('Disable your account successfully')
                        localStorage.removeItem('acwtoken');
                        localStorage.removeItem('type');
                        window.location.href = "/login";
                    }).catch(({ response }) => {
                        if (response.status == 500) {
                            Error(response.status + ' Internal Server Error')
                        } else if (response.status == 502) {
                            Error(response.status + ' Bad Gateway')
                        } else {
                            Error(response.statusMessage)
                        }
                    });
            }
        })

    }
    onChange = e => {
        const data = this.props.ProfileState.profile
        const { name, value } = e.target;
        const Error = name + "Error";
        const ValidError = name + "ValidError";
        this.setState({ [name]: value, [Error]: false })

        if (name == 'email') {
            var email = value.toLowerCase();
            if (email) {
                if (Emailvalidate(email)) {
                    this.setState({ [ValidError]: false, [Error]: false, isEmailSaveShow: true })
                } else {
                    this.setState({ [ValidError]: true, isEmailSaveShow: false })
                }
            }
            else {
                this.setState({ emailError: true, [ValidError]: false, isEmailSaveShow: false });
            }
            this.props.HandleInputChange(name, email)
        } else if (name == 'confirmpassword') {
            this.props.HandleInputChange(name, value)
            if (value) {
                if (data.password != value) {
                    this.setState({ passwordValidError: true, isPasswordSaveShow: false })
                } else {
                    this.setState({ passwordValidError: false, isPasswordSaveShow: true })
                }
            } else {
                this.setState({ passwordValidError: false, isPasswordSaveShow: false })
            }
        } else if (name == 'username') {
            if (value) {
                this.setState({ [Error]: false, isUserNameSaveShow: true })
                this.props.HandleInputChange(name, value)
            } else {
                this.setState({ [Error]: true, isUserNameSaveShow: false })
                this.props.HandleInputChange(name, value)
            }
        } else if (name == 'password') {
            this.props.HandleInputChange(name, value)
            if (value) {
                if (data.confirmpassword != value) {
                    this.setState({ isPasswordSaveShow: false })
                } else {
                    this.setState({ passwordValidError: false, isPasswordSaveShow: true })
                }
            } else {
                this.setState({ passwordValidError: false, isPasswordSaveShow: false })
            }
        } else {
            this.props.HandleInputChange(name, value)
        }



    }

    nameClick = (e) => {
        this.setState(prevState => ({
            isUserNameShow: !prevState.isUserNameShow
        }))
    }
    emailClick = (e) => {
        this.setState(prevState => ({
            isEmailShow: !prevState.isEmailShow
        }))
    }
    passClick = (e) => {
        this.setState(prevState => ({
            isPassShow: !prevState.isPassShow
        }))
    }
    preview = (e) => {
        this.setState({ isPreview: true })
    }
    onClickSwap = (e) => {
        this.setState({ isSwap: true })
    }
    disableProfile = (e) => {
        const { value, name, checked } = e.target;
        axios.post(ImportedURL.API.updateUserProfileStatus, { isProfileStatus: checked })
            .then((data) => {
                this.props.ViewProfile();
                Success(`Your profile picture is ${checked ? 'activated' : 'disabled'}`)
            }).catch(err => {
                console.log('Something went wrong')
            })
    }

    disableCoverPicture = (e) => {
        const { value, name, checked } = e.target;
        axios.post(ImportedURL.API.updateUserCOverStatus, { isCoverStatus: checked })
            .then((data) => {
                this.props.ViewProfile();
                Success(`Your cover picture is ${checked ? 'activated' : 'disabled'}`)
            }).catch(err => {
                console.log('Something went wrong')
            })
    }
    render() {
        const { redirectLogin, brochure, isPreview, isSwap } = this.state;
        if (redirectLogin) return <Redirect to={'/login'} />
        if (brochure) return <Redirect to={'/brochure'} />
        if (isSwap) return <Redirect to={'/swap-id'} />


        const { isPassShow, isEmailShow, isUserNameShow } = this.state;
        const { socialmediaSelect } = this.state
        const { ProfileState, appState } = this.props;
        const spinner = ProfileState.spinner;
        const profiledata = ProfileState.profile;
        const listapp = appState.listApp
        const listUser = ProfileState.listUser;

        let querString = '';
        let ispublic = '';
        if (listUser && listUser.length > 0) {
            listUser.map(data => {
                querString = `?cardType=${data.cardtype}&uId=${data.uniqueid}`;
                ispublic = data.ispublicprofile
            })
        }
        if (isPreview) {
            if (ispublic == false) {
                return <Redirect to={`/private`} />
            } else {
                return <Redirect to={`/preview/${querString}`} />
            }
        }

        const data = ProfileState.profile
        const settingOption = ProfileState.settingOption
        const options = [
            { value: 'instragram', label: 'instragram' },
            { value: 'linkedin', label: 'linkedin' },
            { value: 'facebook', label: 'facebook' }
        ]
        const { isEmailSaveShow, isUserNameSaveShow, isPasswordSaveShow } = this.state;
        return (
            <div>
                <div className='home_section profile_section setting_sec gap_padding_space pro_bg account_setting_sec' style={{ height: (isPassShow || isEmailShow || isUserNameShow ? "auto" : 'auto') }}>
                    <Container >
                        <Row className="justify-content-md-center">
                            <Col xs="12" lg="5" md="12" sm="12" >
                                <div className='acw_card_nav_images'>
                                    <div className='acw_card_logo'>
                                        <div className='acw_image1'>
                                            <a href='#'>
                                                <img src='../assets/images/acwlogo.png' />
                                            </a>
                                        </div>
                                        <div className='acw_vertical_line'></div>
                                        <div className='acw_image2'>
                                            <a href='#'>
                                                <img src='../assets/images/nfclogo.png' />
                                            </a>
                                        </div>
                                    </div>
                                    <div className='next_page'>
                                        <Link to={'/profile'}><FaUserEdit /></Link>
                                    </div>
                                </div>
                                <div className='common_title_part'>
                                    <p>Account Settings</p>
                                </div>
                                <div className='home_sec setting_sec' >
                                    <div>
                                        <div className='account_setting_part'>
                                            <div className='email_name_pass'>
                                                <div className='input_tick'>
                                                    <div className='left_form'>
                                                        <div class="input_design">
                                                            <label for="exampleFormControlInput1" class="form-label" >Email</label>
                                                            <input type="text" class="form-control" name='email' onChange={this.onChange} value={data.email} id="floatingInput" placeholder="Email" spellCheck="false" />
                                                        </div>
                                                    </div>
                                                    <div className='right_tick'>
                                                        {!isEmailSaveShow ?
                                                            <div className='img_size' onClick={(e) => this.submit('email')}>
                                                                <img src={disable_tick} alt='Not Found' />
                                                            </div>
                                                            :
                                                            <div className='img_size' onClick={(e) => this.submit('email')}>
                                                                <img src={active_tick} alt='Not Found' />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='error_msg_header'>
                                                    <div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Email is required</div>
                                                    <div className="invalid-feedback" style={{ display: this.state.emailValidError ? 'block' : 'none' }}>Enter valid email</div>
                                                </div>
                                                <div className='input_tick'>
                                                    <div className='left_form'>
                                                        <div class="input_design">
                                                            <label for="exampleFormControlInput1" class="form-label" >Username</label>
                                                            <input type="text" class="form-control" name='username' onChange={this.onChange} value={data.username} id="floatingInput" placeholder="Username" spellCheck="false" />
                                                        </div>
                                                    </div>
                                                    <div className='right_tick'>
                                                        {!isUserNameSaveShow ?
                                                            <div className='img_size' onClick={(e) => this.submit('username')}>
                                                                <img src={disable_tick} alt='Not Found' />
                                                            </div>
                                                            :
                                                            <div className='img_size' onClick={(e) => this.submit('username')}>
                                                                <img src={active_tick} alt='Not Found' />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='error_msg_header'>
                                                    <div className="invalid-feedback" style={{ display: this.state.usernameError ? "block" : 'none' }}>Username is required</div>
                                                </div>
                                                <div className='input_tick'>
                                                    <div className='left_form'>
                                                        <div class="input_design">
                                                            <label for="exampleFormControlInput1" class="form-label" >New Password</label>
                                                            <div className='pwd_icons'>
                                                                <input type={this.state.hidepassword ? "text" : "password"} class="form-control" name='password' onChange={this.onChange} value={data.password} id="floatingInput" autoCapitalize="none" placeholder="New Password" spellCheck="false" />
                                                                {
                                                                    (this.state.hidepassword)
                                                                        ?
                                                                        <BsFillEyeFill style={{ cursor: 'pointer' }} onClick={this.eyeClick} />
                                                                        :
                                                                        <BsFillEyeSlashFill style={{ cursor: 'pointer' }} onClick={this.eyeClick} />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div class="input_design">
                                                            <label for="exampleFormControlInput1" class="form-label" >Confirm Password</label>
                                                            <div className='pwd_icons'>
                                                                <input type={this.state.hidepasswordconfirm ? "text" : "password"} class="form-control" name='confirmpassword' onChange={this.onChange} value={data.confirmpassword} id="floatingInput" placeholder="Confirm Password" spellCheck="false" />

                                                                {
                                                                    (this.state.hidepasswordconfirm)
                                                                        ?
                                                                        <BsFillEyeFill style={{ cursor: 'pointer' }} onClick={this.eyeClickConfirm} />
                                                                        :
                                                                        <BsFillEyeSlashFill style={{ cursor: 'pointer' }} onClick={this.eyeClickConfirm} />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='right_tick'>
                                                        {!isPasswordSaveShow ?
                                                            <div className='img_size'>
                                                                <img src={disable_tick} alt='Not Found' />
                                                            </div>
                                                            :
                                                            <div className='img_size' onClick={(e) => this.submit('password')}>
                                                                <img src={active_tick} alt='Not Found' />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='error_msg_header'>
                                                    <div className="invalid-feedback" style={{ display: this.state.passwordValidError ? 'block' : 'none' }}>Password mismatch</div>
                                                    <div className="invalid-feedback" style={{ display: this.state.passwordError ? "block" : 'none' }}>Password is required</div>
                                                </div>
                                            </div>

                                            <div className='common_box_size'>
                                                <div className='brochure' onClick={this.Myhotelai}>
                                                    <div className='d-flex'>
                                                        <div className='imag_size'>
                                                            <img src={logo} width={30} height={30} data-toggle="tooltip" data-original-title="Avatar Name" alt="Not found" />
                                                        </div>
                                                        <div className='d-flex align-self-center' >
                                                            <p>MyHotel AI Brochure</p>
                                                        </div>
                                                    </div>
                                                    <div className='right_arrow'>
                                                        <AiOutlineCaretRight />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className='common_box_size'>
                                                <div className='dis_log' onClick={this.disable}>
                                                    <div className='d-flex'>
                                                        <div className='imag_size'>
                                                            <img src={disable_account} width={30} height={30} data-toggle="tooltip" data-original-title="Avatar Name" alt="Not found" />
                                                        </div>
                                                        <div className='d-flex align-self-center' >
                                                            <p>Disable</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className='common_box_size'>
                                                <div className='dis_log' onClick={this.preview}>
                                                    <div className='d-flex'>
                                                        <div className='imag_size'>
                                                            <img src={preview} width={30} height={30} data-toggle="tooltip" data-original-title="Avatar Name" alt="Not found" />
                                                        </div>
                                                        <div className='d-flex align-self-center' >
                                                            <p>Preview</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='common_box_size '>
                                                <div className='brochure bg-white' style={{ border: '1px solid #150B4F' }}>
                                                    <div className='d-flex'>
                                                        <div className='imag_size'>
                                                            <img src={cover_dis} width={30} height={30} data-toggle="tooltip" data-original-title="Avatar Name" alt="Not found" />
                                                        </div>
                                                        <div className='d-flex align-self-center' >
                                                            <p className='disp_p'>Display Profile </p>
                                                        </div>
                                                    </div>
                                                    <div className='right_arrow'>
                                                        <div className="d-flex">
                                                            <div className="profile_infosec d-flex align-items-center mt-0" style={{ boxShadow: "none" }} >
                                                                <label class="switch">
                                                                    <input type="checkbox" name="personalstatus" checked={profiledata.isProfileStatus} onChange={this.disableProfile} />
                                                                    <div class="slider round"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='common_box_size '>
                                                <div className='brochure bg-white' style={{ border: '1px solid #150B4F' }}>
                                                    <div className='d-flex'>
                                                        <div className='imag_size'>
                                                            <img src={banner_img} width={30} height={30} data-toggle="tooltip" data-original-title="Avatar Name" alt="Not found" />
                                                        </div>
                                                        <div className='d-flex align-self-center' >
                                                            <p className='disp_p'>Display cover picture </p>
                                                        </div>
                                                    </div>
                                                    <div className='right_arrow'>
                                                        <div className="d-flex">
                                                            <div className="profile_infosec d-flex align-items-center mt-0" style={{ boxShadow: "none" }} >
                                                                <label class="switch">
                                                                    <input type="checkbox" name="personalstatus" checked={profiledata.isCoverStatus} onChange={this.disableCoverPicture} />
                                                                    <div class="slider round"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='common_box_size '>
                                                <div className='brochure bg-white' onClick={this.onClickSwap} style={{ border: '1px solid #150B4F' }}>
                                                    <div className='d-flex'>
                                                        <div className='imag_size'>
                                                            <img src={swap_img} width={30} height={30} data-toggle="tooltip" data-original-title="Avatar Name" alt="Not found" />
                                                        </div>
                                                        <div className='d-flex align-self-center' >
                                                            <p className='disp_p'>Swap ID</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='common_box_size'>
                                                <div className='dis_log' onClick={(e) => this.Logout(e)}>
                                                    <div className='d-flex'>
                                                        <div className='imag_size'>
                                                            <img src={log_out} width={30} height={30} data-toggle="tooltip" data-original-title="Avatar Name" alt="Not found" />
                                                        </div>
                                                        <div className='d-flex align-self-center' >
                                                            <p>Logout</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {(this.state.updateSpinner) ?
                    <div className='common_loader_ag_grid'>
                        <img className='loader_img_style_common_ag_grid' src='/assets/images/logo.jpg' />
                        <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                        </Spinner>
                    </div>
                    : ""}
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    ProfileState: state.profile,
    appState: state.app,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleInputChange: AC_HANDLE_INPUT_CHANGE_PROFILE,
        ListApp: AC_LIST_APP,
        ViewProfile: AC_VIEW_PROFILE,
        HandleChangeBroche: AC_HANDLE_INPUT_CHANGE_BROCHURE,
        ListUser: AC_LIST_PROFILE,

    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Settings);