import React, { useState } from 'react'
import { Row, Tab, Tabs, Col, Container, Button, Card, TabContent, TabPane } from 'react-bootstrap';
import { BiDollar } from 'react-icons/bi';
import { Redirect } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { Link } from 'react-router-dom';

export default function Digitalcard() {
    const [activeTab, setActiveTab] = useState('tab1');
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
    const scrollEnquiry = () => {
        let formEnquiry = document.getElementById("enquiry-form-data");
        if (formEnquiry) {
            formEnquiry.scrollIntoView()
        }
    }
    const [redirect, setRedirect] = useState(false);

    const handleClick = () => {
        // history.push("/detail");
        setRedirect(true)
    }
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 500 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 500, min: 0 },
            items: 1
        }
    };
    const responsivepvc = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div>
            {redirect && <Redirect to="/detail" />}
            <div className='section'>
                <div className='product_section digitalia_sec'>
                    <Container className='container-new-big'>
                        <h2>Pick Your Card </h2>
                        <div className='tab_1'>
                                    <div className='tab_inner_page mt-5'>
                                        <div className='carouisel_row1'> 
                                            <Carousel responsive={responsive} infinite={true}>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="landing_page/assets/images/card/digitalcard01.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>White <br/> ACW Card</h5>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    {/* <p className='cost_amo'> <BiDollar />35 </p> */}
                                                                </div>
                                                            </div>
                                                            <Link to='/abouts/features'>
                                                                <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            </Link>
                                                            {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                            <Link to='/details/enquiry-form'>
                                                                <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                            </Link>

                                                        </Card.Body>
                                                    </Card>

                                                    {/* === */}
                                                    <Card>
                                                        <Card.Img variant="top" src="landing_page/assets/images/card/green.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Green <br/> ACW Card</h5>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    {/* <p className='cost_amo'> <BiDollar />35 </p> */}
                                                                </div>
                                                            </div>
                                                            <Link to='/abouts/features'>
                                                                <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            </Link>
                                                            {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                            <Link to='/details/enquiry-form'>
                                                                <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                            </Link>

                                                        </Card.Body>
                                                    </Card>
                                                    {/* === */}
                                                </div>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="landing_page/assets/images/card/digitalcard02.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>VIP black premium <br/> ACW Card </h5>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    {/* <p className='cost_amo'> <BiDollar />35 </p> */}
                                                                </div>
                                                            </div>
                                                            <Link to='/abouts/features'>
                                                                <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            </Link>
                                                            {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                            <Link to='/details/enquiry-form'>
                                                                <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                            </Link>

                                                        </Card.Body>
                                                    </Card>
                                                    <Card>
                                                        <Card.Img variant="top" src="landing_page/assets/images/card/blue.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Blue <br/> ACW Card </h5>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    {/* <p className='cost_amo'> <BiDollar />35 </p> */}
                                                                </div>
                                                            </div>
                                                            <Link to='/abouts/features'>
                                                                <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            </Link>
                                                            {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                            <Link to='/details/enquiry-form'>
                                                                <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                            </Link>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="landing_page/assets/images/card/digitalcard03.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Silver <br/> ACW Card</h5>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    {/* <p className='cost_amo'> <BiDollar />35 </p> */}
                                                                </div>
                                                            </div>
                                                            <Link to='/abouts/features'>
                                                                <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            </Link>
                                                            {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                            <Link to='/details/enquiry-form'>
                                                                <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                            </Link>

                                                        </Card.Body>
                                                    </Card>
                                                    <Card>
                                                        <Card.Img variant="top" src="landing_page/assets/images/card/digitalcard09.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black <br/> ACW Card</h5>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    {/* <p className='cost_amo'> <BiDollar />35 </p> */}
                                                                </div>
                                                            </div>
                                                            <Link to='/abouts/features'>
                                                                <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            </Link>
                                                            {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                            <Link to='/details/enquiry-form'>
                                                                <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                            </Link>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="landing_page/assets/images/card/digitalcard04.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Silver <br/> ACW Card</h5>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    {/* <p className='cost_amo'> <BiDollar />35 </p> */}
                                                                </div>
                                                            </div>
                                                            <Link to='/abouts/features'>
                                                                <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            </Link>
                                                            {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                            <Link to='/details/enquiry-form'>
                                                                <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                            </Link>

                                                        </Card.Body>
                                                    </Card>
                                                    <Card>
                                                        <Card.Img variant="top" src="landing_page/assets/images/card/digitalcard10.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Brown <br/> ACW Card</h5>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    {/* <p className='cost_amo'> <BiDollar />35 </p> */}
                                                                </div>
                                                            </div>
                                                            <Link to='/abouts/features'>
                                                                <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            </Link>
                                                            {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                            <Link to='/details/enquiry-form'>
                                                                <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                            </Link>

                                                        </Card.Body>
                                                    </Card>
                                                </div>

                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="landing_page/assets/images/card/digitalcard05.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Light blue <br/> ACW Card</h5>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    {/* <p className='cost_amo'> <BiDollar />35 </p> */}
                                                                </div>
                                                            </div>
                                                            <Link to='/abouts/features'>
                                                                <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            </Link>
                                                            {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                            <Link to='/details/enquiry-form'>
                                                                <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                            </Link>

                                                        </Card.Body>
                                                    </Card>
                                                    <Card>
                                                        <Card.Img variant="top" src="landing_page/assets/images/card/red.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Red <br/> ACW Card</h5>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    {/* <p className='cost_amo'> <BiDollar />35 </p> */}
                                                                </div>
                                                            </div>
                                                            <Link to='/abouts/features'>
                                                                <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            </Link>
                                                            {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                            <Link to='/details/enquiry-form'>
                                                                <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                            </Link>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="landing_page/assets/images/card/digitalcard03.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                <h5>Silver <br/> ACW Card</h5>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    {/* <p className='cost_amo'> <BiDollar />35 </p> */}
                                                                </div>
                                                            </div>
                                                            <Link to='/abouts/features'>
                                                                <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            </Link>
                                                            {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                            <Link to='/details/enquiry-form'>
                                                                <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                            </Link>

                                                        </Card.Body>
                                                    </Card>
                                                    <Card>
                                                        <Card.Img variant="top" src="landing_page/assets/images/card/blue.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                <h5>Blue  <br/> ACW Card</h5>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    {/* <p className='cost_amo'> <BiDollar />35 </p> */}
                                                                </div>
                                                            </div>
                                                            <Link to='/abouts/features'>
                                                                <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            </Link>
                                                            {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                            <Link to='/details/enquiry-form'>
                                                                <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                            </Link>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="landing_page/assets/images/card/digitalcard07.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Grey <br/> ACW Card</h5>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    {/* <p className='cost_amo'> <BiDollar />35 </p> */}
                                                                </div>
                                                            </div>
                                                            <Link to='/abouts/features'>
                                                                <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            </Link>
                                                            <Link to='/details/enquiry-form'>
                                                                <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                            </Link>

                                                        </Card.Body>
                                                    </Card>
                                                    <Card>
                                                        <Card.Img variant="top" src="landing_page/assets/images/card/darkblue.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Darkblue <br/> ACW Card</h5>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    {/* <p className='cost_amo'> <BiDollar />35 </p> */}
                                                                </div>
                                                            </div>
                                                            <Link to='/abouts/features'>
                                                                <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            </Link>
                                                            <Link to='/details/enquiry-form'>
                                                                <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                            </Link>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Carousel>
                                        </div>
                                    </div>
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    )
}
