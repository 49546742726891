import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { AiOutlineAppstore } from "react-icons/ai";
import { useParams } from "react-router";
import { AC_EMPTY_USER, AC_HANDLE_INPUT_CHANGE_PROFILE, AC_USER_SPINNER, AC_VIEW_USER } from "../../actions/profileAction";
import ImportedURL from "../../common/api";
import moment from "moment";
import { Capitalize } from "../../common/validate";
import { AC_EMPTY_COMPANY, AC_SPINNER_COMPANY, AC_VIEW_COMPANY } from "../../actions/companyAction";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class ViewCompany extends React.Component {
    componentDidMount() {
        this.props.EmptyCompany()
        const { params, path } = this.props.match;
        if (params.id) {
            this.props.SpinnerCompany();
            this.props.ViewCompany(params.id);
            this.setState({ modalType: path === "/view-user/:id" ? "View" : "Edit", id: params.id })
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    render() {
        const { companyState, AccountState } = this.props;
        const data = companyState.company
        const spinner = companyState.spinner
        const role = AccountState.role;
        return (
            <>
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-12 breadcome_value">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item header_color_breadcome"> <Link to='/admin'>Dasboard</Link></li>
                                    {role == 'admin' ? <li class="breadcrumb-item active"><Link to='/admin/list-company'>List Company</Link></li> : ''}
                                    <li class="breadcrumb-item active">View Company</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="col-md-12">
                        <div class="card card-primary header_border" >
                            <div class="table-title">
                                <div className="card-header">
                                    <h3 className="card-title d-flex "> <div className='rounded_icon'>  <i className="fa fa-building-o" /></div><h2 class="card-title header_title">VIEW COMPANY</h2> </h3>
                                    {
                                        (role == 'admin')
                                            ?
                                            <div className="card-options">
                                                <div className="d-flex justify-content-end">
                                                    <div className="header-action mr-2">
                                                        <Link to='/admin/list-company'><button type="button" className="btn btn-primary button_color" id='Add'> <i className="fa fa-arrow-left mr-2" id='Add' />Back</button></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    }
                                </div>
                                <form id="quickForm" autoComplete="off">
                                    <div class="card-body">
                                        <div className="row">
                                            {
                                                role == 'admin'
                                                    ?
                                                    <>
                                                        <div className="col-3">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">Category</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-2">:</div>
                                                        <div className="col-7">
                                                            <div className="form-group">
                                                                {data.categoryName ? data.categoryName : '---'}
                                                            </div>
                                                        </div>
                                                    </>
                                                    : ''
                                            }
                                            <div className="col-3" style={{ paddingTop: '15px' }}>
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Logo</label>
                                                </div>
                                            </div>
                                            <div className="col-2" style={{ paddingTop: '15px' }}>:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    <a href={ImportedURL.LIVEURL + data.image} target="_blank" style={{ color: 'black', textDecoration: 'none' }}>
                                                        <img src={data.image ? ImportedURL.LIVEURL + data.image : ImportedURL.LIVEURL + 'public/assets/images/notfoundimage.jpg'} width={60} height={60} />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Company Name</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    {data.name ? data.name : '---'}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Email</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    {data.email ? data.email : '---'}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Username</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    {data.username ? data.username : '---'}
                                                </div>
                                            </div>
                                            {/* {
                                                role == 'admin'
                                                    ?
                                                    <>
                                                        <div className="col-3">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">Password</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-2">:</div>
                                                        <div className="col-7">
                                                            <div className="form-group">
                                                                {data.password ? data.password : '---'}
                                                            </div>
                                                        </div>
                                                    </>
                                                    : ''
                                            } */}
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Phone Number</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    {data.phonenumber ? data.phonenumber : '---'}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Company ID</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    {data.companytype ? data.companytype : '---'}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Address</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    {data.address ? data.address : '---'}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">City</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    {data.city ? data.city : '---'}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">State</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    {data.state ? data.state : '---'}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Postcode</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    {data.postcode ? data.postcode : '---'}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Country</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    {data.country ? data.country : '---'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div >
                </div >
                {
                    spinner ?
                        <div className='common_loader_ag_grid'>
                            < img className='loader_img_style_common_ag_grid' src='/assets/images/logo.jpg' />
                            <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                            </Spinner>
                        </div >
                        : ""
                }
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        AccountState: state.account,
        companyState: state.company,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ViewCompany: AC_VIEW_COMPANY,
        SpinnerCompany: AC_SPINNER_COMPANY,
        EmptyCompany: AC_EMPTY_COMPANY,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withParams(ViewCompany));