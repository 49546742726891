"use client";
import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Modal, Ratio } from 'react-bootstrap';
import Slider from 'react-slick';
import { GrFormNext, GrFormPrevious } from "react-icons/gr";


function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Acw  Card Introducing Video
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ width: '100%', height: 'auto' }}>
                    <Ratio aspectRatio="16x9">
                        <video src="../videos/about.mov"
                            controls autoPlay muted={props.muted} height="480" width="640" id="video1">
                        </video>
                    </Ratio>
                </div>
            </Modal.Body>
        </Modal>
    );
}


function MyVerticallyCenteredModal2(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Acw  Card Introducing Video 2
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ width: '100%', height: 'auto' }}>
                    <Ratio aspectRatio="16x9">
                        <video src="../videos/about.mov"
                            controls autoPlay muted={props.muted} height="480" width="640" id="video2">
                        </video>
                    </Ratio>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default function Works() {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    const [modalShow, setModalShow] = React.useState(false);
    const [modalShow2, setModalShow2] = React.useState(false);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        loop: true,
        nextArrow: <GrFormNext />,
        prevArrow: <GrFormPrevious />,

        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true,
                },
            },

            {
                breakpoint: 997,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    slidesToScroll: 1,
                    arrows: true,
                },
            },
        ],
    };


    return (
        <div className='works_section section' id="work">
            <Container className='container-new-big'>
                <h2>
                    How Does It Work
                </h2>
                <p>
                    Our Best custom <strong> NFC business cards </strong> are as breezy as sharing a handshake. Here is how you can share your profile in a single tap.
                </p>
                <div className='video_part'>
                    <img src='./landing_page/assets/images/videoimage.png' onClick={() => setModalShow(true)} alt="videoimage" />
                    <MyVerticallyCenteredModal
                        show={modalShow}
                        muted={false}
                        onHide={() => setModalShow(false)}
                    />
                </div>
            </Container>
        </div>
    )
}
