import React, { useEffect, useRef } from "react";
import About from './about';
import Aboutlogin from './aboutlogin';
import Ready from './ready';
import Products from './products';
import Aboutbulk from './aboutbulk';
import { useParams } from 'react-router-dom';

export default function Aboutindex() {
    const { id } = useParams()
    const featureDivRef = useRef();
    useEffect(() => {
        if (featureDivRef.current && id) {
            featureDivRef.current.scrollIntoView()
        }
    }, []);
    return (
        <div>
            <>
                <About featureDivRef={featureDivRef} />
                <Aboutlogin />
                <Ready />
                <Products />
                <Aboutbulk />
            </>
        </div>
    )
}
