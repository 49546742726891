import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Container, Col, Spinner, Dropdown, Button, ButtonGroup } from "react-bootstrap";
import { AiFillCamera, AiOutlinePlus, AiOutlineSetting, AiOutlinePhone, } from "react-icons/ai";
import { RiImageAddFill } from "react-icons/ri";
import { BiEdit, BiMap } from 'react-icons/bi';
import { MdDeleteOutline } from "react-icons/md";
import Select from "react-select";
import Swal from "sweetalert2";
import { FaUserEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BsEmojiHeartEyes, BsEyeFill } from "react-icons/bs";


import {
    AC_HANDLE_INPUT_CHANGE_PROFILE,
    AC_HANDLE_INPUT_CHANGE_SETTINGS,
    AC_PROFILE_SPINNER,
    AC_VIEW_PROFILE,
} from "../../actions/profileAction";
import ImportedURL from "../../common/api";
import {
    Emailvalidate,
    FileAndImagevalidation,
    GetAge,
    Imagevalidation,
    Phonenumber,
    Urlvalidate,
    dataURLtoFile,
} from "../../common/validate";
import { Success, Error } from "../../common/swal";
import { AC_LIST_APP } from "../../actions/appAction";
import CountryCodeJson from "../../common/countrycode.json";
import DatePicker, { Calendar } from "react-multi-date-picker";
import { addYears } from 'date-fns';
import moment from "moment";
import { Modal } from "react-bootstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Buffer } from "buffer";
import active_tick from "../images/v2/active_tick.png";
import disable_tick from "../images/v2/disable_tick.png";
import { AC_EMPTY_BUSINESS, AC_HANDLE_INPUT_CHANGE_BUSINESS, AC_LIST_BUSINESSESS, AC_LIST_PERSONAL, } from "../../actions/businessAction";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import profile from "../images/v2/profile.png";
import edit from "../images/v2/edit.png";
import bnotfound from "../images/v2/b-not-found.png";
import personal_log from "../images/v2/personal_log.png";
import { HiRefresh } from "react-icons/hi";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// Latest version - v3.0.0 with Tree Shaking to reduce bundle size
import { Country, State, City } from 'country-state-city';
import zipcodes from 'zipcodes';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'
import parsePhoneNumber from 'libphonenumber-js'
import { GoogleMap, LoadScript, Autocomplete } from '@react-google-maps/api';
import Autocomplete1 from 'react-autocomplete';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import banner from '../images/v2/acwprofilebg.png'
import userlogo from '../images/v2/user.png'
import { MdFileOpen } from "react-icons/md";

const crop = {
    unit: "px",
    x: 130,
    y: 50,
};

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 2
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
    }
};

class Preview extends Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
        this.fileInputBanner = React.createRef();
        this.fileInputBusinesssLogo = React.createRef();
        this.state = {
            bgimage: "",
            profileimg: "",
            skill: "",
            socialmediaSelect: {},
            socialmediaValue: [],
            urlError: false,
            emailError: false,
            updateSpinner: false,
            phoneNoPatternError: false,
            busPhoneNoPatternError: false,
            phoneNoPatternArrayError: false,
            name: "React",
            src: null,
            crop: {
                unit: "px",
                x: 130,
                y: 50,
                width: 200,
                height: 200,
            },
            croppedImageUrl: null,
            showModal: false,
            typeImage: "",
            onChangeHeight: false,
            profileImage: "",
            isUserdDisplayNameSaveShow: false,
            isUserdHeadlineSaveShow: false,
            isHeadlineSaveShow: false,
            bussinesslogoSrc: "",
            bussinesslogoError: false,
            isBusinessPreview: false,
            businessPreviewId: "",
            designationError: false,
            workError: false,
            businessphonenumberError: false,
            collapsed: false,
            showModal1: false,

            streetBord: false,
            cityBord: false,
            stateBord: false,
            zipBord: false,
            countryBord: false,
            isClear: false,
            isClearUrl: false,
            isClearNumber: false,
            isClearEmail: false,
            isCoseModel: false,
            isCoseModel1: false,
            isShowSwal: true,
            apiKey: 'AIzaSyA2FJmWEkxtZDw3E8thlgXRQIU1sPMJwKU',
            scriptLoaded: false,
            no: '',
            street: '',
            city: '',
            state: '',
            country: '',
            zip: '',

            businessBtnChange: false,
            personalBtnChange: false,
            isEducation: false,
            viewsCount: '',


            Country: Country.getAllCountries(),
            State: State.getAllStates(),
            City: City.getAllCities(),
        };
        this.containerRef = React.createRef();
    }

    async componentDidMount() {
        document.title = "ACW CARD - Profile";
        document.description =
            "ACW Card enables you to share your business and personal profiles along with digital uploads of key documents to strengthen your portfolio.";
        let search = this.props.location.search.split('=');
        let val = search.pop();
        await axios.get(ImportedURL.API.listTapSeen, { params: { tapseens: val } })
            .then((res) => {
                if (res && res.data) {
                    if (res.data.length > 0) {
                        this.setState({ viewsCount: res.data.length });
                    }
                }
            }).catch(err => console.log(err))
        this.props.SpinnerProfile();
        this.props.ViewProfile({ reverseStatus: true });
        this.props.ListBusiness();
        this.props.ListPersonal();
        await this.props.ListApp();


    }

    businessPreview = (id) => {
        this.setState({ isBusinessPreview: true, businessPreviewId: id });
    };


    render() {
        if (this.state.isBusinessPreview)
            return <Redirect to={"/viewpreview/" + this.state.businessPreviewId} />;
        const { viewsCount, } = this.state;
        const { socialmediaSelect } = this.state;
        const { ProfileState, appState, BusinessState } = this.props;
        const listapp = appState.listApp;
        const bData = BusinessState.bussiness;

        const listBusinessData = BusinessState.listBusiness;
        const listPersonalData = BusinessState.listPersonal;

        listapp?.sort((a, b) => Number(a.sortorder) - Number(b.sortorder));
        const spinner = ProfileState.spinner;
        const data = ProfileState.profile;

        const dobDate = data.dob ? moment(data.dob).format("MM-DD-YYYY") : "";
        const selectOption = [];
        if (listapp && listapp.length > 0) {
            listapp.filter((e) => e.status == true).map((app) => {
                app.typesList && app.typesList.length > 0 &&
                    app.typesList.map((item) => {
                        selectOption.push({
                            label: item.appname,
                            value: item.appname,
                            logo: item.logo,
                            id: item.id,
                            inputtype: item.inputtype,
                        });
                    });
            });
        }

        const bloodgroupoptions = [
            { value: 'A positive', label: 'A positive', name: 'bloodgroup' },
            { value: 'A negative', label: 'A negative', name: 'bloodgroup' },
            { value: 'AB positive', label: 'AB positive', name: 'bloodgroup' },
            { value: 'AB negative', label: 'AB negative', name: 'bloodgroup' },
            { value: 'B Positive', label: 'B Positive', name: 'bloodgroup' },
            { value: 'B negative', label: 'B negative', name: 'bloodgroup' },
            { value: 'O positive', label: 'O positive', name: 'bloodgroup' },
            { value: 'O Negative', label: 'O Negative', name: 'bloodgroup' },
        ]
        let bloodgroupVal;
        if (bloodgroupoptions && bloodgroupoptions.length > 0) {
            bloodgroupoptions.map((item => {
                if (data.bloodgroup == item.value) {
                    bloodgroupVal = { value: item.value, label: item.label, name: 'bloodgroup' }
                }
            }))
        }

        const { streetBord, cityBord, stateBord, zipBord, countryBord, isClear, isClearUrl, isClearNumber, isClearEmail, isCoseModel, isCoseModel1, Country, State, City, apiKey, scriptLoaded, businessBtnChange, personalBtnChange, isEducation } = this.state;

        const currentDate = new Date();
        const fifteenYearsAgo = new Date();
        fifteenYearsAgo.setFullYear(currentDate.getFullYear() - 15);

        // ========== Preview changes 
        let locationData = {}
        if (listPersonalData && listPersonalData.length > 0) {
            listPersonalData.map(data => {
                locationData = data && data.address ? data.address.address : ''
            })
        }
        if (listPersonalData.length === 0 && (listBusinessData && listBusinessData.length === 1)) {
            listBusinessData.map(data => {
                locationData = data && data.address ? data.address.address : ''
            })
        }

        let phoneShowInPersonal = ''
        if (listPersonalData && listPersonalData.length > 0) {
            listPersonalData.map(data => {
                let call = data && data.call
                if (call && call.length != 0) {
                    phoneShowInPersonal = (call.cc && call.cc.value) + ' ' + call.value
                }
            })
        }
        if (listPersonalData.length === 0 && (listBusinessData && listBusinessData.length === 1)) {
            listBusinessData.map(data => {
                let call = data && data.call
                if (call && call.length != 0) {
                    phoneShowInPersonal = (call.cc && call.cc.value) + ' ' + call.value
                }
            })
        }

        let displayLoc = ''
        if (locationData) {
            displayLoc = ((locationData && locationData.street) ? (locationData.street).replace(/,/g, "") + ',' : "") + (locationData.city ? (locationData.city).replace(/,/g, "") + ',' : "") + (locationData.state ? (locationData.state).replace(/,/g, "") + ',' : "") + (locationData.country ? (locationData.country).replace(/,/g, "") + ',' + ' ' : "") + (locationData.zip ? "- " + locationData.zip : "")
            var mapUrlDisplay = `https://www.google.com/maps/place/${encodeURIComponent(displayLoc)}`;
        }
        return (
            <>
                <div style={{ position: "relative" }}>
                    <div className="home_section profile_section gap_padding_space pro_bg">
                        <Container >
                            <Row className="justify-content-md-center">
                                <Col xs="12" lg="5" md="12" sm="12">
                                    <div className="acw_card_nav_images">
                                        <div className="acw_card_logo">
                                            <div className="acw_image1">
                                                {/* <Link to="/"> */}
                                                <img src="../assets/images/acwlogo.png" />
                                                {/* </Link> */}
                                            </div>
                                            <div className="acw_vertical_line"></div>
                                            <div className="acw_image2">
                                                {/* <Link to="/"> */}
                                                <img src="../assets/images/nfclogo.png" />
                                                {/* </Link> */}
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="acw_card_setting">
                                                <Link style={{ color: "#fff" }} to="/profile">
                                                    <FaUserEdit className="acoount_setting" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="profile_header_text d-flex"
                                        style={{
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div className="d-flex align-items-center">
                                            <p className="back_text mb-0">Preview</p>
                                        </div>
                                    </div>
                                    <div className="home_sec">
                                        <div className='account_profile_sec'>
                                            <div className='account_profile'>
                                                <div className='tap_banner_img'>
                                                    <div className='img_size'>
                                                        {
                                                            ((this.state.bgimage || data.banner) && data.isCoverStatus) ?
                                                                <img src={this.state.bgimage ? URL.createObjectURL(this.state.bgimage) : ImportedURL.LIVEURL + data.banner} alt='' />
                                                                : <img className="avatar" src={banner} data-toggle="tooltip" data-original-title="Avatar Name" alt="" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='address_img'>
                                                    <div className='row'>
                                                        <div className='col-8'>
                                                            <div className='address'>
                                                                <h6>{data.displayname ? data.displayname : '---'} </h6>
                                                                <span>{data.headline ? data.headline : ''}</span>
                                                            </div>
                                                            <div className='d-flex '>
                                                                <div className='icon'>
                                                                    {
                                                                        displayLoc
                                                                            ?
                                                                            <a href={mapUrlDisplay} target="_blank" >
                                                                                <BiMap className='display_name_map' />
                                                                            </a>
                                                                            : ""
                                                                    }
                                                                </div>
                                                                <div className='content'>
                                                                    {
                                                                        displayLoc
                                                                            ?
                                                                            <a href={mapUrlDisplay} target="_blank" style={{ color: 'black', textDecoration: 'none' }}>
                                                                                <span>{locationData ? locationData.street : ''}</span><br></br>
                                                                                <span>{locationData ? locationData.city + ', ' + locationData.state + ' ' + locationData.zip : ''}</span><br></br>
                                                                                <span>{locationData ? locationData.country : ''}</span>
                                                                                {/* {displayLoc}     */}
                                                                            </a>
                                                                            : ""
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className='d-flex '>
                                                                {
                                                                    phoneShowInPersonal
                                                                    &&
                                                                    <>
                                                                        <a href={"tel:" + phoneShowInPersonal} target="_blank" >
                                                                            <AiOutlinePhone className='display_name_profile' />
                                                                        </a>
                                                                        <p>
                                                                            <a href={"tel:" + phoneShowInPersonal} target="_blank" className='viewunique_call'>
                                                                                {phoneShowInPersonal}
                                                                            </a>
                                                                        </p>
                                                                    </>

                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='col-4'>
                                                            <div className='right_img'>
                                                                {(this.state.profileimg || data.image) && data.isProfileStatus ?
                                                                    <>
                                                                        <img src={this.state.profileimg ? URL.createObjectURL(this.state.profileimg) : ImportedURL.LIVEURL + data.image} />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <img src={userlogo} />
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className="d-flex justify-content-center">
                                                                <div className="viewers_list">
                                                                    <div className="d-flex">
                                                                        <div className="eye"><BsEyeFill /></div>
                                                                        <div className="count"> : {viewsCount}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className='view_count'>
                                                                <div className='eye_icon'>
                                                                    <div class="circle"><BsEmojiHeartEyes /></div>
                                                                    <div className='text'>Views : {viewsCount}  </div>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='skill_section'>
                                                    {
                                                        (data.skill && data.skill.length > 0)
                                                        &&
                                                        data.skill.map((item, i) => {
                                                            return (
                                                                <>
                                                                    <div className='skill_btn'>
                                                                        {item}
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                        {/* ============== Personal Detaile =========== */}
                                        {listPersonalData && listPersonalData.length > 0 &&
                                            listPersonalData.filter(e => e.status == true).map(personal => {
                                                return <div className='tap_bussiness_details'>
                                                    <div className='our_budiness_part'>
                                                        <h5>Personal Details</h5>
                                                        <div className='buss_btns' onClick={() => this.businessPreview(personal._id)}>
                                                            <div className='img_size' >
                                                                <img src={personal_log} alt='' />
                                                            </div>
                                                            <div className='d-flex align-items-center' style={{ cursor: 'pointer' }}>
                                                                <span>Personal Details</span>
                                                            </div>
                                                            <div className='file_open '>
                                                                <MdFileOpen />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            })
                                        }
                                        {/* ============= Our Business ===================== */}
                                        {listBusinessData && listBusinessData.length > 0 &&
                                            <div className='tap_bussiness_details'>
                                                <div className='our_budiness_part'>
                                                    <h5>Our Business</h5>
                                                    {listBusinessData && listBusinessData.length > 0 ?
                                                        listBusinessData.filter(e => e.status == true).map(business => {
                                                            return <div className='buss_btns' onClick={() => this.businessPreview(business._id)}>
                                                                <div className='img_size' >
                                                                    <img src={business.image ? ImportedURL.FILEURL + business.image : bnotfound} alt='' />
                                                                </div>
                                                                <div className='d-flex align-items-center' style={{ cursor: 'pointer' }}>
                                                                    <span>{business.work}</span>
                                                                </div>
                                                                <div className='file_open '>
                                                                    <MdFileOpen />
                                                                </div>
                                                            </div>
                                                        })
                                                        :
                                                        <>
                                                            <div className='nothing_here_image'>
                                                                <img src="../assets/images/nothing-here.png" />
                                                            </div>
                                                            <div className='nothing_here_text'> No record to display</div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <div className="crop_model_section">
                                <Modal className={"info-modal crop_modal"} show={this.state.showModal} style={{ display: this.state.showModal ? 'block' : 'none' }}>
                                    <div className="modal-header">
                                        <Modal.Title>{"Crop Image"}</Modal.Title>
                                        <button type="button" id="closeModal" onClick={this.ModalImages} className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                                <img src="../../assets/images/cancel.png" />
                                            </span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="crop_Images">
                                            {this.state.src && (
                                                <ReactCrop
                                                    src={this.state.src}
                                                    crop={this.state.crop}
                                                    onImageLoaded={this.onImageLoaded}
                                                    onComplete={this.onCropComplete}
                                                    onChange={this.onCropChange}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <div className="">
                                            <button
                                                type="button"
                                                class="btn btn-secondary"
                                                data-bs-dismiss="modal"
                                                onClick={this.ModalImages}
                                            >
                                                Close
                                            </button>
                                        </div>
                                        <div className="profile_save_btn">
                                            <button type="button" class="btn" onClick={this.CropImages}>
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </Container>
                    </div >

                    {spinner || this.state.updateSpinner ? (
                        <div
                            style={{
                                height: "100vh",
                                position: "fixed",
                                width: "100%",
                                top: "0px",
                            }}
                        >
                            <div style={{ position: "relative" }}></div>
                            <div className="common_loader">
                                <img
                                    className="loader_img_style_common"
                                    src="/assets/images/logo.jpg"
                                />
                                <Spinner
                                    className="spinner_load_common"
                                    animation="border"
                                    variant="info"
                                ></Spinner>
                            </div>
                        </div>
                    ) : (
                        ""
                    )
                    }
                </div >
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    accountState: state.account,
    ProfileState: state.profile,
    BusinessState: state.business,
    appState: state.app,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            HandleInputChange: AC_HANDLE_INPUT_CHANGE_PROFILE,
            HandleInputBusinessChange: AC_HANDLE_INPUT_CHANGE_BUSINESS,
            ListBusiness: AC_LIST_BUSINESSESS,
            ListApp: AC_LIST_APP,
            ViewProfile: AC_VIEW_PROFILE,
            SpinnerProfile: AC_PROFILE_SPINNER,
            HandleChangesSetting: AC_HANDLE_INPUT_CHANGE_SETTINGS,
            EmptyBusiness: AC_EMPTY_BUSINESS,
            // === PERSONAL
            ListPersonal: AC_LIST_PERSONAL,
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Preview);
