import React, { Component } from 'react'
import { Row, Container, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link, Redirect } from "react-router-dom";
import { browserHistory, Router, Route } from 'react-router';
import { withRouter } from 'react-router-dom';

import introJs from 'intro.js';
import 'intro.js/introjs.css';
// import 'intro.js/introjs.js';

class Brochure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            skip: false,
            profile: false,
            time: 10,
            setting: false,
            currentStep: 0
        }

    }
    componentDidMount() {
        const { history } = this.props;
        if (history.location && history.location.pathname && history.location.pathname == '/brochure') {
            const intro = introJs();
            intro.setOptions({
                tooltipClass: 'my-tooltip-class',
                steps: [
                    {
                        intro: '<img src="./assets/images/brochure_01.png" alt="">',
                        position: 'top'
                    },
                    {
                        intro: '<img  src="./assets/images/brochure_02.png" alt="">',
                        position: 'top'
                    },
                    {
                        intro: '<img  src="./assets/images/brochure_03.jpg" alt="">',
                        position: 'top'
                    },
                    {
                        intro: '<img  src="./assets/images/brochure_04.jpg" alt="">',
                        position: 'top'
                    },
                    {
                        intro: '<img  src="./assets/images/brochure_05.jpg" alt="">',
                        position: 'top'
                    },
                ],
                buttons: [
                    {
                        text: 'Next',
                        className: 'my-next-button',
                        onClick: function () {
                            introJs().next();
                        }
                    },
                    {
                        text: 'Back',
                        className: 'my-back-button',
                        onClick: function () {
                            introJs().previous();
                        }
                    }
                ],
                positionPrecedence: ['top', 'bottom', 'left', 'right'],
                nextLabel: 'Next',
                prevLabel: 'Back',
                // skipLabel: 'Skip',
                doneLabel: 'Done',
                showButtons: true,
                showStepNumbers: false,
                nextTooltipPosition: 'top',
                prevTooltipPosition: 'top',
                autoNext: true, // enable auto next
                autoNextTimeout: 1000, // auto next timeout (in milliseconds)
                exitOnOverlayClick: false,
                overlayOpacity: 0,
                showSkip: false,
                showSkipButton: false,
                keyboardNavigation: false,
                autoplay: true,
                onExit: () => {
                    this.handleExit(); // call another function
                },
            });
            intro.start();
            intro.oncomplete(function () {
                window.location.href = "/profile";
            });
            intro.onexit(function () {
                window.location.href = "/profile";
            });
            this.timer = setInterval(() => {
                if (intro._currentStep < intro._options.steps.length - 1) {
                    intro.nextStep();
                    this.setState({ currentStep: intro._currentStep });
                } else {
                    clearInterval(this.timer);
                }
            }, 10000);
        }
    }

    render() {
        if (this.state.profile) return <Redirect to={'/profile'} />
        if (this.state.setting) return <Redirect to={'/accountsetting'} />

        const { images } = this.state;


        return (
            <div>

                <div className='home_section profile_section gap_padding_space pro_bg'>
                    {/* <Container>
                        <Row className="justify-content-md-center">
                            <Col xs="12" lg="5" md="12" sm="12">
                                <OwlCarousel items={1}
                                    className="owl-theme carouse_one owl-carousel"
                                    loop
                                    autoplayTimeout={10000}
                                    dots={false}
                                    autoplay={true}
                                    margin={8} >
                                    <div class="item Brochure_button_header" style={{ position: 'relative' }}>
                                        <img className="img" src={'./assets/images/brochure_01.jpg'} />
                                        <div class="Brochure_button">
                                            <button type="button" class="shopnow_btn btn btn-primary" onClick={this.skipBrochure}>{count}</button>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <img className="img" src={'./assets/images/brochure_02.jpg'} />
                                        <div class="Brochure_button">
                                            <button type="button" class="shopnow_btn btn btn-primary" onClick={this.skipBrochure}>{count}</button>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <img className="img" src={'./assets/images/brochure_03.jpg'} />
                                        <div class="color_duf_btn" >
                                            <button type="button" class="  shopnow_btn btn btn-primary " onClick={this.skipBrochure}>{count}</button>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <img className="img" src={'./assets/images/brochure_04.jpg'} />
                                        <div class="Brochure_button_left">
                                            <button type="button" class="shopnow_btn btn btn-primary" onClick={this.skipBrochure}>{count}</button>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <img className="img" src={'./assets/images/brochure_05.jpg'} />
                                        <div class="Brochure_button">
                                            <button type="button" class="shopnow_btn btn btn-primary" onClick={this.skipBrochure}>{count}</button>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <img className="img" src={'./assets/images/brochure_06.jpg'} />
                                        <div class="Brochure_button">
                                            <button type="button" class="shopnow_btn btn btn-primary" onClick={this.skipBrochure}>{count}</button>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </Col>
                        </Row>
                    </Container> */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    ProfileState: state.profile,
    appState: state.app,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Brochure);