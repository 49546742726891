import React, { Component } from 'react'
import axios from 'axios';
import ImportedUrl from '../../../common/api';
import { Row, Container, Col, Spinner, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import { Success, Error } from '../../../common/swal';
import { Emailvalidate, isValidPassword } from '../../../common/validate';
import { useParams } from "react-router";
import ImportedURL from '../../../common/api';
import { Buffer } from 'buffer';
import QRCode from 'qrcode.react';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}
class SignUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            password: '',
            usernameError: false,
            emailError: false,
            passwordError: false,
            hidepassword: false,
            emailValidError: false,
            uniqueIdBack: false,
            unique: '',
            barCodeValue: '',
            strongPass: false,
        }
    }
    componentDidMount() {
        document.title = 'ACW CARD - Sign up'
        document.description = 'ACW Card enables you to share your business and personal profiles along with digital uploads of key documents to strengthen your portfolio.'
        let unique = window.location.href.split('/')[3];
        this.setState({ barCodeValue: ImportedURL.LOCALURL + unique })

    }
    onChange = e => {
        const passwordRegex = /^(?=.[a-z])(?=.[A-Z])(?=.\d)(?=.[@$!%?&])[A-Za-z\d@$!%?&]{8,}$/;
        const { name, value } = e.target;
        const Error = name + "Error";
        const ValidError = name + "ValidError";
        this.setState({ [name]: value, [Error]: false })
        if (name === 'email') {
            this.setState({ email: value });
            var email = value;
            if (email) {
                if (Emailvalidate(email)) {
                    this.setState({ [ValidError]: false, [Error]: false })
                } else {
                    this.setState({ [ValidError]: true })
                }
            }
            else {
                this.setState({ emailError: true, [ValidError]: false });
            }
        } else if (name === 'username') {
            if (value) {
                this.setState({ username: value, usernameError: false });

            } else {
                this.setState({ username: value, usernameError: true });

            }
            this.setState({ username: value });
        } else if (name === 'password') {
            if (value) {
                this.setState({ password: value });
                this.setState({ passwordError: false })
                if (isValidPassword(value)) {
                    this.setState({ strongPass: false, passwordError: false })
                    this.setState({ password: value });
                } else {
                    this.setState({ password: value });
                    this.setState({ strongPass: true, passwordError: false })
                }
            } else {
                this.setState({ passwordError: true, strongPass: false })
                this.setState({ password: value });
            }
        }
    }
    submit = (e) => {
        var { username, email, password, emailValidError, strongPass } = this.state
        username = username ? username.trim() : ''
        email = email ? email.trim() : ''
        password = password ? password.trim() : '';
        let valid = true
        if (emailValidError) {
            valid = false
        }
        if (!username) {
            this.setState({ usernameError: true })
            valid = false
        }
        if (!email) {
            this.setState({ emailError: true, emailValidError: false })
            valid = false
        }
        if (!password) {
            this.setState({ passwordError: true })
            valid = false
        }
        if (strongPass) {
            valid = false
        }

        const canvas = document.getElementById("HpQrcode");
        const base64Image = canvas.toDataURL("image/png");
        const fileType = base64Image.split(";")[0].split(":")[1];
        const buffer = Buffer.from(
            base64Image.replace(/^data:image\/\w+;base64,/, ""),
            "base64"
        );
        let fileName = "qr.jpeg"
        const file = new File([buffer], fileName, { type: fileType });

        if (valid) {
            this.setState({ updateSpinner: true })
            axios.get('https://ipapi.co/json/').then(async (response) => {
                let data = response.data;
                var formData = new FormData();
                formData.append("email", email);
                formData.append("password", password);
                formData.append("username", username);
                formData.append("time", new Date());
                formData.append("ip", data.ip);
                formData.append("city", data.city);
                formData.append("region", data.region);
                formData.append("country", data.country_name);
                formData.append("countrycode", data.country_code);
                formData.append("latitude", data.latitude);
                formData.append("longitude", data.longitude);
                formData.append("postal", data.postal);
                formData.append("currency", data.currency);
                const unique = window.location.href.split('/')[4];
                if (unique) {
                    if ((unique.includes('&uId=')) && (unique.includes('cardType='))) {
                        let uniqueid = unique.split('&uId=')[1]
                        let cardtype = '';
                        let companytype = '';
                        if (unique.includes('companyType=')) {
                            cardtype = (unique.split('&')[0]).split('cardType=')[1]
                            companytype = (unique.split('&')[1]).split('companyType=')[1]
                        } else {
                            cardtype = (unique.split('&uId=')[0]).split('cardType=')[1]
                        }
                        formData.append("uniqueid", uniqueid);
                        formData.append("cardtype", cardtype);
                        if (companytype) formData.append("companytype", companytype);
                    }
                }
                formData.append("barcodeQr", file);
                axios.post(ImportedUrl.API.signup, formData)
                    .then((res) => {
                        this.setState({ updateSpinner: false })
                        if (res.data) {
                            Success('Registered successfully');
                            const { token, type } = res.data
                            localStorage.setItem('acwtoken', token);
                            localStorage.setItem('type', type);
                            if (type == "admin") {
                                window.location.href = "/admin";
                            } else {
                                window.location.href = "/profile";
                            }
                        }
                    }).catch(({ response }) => {
                        this.setState({ updateSpinner: false })
                        if (response.status == 401) {
                            Error('Something wrong, Retry again!')
                        } else if (response.status == 510) {
                            Error('Email does not exit')
                        } else if (response.status == 502) {
                            Error(response.status + ' Bad Gateway')
                        } else if (response.status == 500) {
                            Error('Internal Server Error')
                        } else if (response.status == 409) {
                            Error('Email already exist')
                        } else if (response.status == 408) {
                            Error('Username already exist')
                        } else if (response.status == 407) {
                            Error('This account already exist')
                        } else if (response.status == 400) {
                            Error('Bad request')
                        }
                    });
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    eyeClick = () => {
        this.setState({ hidepassword: !this.state.hidepassword })
    }
    render() {

        const unique = window.location.href.split('/')[4];
        let uniqueid = unique.split('&uId=')[1]
        let cardtype = '';
        let companytype = '';
        if (unique.includes('companyType=')) {
            cardtype = (unique.split('&')[0]).split('cardType=')[1]
            companytype = (unique.split('&')[1]).split('companyType=')[1]
        } else {
            cardtype = (unique.split('&uId=')[0]).split('cardType=')[1]
        }
        return (
            <>
                <div className="login">
                    <Container className="container-new-big">
                        <div className="login_div">
                            <Row>
                                <Col lg={6}>
                                    <div className="left-login">
                                        <img src="../../../landing_page/assets/images/login.png" alt="login-img" />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="right-login">
                                        <h4 className="login_txt">Sign Up To Your Account</h4>
                                        <p className="accout_content">You are just one step away from exploring futuristic avenues in networking</p>
                                        <div className="mb-3">
                                            <input type="text" onChange={this.onChange} name='username' className="form-control" id="signupusername" placeholder="Your Username" />
                                            {this.state.usernameError ? <span className='error-message'>Your Username is required</span> : ''}
                                        </div>
                                        <div className="mb-3">
                                            <input type="text" onChange={this.onChange} name='email' className="form-control" id="signupemail" placeholder="Your Email Address" />
                                            {this.state.emailError ? <span className='error-message'>Your Email Address is required</span> : ''}
                                            {this.state.emailValidError ? <span className='error-message'>Enter valid email</span> : ''}
                                        </div>
                                        <div class="mb-3 input_design">
                                            <div className='pwd_icons'>
                                                <input type={this.state.hidepassword ? "text" : "password"} class="form-control password_input" onChange={this.onChange} id="signuppassword" name='password' placeholder="Your Password" />
                                                <div className="invalid-feedback" style={{ display: this.state.passwordError ? "block" : 'none' }}>Your Password is required</div>
                                                <div className="invalid-feedback" style={{ display: this.state.strongPass ? "block" : 'none' }}>Password should contain at least 1 uppercase, 1 lowercase,  1 special character, 1 number and atleast 8 characters in total</div>
                                                {
                                                    (this.state.hidepassword)
                                                        ?
                                                        <BsFillEyeFill style={{ cursor: 'pointer' }} onClick={this.eyeClick} />
                                                        :
                                                        <BsFillEyeSlashFill style={{ cursor: 'pointer' }} onClick={this.eyeClick} />
                                                }
                                            </div>
                                        </div>
                                        <div className="login_btn">
                                            {
                                                this.state.spinner
                                                    ?
                                                    <Button className="login_txt">Sign Up</Button>
                                                    :
                                                    <Button className="login_txt" onClick={this.submit}>Sign Up</Button>
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <div style={{ display: 'none' }} className="HpQrcode">
                        <QRCode id="HpQrcode" value={this.state.barCodeValue} width="100px" height="100px" />
                    </div>
                </div>
            </>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}
export default connect(mapDispatchToProps)(withParams(SignUp))