import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select';
import axios from 'axios';
import moment from 'moment';
import { BiSave } from "react-icons/bi";
import { Link } from "react-router-dom";
import ImportedURL from "../../common/api";
import { Currency } from "../../common/validate";
import { Spinner } from "react-bootstrap";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { AiOutlineAppstore, AiOutlineQuestionCircle, GrFormAdd } from "react-icons/ai";
import { Redirect } from 'react-router-dom';
import { AC_APP_SPINNER, AC_LIST_APP } from "../../actions/appAction";
import { Error, Success } from '../../common/swal';
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import { AC_LIST_PROFILE, AC_USER_SPINNER } from "../../actions/profileAction";
import { AC_ENQUIRY_SPINNER, AC_LIST_ENQUIRY, AC_VIEW_ENQUIRY } from "../../actions/enquiryAction";
import { AC_EMPTY_SWAP, AC_HANDLE_SWAP_CHANGE } from "../../actions/swapAction";
import { Capitalize } from "../../common/validate";

class Swap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isNewUniqueErr: false,
            isOldUniqueErr: false,
        }
    }
    componentDidMount() {
        this.props.ListUser()
    }
    changeSelectArray = (e) => {
        const { value, label, name } = e;
        if (name == 'olduniqueid') {
            if (value) {
                this.props.HandleChangeSwap('olduniqueid', value)
                this.setState({ isOldUniqueErr: false })
            } else {
                this.props.HandleChangeSwap('olduniqueid', value)
                this.setState({ isOldUniqueErr: true })
            }
        }
    }
    onChange = (e) => {
        const { name, value } = e.target;
        var pattern = /^[a-zA-Z0-9]+$/;
        if (name == 'newuniqueid') {
            let val = value.replace(/[^a-zA-Z0-9]/g, '')
            if (val) {
                if (val.length >= 12) {
                    this.props.HandleChangeSwap('newuniqueid', (val.toUpperCase()))
                    this.setState({ isNewUniqueErr: false })
                } else {
                    this.props.HandleChangeSwap('newuniqueid', (val.toUpperCase()))
                    this.setState({ isNewUniqueErr: true })
                }
            } else {
                this.setState({ isNewUniqueErr: false })
                this.props.HandleChangeSwap('newuniqueid', (val.toUpperCase()))
            }
        }
    }
    submit = (e) => {
        const { profileState, swapState, accountState } = this.props;
        const account = accountState.account
        const data = swapState.swap;
        let valid = 1;
        if (!data.olduniqueid) {
            this.setState({ isOldUniqueErr: true })
            valid = 0;
        }
        if (data.newuniqueid.length < 12) {
            this.setState({ isNewUniqueErr: true })
            valid = 0;
        }
        if (valid) {
            const listUser = profileState.listUser;
            const newUniqueIndex = listUser && listUser.length > 0 && listUser.findIndex(r => r.uniqueid == data.newuniqueid);
            if (newUniqueIndex === -1) {
                let formData = {
                    olduniqueid: data.olduniqueid,
                    newuniqueid: data.newuniqueid,
                }
                axios.post(ImportedURL.API.addUpdateSwap, formData)
                    .then(data => {
                        this.props.EmptySwap();
                        this.props.ListUser()
                        Success('Updated sucessfully');
                    })
            } else {
                Error('This account ID is already exist , So enter your new card ID.')
            }
        }

    }
    render() {
        const { profileState, swapState, accountState } = this.props;
        const listUser = profileState.listUser;
        const data = swapState.swap;
        const account = accountState.account
        const listUserArr = [];
        if (listUser && listUser.length > 0) {
            listUser.filter((e) => e.status === true).map(data => {
                if (account.role == 'admin') {
                    listUserArr.push({ label: data.uniqueid, value: data._id, name: 'olduniqueid' })
                } else {
                    if (data.company) {
                        let CompanyId = account.data ? account.data._id : ''
                        if (data.company == CompanyId) {
                            listUserArr.push({ label: data.uniqueid, value: data._id, name: 'olduniqueid' })
                        }
                    }
                }
            })
        }
        var selectedVal = ''
        if (data.olduniqueid) {
            listUserArr && listUserArr.length > 0 && listUserArr.map(item => {
                if (item.value == data.olduniqueid) {
                    selectedVal = { label: item.label, value: item.value, name: 'olduniqueid' }
                }
            })
        }

        return (
            <>
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-12 breadcome_value">
                                <ol class="breadcrumb ">
                                    <li class="breadcrumb-item header_color_breadcome"> <Link to='/admin'>Dasboard</Link></li>
                                    <li class="breadcrumb-item active">Swap</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="col-md-12">
                        <div class="card card-primary header_border" >
                            <div class="table-title">
                                <div className="card-header">
                                    <h3 className="card-title d-flex "> <div className='rounded_icon'> <i class="fa fa-arrows-alt mr-2 header_icon" aria-hidden="true"></i> </div><h2 class="card-title header_title">Swap </h2> </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div className="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-5">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Old unique ID<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                            <Select
                                                className='overall_select_option'
                                                value={selectedVal}
                                                onChange={this.changeSelectArray}
                                                options={listUserArr && listUserArr.length > 0 ? listUserArr.map(item => {
                                                    return { label: item.label, value: item.value, name: 'olduniqueid' }
                                                }) : ''}
                                                styles={{
                                                    menu: provided => ({ ...provided, zIndex: 9999, textAlign: 'left' })
                                                }}
                                            />
                                            <div className="invalid-feedback" style={{ display: this.state.isOldUniqueErr ? "block" : 'none' }}>Old unique ID is required</div>
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">New unique ID<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                            <input type="text" className="form-control" name='newuniqueid' onChange={this.onChange} placeholder="New unique ID" value={data.newuniqueid} />
                                            <div className="invalid-feedback" style={{ display: this.state.isNewUniqueErr ? "block" : 'none' }}>Should be 12 charecter here.</div>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="card-footer submit_button border-0">
                                            <button type="button" onClick={this.submit} className="btn btn-primary button_color" id='Add'> <BiSave className="mr-2 sidebar_icon" />Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


function mapStateToProps(state) {
    return {
        accountState: state.account,
        swapState: state.swap,
        profileState: state.profile,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ListUser: AC_LIST_PROFILE,
        HandleChangeSwap: AC_HANDLE_SWAP_CHANGE,
        EmptySwap: AC_EMPTY_SWAP,

    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Swap);