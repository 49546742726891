import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ImportedURL from '../../common/api';
import { CapitalizeFirstLetter } from '../../common/validate';
import Swal from 'sweetalert2';

class Header extends Component {
	signOut = (e) => {
		Swal.fire({
			title: 'Are you sure want to logout ?',
			showCancelButton: true,
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ok',
			imageUrl: '../../assets/images/signout.png',
			customClass: {
				popup: 'swal_pop',
				title: 'swal_title',
				image: 'swal_image',
				actions: 'swal_action',
				confirmButton: 'swal_confirm',
				cancelButton: 'swal_close',
			}
		}).then((result) => {
			if (result.isConfirmed) {
				e.preventDefault();
				localStorage.removeItem('acwtoken');
				window.location.href = "/login";
			}
		})
	}
	render() {
		const { fixNavbar, darkHeader, accountState } = this.props;
		const account = accountState.account
		return (
			<div>
				<div
					id="page_top"
					className={`section-body ${fixNavbar ? "sticky-top" : ""} ${darkHeader ? "top_dark" : ""}`}
				>
					<div className="container-fluid">
						<div className="page-header">
							<div className="left">
								{
									(account && account.data && account.role != 'admin')
										?
										<>
											<div className='logo'>
												<div className='img_size' style={{ width: '50px', height: '50px', marginRight: '10px' }}>
													<img style={{ width: '100%', height: '100%', objectFit: 'fil', borderRadius: '50%', border: '2px solid #1c3078 ' }} src={ImportedURL.LIVEURL + account.data.image} alt='not found' />
												</div>
											</div>
											<div className='address' style={{ height: '50px', color: '#000', fontWeight: '500', fontSize: '15px' }}>
												<p className='mb-0 h_name'>{account.name}</p>
												<p className='mb-0'>{CapitalizeFirstLetter(account.data.address) + ", " + CapitalizeFirstLetter(account.data.city) + ", " + CapitalizeFirstLetter(account.data.state) + ", " + account.data.postcode + ', ' + CapitalizeFirstLetter(account.data.country) + '.'}</p>
											</div>
										</>
										: ''

								}
							</div>
							<div className="right">
								<div className="notification d-flex">
									{/* <div className="dropdown d-flex">
										<a
											href="/#"
											className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
											data-toggle="dropdown"
										>
											<i className="fa fa-envelope" />
											<span className="badge badge-primary nav-unread" />
										</a>
										<div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
											<ul className="list-unstyled feeds_widget">
												<li>
													<div className="feeds-left">
														<i className="fa fa-user" />
													</div>
													<div className="feeds-body">
														<h4 className="title">
															New User{' '}
															<small className="float-right text-muted">10:45</small>
														</h4>
														<small>I feel great! Thanks team</small>
													</div>
												</li>
											</ul>
											<div className="dropdown-divider" />
											<Link to="/notifications"
												className="dropdown-item text-center text-muted-dark readall"
											>
												See all notifications
											</Link>
										</div>
									</div>
									<div className="dropdown d-flex">
										<a
											href="/#"
											className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
											data-toggle="dropdown"
										>
											<i className="fa fa-bell" />
											<span className="badge badge-primary nav-unread" />
										</a>
										<div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
											<ul className="list-unstyled feeds_widget">
												<li>
													<div className="feeds-left">
														<i className="fa fa-user" />
													</div>
													<div className="feeds-body">
														<h4 className="title">
															New User{' '}
															<small className="float-right text-muted">10:45</small>
														</h4>
														<small>I feel great! Thanks team</small>
													</div>
												</li>
											</ul>
											<div className="dropdown-divider" />
											<Link to="/notifications"
												className="dropdown-item text-center text-muted-dark readall"
											>
												See all notifications
											</Link>
										</div>
									</div> */}
									<div className="dropdown d-flex">
										<a
											href="/#"
											className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
											data-toggle="dropdown"
										>
											<img className="avatar" src={account.image != '' ? ImportedURL.LIVEURL + account.image : "../assets/images/xs/avatar2.jpg"} data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url"></img>
										</a>
										<div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
											<div className="card-body">
												<div className="form-group d-flex">
													<i className="icon-user  mr-2" style={{ position: 'relative', top: '3px' }}></i>
													<label className="form-label">{(account.username ? account.username : '---')}</label>
												</div>
												<div className="form-group d-flex">
													<i className="fe fe-mail  mr-2" style={{ position: 'relative', top: '3px' }}></i>
													<label className="form-label">{account.email ? account.email : '---'}</label>
												</div>
												<div className="form-group d-flex">
													<i className="fe fe-award  mr-2" style={{ position: 'relative', top: '3px' }}></i>
													<label className="form-label">{account.role == 'admin' ? 'Admin' : (account.role ? account.role : '---')}</label>
												</div>
											</div>
											<div className="dropdown-item sign_out" style={{ cursor: 'pointer' }} onClick={this.signOut}>
												<i className="dropdown-icon fe fe-log-out " /> Sign out
											</div>
										</div>
										{/* <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
											<Link to="/admin/admin-setting" className="dropdown-item">
												<i className="dropdown-icon fe fe-settings" /> Settings
											</Link>
											<Link to="/login" className="dropdown-item" onClick={this.signOut}>
												<i className="dropdown-icon fe fe-log-out" /> Sign out
											</Link>
										</div> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div >
		);
	}
}
const mapStateToProps = state => ({
	fixNavbar: state.settings.isFixNavbar,
	darkHeader: state.settings.isDarkHeader,
	accountState: state.account,
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(Header);