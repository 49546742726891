import React from 'react'
import Digitalcard from './digitalcard';
import Order from './order';
import Howdoes from './how-does';
import Bulk from '../home/bulk';



export default function digitalindex() {
    return (
        <div>
            <>
                <Order />
                <Digitalcard />
                {/* <Howdoes /> */}
                <Bulk />
            </>
        </div>
    )
}
