import React from "react";
import Logins from "./login";


export default function Login() {
    return (
        <>
           <Logins/>
        </>
    );
}