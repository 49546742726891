import React, { Component } from 'react';
import { connect } from 'react-redux';
import './App.css';
import './assets/css/admin.css'
import Layout from './components/Shared/Layout';
import Login from './components/Authentication/login';
import ForgotPassword from './components/Authentication/forgotpassword';
import NotFound from './components/Authentication/404';
import InternalServer from './components/Authentication/500';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AC_ACCOUNT_DETAILS, AC_PREVILEGE_DETAILS } from './actions/accountAction';
import Index from './acwcard_landing';
import axios from 'axios';
import ImportedURL from './common/api';
import { AC_VIEW_PROFILE, AC_VIEW_PROFILE_UNIQUEID, AC_VIEW_PROFILE_USERNAME } from './actions/profileAction';
import account from './acwcard_client_side/account/account';
import profile from './acwcard_client_side/profile/profile';
import preview from './acwcard_client_side/preview/preview';
import viewpreview from './acwcard_client_side/preview/viewpreview';
import settings from './acwcard_client_side/settings/settings';
import brochure from './acwcard_client_side/brochure/brochure';
import Uniqueid from './acwcard_client_side/uniqueid/uniqueid';
import Signup from './acwcard_client_side/auth/signup';
import Private from './acwcard_client_side/account/private';
import Invalid from './acwcard_client_side/account/invalid';
import editprofile from './acwcard_client_side/profile/editprofile';
import viewuniqueid from './acwcard_client_side/uniqueid/viewuniqueid';
import uniqueid from './acwcard_client_side/uniqueid/uniqueid';
import Loading from './acwcard_client_side/auth/loading';
import swapid from './acwcard_client_side/settings/swapid';

const token = localStorage.getItem("acwtoken");
const type = localStorage.getItem('type');
const urlPath = window.location.href.split('/')[3]
class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initial: false,
			uniqueState: false,
			usernameStatus: false,
			privateStatus: false,
			notfoundStatus: false,
			uniqueSignUp: false,
			loginStatus: false,
			landingPageShows: false,
			navigationLink: [
				"admin", "profile", 'account', "accountsetting", "signup", "login", "signup", "preview", "#works", "#", "#features", "#enquiry", "forgotpassword", "brochure", "uniqueid", "viewuniqueid", "Private", "notfound", "digitalcard", "swap-id", "about", "abouts", "pricing", "contact", "detail", "details"
			]
		}
	}
	componentDidMount() {
		if (token) {
			this.props.AccountDetails();
		}
		let viewUnique = window.location.href.split('/')[5];
		const unique = window.location.href.split('/')[3];
		const signupUnique = window.location.href.split('/')[4];
		let previewCheck = (unique.includes('preview'))

		if (unique == 'signup') {
			if (signupUnique && (signupUnique.includes('cardType=')) && (signupUnique.includes('uId='))) {
				let uniqueid = signupUnique.split('&uId=')[1];
				let id = uniqueid.substring(0, 12);
				axios.get(ImportedURL.API.findUniqueId + "/" + id)
					.then((res) => {
						if (res.data && res.data === true) {
							window.location.href = "/" + signupUnique;
						}
					}).catch(({ response }) => { console.log(response); });
			}
		}

		if (unique && (unique.includes('uniqueId=')) && (unique.includes('type='))) {
			let getToken = (unique.split('&')[0]).split('uniqueId=')[1]
			let getType = (unique.split('&')[1]).split('type=')[1]
			localStorage.setItem('acwtoken', getToken);
			localStorage.setItem('type', getType);
			if (getType == "admin" || getType == 'company') {
				window.location.href = "/admin";
			} else {
				window.location.href = "/profile";
			}
		}
		if (viewUnique) {
			if ((viewUnique.includes('&uId=')) && (viewUnique.includes('cardType=')) && previewCheck == false) {
				let uniqueid = viewUnique.split('&uId=')[1]
				if (uniqueid.length > 12) {
					let id = uniqueid.substring(0, 12);
					let cardtype = '';
					let companytype = '';
					if (unique.includes('companyType=')) {
						cardtype = (viewUnique.split('&')[0]).split('cardType=')[1]
						companytype = (viewUnique.split('&')[1]).split('companyType=')[1]
					} else {
						cardtype = (viewUnique.split('&uId=')[0]).split('cardType=')[1]
					}
					if (viewUnique.includes('companyType=')) window.location.href = "/?cardType=" + cardtype + "&companyType=" + companytype + "&uId=" + id;
					else window.location.href = "/?cardType=" + cardtype + "&uId=" + id;
				} else {
					axios.get(ImportedURL.API.viewProfileUniqueId + "/" + uniqueid, { params: { group: true } })
						.then((res) => {
							if (res.data && res.data != undefined) {
								this.setState({ usernameStatus: true, privateStatus: res.data.ispublicprofile })
							}
						}).catch(({ response }) => { console.log(response); });
				}
			}
		}
		if (unique && (unique.includes('cardType=')) && (unique.includes('HId='))) {
			let cardtype = (unique.split('&')[0]).split('cardType=')[1]
			let hoteltype = (unique.split('&')[1]).split('HId=')[1]
			axios.post(ImportedURL.API.getUserViewReview, { cardtype, hoteltype })
				.then((res) => {
					if (res.data) {
						window.location.href = res.data
					} else {
						this.setState({ notfoundStatus: true })
					}
				}).catch(({ response }) => { console.log(response); });

		} else {
			if (unique) {
				if ((unique.includes('&uId=')) && (unique.includes('cardType=')) && previewCheck == false) {
					let uniqueid = unique.split('&uId=')[1]
					if (uniqueid.length > 12) {
						let id = uniqueid.substring(0, 12);
						let cardtype = '';
						let companytype = '';
						if (unique.includes('companyType=')) {
							cardtype = (unique.split('&')[0]).split('cardType=')[1]
							companytype = (unique.split('&')[1]).split('companyType=')[1]
						} else {
							cardtype = (unique.split('&uId=')[0]).split('cardType=')[1]
						}
						if (unique.includes('companyType=')) window.location.href = "/?cardType=" + cardtype + "&companyType=" + companytype + "&uId=" + id;
						else window.location.href = "/?cardType=" + cardtype + "&uId=" + id;
					} else {
						axios.get(ImportedURL.API.viewProfileUniqueId + "/" + uniqueid, { params: { group: true } })
							.then((res) => {
								if (res.data && res.data != undefined) {
									this.setState({ usernameStatus: true, privateStatus: res.data.ispublicprofile })
								}
								if (res.data.isinfostatus != undefined && res.data.isinfostatus) {
									axios.post(ImportedURL.API.updateTapCardStatus, { tapStatus: true })
										.then((res) => {
											this.setState({ landingPageShows: true })
										}).catch(({ response }) => {
											if (response) {
												Error('Bad request')
											}
										});
								}
								if (res.data.uniqueid != undefined) {
									this.props.ProfileUnique(uniqueid)
									this.setState({ uniqueState: true, uniqueSignUp: false })
								} else {
									this.setState({ uniqueState: true, uniqueSignUp: true })
									if (token) {
										localStorage.removeItem('acwtoken');
										localStorage.removeItem('type');
										window.location.href = "/signup/" + unique;
									} else {
										window.location.href = "/signup/" + unique;
									}
								}
							}).catch(({ response }) => { console.log(response); });
					}
				} else {
					axios.get(ImportedURL.API.viewPrivateAccount + "/" + unique)
						.then((res) => {
							this.setState({ landingPageShows: true })
							if (res.data.id != undefined && res.data.id) {
								this.props.ProfileUsername(res.data.id)
								this.setState({ usernameStatus: true, privateStatus: res.data.status })
							} else {
								if (this.state.navigationLink.includes(unique)) {
									if (token) {
										if (unique == 'login') {
											window.location.href = "/profile";
										}
									}
									this.setState({ notfoundStatus: false })
								} else {
									const finalValue = window.location.href.split('/')[4]
									if (!finalValue) {
										this.setState({ notfoundStatus: true })
									}
								}
							}
						}).catch(({ response }) => { console.log(response); });
				}
			} else {
				this.setState({ landingPageShows: true })
			}
		}
		if (unique && unique == 'viewuniqueid') this.setState({ uniqueState: true });
	}


	render() {
		const { darkMode, boxLayout, darkSidebar, iconColor, gradientColor, rtl, fontType } = this.props;
		if (this.state.uniqueState) {
			return (
				<>
					<Router>
						{(this.state.uniqueSignUp) ?
							<Switch> <Route path="/" component={Index} /></Switch>
							:
							<>
								{(this.state.privateStatus) ?
									<Switch>
										<Route path="/viewuniqueid/:id" component={viewuniqueid} />
										<Route path="/" component={uniqueid} />
									</Switch>
									:
									<Switch><Private /></Switch>}
							</>
						}
					</Router>
				</>
			)
		} else if (this.state.usernameStatus) {
			return (
				<>
					<Router>
						{(this.state.privateStatus) ?
							<Switch>
								<Route path="/viewuniqueid/:id/:id" component={viewuniqueid} />
								<Route path="/" component={uniqueid} />
							</Switch>
							:
							<Switch><Private /></Switch>}
					</Router>
				</>
			)
		} else if (this.state.notfoundStatus) {
			return (<><Router><Invalid /></Router></>)
		} else {
			return (
				<>
					<div className={`${darkMode ? "dark-mode" : ""}${darkSidebar ? "" : "sidebar_dark"} ${iconColor ? "iconcolor" : ""} ${gradientColor ? "gradient" : ""} ${rtl ? "rtl" : ""} ${fontType ? fontType : ""}${boxLayout ? "boxlayout" : ""}`}>
						<Router>
							{token ?
								<>
									{(type == 'admin' || type == 'company') ?
										<>
											{urlPath ?
												// <Switch>
												// 	<Route path="/notfound" component={NotFound} />
												// 	<Route path="/internalserver" component={InternalServer} />
												// 	<Route path="/admin" component={Layout} />
												// </Switch>
												<Route path="/admin" component={Layout} />
												:
												<>
													{
														this.state.landingPageShows
															?
															<Route path="/" component={Index} />
															:
															<Loading />
													}
												</>
											}
										</>
										:
										<>
											<Switch>
												{/* <Route path="/account" component={account} /> */}
												<Route exact path="/profile" component={profile} />
												<Route path="/editprofile/:id" component={editprofile} />
												<Route path="/preview" component={preview} />
												<Route path="/viewpreview/:id" component={viewpreview} />
												<Route path="/accountsetting" component={settings} />
												<Route path="/swap-id" component={swapid} />
												<Route path="/brochure" component={brochure} />
												<Route path="/viewuniqueid/:id" component={viewuniqueid} />
												<Route path="/private" component={Private} />
												{
													this.state.landingPageShows
														?
														<Route path="/" component={Index} />
														:
														<Loading />
												}
											</Switch>
										</>
									}
								</>
								:
								<Switch>
									{/* <Route path="/login" component={Login}></Route>
								<Route path="/login/:id" component={Login}></Route> */}
									<Route path="/brochure" component={brochure} />
									{/* <Route exact path="/forgotpassword" component={forgetpwd} /> */}
									{
										this.state.landingPageShows
											?
											<>
												<Route path="/" component={Index} />
											</>
											:
											<Loading />
									}
								</Switch>
							}
						</Router>
					</div>
				</>
			);
		}
	}
}
const mapStateToProps = state => ({
	accountState: state.account,
	darkMode: state.settings.isDarkMode,
	darkSidebar: state.settings.isDarkSidebar,
	iconColor: state.settings.isIconColor,
	gradientColor: state.settings.isGradientColor,
	rtl: state.settings.isRtl,
	fontType: state.settings.isFont,
	boxLayout: state.settings.isBoxLayout,
	notification: state.notification
})

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		AccountDetails: AC_ACCOUNT_DETAILS,
		PrevilegesDetails: AC_PREVILEGE_DETAILS,
		ProfileUnique: AC_VIEW_PROFILE_UNIQUEID,
		ProfileUsername: AC_VIEW_PROFILE_USERNAME,
		ViewProfile: AC_VIEW_PROFILE,
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(App)