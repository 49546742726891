import React from "react";
import Plan from "./plan";
import PricingBox from "./pricing-box";
import Bulk from "../home/bulk";
import Demo from "../home/demo";


export default function PricingPlan() {
    return (
        <>
            <Plan />
            <PricingBox />
            {/* <Bulk/> */}
            <Demo/>
        </>
    );
}