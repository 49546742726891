import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import AOS from 'aos';
import "aos/dist/aos.css";
import "./styles/style.css";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Home from './components/home/index';
import DigitalCard from './components/digitalcard/index';
import About from './components/about/index';
import Contact from './components/contact/index';
import Login from './components/login/index';
import Detail from './components/detail/index';
import Pricing from './components/pricing/index';
import Header from "./layouts/header";
import Footer from "./layouts/footer";
import Forgetpwd from './components/login/forgetpwd';
import Signup from './components/login/signup';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      temp: "",
    }
  }
  componentDidMount() {
    document.title = 'ACW CARD - A new-gen versatile digital card'
    document.description = 'ACW Card enables you to share your business and personal profiles along with digital uploads of key documents to strengthen your portfolio.'
    AOS.init();
  }
  onClick = (data) => {
    this.setState({ temp: data })
  }
  onClickEmt = (data) => {
    this.setState({ temp: '' })
  }
  render() {
    return (
      <div className='acwcard_landing_container' style={{ background: '#fff' }}>
        <Router>
          <Header />
          <Switch>
            <Route path="/digitalcard" component={DigitalCard}>
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/abouts/:id">
              <About />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/login/:id">
              <Login />
            </Route>
            <Route path="/detail">
              <Detail />
            </Route>
            <Route path="/details/:id">
              <Detail />
            </Route>
            <Route path="/pricing">
              <Pricing />
            </Route>
            <Route path="/forgotpassword">
              <Forgetpwd />
            </Route>
            <Route exact path="/signup">
              <Signup />
            </Route>
          </Switch>
          <Footer />
        </Router>
      </div>
    );
  }
}

export default Index;
