import axios from 'axios';
import ImportedURL from '../common/api';

export function AC_HANDLE_INPUT_CHANGE_CARDTYPE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_INPUT_CHANGE_CARDTYPE", name: name, value: value });
    };
}

export function AC_LIST_CARDTYPE() {
    return function (dispatch) {
        return axios.get(ImportedURL.API.listCardtype)
            .then((res) => {
                dispatch({ type: "LIST_CARDTYPE", payload: res.data, spinner: false })
            }).catch(({ response }) => {
                dispatch({ type: "LIST_CARDTYPE", payload: [], spinner: false })
                console.log(response);
            });
    };
}


export function AC_VIEW_CARDTYPE(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewCardtype + '/' + id)
            .then((res) => {
                dispatch({ type: "VIEW_CARDTYPE", payload: res.data, spinner: false })
            }).catch(({ response }) => {
                dispatch({ type: "VIEW_CARDTYPE", payload: {}, spinner: false })
                console.log(response);
            });
    };
}

export function AC_EMPTY_CARDTYPE() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_CARDTYPE" })
    };
}

export function AC_SPINNER_CARDTYPE() {
    return function (dispatch) {
        dispatch({ type: "SPINNER_CARDTYPE" })
    };
}

export function AC_VIEW_SPINNER_CARDTYPE() {
    return function (dispatch) {
        dispatch({ type: "VIEW_SPINNER_CARDTYPE" })
    };
}
