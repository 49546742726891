import React from 'react'
import Contact from './contact'
export default function conatctindex() {
    return (
        <div>
            <>
            <Contact/>
            </>
        </div>
    )
}
