import CONSTANTS from "../common/constants";
const initialState = {
	account: {
		name: '',
		hotelid: '',
		email: '',
		location: '',
		_id: '',
	},
	username: "",
	role: '',
	loginHistory: [],
	spinner: true,
	previleges: CONSTANTS.previleges,

}

export default (state = initialState, action) => {

	switch (action.type) {
		case 'HANDLE_INPUT_CHANGE_PROFILE':
			return Object.assign({}, state, {
				account: {
					...state.account,
					[action.name]: action.value
				}
			})
		case 'HANDLE_INPUT_CHANGE_ADMIN':
			return Object.assign({}, state, {
				account: {
					...state.account,
					[action.name]: action.value
				}
			})
		case 'ACCOUNT_DETAILS':
			return Object.assign({}, state, {
				account: action.payload,
				role: (action.payload && action.payload.role) ? action.payload?.role : '',
				previleges: (action.payload && action.payload.previleges) ? action.payload.previleges : CONSTANTS.previleges,
				spinner: action.spinner
			})
		case 'PREVILEGE_DETAILS':
			return Object.assign({}, state, {
				previleges: action.payload.previleges
			})
		default:
			return state;
	}
}