import React, { Component } from 'react'
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Container, Col, Spinner } from 'react-bootstrap';
import { AiOutlinePhone } from 'react-icons/ai';
import { BiSave, BiMap } from 'react-icons/bi';
import { HiOutlineChevronLeft } from 'react-icons/hi';
import { MdFileOpen } from "react-icons/md";
import { Link } from "react-router-dom";
import { AC_HANDLE_INPUT_CHANGE_PROFILE, AC_HANDLE_INPUT_CHANGE_SETTINGS, AC_PROFILE_SPINNER, AC_VIEW_PROFILE, AC_VIEW_PROFILE_UNIQUEID, AC_VIEW_PROFILE_USERNAME } from '../../actions/profileAction';
import ImportedURL from '../../common/api';
import { AC_LIST_APP, AC_LIST_APP_UNIQUE } from '../../actions/appAction';
import moment from "moment";
import { Modal } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { AC_HANDLE_INPUT_CHANGE_BUSINESS, AC_LIST_BUSINESSESS, AC_LIST_BUSINESSESS_UNIQUE, AC_LIST_PERSONAL_UNIQUE, AC_VIEW_BUSINESSESS, AC_VIEW_PERSONAL } from '../../actions/businessAction';
import Swal from 'sweetalert2';
import { Success, Error } from '../../common/swal';
import { Emailvalidate, FileAndImagevalidation, GetAge, Imagevalidation, Phonenumber, Urlvalidate, dataURLtoFile } from '../../common/validate';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import logo from '../images/v2/acwlogo.png';
import nfc from '../images/v2/nfclogo.png';
import banner from '../images/v2/acwprofilebg.png'
import userlogo from '../images/v2/user.png'
class ViewUniqueId extends Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
        this.fileInputBanner = React.createRef();
        this.state = {
            bgimage: '',
            profileimg: '',
            skill: '',
            socialmediaSelect: {},
            socialmediaValue: [],
            urlError: false,
            emailError: false,
            updateSpinner: false,
            phoneNoPatternError: false,
            busPhoneNoPatternError: false,
            phoneNoPatternArrayError: false,
            name: 'React',
            src: null,
            crop: {
                unit: 'px',
                x: 130,
                y: 50,
                width: 200,
                height: 200
            },
            croppedImageUrl: null,
            showModal: false,
            typeImage: '',
            onChangeHeight: false,
            profileImage: '',
            isUserdDisplayNameSaveShow: false,
            isHeadlineSaveShow: false,
            bussinesslogoSrc: '',
            bussinesslogoError: false,
            isBusinessEditedit: false,
            businessEditeditId: '',
            modelData: {},
            redirectpage: '',
            isRedirectpage: false,
            matches: window.matchMedia("(min-width: 1100px)").matches,
            reirectUserName: '',
            modelTitle: '',
            personalDetailsShow: false,
        }
    }


    async componentDidMount() {
        document.title = 'ACW CARD - Profile'
        document.description = 'ACW Card enables you to share your business and personal profiles along with digital uploads of key documents to strengthen your portfolio.'

        const handler = e => this.setState({ matches: e.matches });
        window.matchMedia("(min-width: 1100px)").addEventListener('change', handler);

        let id = this.props.match.params.id;

        await this.props.SpinnerProfile();
        await this.props.ListBusiness();
        await this.props.ListApp();
        this.setState({ redirectpage: this.props.location.search });

        const urlLoc = this.props.location
        let search = urlLoc.search.split('=');
        let val = search && search.length > 0 && search.pop();
        let userNameSide = urlLoc.pathname.split('/');
        const urlPath = window.location.href.split('/')[5]

        let userId = userNameSide && userNameSide.length > 0 ? userNameSide[3] : [];
        let businessId = userNameSide && userNameSide.length > 0 ? userNameSide[2] : [];
        this.setState({ reirectUserName: userNameSide && userNameSide.length > 0 ? userNameSide.pop() : [] })
        const Index = this.props.BusinessState.listBusinessUnique.findIndex(e => e._id == id);
        if (val != '') {
            await this.props.ListBusiness({ uniqueid: val });
            await this.props.ListPersonal({ uniqueid: val });
            await this.props.ViewProfileUnique(val);
            if (Index == -1) {
                this.setState({ personalDetailsShow: true })
                await this.props.ViewPersonal(id)
                await axios.get(ImportedURL.API.listPersonelUnique, { params: { uniqueid: val } })
                    .then((res) => {
                        if (res.data[0].status === false) {
                            window.location.href = "/" + urlPath;
                        }
                    }).catch(({ response }) => { console.log(response) });
            } else {
                this.setState({ personalDetailsShow: false })
                await this.props.ViewBusiness(id)
            }
        } else {
            await this.props.ListBusiness({ uniqueid: val });
            await this.props.ListPersonal({ uniqueid: val });
            const listPersonalData = this.props.BusinessState.listPersonalUnique;
            let nameTaFind = listPersonalData && listPersonalData.findIndex(e => e._id == businessId)
            await this.props.ViewProfileUniqueName(userId);
            if (nameTaFind == -1) {
                await this.props.ViewBusiness(businessId);
            } else {
                await this.props.ViewPersonal(businessId)
            }
        }
    }
    vCard = () => {
        let id = this.props.match.params.id;
        const unique = window.location.href.split('/')[3]
        const { ProfileState, accountState } = this.props;
        const uniqueData = ProfileState.profile
        const account = accountState.account;
        this.setState({ updateSpinner: true })
        axios.get('https://ipapi.co/json/').then((response) => {
            let data = response.data;
            const formData = {
                time: new Date(),
                ip: data.ip,
                city: data.city,
                country: data.country_name,
                uniqueid: uniqueData.uniqueid ? uniqueData.uniqueid : "",
                id: uniqueData._id ? uniqueData._id : '',
                website: this.state.matches,
                personalDetailsShow: this.state.personalDetailsShow
            }
            // if (unique.includes('&uId=')) {
            formData['group'] = true
            // } else {
            //     formData['group'] = false
            // }
            if (account._id) {
                formData['saverId'] = account._id
            }

            formData['business_id'] = id
            axios.post(ImportedURL.API.vCardGenerate, formData)
                .then((res) => {
                    this.setState({ updateSpinner: false })
                    if (res.data) {
                        var url = 'data:text/x-vcard;charset=utf-8,' + encodeURIComponent(res.data.vcard);
                        document.location.href = url;
                    }
                }).catch(({ response }) => {
                    this.setState({ updateSpinner: false })
                    if (response) {
                        if (response.status == 401) {
                            Error('Something wrong, Retry again!')
                        } else if (response.status == 502) {
                            Error(response.status + ' Bad Gateway')
                        } else if (response.status == 500) {
                            Error('Internal Server Error')
                        } else if (response.status == 400) {
                            Error('Bad request')
                        }
                    }
                });
        }).catch((error) => {
            console.log(error);
        });
    }

    businessEdit = (id) => {
        this.setState({ isBusinessEditedit: true, businessEditeditId: id });
    }

    sendModelView = (app) => {
        this.setState({ modelId: app._id, modelTitle: app.name })
        const { BusinessState, accountState } = this.props;
        const data = BusinessState.bussiness.applink;

        const filteredArray = data.filter(obj1 =>
            app.typesList.some(obj2 => obj2.id === obj1.id._id)
        );
        this.props.HandleInputBusinessChange('modelData', filteredArray)
    }
    trigerBack = (e) => {
        this.setState({ isRedirectpage: true })
    }

    render() {
        if (this.state.isRedirectpage) {
            if (this.state.reirectUserName) {
                return <Redirect to={'/' + this.state.reirectUserName} />
            } else {
                return <Redirect to={'/' + this.state.redirectpage} />
            }
        }
        const { crop, croppedImageUrl, src, isUserdDisplayNameSaveShow, modelTitle } = this.state;
        const { socialmediaSelect } = this.state
        const { ProfileState, appState, BusinessState } = this.props;
        const listapp = appState.listAppUnique;
        const bData = BusinessState.bussiness;
        const listBusinessData = BusinessState.listBusinessUnique;
        const listPersonalData = BusinessState.listPersonalUnique;
        listapp?.sort((a, b) => Number(a.sortorder) - Number(b.sortorder));
        const spinner = ProfileState.spinner
        const data = ProfileState.profile
        let locationData = {}
        if (bData && bData != undefined) {
            locationData = bData && bData.address ? bData.address.address : ''
        }

        let phoneShowInPersonal = ''

        if (bData && bData != undefined) {
            let call = bData && bData.call
            if (call && call.length != 0) {
                phoneShowInPersonal = (call.cc && call.cc.value) + ' ' + call.value
            }
        }

        const dobDate = data.dob ? moment(data.dob).format('MM-DD-YYYY') : '';
        let displayLoc = ''
        if (locationData) {
            displayLoc = (locationData.street ? (locationData.street).replace(/,/g, "") + ',' : "") + (locationData.city ? (locationData.city).replace(/,/g, "") + ',' : "") + (locationData.state ? (locationData.state).replace(/,/g, "") + ',' : "") + (locationData.country ? (locationData.country).replace(/,/g, "") + ',' + ' ' : "") + (locationData.zip ? "- " + locationData.zip : "")
            var mapUrlDisplay = `https://www.google.com/maps/place/${encodeURIComponent(displayLoc)}`;
        }


        const linkStatus = BusinessState.bussiness && BusinessState.bussiness.applinkstatus;
        if (linkStatus && linkStatus.length > 0) {
            var listStatusVal = linkStatus.filter(e => e.status == true)
        }

        const matchedData = listapp.filter(obj1 =>
            (listStatusVal && listStatusVal.some(obj2 => obj2.appid === obj1._id))
        );
        return (
            <>
                <div style={{ position: 'relative' }}>
                    <div className='home_section profile_section gap_padding_space pro_bg'>
                        <Container>
                            <Row className="justify-content-md-center">
                                <Col xs="12" lg="5" md="12" sm="12" >
                                    <div className='acw_card_nav_images'>
                                        <div className='acw_card_logo'>
                                            <div className='acw_image1'>
                                                {/* <Link to="/"> */}
                                                <img src={logo} />
                                                {/* </Link> */}
                                            </div>
                                            <div className='acw_vertical_line'></div>
                                            <div className='acw_image2'>
                                                {/* <Link to="/"> */}
                                                <img src={nfc} />
                                                {/* </Link> */}
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            {/* <div className='acw_card_setting'>
                                                <Link style={{ color: '#fff' }} to="/accountsetting" onClick={(e) => this.props.HandleChangesSetting('profile')}>
                                                    <AiOutlineSetting className='acoount_setting' />
                                                </Link>
                                            </div> */}

                                        </div>
                                    </div>
                                    <div className='profile_header_text d-flex' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className='d-flex align-items-center'>
                                            <p className='back_text mb-0'>
                                                View Profile
                                            </p>
                                        </div>
                                        <div className='profile_back_arrow' onClick={this.trigerBack} stya>
                                            <HiOutlineChevronLeft />
                                        </div>
                                    </div>

                                    <div className='home_sec'>
                                        <div className='account_profile_sec'>
                                            <div className='account_profile'>
                                                <div className='tap_banner_img'>
                                                    <div className='img_size'>
                                                        {
                                                            ((this.state.bgimage || data.banner) && data.isCoverStatus) ?
                                                                <img src={this.state.bgimage ? URL.createObjectURL(this.state.bgimage) : ImportedURL.LIVEURL + data.banner} alt='' />
                                                                : <img className="avatar" src={banner} data-toggle="tooltip" data-original-title="Avatar Name" alt="" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='address_img'>
                                                    <div className='row'>
                                                        <div className='col-8'>
                                                            <div className='address'>
                                                                <h6>{data.displayname ? data.displayname : '---'} </h6>
                                                                <span>{data.headline ? data.headline : ''}</span>
                                                                {
                                                                    bData.viewBusinessStatus
                                                                        ?
                                                                        <>
                                                                            {bData.work ? <span> {bData.work}</span> : ''}
                                                                            {bData.designation ? <span> {bData.designation}</span> : ''}
                                                                        </> : ''
                                                                }
                                                            </div>
                                                            <div className='d-flex '>
                                                                <div className='icon'>
                                                                    {
                                                                        displayLoc
                                                                            ?
                                                                            <a href={mapUrlDisplay} target="_blank" >
                                                                                <BiMap className='display_name_map' />
                                                                            </a>
                                                                            : ""
                                                                    }
                                                                </div>
                                                                <div className='content'>
                                                                    {
                                                                        displayLoc
                                                                            ?
                                                                            <a href={mapUrlDisplay} target="_blank" style={{ color: 'black', textDecoration: 'none' }}>
                                                                                <span>{locationData ? locationData.street : ''}</span><br></br>
                                                                                <span>{locationData ? locationData.city + ', ' + locationData.state + ' ' + locationData.zip : ''}</span><br></br>
                                                                                <span>{locationData ? locationData.country : ''}</span>
                                                                                {/* {displayLoc}     */}
                                                                            </a>
                                                                            : ""
                                                                    }
                                                                </div>
                                                            </div>


                                                            <div className='d-flex '>
                                                                {
                                                                    phoneShowInPersonal
                                                                    &&
                                                                    <>
                                                                        <a href={"tel:" + phoneShowInPersonal} target="_blank" >
                                                                            <AiOutlinePhone className='display_name_profile' />
                                                                        </a>
                                                                        <p>
                                                                            <a href={"tel:" + phoneShowInPersonal} target="_blank" className='viewunique_call'>
                                                                                {phoneShowInPersonal}
                                                                            </a>
                                                                        </p>
                                                                    </>

                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='col-4'>
                                                            <div className='right_img'>
                                                                {
                                                                    bData.viewBusinessStatus
                                                                        ?
                                                                        <>
                                                                            {
                                                                                bData.image
                                                                                    ?
                                                                                    <img src={ImportedURL.FILEURL + bData.image} />
                                                                                    :
                                                                                    <img src={userlogo} />
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {(this.state.profileimg || data.image) && data.isProfileStatus ?
                                                                                <>
                                                                                    <img src={this.state.profileimg ? URL.createObjectURL(this.state.profileimg) : ImportedURL.LIVEURL + data.image} />
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <img src={userlogo} />
                                                                                </>
                                                                            }
                                                                        </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='skill_section'>
                                                    {
                                                        (data.skill && data.skill.length > 0)
                                                        &&
                                                        data.skill.map((item, i) => {
                                                            return (
                                                                <>
                                                                    <div className='skill_btn'>
                                                                        {item}
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </div>
                                                <div className='edit_contact_btn '>
                                                    <button onClick={() => this.vCard()} className="save_contact  custom-btn btn_save_cont">
                                                        <BiSave className="sidebar_icon mr-2" />Save Contact
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='accordian_sec'>
                                            <div class="accordion mt-3" id="accordionExample">
                                                {
                                                    (matchedData != undefined && matchedData.length > 0)
                                                    &&
                                                    matchedData.filter(e => e.status == true).map((app, i) => {
                                                        let { socialmediaValue } = this.state
                                                        let toggleStatus = (bData.applinkstatus && bData.applinkstatus.length > 0) ? (bData.applinkstatus).find((e) => e.appid == app._id) : ""
                                                        let status = (socialmediaValue && socialmediaValue.length > 0) ? (socialmediaValue.reverse()).find((e) => e.Uid == app._id) : ''

                                                        let link = ''
                                                        let valueData = ''
                                                        if (status != undefined && status) {
                                                            link = status.link
                                                            valueData = status.value
                                                        }
                                                        return (
                                                            <>
                                                                <div className='profile_infosec accordion-item edit_profile_infosec tap_view_box' onClick={(e) => this.sendModelView(app)}>
                                                                    <button class="btn d-flex justify-content-between align-items-center" type="button">
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='tap_left_icon_list'>
                                                                                <div className='img_size'>
                                                                                    <img src={ImportedURL.LIVEURL + app.applogo} />
                                                                                </div>
                                                                            </div>
                                                                            <label className='form-label mb-0 px-0 ml-3' data-toggle="modal" data-target="#exampleModalCenter"> {app.name}</label>
                                                                        </div>
                                                                        <div className='file_open mr-2' data-toggle="modal" data-target="#exampleModalCenter" >
                                                                            <MdFileOpen />
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>




                                        {/* =============Model xxxxxxxxxx  */}
                                        <div class="modal fade personal_details_model" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLongTitle">{modelTitle}</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body collapseSix modeleditopt">
                                                        {
                                                            (listapp != undefined && listapp.length > 0) && listapp.filter(e => e.status == true).map((app, i) => {
                                                                if (app._id == this.state.modelId) {
                                                                    let { socialmediaValue } = this.state
                                                                    let toggleStatus = (bData.applinkstatus && bData.applinkstatus.length > 0) ? (bData.applinkstatus).find((e) => e.appid == app._id) : ""
                                                                    let status = (socialmediaValue && socialmediaValue.length > 0) ? (socialmediaValue.reverse()).find((e) => e.Uid == app._id) : ''
                                                                    let link = ''
                                                                    let valueData = ''
                                                                    if (status != undefined && status) {
                                                                        link = status.link
                                                                        valueData = status.value
                                                                    }
                                                                    return (
                                                                        <div className='profile_images_box_shadow'>
                                                                            <div className='link_num_mail_show_desin '>
                                                                                {
                                                                                    (bData.applink && bData.applink.length > 0) &&
                                                                                    bData.applink.filter(e => e.appStatus == true).map((item, index) => {
                                                                                        if (item.parentid == app._id) {
                                                                                            return (
                                                                                                <>
                                                                                                    <div key={index} className='mb-2 link_num_mail_space'>
                                                                                                        {
                                                                                                            (item.inputtype == 'url')
                                                                                                            &&
                                                                                                            <a href={item.link.includes('https://') ? item.link : `https://${item.link}`} target="_blank" style={{ color: 'black', textDecoration: 'none' }}>
                                                                                                                <div className='img_size'>
                                                                                                                    <img src={ImportedURL.LIVEURL + item.logo} />
                                                                                                                </div>
                                                                                                                <span className='tap_view_appname'>{item.appname}</span>
                                                                                                            </a>
                                                                                                        }
                                                                                                        {
                                                                                                            (item.inputtype == 'number')
                                                                                                            &&
                                                                                                            <a href={"tel:" + ((item.cc.value != undefined && item.cc) ? item.cc.value + "" : "") + item.value} target="_blank" style={{ color: 'black', textDecoration: 'none' }}>
                                                                                                                <div className='img_size'>
                                                                                                                    <img src={ImportedURL.LIVEURL + item.logo} />
                                                                                                                </div>
                                                                                                                <span className='tap_view_appname'>{item.appname}</span>
                                                                                                            </a>
                                                                                                        }
                                                                                                        {
                                                                                                            (item.inputtype == 'email')
                                                                                                            &&
                                                                                                            <a href={"mailto:" + item.link} target="_blank" style={{ color: 'black', textDecoration: 'none' }}>
                                                                                                                <div className='img_size'>
                                                                                                                    <img src={ImportedURL.LIVEURL + item.logo} />
                                                                                                                </div>
                                                                                                                <span className='tap_view_appname'>{item.appname}</span>
                                                                                                            </a>
                                                                                                        }
                                                                                                        {
                                                                                                            (item.inputtype == 'document')
                                                                                                            &&
                                                                                                            <a href={ImportedURL.FILEURL + item.value} target="_blank" style={{ color: 'black', textDecoration: 'none' }}>
                                                                                                                <div className='img_size'>
                                                                                                                    <img src={ImportedURL.LIVEURL + item.logo} />
                                                                                                                </div>
                                                                                                                <span className='tap_view_appname'>{item.appname}</span>
                                                                                                            </a>
                                                                                                        }
                                                                                                        {
                                                                                                            (item.inputtype == 'none')
                                                                                                            &&
                                                                                                            <div className='img_size'>
                                                                                                                <img src={ImportedURL.LIVEURL + item.logo} />
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            {
                                                                                (bData.applink && bData.applink.length > 0)
                                                                                &&
                                                                                bData.applink.filter(e => e.appStatus == true).map((item, i) => {
                                                                                    let location = ((item.address && item.address.street) ? (item.address.street).replace(/,/g, "") + ',' : "") + ((item.address && item.address.city) ? (item.address.city).replace(/,/g, "") + "," : "") + ((item.address && item.address.state) ? (item.address.state).replace(/,/g, "") + ',' : "") + ((item.address && item.address.country) ? (item.address.country).replace(/,/g, "") : "")
                                                                                    const mapUrl = `https://www.google.com/maps/place/${encodeURIComponent(location)}`;
                                                                                    if (item.parentid == app._id) {
                                                                                        return (
                                                                                            <>
                                                                                                {
                                                                                                    (item.inputtype == 'address')
                                                                                                    &&
                                                                                                    <div className='tap_address_box'>
                                                                                                        <a href={mapUrl} target='_blank'>
                                                                                                            <div className='text_imgs'>
                                                                                                                <div className='img_size'>
                                                                                                                    <img src={ImportedURL.LIVEURL + item.logo} />
                                                                                                                </div>
                                                                                                                <div className='content'>
                                                                                                                    <h5>{item.appname}</h5>
                                                                                                                    <p>{item.address.street}, </p>
                                                                                                                    <p>{item.address.city}, </p>
                                                                                                                    <p>{item.address.state}, </p>
                                                                                                                    <p>{item.address.country}, </p>
                                                                                                                    <p>{item.address.zip}. </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                }

                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        </div>
                                                                    )
                                                                }

                                                            })
                                                        }
                                                        <div className='close_modal d-flex justify-content-center mt-3'>
                                                            <button type="button" class="btn " data-dismiss="modal">Close</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div >
                    <div className='crop_model_section'>
                        <Modal className={'info-modal crop_modal'} show={this.state.showModal}  >

                            <div className="modal-header">
                                <Modal.Title>{"Crop Image"}</Modal.Title>
                                <button type="button" id="closeModal" onClick={this.ModalImages} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            {/* <Modal.Header closeButton>
                                <Modal.Title>{"Crop Image"}</Modal.Title>
                            </Modal.Header> */}
                            <div class="modal-body">
                                <div class="crop_Images">
                                    {(this.state.src) && (
                                        <ReactCrop
                                            src={this.state.src}
                                            crop={this.state.crop}
                                            onImageLoaded={this.onImageLoaded}
                                            onComplete={this.onCropComplete}
                                            onChange={this.onCropChange}
                                        />
                                    )}
                                </div>
                            </div>
                            <div class="modal-footer">
                                <div className=''>
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={this.ModalImages}>Close</button>
                                </div>
                                <div className='profile_save_btn'>
                                    <button type="button" class="btn" onClick={this.CropImages}>Crop</button>
                                </div>
                            </div>
                        </Modal>
                    </div>

                    {
                        (spinner || this.state.updateSpinner) ?
                            <div style={{ height: '100vh', position: 'fixed', width: '100%', top: '0px', }}>
                                <div style={{ position: 'relative' }}></div>
                                <div className='common_loader'>
                                    <img className='loader_img_style_common' src='/assets/images/logo.jpg' />
                                    <Spinner className='spinner_load_common' animation="border" variant="info" >
                                    </Spinner>
                                </div>
                            </div>
                            : ""
                    }
                </div >
            </>


        )
    }
}

const mapStateToProps = (state) => ({
    ProfileState: state.profile,
    accountState: state.account,
    BusinessState: state.business,
    appState: state.app,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ListBusiness: AC_LIST_BUSINESSESS_UNIQUE,
        ViewProfileUnique: AC_VIEW_PROFILE_UNIQUEID,
        ViewProfileUniqueName: AC_VIEW_PROFILE_USERNAME,
        ViewBusiness: AC_VIEW_BUSINESSESS,
        SpinnerProfile: AC_PROFILE_SPINNER,
        ListApp: AC_LIST_APP_UNIQUE,
        HandleChangesSetting: AC_HANDLE_INPUT_CHANGE_SETTINGS,
        HandleInputChange: AC_HANDLE_INPUT_CHANGE_PROFILE,
        HandleInputBusinessChange: AC_HANDLE_INPUT_CHANGE_BUSINESS,
        ViewPersonal: AC_VIEW_PERSONAL,
        ListPersonal: AC_LIST_PERSONAL_UNIQUE,

    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewUniqueId);