import axios from 'axios';
import ImportedURL from '../common/api';

export function AC_HANDLE_INPUT_CHANGE_BLOODGROUP(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_INPUT_CHANGE_BLOODGROUP", name: name, value: value });
    };
}

export function AC_LIST_BLOODGROUP() {
    return function (dispatch) {
        return axios.get(ImportedURL.API.listBloodgroup)
            .then((res) => {
                dispatch({ type: "LIST_BLOODGROUP", payload: res.data, spinner: false })
            }).catch(({ response }) => {
                dispatch({ type: "LIST_BLOODGROUP", payload: [], spinner: false })
                console.log(response);
            });
    };
}


export function AC_VIEW_BLOODGROUP(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewBloodgroup + '/' + id)
            .then((res) => {
                dispatch({ type: "VIEW_BLOODGROUP", payload: res.data, spinner: false })
            }).catch(({ response }) => {
                dispatch({ type: "VIEW_BLOODGROUP", payload: {}, spinner: false })
                console.log(response);
            });
    };
}

export function AC_EMPTY_BLOODGROUP() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_BLOODGROUP" })
    };
}
