import React, { Component } from 'react'
import './style.css'
import { Container } from 'react-bootstrap'
import { PulseLoader } from 'react-spinners';

export default class Loading extends Component {
    render() {
        return (
            <>
                <div className='center-container'>
                    <Container>
                        <div className='error-page'>
                            <p className='oops-para'>Redirecting <PulseLoader color="#000000" size={5} /></p>
                        </div>
                    </Container>
                </div>
            </>
        )
    }
}
