"use client";
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";


export default function create() {
    const scrollEnquiry = () => {
        let formEnquiry = document.getElementById("product_link");
        if (formEnquiry) {
            formEnquiry.scrollIntoView()
        }
    }
    return (
        <div className='get_start_sec section'>
            <Container className='container-new-big'>
                <Row>
                    <Col lg={6} className='left_cre mt-3'>
                        <div className='left_start'>
                            <h2>
                                Create Your Card
                            </h2>
                            <p>Ready to stand out in the digital world? Create your ACW Card now, it's quick and easy! Choose our <strong> PVC NFC Cards </strong> and boost your business.</p>
                            <div className='website_btn1'>
                                <Link to="#product_link" onClick={scrollEnquiry} title='Get started'>Get started</Link>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} className='mt-3'>
                        <div className='right_start'>
                            <img src='./landing_page/assets/images/create.png' alt='create' />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
