import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import ImportedURL from "../../common/api";
import { AC_EMPTY_HOTEL, AC_SPINNER_HOTEL, AC_VIEW_HOTEL } from "../../actions/hotelAction";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class ViewHotel extends React.Component {
    componentDidMount() {
        this.props.EmptyHotel()
        const { params, path } = this.props.match;
        if (params.id) {
            this.props.SpinnerHotel();
            this.props.ViewHotel(params.id);
            this.setState({ modalType: path === "/view-user/:id" ? "View" : "Edit", id: params.id })
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    render() {
        const { hotelState, AccountState } = this.props;
        const data = hotelState.hotel
        const spinner = hotelState.spinner
        const role = AccountState.role;
        return (
            <>
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-12 breadcome_value">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item header_color_breadcome"> <Link to='/admin'>Dasboard</Link></li>
                                    {role == 'admin' ? <li class="breadcrumb-item active"><Link to='/admin/list-hotel'>List Hotel</Link></li> : ''}
                                    <li class="breadcrumb-item active">View Hotel</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="col-md-12">
                        <div class="card card-primary header_border" >
                            <div class="table-title">
                                <div className="card-header">
                                    <h3 className="card-title d-flex "> <div className='rounded_icon'>  <i className="fa fa-h-square" /></div><h2 class="card-title header_title">VIEW HOTEL</h2> </h3>
                                    {
                                        (role == 'admin')
                                            ?
                                            <div className="card-options">
                                                <div className="d-flex justify-content-end">
                                                    <div className="header-action mr-2">
                                                        <Link to='/admin/list-hotel'><button type="button" className="btn btn-primary button_color" id='Add'> <i className="fa fa-arrow-left mr-2" id='Add' />Back</button></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    }
                                </div>
                                <form id="quickForm" autoComplete="off">
                                    <div class="card-body">
                                        <div className="row">
                                            <div className="col-3" style={{ paddingTop: '15px' }}>
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Logo</label>
                                                </div>
                                            </div>
                                            <div className="col-2" style={{ paddingTop: '15px' }}>:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    <a href={ImportedURL.LIVEURL + data.logo} target="_blank" style={{ color: 'black', textDecoration: 'none' }}>
                                                        <img src={data.logo ? ImportedURL.LIVEURL + data.logo : ImportedURL.LIVEURL + 'public/assets/images/notfoundimage.jpg'} width={60} height={60} />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Hotel Name</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    {data.name ? data.name : '---'}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Email</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    {data.email ? data.email : '---'}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Phone Number</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    {data.phonenumber ? data.phonenumber : '---'}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Hotel ID</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    {data.hoteltype ? data.hoteltype : '---'}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Address</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    {data.address ? data.address : '---'}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">City</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    {data.city ? data.city : '---'}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">State</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    {data.state ? data.state : '---'}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Postcode</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    {data.postcode ? data.postcode : '---'}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Country</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group">
                                                    {data.country ? data.country : '---'}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Tripadvisor Social Review</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group text_overflow_hotel">
                                                    <a href={data.tripadvisorurl ? data.tripadvisorurl : ''}>
                                                        {data.tripadvisorurl ? data.tripadvisorurl : '---'}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Google Social Review</label>
                                                </div>
                                            </div>
                                            <div className="col-2">:</div>
                                            <div className="col-7">
                                                <div className="form-group text_overflow_hotel">
                                                    <a href={data.googleurl ? data.googleurl : ''}>
                                                        {data.googleurl ? data.googleurl : '---'}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div >
                </div >
                {
                    spinner ?
                        <div className='common_loader_ag_grid'>
                            < img className='loader_img_style_common_ag_grid' src='/assets/images/logo.jpg' />
                            <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                            </Spinner>
                        </div >
                        : ""
                }
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        AccountState: state.account,
        hotelState: state.hotel,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ViewHotel: AC_VIEW_HOTEL,
        SpinnerHotel: AC_SPINNER_HOTEL,
        EmptyHotel: AC_EMPTY_HOTEL,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withParams(ViewHotel));