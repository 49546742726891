import Bannervideo from "./bannervideo";
import Explore from "./explore";
import Product from "./product";
import About from "./about";
import Virtualcard from "./virtualcard";
import Works from "./works";
import Pricing from "./pricing";
import Bulk from "./bulk";
import Testimonials from "./testimonial";
import Create from "./create";
import Demo from "./demo";

function Index() {
    return (
        <>
            <Bannervideo />
            <Explore />
            <Product />
            <About />
            <Virtualcard />
            <Works />
            {/* <Pricing /> */}
            <Bulk/>
            <Testimonials/>
            <Create/>
            <Demo/>
        </>
    );
}

export default Index;
