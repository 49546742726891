import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import axios from 'axios';
import { Link } from "react-router-dom";
import ImportedURL from "../../common/api";
import { Spinner } from "react-bootstrap";
import { Error, Success } from "../../common/swal";
import { Redirect } from 'react-router-dom';
import { useParams } from "react-router";
import CountryCodeJson from "../../common/countrycode.json";
import Select from "react-select";
import { Emailvalidate, Imagevalidation, Phonenumber } from "../../common/validate";
import { AC_EMPTY_HOTEL, AC_HANDLE_INPUT_CHANGE_HOTEL, AC_SPINNER_HOTEL, AC_VIEW_HOTEL } from "../../actions/hotelAction";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class AddHotel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalType: "Add",
            id: '',
            preview: [],
            username: '',
            email: '',
            password: '',
            nameError: false,
            usernameError: false,
            emailError: false,
            passwordError: false,
            hidepassword: false,
            emailValidError: false,
            barCodeValue: '',
            logo: ''
        }
    }
    componentDidMount() {
        this.props.EmptyHotel();
        const { params, path } = this.props.match;
        if (params.id) {
            this.props.SpinnerHotel();
            this.props.ViewHotel(params.id);
            this.setState({ modalType: path === "/view-room/:id" ? "View" : "Edit", id: params.id })
        }

    }
    submit = () => {
        const { hotelState } = this.props;
        const getData = hotelState.hotel
        const data = { ...getData };
        for (let key in data) {
            if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
                data[key] = data[key].trim();
            }
        }
        let valid = 1;
        if (!data.name) {
            this.setState({ nameError: true });
            valid = 0;
        }
        if (!data.email) {
            this.setState({ emailError: true });
            valid = 0;
        }
        if (!data.phonenumber) {
            this.setState({ phonenumberError: true });
            valid = 0;
        }
        if (!data.hoteltype) {
            this.setState({ hoteltypeError: true });
            valid = 0;
        }
        if (!data.address) {
            this.setState({ addressError: true });
            valid = 0;
        }
        if (!data.city) {
            this.setState({ cityError: true });
            valid = 0;
        }
        if (!data.state) {
            this.setState({ stateError: true });
            valid = 0;
        }

        if (!data.postcode) {
            this.setState({ postcodeError: true });
            valid = 0;
        }
        if (!data.country) {
            this.setState({ countryError: true });
            valid = 0;
        }
        if (!data.logo) {
            this.setState({ logoError: true });
            valid = 0;
        }
        if (!data.tripadvisorurl) {
            this.setState({ tripadvisorurlError: true });
            valid = 0;
        }
        if (!data.googleurl) {
            this.setState({ googleurlError: true });
            valid = 0;
        }
        if (this.state.emailValidError) {
            valid = 0;
        }
        if (this.state.phonenumberValidError) {
            valid = 0;
        }
        if (this.state.invalidFileError) {
            valid = 0;
        }

        if (valid) {
            var formData = new FormData();
            for (let key in data) {
                if (key === 'cc') formData.append(key, JSON.stringify(data[key]));
                else formData.append(key, data[key])
            }
            this.setState({ saving: true })
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addHotel, formData)
                    .then((res) => {
                        this.setState({ saving: false, ListState: true })
                        Success("Hotel created successfully");
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        this.ErrorResponse(response);
                    });
            } else {
                axios.post(ImportedURL.API.updateHotel + "/" + this.state.id, formData)
                    .then((res) => {
                        this.setState({ saving: false, ListState: true })
                        Success("Hotel updated successfully");
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        this.ErrorResponse(response);
                    });
            }
        }
    }
    ErrorResponse = (response) => {
        if (response) {
            if (response.status == 401) {
                Error('Something wrong, Retry again!')
            } else if (response.status == 502) {
                Error(response.status + ' Bad Gateway')
            } else if (response.status == 500) {
                Error('Internal Server Error')
            } else if (response.status == 409) {
                Error('Email already exist')
            } else if (response.status == 408) {
                Error('Username already exist')
            } else if (response.status == 406) {
                Error('Hotel ID already exist')
            } else if (response.status == 405) {
                Error('Email already exist in user')
            } else if (response.status == 400) {
                Error('Bad request')
            }
        } else {
            Error("Bad Gateway")
        }
    }

    onChange = e => {
        const data = this.props.hotelState.hotel
        const { name, value } = e.target;
        const Error = name + "Error";
        const ValidError = name + "ValidError";
        this.setState({ [name]: value, [Error]: false })
        if (name === 'email') {
            this.props.HandleChange(name, value)
            var email = value;
            if (email) {
                if (Emailvalidate(email)) {
                    this.setState({ [ValidError]: false, [Error]: false })
                } else {
                    this.setState({ [ValidError]: true })
                }
            }
            else {
                this.setState({ emailError: true, [ValidError]: false });
            }
        } else if (name == 'phonenumber') {
            let s1 = value.replace(/[^a-z\d\s]+/gi, "");
            let s2 = s1.replace(/[- )(]/g, '')
            var s3 = s2.slice(0, 10);
            var val = s3.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            this.props.HandleChange(name, val);
            if (val) {
                if (Phonenumber(val)) {
                    this.setState({ [ValidError]: false })
                } else {
                    this.setState({ [ValidError]: true })
                }
            } else {
                this.setState({ [ValidError]: false })
            }
        } else if (name == 'hoteltype') {
            let s1 = value.replace(/[^a-z\d\s]+/gi, "");
            let s2 = s1.replace(/[- )(]/g, '')
            var val = s2.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            this.props.HandleChange(name, val);
        } else if (name == 'logo') {
            const imgvalidate = Imagevalidation(e.target.files[0]);
            if (imgvalidate) {
                this.setState({ invalidFileError: false })
                this.props.HandleChange(name, e.target.files[0]);
            } else {
                this.setState({ invalidFileError: true })
                document.getElementById('imageSet').value = '';
                this.props.HandleChange(name, '');
            }
        } else {
            this.props.HandleChange(name, value)
        }
    }
    onSelectFlag = (e) => {
        const { name, value, label } = e;
        let data = {
            name: value,
            value: label,
        };
        this.props.HandleChange("cc", data);
    };
    render() {
        if (this.state.ListState) return <Redirect to={'/admin/list-hotel'} />

        const { accountState, hotelState } = this.props;
        const data = hotelState.hotel
        const spinner = hotelState.spinner;

        return (
            <>
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-12 breadcome_value">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item header_color_breadcome"> <Link to='/admin'>Dasboard</Link></li>
                                    <li class="breadcrumb-item active"><Link to='/admin/list-hotel'>List Hotel</Link></li>
                                    <li class="breadcrumb-item active">{this.state.modalType} Hotel</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="col-md-12">
                        <div class="card card-primary header_border" >
                            <div class="table-title">
                                <div className="card-header">
                                    <h3 className="card-title d-flex "> <div className='rounded_icon'> <i className="fa fa-h-square" /></div><h2 class="card-title header_title">{(this.state.modalType).toUpperCase()} Hotel</h2> </h3>
                                    <div className="card-options">
                                        <div className="d-flex justify-content-end">
                                            <div className="header-action mr-2">
                                                <Link to='/admin/list-hotel'><button type="button" className="btn btn-primary button_color" id='Add'> <i className="fa fa-arrow-left mr-2" id='Add' />Back</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <form id="quickForm" autoComplete="off">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Hotel Name<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='name' value={data.name} onChange={this.onChange} placeholder="Hotel Name" />
                                                    <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Hotel Name is required</div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Email<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='email' value={data.email} onChange={this.onChange} placeholder="Email" />
                                                    <div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Email is required</div>
                                                    <div className="invalid-feedback" style={{ display: this.state.emailValidError ? 'block' : 'none' }}>Enter valid email</div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <label for="exampleInputEmail1">Phone Number<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                <div className="row">
                                                    <div className="col-4">
                                                        <Select
                                                            value={data.cc && data.cc.value != undefined ? { label: data.cc.value, value: data.cc.name, flag: data.cc.name.toLowerCase(), } : ""}
                                                            onChange={this.onSelectFlag}
                                                            options={CountryCodeJson &&
                                                                CountryCodeJson.map((item) => {
                                                                    return {
                                                                        label: item.dial_code,
                                                                        flag: item.code.toLowerCase(),
                                                                        value: item.code,
                                                                        name: "cc",
                                                                    };
                                                                })
                                                            }
                                                            formatOptionLabel={(e) => {
                                                                return (
                                                                    <div style={{ display: "block", alignItems: "center", }} >
                                                                        {e.flag ? (
                                                                            <img src={ImportedURL.LOCALURL + "assets/images/flags/" + e.flag + ".svg"}
                                                                                style={{ height: "20px", width: "25px", objectFit: "contain", }}
                                                                                className="contain_image"
                                                                            />
                                                                        ) : ("")}
                                                                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                                    </div>
                                                                );
                                                            }}
                                                            styles={{
                                                                menu: provided => ({ ...provided, zIndex: 9999, textAlign: 'left' })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-8">
                                                        <div class="form-group">
                                                            <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='phonenumber' value={data.phonenumber} onChange={this.onChange} placeholder="Phone Number" />
                                                        </div>
                                                    </div>
                                                    <div className="invalid-feedback" style={{ display: this.state.phonenumberError ? "block" : 'none' }}>Phone Number is required</div>
                                                    <div className="invalid-feedback" style={{ display: this.state.phonenumberValidError ? "block" : 'none' }}>Phone Number should contain 10 digits</div>
                                                </div>

                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Hotel ID<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='hoteltype' value={data.hoteltype} onChange={this.onChange} placeholder="Hotel ID" />
                                                    <div className="invalid-feedback" style={{ display: this.state.hoteltypeError ? "block" : 'none' }}>Hotel ID is required</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">
                                                        Address<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='address' onChange={this.onChange} value={data.address} placeholder="Address" />
                                                    <div className="invalid-feedback" style={{ display: this.state.addressError ? "block" : 'none' }}> Address is required</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">
                                                        City<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='city' onChange={this.onChange} value={data.city} placeholder="City" />
                                                    <div className="invalid-feedback" style={{ display: this.state.cityError ? "block" : 'none' }}> City is required</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">
                                                        State<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='state' onChange={this.onChange} value={data.state} placeholder="State" />
                                                    <div className="invalid-feedback" style={{ display: this.state.stateError ? "block" : 'none' }}> State is required</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">
                                                        Postcode<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='postcode' onChange={this.onChange} value={data.postcode} placeholder="Postcode" />
                                                    <div className="invalid-feedback" style={{ display: this.state.postcodeError ? "block" : 'none' }}> Postcode is required</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">
                                                        Country<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='country' onChange={this.onChange} value={data.country} placeholder="Country" />
                                                    <div className="invalid-feedback" style={{ display: this.state.countryError ? "block" : 'none' }}> Country is required</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">
                                                        Tripadvisor Social Review<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='tripadvisorurl' onChange={this.onChange} value={data.tripadvisorurl} placeholder="Tripadvisor Social Review" />
                                                    <div className="invalid-feedback" style={{ display: this.state.tripadvisorurlError ? "block" : 'none' }}> Tripadvisor Social Review is required</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">
                                                        Google Social Review<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='googleurl' onChange={this.onChange} value={data.googleurl} placeholder="Google Social Review" />
                                                    <div className="invalid-feedback" style={{ display: this.state.googleurlError ? "block" : 'none' }}> Google Social Review is required</div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">
                                                        Logo<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input type="file" accept="image/*" className="form-control" placeholder="Logo" name='logo' onChange={this.onChange} id="imageSet" />
                                                    <div className="invalid-feedback" style={{ display: this.state.logoError ? "block" : 'none' }}>Logo is required</div>
                                                    <div className="invalid-feedback" style={{ display: this.state.invalidFileError ? "block" : 'none' }}>Invalid file extension</div>
                                                </div>
                                                {data.logo ?
                                                    <div className="form-group">
                                                        <img src={typeof data.logo == 'string' ? ImportedURL.LIVEURL + data.logo : window.URL.createObjectURL(data.logo)} width="100px" alt='' />
                                                    </div>
                                                    : ''}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="card-footer text-right mandatory">
                                        <label className="text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                        <div className="text-right">
                                            {
                                                this.state.saving ?
                                                    <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>{this.state.modalType == "Edit" ? "Updating" : "Saving"}</button>
                                                    :
                                                    <button type="button" className="btn commor_save" onClick={this.submit}>
                                                        <i className="fe fe-save mr-2"></i>{this.state.modalType == "Edit" ? "Update" : "Save"}
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {spinner ?
                    <div className='common_loader_ag_grid'>
                        <img className='loader_img_style_common_ag_grid' src='/assets/images/logo.jpg' />
                        <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                        </Spinner>
                    </div>
                    : ""}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        accountState: state.account,
        hotelState: state.hotel,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleChange: AC_HANDLE_INPUT_CHANGE_HOTEL,
        ViewHotel: AC_VIEW_HOTEL,
        EmptyHotel: AC_EMPTY_HOTEL,
        SpinnerHotel: AC_SPINNER_HOTEL,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)((AddHotel));