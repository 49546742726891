"use client";
import React from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function bulk() {
    const scrollEnquiry = () => {
        let formEnquiry = document.getElementById("enquiry-form-data");
        if (formEnquiry) {
            formEnquiry.scrollIntoView()
        }
    }
    return (
        <div className='bulk_order_sec section about_bulk_sec'>
            <Container className='container-new-big'>
                <div className='bulk_order'>
                    <h2>
                        Bulk Orders
                    </h2>
                    <p>We also provide options for bulk orders for corporate teams. You can get PVC customized cards according to corporate needs.</p>
                    {/* <Button title='Enquiry'>Enquiry</Button> */}
                    <Link to='/details/enquiry-form'>
                        <Button className='about-bulk-btn' onClick={scrollEnquiry}>Enquiry</Button>
                    </Link>
                </div>
            </Container>
        </div>
    )
}
