const initialState = {
    viewLandingPageEnquiry: {},
    listLandingPageEnquiry: [],
    spinner: true,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'LIST_LANDING_PAGE_ENQUIRIY':
            return {
                ...state,
                listLandingPageEnquiry: action.payload,
                spinner: action.spinner
            }
        case 'LANDING_PAGE_ENQUIRIY_SPINNER':
            return Object.assign({}, state, {
                spinner: true
            })
        case 'VIEW_LANDING_PAGE_ENQUIRIY':
            return Object.assign({}, state, {
                viewLandingPageEnquiry: action.payload,
                spinner: action.spinner
            })

        default:
            return state;
    }
}