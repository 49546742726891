import axios from 'axios';
import ImportedURL from '../common/api';


export function AC_HANDLE_SWAP_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_SWAP_CHANGE", name: name, value: value });
    }
}

export function AC_EMPTY_SWAP() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_SWAP" })
    };
}