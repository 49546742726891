import React, { Component } from "react";
import { connect } from "react-redux";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { bindActionCreators } from "redux";
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { AC_CHANGE_PREVILEGE, AC_DELETE_CATEGORY, AC_EMPTY_CATEGORY, AC_HANDLE_CATEGORY_CHANGE, AC_LIST_CATEGORY, AC_RESET_CATEGORY, AC_SPINNER_CATEGORY, AC_UPDATE_CATEGORY, AC_VIEW_CATEGORY } from '../../actions/categoryAction';
import CONSTANTS from "../../common/constants";
import ImportedURL from "../../common/api";
import { Error, Success } from "../../common/swal";
import Spinner from 'react-bootstrap/Spinner';

class AddCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: "Add",
      id: "",
      previlegeupdated: false,
      saving: false,
      spinner: false,
      oldprevileges: '',
      initialchange: true,
    };
  }

  onChange = e => {
    const { name, value } = e.target;
    const Error = name + "Error";
    this.props.HandleInputChange(name, value);
    this.setState({ [Error]: false });
  }
  changePrevilege = (index, key) => {
    this.props.ChangePrevilege(index, key);
    this.setState({ previlegeupdated: true })

    if (this.state.initialchange && this.state.modalType == 'Edit') {
      const { CategoryState } = this.props;
      this.setState({ oldprevileges: CategoryState.category.previleges, initialchange: false });
    }
  }
  setAll = e => {
    const { checked } = e.target;
    let previleges = checked ? CONSTANTS.allprevileges : CONSTANTS.previleges;
    this.props.HandleInputChange('previleges', previleges);
    this.setState({ previlegeupdated: true, setChecked: checked })
    if (this.state.initialchange && this.state.modalType == 'Edit') {
      const { CategoryState } = this.props;
      this.setState({ oldprevileges: CategoryState.category.previleges, initialchange: false });
    }

  }
  fetchPermissions = () => {
    const { CategoryState } = this.props;
    let categoryprevileges = CategoryState.category && CategoryState.category.previleges ? [...CategoryState.category.previleges] : [];
    let previleges = CONSTANTS.previleges;
    for (let i = 0; i < previleges.length; i++) {
      let Previlege = categoryprevileges.find(obj => { return obj.name == previleges[i].name });
      if (!Previlege) categoryprevileges.push(previleges[i])
    }
    this.props.HandleInputChange('previleges', categoryprevileges);
    this.setState({ previlegeupdated: true })
  }
  submit = () => {
    const { history } = this.props;
    const data = this.props.CategoryState.category;
    data['name'] = data.name ? data.name.trim() : ''
    if (!data.name) {
      this.setState({ nameError: true });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
    else {
      this.setState({ saving: true })
      const formData = {
        previleges: JSON.stringify(data.previleges),
        name: data.name,
      }
      if (this.state.modalType === "Add") {
        axios.post(ImportedURL.API.addCategory, formData)
          .then((res) => {
            this.setState({ saving: false })
            Success("Category Created Successfully");
            this.props.ResetCategory();
            history.push("/admin/list-category");
          }).catch(({ response }) => {
            this.setState({ saving: false });
            this.ErrorResponses(response);
          });
      } else {
        axios.post(ImportedURL.API.updateCategory + "/" + this.state.id, formData)
          .then((res) => {
            this.setState({ saving: false })
            Success("Category Updated Successfully");
            history.push("/admin/list-category");
            this.setState({ previlegeupdated: false })
          }).catch(({ response }) => {
            this.setState({ saving: false });
            this.ErrorResponses(response);
          });
      }
    }
  }
  ErrorResponses = (response) => {
    if (response) {
      if (response.status == 409) {
        Error('Category Already Exist')
      } else if (response.status == 400) {
        Error('Bad request')
      } else if (response.status == 500) {
        Error(response.status + ' Internal Server Error')
      } else if (response.status == 502) {
        Error(response.status + ' Bad Gateway')
      } else if (response.status == 408) {
        Error('Sort order Already Exist')
      } else {
        Error(response.statusMessage)
      }
    } else {
      Error("Bad Gateway");
    }
  }
  componentDidMount() {
    this.props.ListCategory();
    this.props.EmptyCategory();
    const { params, path } = this.props.match;
    if (params.id) {
      this.props.SpinnerCategory();
      this.props.ViewCategory(params.id);
      this.setState({ modalType: path === "/admin/view-category/:id" ? "View" : "Edit", id: params.id })
    } else {
      this.props.ResetCategory();
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
  render() {
    const { CategoryState, AccountState } = this.props;
    const data = CategoryState.category;
    const spinner = CategoryState.spinner;
    const previleges = data.previleges ? data.previleges : CONSTANTS.previleges;
    return (
      <>
        <div>
          <div class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-12 breadcome_value">
                  <ol class="breadcrumb ">
                    <li class="breadcrumb-item header_color_breadcome"> <Link to='/admin'>Dasboard</Link></li>
                    <li class="breadcrumb-item header_color_breadcome"> <Link to='//admin/list-category'>List Category</Link></li>
                    <li class="breadcrumb-item active">{this.state.modalType} Category</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div class="col-md-12">
              <div class="card card-primary header_border" >
                <div class="table-title">
                  <div className="card-header">
                    <h3 className="card-title d-flex "> <div className='rounded_icon'> <i className="icon-bar-chart" /></div><h2 class="card-title header_title">{(this.state.modalType).toUpperCase()} COMPANY</h2> </h3>
                    <div className="card-options">
                      <div className="d-flex justify-content-end">
                        <div className="header-action mr-2">
                          <Link to='/admin/list-category'><button type="button" className="btn btn-primary button_color" id='Add'> <i className="fa fa-arrow-left mr-2" id='Add' />Back</button></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <form id="quickForm" autoComplete="off">
                    <div class="card-body">
                      <div class="row">
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group" style={this.state.modalType == "View" ? { display: "inline-flex", fontSize: "18px" } : {}}>
                            <label className="form-label" style={this.state.modalType ? { fontSize: "18px" } : {}}>Category Name<span className="ml-1" style={this.state.modalType == "View" ? { padding: "0px 20px", } : {}}>{this.state.modalType == "View" ? <span>:</span> : <span style={{ color: "red" }}>*</span>}</span></label>
                            {this.state.modalType == "View" ? <label>{data.name}</label> : <input type="text" className="form-control" placeholder="Category Name" name="name" disabled={this.state.modalType == "View"} onChange={this.onChange} value={data.name} />}
                            <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Category Name is required</div>
                          </div>
                        </div>
                        {this.state.modalType == "View" ?
                          <div className="card set_permission">
                            <div className="cord-body py-4">
                              <div className="category_previleges">
                                <h5> Set Permissons </h5>
                              </div>
                              {
                                previleges.map((data, index) => {
                                  if (data.add || data.edit || data.view || data.invite || data.share) {
                                    return (
                                      <>
                                        <div className="category_prev_list pt-3">
                                          <div className="row">
                                            <div className="col-lg-3">
                                              <div className="mainten_name">
                                                <h6>{data.name}</h6>
                                              </div>
                                            </div>
                                            <div className="col-lg-1">
                                              <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                            </div>
                                            <div className="col-lg-8">
                                              <div className="category_access">
                                                <ul>
                                                  {data.add ? <li><span className="tag tag-blue mb-3">ADD</span></li> : ''}
                                                  {data.view ? <li><span className="tag tag-pink mb-3">VIEW</span></li> : ''}
                                                  {data.edit ? <li><span className="tag mb-3" style={{ background: "#5a5278", color: "white" }}>EDIT</span></li> : ''}
                                                  {data.share ? <li><span className="tag tag-gray mb-3" style={{ background: "#e4bd51", color: "white" }}>SHARE</span></li> : ''}
                                                  {data.invite ? <li><span className="tag mb-3" style={{ background: "#cedd7a", color: "white" }}>INVITE</span></li> : ''}
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )
                                  }
                                })
                              }
                            </div>
                          </div>
                          :
                          <div className="col-lg-12 category_pad">
                            <div className="card add_category">
                              <div className="card-header set_permission_res">
                                <h3 className="card-title">Set Permissons</h3>
                                <div className="card-options">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <ul className="nav nav-tabs page-header-tab"></ul>
                                    <div className="header-action">
                                      <div className="input-group set_permission_input_res">
                                        {this.state.oldprevileges && <span className="input-group-btn mr-2">
                                          <button className="btn btn-icon btn-sm show-fetch" type="button" onClick={() => { this.props.HandleInputChange('previleges', this.state.oldprevileges); this.setState({ initialchange: true, oldprevileges: '', previlegeupdated: false, setChecked: false }) }} >
                                            <span className="fa fa-refresh mr-2"></span>Reset old permissions
                                          </button>
                                        </span>}
                                        <span className="input-group-btn mr-2">
                                          <button className="btn btn-icon btn-sm fetch_btn" type="button" onClick={this.fetchPermissions} >
                                            <span className="fa fa-refresh mr-2" style={{ position: 'relative', top: '3px' }}></span>Fetch Permissons
                                          </button>
                                        </span>
                                        <label className="custom-switch">
                                          <input
                                            checked={this.state.setChecked}
                                            type="checkbox"
                                            name="custom-switch-checkbox"
                                            className="custom-switch-input"
                                            onChange={this.setAll}
                                          />
                                          <span className="custom-switch-indicator"></span>
                                          <span className="custom-switch-description">
                                            Set All
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body card-footer" style={{ border: "none" }}>
                                <div className="Over_all_table_style table-responsive edit_user_table">
                                  <table className="table table-striped mb-0 ">
                                    <thead>
                                      <tr>
                                        <th style={{ textAlign: 'center' }}>Management</th>
                                        <th>View</th>
                                        <th>Add</th>
                                        <th>Edit</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {previleges.map((data, index) => {
                                        if (data.sidebarname) {
                                          return (
                                            <tr key={index}>
                                              <td style={{ position: 'relative' }}>
                                                <p style={{ marginTop: "revert", textAlign: 'center' }}>
                                                  {data.sidebarname} {data.mobile ? <i class="fa fa-mobile ml-2 mobile_permission_icon" aria-hidden="true"></i> : ''}
                                                </p>
                                              </td>
                                              <td>
                                                {
                                                  data.view != undefined ?
                                                    <label className="custom-control custom-checkbox">
                                                      <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={data.view} onChange={(event) => this.changePrevilege(index, 'view')} />
                                                      <span className="custom-control-label"></span>
                                                    </label>
                                                    : "---"
                                                }
                                              </td>
                                              <td>
                                                {data.name !== "Company" ?
                                                  (
                                                    data.add != undefined ?
                                                      <label className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={data.add} onChange={(event) => this.changePrevilege(index, 'add')} />
                                                        <span className="custom-control-label"></span>
                                                      </label>
                                                      : '---'
                                                  )
                                                  : '---'
                                                }
                                              </td>
                                              <td>
                                                {data.name !== "Company" ?
                                                  (
                                                    data.edit != undefined ?
                                                      <label className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={data.edit} onChange={(event) => this.changePrevilege(index, 'edit')} />
                                                        <span className="custom-control-label"></span>
                                                      </label>
                                                      : '---'
                                                  )
                                                  : '---'
                                                }
                                              </td>
                                            </tr>
                                          )
                                        }
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    {
                      this.state.modalType != "View"
                        ?
                        <div className="card-footer text-right mandatory">
                          <label className="text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                          <div className="text-right">
                            {
                              this.state.saving ?
                                <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>{this.state.modalType == "Edit" ? "Updating" : "Saving"}</button>
                                :
                                <button type="button" className="btn commor_save" onClick={this.submit}>
                                  <i className="fe fe-save mr-2"></i>{this.state.modalType == "Edit" ? "Update" : "Save"}
                                </button>
                            }
                          </div>
                        </div>
                        : ''
                    }
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="overlay" style={{ display: spinner ? 'block' : 'none' }}></div>
        {spinner ?
          <div className="common_loader">
            <img className='loader_img_style_common' src='../../assets/images/load.png' />
            <Spinner className='spinner_load_common' animation="border" variant="info" >
            </Spinner>
          </div> : ''
        }
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  CategoryState: state.category,
  AccountState: state.account,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      EmptyCategory: AC_EMPTY_CATEGORY,
      HandleInputChange: AC_HANDLE_CATEGORY_CHANGE,
      ViewCategory: AC_VIEW_CATEGORY,
      UpdateCategory: AC_UPDATE_CATEGORY,
      ResetCategory: AC_RESET_CATEGORY,
      SpinnerCategory: AC_SPINNER_CATEGORY,
      ChangePrevilege: AC_CHANGE_PREVILEGE,
      ListCategory: AC_LIST_CATEGORY
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(AddCategory);
