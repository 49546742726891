const initialState = {
    swap: {
        olduniqueid: '',
        newuniqueid: '',
    },
    spinner: true,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_SWAP_CHANGE':
            return Object.assign({}, state, {
                swap: {
                    ...state.swap,
                    [action.name]: action.value
                }
            })

        case 'EMPTY_SWAP':
            return Object.assign({}, state, {
                swap: {
                    olduniqueid: '',
                    newuniqueid: '',
                },
                spinner: false
            })

        default:
            return state;
    }
}