import React, { Component } from 'react'
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Container, Col, Spinner } from 'react-bootstrap';
import { AiFillSetting, AiFillCamera } from 'react-icons/ai';
import { Link } from "react-router-dom";
import { AC_HANDLE_INPUT_CHANGE_SETTINGS, AC_VIEW_PROFILE } from '../../actions/profileAction';
import ImportedURL from '../../common/api';
import { Success, Error } from '../../common/swal';
import { QrReader } from 'react-qr-reader';
import { AC_ACCOUNT_DETAILS } from '../../actions/accountAction';

class SwapID extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openScanner: false
        }
        this.qrReaderRef = React.createRef();
    }
    componentDidMount() {
        document.title = 'ACW CARD - Settings'
        document.description = 'ACW Card enables you to share your business and personal profiles along with digital uploads of key documents to strengthen your portfolio.'
        this.props.ViewProfile();
    }
    submit = () => {
        const { ProfileState } = this.props;
        const data = ProfileState.profile
        let valid = 1;
        if (!this.state.uniqueId) {
            valid = 0;
            Error('Scan a QR Code')
        }
        if (this.state.uniqueId == data.uniqueid) {
            valid = 0;
            Error('Swap ID are same')
        }
        if (valid) {
            this.setState({ updateSpinner: true })
            var formData = {
                uniqueId: this.state.uniqueId ? this.state.uniqueId : '',
                cardtype: this.state.cardtype ? this.state.cardtype : '',
                companytype: this.state.companytype ? this.state.companytype : '',
                _id: data._id ? data._id : '',
                uniqueid: data.uniqueid ? data.uniqueid : '',
            }
            axios.post(ImportedURL.API.swapAccountIdInUser, formData)
                .then((res) => {
                    this.setState({ updateSpinner: false })
                    Success('Unique ID swaped successfully');
                    this.props.AccountDetails();
                    this.props.ViewProfile();
                    this.setState({ uniqueId: '', cardtype: '', companytype: '' })
                }).catch(({ response }) => {
                    this.setState({ updateSpinner: false })
                    if (response) {
                        if (response.status == 401) {
                            Error('Something wrong, Retry again!')
                        } else if (response.status == 510) {
                            Error('Email does not exit')
                        } else if (response.status == 502) {
                            Error(response.status + ' Bad Gateway')
                        } else if (response.status == 500) {
                            Error('Internal Server Error')
                        } else if (response.status == 409) {
                            Error('Username already exist')
                        } else if (response.status == 408) {
                            Error('Email already exist')
                        } else if (response.status == 400) {
                            Error('Bad request')
                        } else {
                            Error('Bad request')
                        }
                    }
                });
        }
    }
    scannerStatus = () => {
        this.setState({ openScanner: !this.state.openScanner })
    }
    scannerData = (result, error) => {
        if (result && result?.text) {
            const unique = result?.text.split('/')[3]
            const id = unique ? unique.split('&uId=')[1] : '';
            let uniqueId = id ? id.substring(0, 12) : '';
            let cardtype = '';
            let companytype = '';
            if (uniqueId) {
                if (unique.includes('companyType=')) {
                    cardtype = (unique.split('&')[0]).split('cardType=')[1]
                    companytype = (unique.split('&')[1]).split('companyType=')[1]
                } else {
                    cardtype = (unique.split('&uId=')[0]).split('cardType=')[1]
                }
            }
            if (uniqueId) {
                this.setState({ uniqueId: uniqueId, cardtype: cardtype, companytype: companytype, openScanner: false })
            }
        }
    }
    render() {
        const { isPassShow, isEmailShow, isUserNameShow } = this.state;
        const { ProfileState } = this.props;
        const data = ProfileState.profile
        return (
            <div>
                <div className='home_section profile_section setting_sec gap_padding_space pro_bg account_setting_sec' style={{ height: (isPassShow || isEmailShow || isUserNameShow ? "auto" : 'auto') }}>
                    <Container >
                        <Row className="justify-content-md-center account_setting_swap">
                            <Col xs="12" lg="5" md="12" sm="12" >
                                <div className='acw_card_nav_images'>
                                    <div className='acw_card_logo'>
                                        <div className='acw_image1'>
                                            <a href='#'>
                                                <img src='../assets/images/acwlogo.png' />
                                            </a>
                                        </div>
                                        <div className='acw_vertical_line'></div>
                                        <div className='acw_image2'>
                                            <a href='#'>
                                                <img src='../assets/images/nfclogo.png' />
                                            </a>
                                        </div>
                                    </div>
                                    <Link style={{ color: "#fff" }} to="/accountsetting" onClick={(e) => this.props.HandleChangesSetting("profile")} >
                                        <AiFillSetting className="acoount_setting" />
                                    </Link>
                                </div>
                                <div className='common_title_part'>
                                    <p>Swap ID</p>
                                </div>
                                <div className='home_sec setting_sec' style={{ height: "80vh" }} >
                                    <div className='account_setting_part'>
                                        <div className='email_name_pass'>
                                            <div className='common_box_size'>
                                                <div className='dis_log'>
                                                    <p>{data.uniqueid ? data.uniqueid : '---'}</p>
                                                </div>
                                            </div>
                                            {
                                                this.state.openScanner
                                                    ?

                                                    <>
                                                        <div className='qr_code_scanner'>
                                                            <div class="modal-header document_camera_close" style={{ borderBottom: "none" }} onClick={this.scannerStatus}>
                                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <QrReader
                                                                ref={this.qrReaderRef}
                                                                delay={100}
                                                                constraints={{ facingMode: 'environment', focusMode: "continuous" }}
                                                                onResult={(result, error) => this.scannerData(result, error)}
                                                            />
                                                        </div>
                                                    </> :
                                                    <>
                                                        <div className='common_box_size'>
                                                            <div className='dis_log'>
                                                                <div className="document_icon" >
                                                                    <label className="document_camera" onClick={this.scannerStatus}>
                                                                        <span style={{ color: "#fff" }} >
                                                                            <AiFillCamera style={{ color: "#fff" }} />
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                            <div className='common_box_size'>
                                                <div className='dis_log'>
                                                    <p>{this.state.uniqueId ? this.state.uniqueId : "---"}</p>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="profile_save_btn" >
                                                    <button type="button" class="btn btn-primary" onClick={this.submit}>
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {(this.state.updateSpinner) ?
                    <div className='common_loader_ag_grid'>
                        <img className='loader_img_style_common_ag_grid' src='/assets/images/logo.jpg' />
                        <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                        </Spinner>
                    </div>
                    : ""}
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    ProfileState: state.profile,
    appState: state.app,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ViewProfile: AC_VIEW_PROFILE,
        HandleChangesSetting: AC_HANDLE_INPUT_CHANGE_SETTINGS,
        AccountDetails: AC_ACCOUNT_DETAILS,
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(SwapID);