"use client";
import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
export default function order() {
    return (
        <div className='order_sec section'>
            <div className='order_section'>
                <div className='react_videoplayer_innerpage'>
                    {/* <ReactPlayer url='../videos/testvideo.mp4' volume autoPlay playing loop controls /> */}
                    <video autoPlay  loop volume muted>
                        {/* Provide the video source */}
                        <source src="../videos/digitalcard.mov" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

            </div>
        </div>
    )
}
