import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_LIST_LANDING_PAGE_ENQUIRIY() {
    return function (dispatch) {
        return axios.get(ImportedURL.API.listLandingPageEnquiry)
            .then((res) => {
                dispatch({ type: "LIST_LANDING_PAGE_ENQUIRIY", payload: res.data, spinner: false })
            }).catch(({ response }) => {
                dispatch({ type: "LIST_LANDING_PAGE_ENQUIRIY", payload: [], spinner: false })
                console.log(response);
            });
    };
}

export function AC_VIEW_LANDING_PAGE_ENQUIRIY(id) {
    return function (dispatch) {
        axios.get(ImportedURL.API.viewLandingPageEnquiry + "/" + id)
            .then(({ data }) => {
                dispatch({ type: 'VIEW_LANDING_PAGE_ENQUIRIY', payload: data, spinner: false });
            }).catch(({ response }) => {
                dispatch({ type: "VIEW_LANDING_PAGE_ENQUIRIY", payload: {}, spinner: false })
                console.log(response);
            });
    }
}

export function AC_LANDING_PAGE_ENQUIRIY_SPINNER() {
    return function (dispatch) {
        dispatch({ type: "LANDING_PAGE_ENQUIRIY_SPINNER" })
    };
}