import axios from 'axios';
import ImportedURL from '../common/api';

export function AC_HANDLE_INPUT_CHANGE_EDUCATION(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_INPUT_CHANGE_EDUCATION", name: name, value: value });
    };
}

export function AC_LIST_EDUCATION() {
    return function (dispatch) {
        return axios.get(ImportedURL.API.listEducation)
            .then((res) => {
                dispatch({ type: "LIST_EDUCATION", payload: res.data, spinner: false })
            }).catch(({ response }) => {
                dispatch({ type: "LIST_EDUCATION", payload: [], spinner: false })
                console.log(response);
            });
    };
}


export function AC_VIEW_EDUCATION(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewEducation + '/' + id)
            .then((res) => {
                dispatch({ type: "VIEW_EDUCATION", payload: res.data, spinner: false })
            }).catch(({ response }) => {
                dispatch({ type: "VIEW_EDUCATION", payload: {}, spinner: false })
                console.log(response);
            });
    };
}

export function AC_EMPTY_EDUCATION() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_EDUCATION" })
    };
}
