import axios from 'axios';
import ImportedURL from '../common/api';

export function AC_HANDLE_INPUT_CHANGE_COMPANY(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_INPUT_CHANGE_COMPANY", name: name, value: value });
    };
}

export function AC_LIST_COMPANY() {
    return function (dispatch) {
        return axios.get(ImportedURL.API.listCompany)
            .then((res) => {
                dispatch({ type: "LIST_COMPANY", payload: res.data, spinner: false })
            }).catch(({ response }) => {
                dispatch({ type: "LIST_COMPANY", payload: [], spinner: false })
                console.log(response);
            });
    };
}


export function AC_VIEW_COMPANY(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewCompany + '/' + id)
            .then((res) => {
                dispatch({ type: "VIEW_COMPANY", payload: res.data, spinner: false })
            }).catch(({ response }) => {
                dispatch({ type: "VIEW_COMPANY", payload: {}, spinner: false })
                console.log(response);
            });
    };
}

export function AC_EMPTY_COMPANY() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_COMPANY" })
    };
}

export function AC_SPINNER_COMPANY() {
    return function (dispatch) {
        dispatch({ type: "SPINNER_COMPANY" })
    };
}
