const initialState = {
    cardtype: {
        cardtype: '',
        cardimage: '',
    },
    listCardtype: [],
    spinner: true,
    viewspinner: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_INPUT_CHANGE_CARDTYPE':
            return Object.assign({}, state, {
                cardtype: {
                    ...state.cardtype,
                    [action.name]: action.value
                }
            })

        case "VIEW_CARDTYPE":
            return Object.assign({}, state, {
                cardtype: action.payload,
                viewspinner: action.spinner
            });

        case 'LIST_CARDTYPE':
            return {
                ...state,
                listCardtype: action.payload,
                spinner: action.spinner
            }

        case 'EMPTY_CARDTYPE':
            return Object.assign({}, state, {
                cardtype: {
                    cardtype: '',
                    cardimage: '',
                },
            })
        case 'SPINNER_CARDTYPE':
            return Object.assign({}, state, {
                spinner: true
            })
        case 'VIEW_SPINNER_CARDTYPE':
            return Object.assign({}, state, {
                viewspinner: true
            })

        default:
            return state;
    }
}