import axios from 'axios';
import ImportedURL from '../common/api';

export function AC_HANDLE_INPUT_CHANGE_BUSINESS(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_INPUT_CHANGE_BUSINESS", name: name, value: value });
    };
}

export function AC_LIST_BUSINESSESS() {
    return function (dispatch) {
        return axios.get(ImportedURL.API.listBusiness)
            .then((res) => {
                dispatch({ type: "LIST_BUSINESS", payload: res.data, spinner: false })
            }).catch(({ response }) => {
                dispatch({ type: "LIST_BUSINESS", payload: [], spinner: false })
                console.log(response);
            });
    };
}

export function AC_LIST_BUSINESSESS_UNIQUE(params = {}) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.listBusinessUnique, { params: params })
            .then((res) => {
                dispatch({ type: "LIST_BUSINESS_UNIQUE", payload: res.data, spinner: false })
            }).catch(({ response }) => {
                dispatch({ type: "LIST_BUSINESS_UNIQUE", payload: [], spinner: false })
                console.log(response);
            });
    };
}

export function AC_VIEW_BUSINESSESS(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewBusiness + '/' + id)
            .then((res) => {
                dispatch({ type: "VIEW_BUSINESSESS", payload: res.data, socialmedia: (res.data.sociallink != undefined && res.data.sociallink && res.data.sociallink.length > 0) ? res.data.sociallink : [], spinner: false })
            }).catch(({ response }) => {
                dispatch({ type: "VIEW_BUSINESSESS", payload: {}, socialmedia: [], spinner: false });
                console.log(response);
            });
    };
}

export function AC_EMPTY_BUSINESS() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_BUSINESS" })
    };
}
export function AC_RUN_SPINNER_BUSINESS() {
    return function (dispatch) {
        dispatch({ type: "RUN_SPINNER_EDIT" })
    };
}
export function AC_RUN_SPINNER_ADD() {
    return function (dispatch) {
        dispatch({ type: "RUN_SPINNER_ADD" })
    };
}



// ====== PERSONAL 
export function AC_LIST_PERSONAL() {
    return function (dispatch) {
        return axios.get(ImportedURL.API.listPersonel)
            .then((res) => {
                dispatch({ type: "LIST_PERSONAL", payload: res.data, spinner: false })
            }).catch(({ response }) => {
                dispatch({ type: "LIST_PERSONAL", payload: [], spinner: false })
                console.log(response);
            });
    };
}
export function AC_LIST_PERSONAL_UNIQUE(params = {}) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.listPersonelUnique, { params: params })
            .then((res) => {
                dispatch({ type: "LIST_PERSONAL_UNIQUE", payload: res.data, spinner: false })
            }).catch(({ response }) => {
                dispatch({ type: "LIST_PERSONAL_UNIQUE", payload: [], spinner: false })
                console.log(response);
            });
    };
}
export function AC_VIEW_PERSONAL(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewPersonel + '/' + id)
            .then((res) => {
                dispatch({ type: "VIEW_PERSONAL", payload: res.data, socialmedia: (res.data.sociallink != undefined && res.data.sociallink && res.data.sociallink.length > 0) ? res.data.sociallink : [], spinner: false });
            }).catch(({ response }) => {
                dispatch({ type: "VIEW_PERSONAL", payload: {}, socialmedia: [], spinner: false });
                console.log(response);
            });
    };
}
