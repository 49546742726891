import React, { Component } from 'react'
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Container, Col, Button, Spinner, Modal } from 'react-bootstrap';
import { FiChevronRight, FiEdit } from 'react-icons/fi';
import { FaUserEdit } from "react-icons/fa";
import { BiSolidHomeHeart, BiMap, BiSave } from 'react-icons/bi';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from 'react-router-dom';
import { AC_DRAG_SOCIALMEDIA_LIST, AC_HANDLE_INPUT_CHANGE_PROFILE, AC_VIEW_PROFILE } from '../../actions/profileAction';
import ImportedURL from '../../common/api';
import { Error } from '../../common/swal';
import { AiOutlinePhone, AiOutlineSetting, AiFillHome } from 'react-icons/ai';
import { BsEmojiHeartEyes, BiSolidHome } from "react-icons/bs";
import { HiOutlineChevronLeft } from 'react-icons/hi';
import { AC_LIST_BUSINESSESS, AC_LIST_BUSINESSESS_UNIQUE, AC_LIST_PERSONAL_UNIQUE, AC_VIEW_BUSINESSESS, AC_VIEW_PERSONAL } from '../../actions/businessAction';
import { MdFileOpen } from "react-icons/md";
// import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import banner from '../images/v2/acwprofilebg.png'
import userlogo from '../images/v2/user.png'
import personal_log from "../images/v2/personal_log.png";
import bnotfound from "../images/v2/b-not-found.png";
import whatsapp_log from "../images/v2/wp.png";

class UniqueId extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updateSpinner: false,
            matches: window.matchMedia("(min-width: 1100px)").matches,
            businessEditeditId: '',
            businessUserId: '',
            isBusinessEditedit: false,
            cardVal: '',
            urlCondition: '',
            userNameUrl: '',
            personalDetailsShow: false,
            uniqueId: '',
            show: false,
            bussinessSelectId: '',
            personalSelectId: '',
            isProfileRedirect: '',
            isShowWhatsApp: false,
            showHome: String,
        }
    }
    componentDidMount() {
        document.title = 'ACW CARD - A new-gen versatile digital card'
        document.description = 'ACW Card enables you to share your business and personal profiles along with digital uploads of key documents to strengthen your portfolio.'

        const handler = e => this.setState({ matches: e.matches });
        window.matchMedia("(min-width: 1100px)").addEventListener('change', handler);

        this.setState({ urlCondition: this.props.location.search, userNameUrl: this.props.location.pathname })
        let search = this.props.location.search.split('=');
        let val = search.pop();
        if (val) {
            this.props.ListBusiness({ uniqueid: val });
            this.props.ListPersonal({ uniqueid: val });

        } else {
            this.props.ListBusiness({ username: this.props.location.pathname?.slice(1) });
            this.props.ListPersonal({ username: this.props.location.pathname?.slice(1) });
        }
        this.setState({ cardVal: this.props.location.search })

        axios.get(ImportedURL.API.listPersonelUnique, { params: { uniqueid: val } })
            .then(async (res) => {
                if (res.data && res.data.length > 0) {
                    res.data.map(data => {
                        if (data.whatsapp != null) {
                            this.setState({ isShowWhatsApp: true })
                        }
                    })
                }
                if (res.data[0]) {
                    await this.props.ViewPersonal(res.data[0]._id)
                    await this.props.ViewBusiness(res.data[0]._id)
                }
                if (res.data[0] && res.data[0].status === true) {
                    this.setState({ personalDetailsShow: true })
                }
            }).catch(({ response }) => { console.log(response); });
        this.setState({ isProfileRedirect: '' })

        axios.get('https://ipapi.co/json/').then((response) => {
            let data = response.data;
            const formData = {
                time: new Date(),
                ip: data.ip,
                uniqueid: val,
            }
            axios.get(ImportedURL.API.listTapSeen, { params: { tapseens: val } })
                .then((data) => {
                    // this.setState({ viewsCount: data.data.length });
                    const findVal = data && data.data.find(e => e.ip == formData.ip);
                    if (findVal == undefined || !findVal) {
                        axios.post(ImportedURL.API.addTapSeen, formData)
                            .then((data) => console.log('success'))
                            .catch(err => { console.log('err'); })
                    }
                }).catch(err => { console.log('err'); })
        })

        const token = localStorage.getItem("acwtoken");
        if (token != null) {
            this.setState({ showHome: 'profile' })
        } else {
            this.setState({ showHome: 'home' })
        }

    }
    vCardGen = (e) => {
        const listPersonalData = this.props.BusinessState.listPersonalUnique;
        const personal = listPersonalData && listPersonalData.length > 0 && listPersonalData.filter(e => e.status == true)
        const listBusinessData = this.props.BusinessState.listBusinessUnique;
        const business = listBusinessData && listBusinessData.length > 0 && listBusinessData.filter(e => e.status == true)
        if (personal.length > 0 && (business == false)) {
            this.vCard();
        } else if ((business && business.length == 1) && personal == false) {
            this.vCard();
        } else if (business && business.length == 1 && personal.length == 1) {
            this.setState({ show: true })
        } else if (business && business.length > 1 && personal.length == 1) {
            this.setState({ show: true })
        } else if (business && business.length > 1 && personal == false) {
            this.setState({ show: true })
        } else {
        }
    }
    businessVcard = (id) => {
        this.setState({ bussinessSelectId: id, personalDetailsShow: false });
        this.vCard();
    }

    PersonalVcard = (id) => {
        this.setState({ personalSelectId: id, personalDetailsShow: true });
        this.vCard();
    }
    vCard = () => {
        this.setState({ spinner: true })
        const listPersonalData = this.props.BusinessState.listPersonalUnique;
        const personal = listPersonalData && listPersonalData.length > 0 && listPersonalData.filter(e => e.status == true)
        let personalLengthOneId = ''
        if (personal && personal.length == 1) {
            personal.map(data => {
                personalLengthOneId = data._id
            })
        }

        const listBusinessData = this.props.BusinessState.listBusinessUnique;
        const business = listBusinessData && listBusinessData.length > 0 && listBusinessData.filter(e => e.status == true)
        let bussinessLengthOneId = ''
        if (business && business.length == 1) {
            business.map(data => {
                bussinessLengthOneId = data._id
            })
        }

        const unique = window.location.href.split('/')[3]
        const { ProfileState, accountState } = this.props;
        const uniqueData = ProfileState.profile
        const account = accountState.account
        this.setState({ updateSpinner: true })
        axios.get('https://ipapi.co/json/').then((response) => {
            let data = response.data;
            const formData = {
                time: new Date(),
                ip: data.ip,
                city: data.city,
                country: data.country_name,
                uniqueid: uniqueData.uniqueid ? uniqueData.uniqueid : "",
                id: uniqueData._id ? uniqueData._id : '',
                website: this.state.matches,
                personalDetailsShow: this.state.personalDetailsShow
            }
            if (unique.includes('&uId=')) {
                formData['group'] = true
            } else {
                formData['group'] = false
            }
            if (account._id) {
                formData['saverId'] = account._id
            }
            formData['business_id'] = bussinessLengthOneId ? bussinessLengthOneId : this.state.bussinessSelectId
            formData['personal_id'] = personalLengthOneId ? personalLengthOneId : this.state.personalSelectId
            axios.post(ImportedURL.API.vCardGenerate, formData)
                .then((res) => {
                    this.setState({ updateSpinner: false })
                    if (res.data) {
                        var url = 'data:text/x-vcard;charset=utf-8,' + encodeURIComponent(res.data.vcard);
                        document.location.href = url;
                        this.setState({ spinner: false })

                    }
                }).catch(({ response }) => {
                    this.setState({ updateSpinner: false })
                    if (response) {
                        if (response.status == 401) {
                            Error('Something wrong, Retry again!')
                        } else if (response.status == 502) {
                            Error(response.status + ' Bad Gateway')
                        } else if (response.status == 500) {
                            Error('Internal Server Error')
                        } else if (response.status == 400) {
                            Error('Bad request')
                        }
                    }
                });
        }).catch((error) => {
            console.log(error);
        });
    }
    businessView = (business) => {
        this.setState({ isBusinessEditedit: true, businessEditeditId: business._id, businessUserId: business.userid });
    }


    tapToRedirectProfile = (e) => {
        const token = localStorage.getItem("acwtoken");
        if (token != null) {
            window.location.href = "/profile";
            this.setState({ showHome: 'profile' })
        } else {
            window.location.href = "/";
            this.setState({ showHome: 'home' })
        }
    }
    whatsappClick = (e) => {
        const listPersonalData = this.props.BusinessState.listPersonalUnique;
        const personal = listPersonalData && listPersonalData.length > 0 && listPersonalData.filter(e => e.status == true)
        let whatsappNum = '';
        let whatsappNumCc = '';
        if (personal && personal.length > 0) {
            personal.map(wpget => {
                if (wpget && wpget.whatsapp) {
                    // whatsappNum = (wpget.whatsapp.cc && wpget.whatsapp.cc.value) + wpget.whatsapp.value
                    whatsappNum = wpget.whatsapp.value
                    whatsappNumCc = (wpget.whatsapp.cc && wpget.whatsapp.cc.value)
                }
            })
        }
        // Replace this with the actual phone number you want to open the chat with
        var phoneNumber = whatsappNum;
        const formattedPhoneNumber = phoneNumber.replace(/[^0-9]/g, ''); // Remove non-digit characters
        const whatsappUrl = `https://wa.me/${whatsappNumCc}${formattedPhoneNumber}`;

        // Open the WhatsApp chat page in a new tab/window
        window.open(whatsappUrl, "_blank");
    }
    render() {
        // if (this.state.isProfileRedirect == 'true') {
        //     return <Redirect to={'/profile'} />
        // }
        // if (this.state.isProfileRedirect == 'false') {
        //     return <Redirect to={'/'} />
        // }

        if (this.state.isBusinessEditedit) {
            if (this.state.urlCondition.includes('uId=')) {
                return <Redirect to={'/viewuniqueid/' + this.state.businessEditeditId + '/' + this.state.cardVal} />
            } else {
                return <Redirect to={'/viewuniqueid/' + this.state.businessEditeditId + '/' + this.state.businessUserId + this.state.userNameUrl} />
            }
        }
        const { ProfileState, accountState, appState, BusinessState } = this.props;
        const data = ProfileState.profile;
        const bData = BusinessState.bussiness;

        const listBusinessData = BusinessState.listBusinessUnique;
        const listPersonalData = BusinessState.listPersonalUnique;
        const account = accountState.account
        const spinner = ProfileState.spinner

        let locationData = {}
        if (listPersonalData && listPersonalData.length > 0) {
            listPersonalData.map(data => {
                locationData = data && data.address ? data.address.address : ''
            })
        }
        if (listPersonalData.length === 0 && (listBusinessData && listBusinessData.length === 1)) {
            listBusinessData.map(data => {
                locationData = data && data.address ? data.address.address : ''
            })
        }

        let phoneShowInPersonal = ''
        if (listPersonalData && listPersonalData.length > 0) {
            listPersonalData.map(data => {
                let call = data && data.call
                if (call && call.length != 0) {
                    phoneShowInPersonal = (call.cc && call.cc.value) + ' ' + call.value
                }
            })
        }

        if (listPersonalData.length === 0 && (listBusinessData && listBusinessData.length === 1)) {
            listBusinessData.map(data => {
                let call = data && data.call
                if (call && call.length != 0) {
                    phoneShowInPersonal = (call.cc && call.cc.value) + ' ' + call.value
                }
            })
        }

        let displayLoc = ''
        if (locationData) {
            displayLoc = ((locationData && locationData.street) ? (locationData.street).replace(/,/g, "") + ',' : "") + (locationData.city ? (locationData.city).replace(/,/g, "") + ',' : "") + (locationData.state ? (locationData.state).replace(/,/g, "") + ',' : "") + (locationData.country ? (locationData.country).replace(/,/g, "") + ',' + ' ' : "") + (locationData.zip ? "- " + locationData.zip : "")
            var mapUrlDisplay = `https://www.google.com/maps/place/${encodeURIComponent(displayLoc)}`;
        }
        return (
            <div style={{ position: 'relative' }}>
                <div className='home_section account_section profile_section gap_padding_space' style={{ height: (listPersonalData && listPersonalData.length > 0 || listBusinessData && listBusinessData.length > 0) ? 'auto' : '100vh' }}>
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col xs="12" lg="5" md="12" sm="12" className='home_sec ' style={{ height: (listPersonalData && listPersonalData.length > 0 || listBusinessData && listBusinessData.length > 0) ? 'auto' : '100vh' }}>
                                <div className='acw_card_nav_images'>
                                    <div className='acw_card_logo'>
                                        <div className='acw_image1'>
                                            {/* <Link to="/"> */}
                                            <img src='../assets/images/acwlogo.png' />
                                            {/* </Link> */}
                                        </div>
                                        <div className='acw_vertical_line'></div>
                                        <div className='acw_image2'>
                                            {/* <Link to="/"> */}
                                            <img src='../assets/images/nfclogo.png' />
                                            {/* </Link> */}
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        {this.state.showHome == 'home' ?
                                            <div className="acw_card_setting" onClick={this.tapToRedirectProfile}>
                                                < AiFillHome className="acoount_setting" />
                                            </div>
                                            :
                                            <div className="acw_card_setting" onClick={this.tapToRedirectProfile}>
                                                <FaUserEdit className="acoount_setting" />
                                            </div>
                                        }
                                    </div>
                                    {/* <div className='profile_log_redirect_btn'>
                                        {this.state.showHome == 'home' ?
                                            <button type='button' className='btn' onClick={this.tapToRedirectProfile}>Home</button>
                                            :
                                            <button type='button' className='btn' onClick={this.tapToRedirectProfile}>Log In</button>
                                        }
                                    </div> */}
                                </div>
                                <div className='profile_header_text d-flex' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div className='d-flex align-items-center'>
                                        <p className='back_text mb-0'>
                                            View Profile
                                        </p>
                                    </div>
                                    {/* <div className='profile_back_arrow' onClick={this.trigerBack} stya>
                                        <HiOutlineChevronLeft />
                                    </div> */}
                                </div>
                                <div className='home_sec'>
                                    <div className='account_profile_sec'>
                                        <div className='account_profile'>
                                            <div className='tap_banner_img'>
                                                <div className='img_size'>
                                                    {
                                                        ((this.state.bgimage || data.banner) && data.isCoverStatus) ?
                                                            <img src={this.state.bgimage ? URL.createObjectURL(this.state.bgimage) : ImportedURL.LIVEURL + data.banner} alt='' />
                                                            : <img className="avatar" src={banner} data-toggle="tooltip" data-original-title="Avatar Name" alt="" />
                                                    }
                                                </div>

                                            </div>
                                            <div className='address_img'>
                                                <div className='row'>
                                                    <div className='col-8'>
                                                        <div className='address'>
                                                            <h6>{data.displayname ? data.displayname : '---'} </h6>
                                                            <span>{data.headline ? data.headline : ''}</span>
                                                        </div>
                                                        <div className='d-flex '>
                                                            <div className='icon'>
                                                                {
                                                                    displayLoc
                                                                        ?
                                                                        <a href={mapUrlDisplay} target="_blank" >
                                                                            <BiMap className='display_name_map' />
                                                                        </a>
                                                                        : ""
                                                                }
                                                            </div>
                                                            <div className='content'>
                                                                {
                                                                    displayLoc
                                                                        ?
                                                                        <a href={mapUrlDisplay} target="_blank" style={{ color: 'black', textDecoration: 'none' }} className='address_lable'>
                                                                            <span>{locationData ? locationData.street : ''}</span><br></br>
                                                                            <span>{locationData ? locationData.city + ', ' + locationData.state + ' ' + locationData.zip : ''}</span><br></br>
                                                                            <span>{locationData ? locationData.country : ''}</span>
                                                                            {/* {displayLoc}     */}
                                                                        </a>
                                                                        : ""
                                                                }
                                                            </div>
                                                        </div>


                                                        <div className='d-flex '>
                                                            {
                                                                phoneShowInPersonal
                                                                &&
                                                                <>
                                                                    <a href={"tel:" + phoneShowInPersonal} target="_blank" >
                                                                        <AiOutlinePhone className='display_name_profile' />
                                                                    </a>
                                                                    <p>
                                                                        <a href={"tel:" + phoneShowInPersonal} target="_blank" className='viewunique_call'>
                                                                            {phoneShowInPersonal}
                                                                        </a>
                                                                    </p>
                                                                </>

                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                        <div className='right_img'>
                                                            {(this.state.profileimg || data.image) && data.isProfileStatus ?
                                                                <>
                                                                    <img src={this.state.profileimg ? URL.createObjectURL(this.state.profileimg) : ImportedURL.LIVEURL + data.image} />
                                                                </>
                                                                :
                                                                <>
                                                                    <img src={userlogo} />
                                                                </>
                                                            }
                                                        </div>
                                                        {/* <div className='view_count'>
                                                            <div className='eye_icon'>
                                                                <div class="circle"><BsEmojiHeartEyes /></div>
                                                                <div className='text'>Views : <span><strong>{this.state.viewsCount}</strong></span></div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='skill_section'>
                                                {
                                                    (data.skill && data.skill.length > 0)
                                                    &&
                                                    data.skill.map((item, i) => {
                                                        return (
                                                            <>
                                                                <div className='skill_btn'>
                                                                    {item}
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }

                                            </div>
                                            {(listPersonalData && listPersonalData.length > 0) || (listBusinessData && listBusinessData.length > 0) ?
                                                <div className='edit_contact_btn '>
                                                    <button onClick={() => this.vCardGen()} className="save_contact custom-btn btn_save_cont" data-toggle="modal" data-target="#exampleModal">
                                                        <BiSave className="sidebar_icon mr-2" />Save Contact
                                                    </button>
                                                </div>
                                                : ""
                                            }

                                            {/* Personal Details */}
                                            {listPersonalData && listPersonalData.length > 0 &&
                                                listPersonalData.filter(e => e.status == true).map(personal => {
                                                    return <div className='tap_bussiness_details'>
                                                        <div className='our_budiness_part'>
                                                            <h5>Personal Details</h5>
                                                            <div className='buss_btns' onClick={() => this.businessView(personal)}>
                                                                <div className='img_size' >
                                                                    <img src={personal_log} alt='' />
                                                                </div>
                                                                <div className='d-flex align-items-center' style={{ cursor: 'pointer' }}>
                                                                    <span>Personal Details</span>
                                                                </div>
                                                                <div className='file_open '>
                                                                    <MdFileOpen />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                })
                                            }
                                            {listBusinessData && listBusinessData.length > 0 &&
                                                <div className='tap_bussiness_details'>
                                                    <div className='our_budiness_part'>
                                                        <h5>Our Business</h5>
                                                        {listBusinessData && listBusinessData.length > 0 ?
                                                            listBusinessData.filter(e => e.status == true).map(business => {
                                                                return <div className='buss_btns' onClick={() => this.businessView(business)}>
                                                                    <div className='img_size' >
                                                                        <img src={business.image ? ImportedURL.FILEURL + business.image : bnotfound} alt='' />
                                                                    </div>
                                                                    <div className='d-flex align-items-center' style={{ cursor: 'pointer' }}>
                                                                        <span>{business.work}</span>
                                                                    </div>
                                                                    <div className='file_open '>
                                                                        <MdFileOpen />
                                                                    </div>
                                                                </div>
                                                            })
                                                            :
                                                            <>
                                                                <div className='nothing_here_image'>
                                                                    <img src="../assets/images/nothing-here.png" />
                                                                </div>
                                                                <div className='nothing_here_text'> No record to display</div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {this.state.isShowWhatsApp ?
                                        <div className='whatsapp_button'>
                                            <div class="wrapper">
                                                <div class="video-main">
                                                    <div class="promo-video">
                                                        <div class="waves-block">
                                                            <div class="waves wave-1"></div>
                                                            <div class="waves wave-2"></div>
                                                            <div class="waves wave-3"></div>
                                                        </div>
                                                        <div class="video video-popup mfp-iframe" data-lity onClick={this.whatsappClick}>
                                                            <div className='wp_img_size'>
                                                                <img src={whatsapp_log} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ''}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {this.state.show &&
                    <div class="modal fade card_view_page" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Directory</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div className='model_list_business'>
                                        {listPersonalData && listPersonalData.length > 0 &&
                                            listPersonalData.filter(e => e.status == true).map(personal => {
                                                let phoneNo = '';
                                                let call = personal && personal.call
                                                if (call.length != 0) {
                                                    phoneNo = (call.cc && call.cc.value) + ' ' + call.value
                                                }
                                                let address = personal.address && personal.address.address ? (personal.address.address.street + ', ' + personal.address.address.city + ', ' + personal.address.address.state + ', ' + personal.address.address.zip + ', ' + personal.address.address.country) : ''
                                                return <div className='box2 ofh'>
                                                    <div className='box' onClick={(e) => this.PersonalVcard(personal._id)}>
                                                        <div class="cross-shadow-ribbon-personal">Personal</div>
                                                        <div className='d-flex'>
                                                            <div className='img_s_width'>
                                                                <div className='img_size' >
                                                                    <img src={personal.image ? ImportedURL.FILEURL + personal.image : bnotfound} alt='' />
                                                                </div>
                                                            </div>
                                                            <div className='content'>
                                                                <h5>{personal.work}</h5>
                                                                {address &&
                                                                    <div className='d-flex '>
                                                                        <div className='icon'>
                                                                            <BiMap />
                                                                        </div>
                                                                        <div>
                                                                            <p>{address}</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {phoneNo &&
                                                                    <div className='d-flex '>
                                                                        <div className='icon phone'>
                                                                            <AiOutlinePhone />
                                                                        </div>
                                                                        <div>
                                                                            <p>{phoneNo}</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                        {listBusinessData && listBusinessData.length > 0 &&
                                            listBusinessData.filter(e => e.status == true && e.isDeleted == false).map(business => {
                                                let phoneNo = '';
                                                let call = business && business.call
                                                if (call.length != 0) {
                                                    phoneNo = (call.cc && call.cc.value) + ' ' + call.value
                                                }
                                                let address = business.address && business.address.address ? (business.address.address.street + ', ' + business.address.address.city + ', ' + business.address.address.state + ', ' + business.address.address.zip + ', ' + business.address.address.country) : ''
                                                return <div className='box2 ofh'>
                                                    <div className='box d-flex' onClick={(e) => this.businessVcard(business._id)}>
                                                        <div class="cross-shadow-ribbon-business">Business</div>
                                                        <div className='d-flex w-100'>
                                                            <div className='img_s_width'>
                                                                <div className='img_size' >
                                                                    <img src={business.image ? ImportedURL.FILEURL + business.image : bnotfound} alt='' />
                                                                </div>
                                                            </div>
                                                            <div className='content'>
                                                                <h5>{business.work}</h5>
                                                                {address &&
                                                                    <div className='d-flex '>
                                                                        <div className='icon'>
                                                                            <BiMap />
                                                                        </div>
                                                                        <div>
                                                                            <p>{address}</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {phoneNo &&
                                                                    <div className='d-flex '>
                                                                        <div className='icon'>
                                                                            <AiOutlinePhone />
                                                                        </div>
                                                                        <div>
                                                                            <p>{phoneNo}</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }








                {/* <Modal show={this.state.show} onHide={this.handleClose} className="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Our Summary </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='model_list_business'>
                            {listPersonalData && listPersonalData.length > 0 &&
                                listPersonalData.filter(e => e.status == true).map(personal => {
                                    let phoneNo = '';
                                    let call = personal && personal.call
                                    if (call.length != 0) {
                                        phoneNo = (call.cc && call.cc.value) + ' ' + call.value
                                    }
                                    let address = personal.address && personal.address.address ? (personal.address.address.street + ', ' + personal.address.address.city + ', ' + personal.address.address.state + ', ' + personal.address.address.zip + ', ' + personal.address.address.country) : ''
                                    return <div className='box2 ofh'>
                                        <div className='box' onClick={(e) => this.PersonalVcard(personal._id)}>
                                            <div class="cross-shadow-ribbon-personal">Personal</div>
                                            <div className='d-flex'>
                                                <div className='img_s_width'>
                                                    <div className='img_size' >
                                                        <img src={personal.image ? ImportedURL.FILEURL + personal.image : bnotfound} alt='' />
                                                    </div>
                                                </div>
                                                <div className='content'>
                                                    <h5>{personal.work}</h5>
                                                    {address &&
                                                        <div className='d-flex '>
                                                            <div className='icon'>
                                                                <BiMap />
                                                            </div>
                                                            <div>
                                                                <p>{address}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {phoneNo &&
                                                        <div className='d-flex '>
                                                            <div className='icon'>
                                                                <AiOutlinePhone />
                                                            </div>
                                                            <div>
                                                                <p>{phoneNo}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                            {listBusinessData && listBusinessData.length > 0 &&
                                listBusinessData.filter(e => e.status == true).map(business => {
                                    let phoneNo = '';
                                    let call = business && business.call
                                    if (call.length != 0) {
                                        phoneNo = (call.cc && call.cc.value) + ' ' + call.value
                                    }
                                    let address = business.address && business.address.address ? (business.address.address.street + ', ' + business.address.address.city + ', ' + business.address.address.state + ', ' + business.address.address.zip + ', ' + business.address.address.country) : ''
                                    return <div className='box2 ofh'>
                                        <div className='box d-flex' onClick={(e) => this.businessVcard(business._id)}>
                                            <div class="cross-shadow-ribbon-business">Business</div>
                                            <div className='d-flex'>
                                                <div className='img_s_width'>
                                                    <div className='img_size' >
                                                        <img src={business.image ? ImportedURL.FILEURL + business.image : bnotfound} alt='' />
                                                    </div>
                                                </div>
                                                <div className='content'>
                                                    <h5>{business.work}</h5>
                                                    {address &&
                                                        <div className='d-flex '>
                                                            <div className='icon'>
                                                                <BiMap />
                                                            </div>
                                                            <div>
                                                                <p>{address}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {phoneNo &&
                                                        <div className='d-flex '>
                                                            <div className='icon'>
                                                                <AiOutlinePhone />
                                                            </div>
                                                            <div>
                                                                <p>{phoneNo}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        {this.state.spinner ?
                            // <div style={{ height: '100vh', position: 'fixed', width: '100%', top: '0px', }}>
                            // <div style={{ position: 'relative' }}></div>
                            <div className='common_loader_model'>
                                <img className='loader_img_style_common' src='/assets/images/logo.jpg' />
                                <Spinner className='spinner_load_common' animation="border" variant="info" >
                                </Spinner>
                            </div>
                            // </div>
                            : ""}
                    </Modal.Body>
                </Modal> */}
                {(spinner || this.state.updateSpinner) ?
                    <div style={{ height: '100vh', position: 'fixed', width: '100%', top: '0px', }}>
                        <div style={{ position: 'relative' }}></div>
                        <div className='common_loader'>
                            <img className='loader_img_style_common' src='/assets/images/logo.jpg' />
                            <Spinner className='spinner_load_common' animation="border" variant="info" >
                            </Spinner>
                        </div>
                    </div>
                    : ""}
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    ProfileState: state.profile,
    accountState: state.account,
    BusinessState: state.business,
    appState: state.app,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleInputChange: AC_HANDLE_INPUT_CHANGE_PROFILE,
        ViewProfile: AC_VIEW_PROFILE,
        dragChange: AC_DRAG_SOCIALMEDIA_LIST,
        ListBusiness: AC_LIST_BUSINESSESS_UNIQUE,
        ListPersonal: AC_LIST_PERSONAL_UNIQUE,
        ViewPersonal: AC_VIEW_PERSONAL,
        ViewBusiness: AC_VIEW_BUSINESSESS,
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(UniqueId);