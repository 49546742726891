import React from 'react'
import { Row, Tab, Tabs, Col, Container, Button, Card } from 'react-bootstrap';
import { BiDollar } from 'react-icons/bi';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';

export default function products() {
    const scrollEnquiry = () => {
        let formEnquiry = document.getElementById("enquiry-form-data");
        if (formEnquiry) {
            formEnquiry.scrollIntoView()
        }
    }
    const scrollEnquiryFeature = () => {
        let formEnquiry = document.getElementById("features_new");
        if (formEnquiry) {
            formEnquiry.scrollIntoView()
        }
    }
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div>
            <div className='section'>
                <div className='product_section digitalia_sec product_sec back-img-color'>
                    <Container className='container-new-big'>
                        <div className='tab_1'>
                            <div className='tab_inner_page'>
                                <p className='realted_head_text'>Related Products</p>
                                <Carousel responsive={responsive} infinite={true}>
                                    <div className='caro_item mx-2'>
                                        <div className='digitalia_card_design'>
                                            <Card>
                                                <Card.Img variant="top" src="../../../landing_page/assets/images/card/digitalcard01.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>White <br/> ACW Card</h5>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    {/* <p className='cost_amo'> <BiDollar />35 </p> */}
                                                                </div>
                                                            </div>
                                                    {/* <Button className='digital_card_btn_1'>Features</Button> */}
                                                    <Link to='/abouts/features'>
                                                        <Button className='digital_card_btn_1' onClick={scrollEnquiryFeature}>Features</Button>
                                                    </Link>
                                                    {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                    <Link to='/details/enquiry-form'>
                                                        <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                    </Link>

                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className='caro_item mx-2'>
                                        <div className='digitalia_card_design'>
                                            <Card>
                                            <Card.Img variant="top" src="../../../landing_page/assets/images/card/digitalcard02.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>VIP black premium <br/> ACW Card </h5>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    {/* <p className='cost_amo'> <BiDollar />35 </p> */}
                                                                </div>
                                                            </div>
                                                    {/* <Button className='digital_card_btn_1'>Features</Button> */}
                                                    {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                    <Link to='/abouts/features'>
                                                        <Button className='digital_card_btn_1' onClick={scrollEnquiryFeature}>Features</Button>
                                                    </Link>
                                                    <Link to='/details/enquiry-form'>
                                                        <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                    </Link>

                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className='caro_item mx-2'>
                                        <div className='digitalia_card_design'>
                                            <Card>
                                            <Card.Img variant="top" src="../../../landing_page/assets/images/card/digitalcard03.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Silver <br/> ACW Card</h5>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    {/* <p className='cost_amo'> <BiDollar />35 </p> */}
                                                                </div>
                                                            </div>
                                                    {/* <Button className='digital_card_btn_1'>Features</Button> */}
                                                    <Link to='/abouts/features'>
                                                        <Button className='digital_card_btn_1' onClick={scrollEnquiryFeature}>Features</Button>
                                                    </Link>
                                                    {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                    <Link to='/details/enquiry-form'>
                                                        <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                    </Link>

                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className='caro_item mx-2'>
                                        <div className='digitalia_card_design'>
                                            <Card>
                                            <Card.Img variant="top" src="../../../landing_page/assets/images/card/digitalcard05.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Light blue <br/> ACW Card</h5>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    {/* <p className='cost_amo'> <BiDollar />35 </p> */}
                                                                </div>
                                                            </div>
                                                    {/* <Button className='digital_card_btn_1'>Features</Button> */}
                                                    <Link to='/abouts/features'>
                                                        <Button className='digital_card_btn_1' onClick={scrollEnquiryFeature}>Features</Button>
                                                    </Link>
                                                    {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                    <Link to='/details/enquiry-form'>
                                                        <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                    </Link>

                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    )
}
