"use client";
import React from 'react'
import { Row, Tab, Tabs, Col, Container, Button } from 'react-bootstrap';
import { BiDollar } from 'react-icons/bi';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';

export default function product() {
    const scrollEnquiry = () => {
        let formEnquiry = document.getElementById("enquiry-form-data");
        if (formEnquiry) {
            formEnquiry.scrollIntoView()
        }
    }
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 600 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 600, min: 0 },
            items: 1
        }
    };
    return (
        <div className='section'>
            <div className='product_section' id='product_link'>
                <Container className='container-new-big'>
                    <h2>Our Products </h2>
                    <div className='tab_1'>
                                <div className='tab_inner_page mt-5'>
                                    <Row>
                                        <Col lg={4}>
                                            <div className='product_card_image'>
                                                <img src='./landing_page/assets/images/card/digitalcard04.png' />
                                            </div>
                                        </Col>
                                        <Col lg={8}>
                                            <h4>ACW Card</h4>
                                            <p className='py-3'>Choose your premium ACW Cards with your customized details on them. Let us know how you want your card to be. </p>
                                            <div className='cost_btn'>
                                                {/* <p className='cost_amo'> <BiDollar />35 </p> */}
                                                <p>
                                                    {/* <Button title='Features'>Features</Button> */}
                                                    <Link to='/abouts/features'>
                                                        <Button>Features</Button>
                                                    </Link>
                                                    {/* <Button title='Benefits'>Benefits</Button> */}
                                                    {/* <Button className='active_btn' title='Enquiry'>Enquiry</Button> */}
                                                    <Link to='/details/enquiry-form'>
                                                        <Button className='active_btn' onClick={scrollEnquiry}>Enquiry</Button>
                                                    </Link>
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                    </div>
                    <div className='carosel_sec py-5 mb-5'>
                        <Carousel responsive={responsive} infinite={true}>
                            <div className='caro_item'>
                                <img src='./landing_page/assets/images/card/digitalcard03.png' />
                            </div>
                            <div className='caro_item'>
                                <img src='./landing_page/assets/images/card/digitalcard02.png' />
                            </div>
                            <div className='caro_item'>
                                <img src='./landing_page/assets/images/card/digitalcard05.png' />
                            </div>
                            <div className='caro_item'>
                                <img src='./landing_page/assets/images/card/digitalcard06.png' />
                            </div>
                            <div className='caro_item'>
                                <img src='./landing_page/assets/images/card/digitalcard07.png' />
                            </div>
                            <div className='caro_item'>
                                <img src='./landing_page/assets/images/card/digitalcard08.png' />
                            </div>
                            <div className='caro_item'>
                                <img src='./landing_page/assets/images/card/digitalcard09.png' />
                            </div>
                        </Carousel>
                    </div>
                </Container>
            </div>
        </div>
    )
}
