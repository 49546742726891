import React from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { AiFillCaretRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';

export default function PricingBox() {
    const scrollEnquiry = () => {
        let formEnquiry = document.getElementById("enquiry-form-data");
        if (formEnquiry) {
            formEnquiry.scrollIntoView()
        }
    }
    return (
        <>
            <div className='pricing_plans'>
                <Container className="container-new-big">
                    <h3 className="pricing_plans_txt">Pricing</h3>
                    <p className="pricing_plans_para">We offer ACW Cards for different needs. Whether you need it for individual needs, your colleagues or large teams, we provide cards tailored to your needs. <br/> For more details.</p>
                    <div className="text-center">
                        <Link to="/details/enquiry-form">
                          <Button className="order_now_btn">Enquiry</Button>
                        </Link>
                    </div>
                    {/* <div className="boxes">
                        <Row>
                            <Col lg={4}>
                                <div className="pricing_box_sec">
                                    <div className="individual_box">
                                        <h4 className="individuals_txt"><span className="icon_bdr"><AiFillCaretRight /></span>Individuals</h4>
                                        <div className="premium">
                                            <h4 className="premium_txt">Premium</h4>
                                        </div>
                                        <h5 className="premium_amount">2.99$ <span className="month_txt">month</span> </h5>
                                        <ul className="premium_unorder">
                                            <li className="premium_list"><span className="dots_list"></span>7-day free trial <br />
                                                included - 5 card</li>
                                            <li className="premium_list"><span className="dots_list"></span>Customized color <br />
                                                themes</li>
                                        </ul>
                                        <div className="order_now_div">
                                            <Button className="order_now_btn">Enquiry</Button>
                                            <Link to='/details/enquiry-form'>
                                                <Button className="order_now_btn" onClick={scrollEnquiry}>Enquiry</Button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="pricing_box_sec">
                                    <div className="individual_box">
                                        <h4 className="individuals_txt"><span className="icon_bdr"><AiFillCaretRight /></span>Teams</h4>
                                        <div className="premium">
                                            <h4 className="premium_txt">Business</h4>
                                        </div>
                                        <h5 className="premium_amount">4.99$ <span className="month_txt">month</span> </h5>
                                        <ul className="premium_unorder">
                                            <li className="premium_list"><span className="dots_list"></span>30-day free trial <br />
                                                included</li>
                                            <li className="premium_list"><span className="dots_list"></span>3 cards minimum</li>
                                        </ul>
                                        <div className="order_now_div">
                                            <Button className="order_now_btn order_now_btn2">Enquiry</Button>
                                            <Link to='/details/enquiry-form'>
                                                <Button className="order_now_btn order_now_btn2" onClick={scrollEnquiry}>Enquiry</Button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="pricing_box_sec">
                                    <div className="individual_box">
                                        <h4 className="individuals_txt"><span className="icon_bdr"><AiFillCaretRight /></span>Large Teams</h4>
                                        <div className="premium">
                                            <h4 className="premium_txt">Enterprise</h4>
                                        </div>
                                        <h5 className="premium_amount">3.90$<span className="month_txt">month</span> </h5>
                                        <ul className="premium_unorder">
                                            <li className="premium_list"><span className="dots_list"></span>Bulk card creation, <br />
                                                Contact creation</li>
                                            <li className="premium_list"><span className="dots_list"></span>Dedicated account <br />
                                                manager</li>
                                        </ul>
                                        <div className="order_now_div">
                                            <Button className="order_now_btn">Enquiry</Button>
                                            <Link to='/details/enquiry-form'>
                                                <Button className="order_now_btn" onClick={scrollEnquiry}>Enquiry</Button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div> */}
                </Container>
            </div>
        </>
    );
}