import axios from 'axios';
import ImportedURL from '../common/api';

export function AC_HANDLE_INPUT_CHANGE_HOTEL(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_INPUT_CHANGE_HOTEL", name: name, value: value });
    };
}

export function AC_LIST_HOTEL() {
    return function (dispatch) {
        return axios.get(ImportedURL.API.listHotel)
            .then((res) => {
                dispatch({ type: "LIST_HOTEL", payload: res.data, spinner: false })
            }).catch(({ response }) => {
                dispatch({ type: "LIST_HOTEL", payload: [], spinner: false })
                console.log(response);
            });
    };
}


export function AC_VIEW_HOTEL(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewHotel + '/' + id)
            .then((res) => {
                dispatch({ type: "VIEW_HOTEL", payload: res.data, spinner: false })
            }).catch(({ response }) => {
                dispatch({ type: "VIEW_HOTEL", payload: {}, spinner: false })
                console.log(response);
            });
    };
}

export function AC_EMPTY_HOTEL() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_HOTEL" })
    };
}

export function AC_SPINNER_HOTEL() {
    return function (dispatch) {
        dispatch({ type: "SPINNER_HOTEL" })
    };
}
