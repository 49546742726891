import React from "react";
import { Container, Button } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BiDollar } from 'react-icons/bi';
import { Link } from 'react-router-dom';

export default function Related_products() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 441 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 440, min: 0 },
            items: 1
        }
    };
    return (
        <>
            <div className="related-produt">
                <Container className='container-new-big'>
                    <h5 className="related-product-txt">Related Products</h5>
                    <div className="products-carousel">
                        <Carousel responsive={responsive} infinite={true}>
                            <div className="related_pro">
                                <div className='caro_item_related'>
                                    <img src='../../../landing_page/assets/images/card/digitalcard04.png' />
                                </div>
                                <div className="under_item">
                                    <div className="black_metal_professional">
                                        <h5 className="black_metal_professional_txt">Silver <br/> ACW Card</h5>
                                        {/* <h6 className="rupees_card"> <span className="ruppee_symbol"><BiDollar /></span>30</h6> */}
                                    </div>
                                    <div className="related_btn">
                                        <Link to='/abouts/features'>
                                            <Button id="features-now">Features</Button>
                                        </Link>
                                        {/* <Button id="order-now">Enquiry</Button> */}
                                    </div>
                                </div>
                            </div>
                            <div className="related_pro">
                                <div className='caro_item_related'>
                                    <img src='../../../landing_page/assets/images/card/digitalcard01.png' />
                                </div>
                                <div className="under_item">
                                    <div className="black_metal_professional">
                                        <h5 className="black_metal_professional_txt">White <br/> ACW Card</h5>
                                        {/* <h6 className="rupees_card"><span className="ruppee_symbol"><BiDollar /></span> 30</h6> */}
                                    </div>
                                    <div className="related_btn">
                                        <Link to='/abouts/features'>
                                            <Button id="features-now">Features</Button>
                                        </Link>
                                        {/* <Button id="order-now">Enquiry</Button> */}
                                    </div>
                                </div>
                            </div>
                            <div className="related_pro">
                                <div className='caro_item_related'>
                                    <img src='../../../landing_page/assets/images/card/digitalcard02.png' />
                                </div>
                                <div className="under_item">
                                    <div className="black_metal_professional">
                                        <h5 className="black_metal_professional_txt">VIP black premium <br/> ACW Card</h5>
                                        {/* <h6 className="rupees_card"><span className="ruppee_symbol"><BiDollar /></span> 30</h6> */}
                                    </div>
                                    <div className="related_btn">
                                        <Link to='/abouts/features'>
                                            <Button id="features-now">Features</Button>
                                        </Link>
                                        {/* <Button id="order-now">Enquiry</Button> */}
                                    </div>
                                </div>
                            </div>
                            <div className="related_pro">
                                <div className='caro_item_related'>
                                    <img src='../../../landing_page/assets/images/card/red.png' />
                                </div>
                                <div className="under_item">
                                    <div className="black_metal_professional">
                                      <h5 className="black_metal_professional_txt">Red <br/> ACW Card</h5>
                                        {/* <h6 className="rupees_card"><span className="ruppee_symbol"><BiDollar /></span> 30</h6> */}
                                    </div>
                                    <div className="related_btn">
                                        <Link to='/abouts/features'>
                                            <Button id="features-now">Features</Button>
                                        </Link>
                                        {/* <Button id="order-now">Enquiry</Button> */}
                                    </div>
                                </div>
                            </div>
                            <div className="related_pro">
                                <div className='caro_item_related'>
                                    <img src='../../../landing_page/assets/images/card/digitalcard05.png' />
                                </div>
                                <div className="under_item">
                                    <div className="black_metal_professional">
                                        <h5 className="black_metal_professional_txt">Light blue <br/> ACW Card</h5>
                                        {/* <h6 className="rupees_card"><span className="ruppee_symbol"><BiDollar /></span> 30</h6> */}
                                    </div>
                                    <div className="related_btn">
                                        <Link to='/abouts/features'>
                                            <Button id="features-now">Features</Button>
                                        </Link>
                                        {/* <Button id="order-now">Enquiry</Button> */}
                                    </div>
                                </div>
                            </div>
                            <div className="related_pro">
                                <div className='caro_item_related'>
                                    <img src='../../../landing_page/assets/images/card/blue.png' />
                                </div>
                                <div className="under_item">
                                    <div className="black_metal_professional">
                                    <h5 className="black_metal_professional_txt"> Blue <br/> ACW Card</h5>
                                        {/* <h6 className="rupees_card"> <span className="ruppee_symbol"><BiDollar /></span>30</h6> */}
                                    </div>
                                    <div className="related_btn">
                                        <Link to='/abouts/features'>
                                            <Button id="features-now">Features</Button>
                                        </Link>
                                        {/* <Button id="order-now">Enquiry</Button> */}
                                    </div>
                                </div>
                            </div>
                            <div className="related_pro">
                                <div className='caro_item_related'>
                                    <img src='../../../landing_page/assets/images/card/digitalcard07.png' />
                                </div>
                                <div className="under_item">
                                    <div className="black_metal_professional">
                                        <h5 className="black_metal_professional_txt">Silver <br/> ACW Card</h5>
                                        {/* <h6 className="rupees_card"><span className="ruppee_symbol"><BiDollar /></span> 30</h6> */}
                                    </div>
                                    <div className="related_btn">
                                        <Link to='/abouts/features'>
                                            <Button id="features-now">Features</Button>
                                        </Link>
                                        {/* <Button id="order-now">Enquiry</Button> */}
                                    </div>
                                </div>
                            </div>
                            <div className="related_pro">
                                <div className='caro_item_related'>
                                    <img src='../../../landing_page/assets/images/card/digitalcard08.png' />
                                </div>
                                <div className="under_item">
                                    <div className="black_metal_professional">
                                        <h5 className="black_metal_professional_txt">Grey <br/> ACW Card</h5>
                                        {/* <h6 className="rupees_card"><span className="ruppee_symbol"><BiDollar /></span> 30</h6> */}
                                    </div>
                                    <div className="related_btn">
                                        <Link to='/abouts/features'>
                                            <Button id="features-now">Features</Button>
                                        </Link>
                                        {/* <Button id="order-now">Enquiry</Button> */}
                                    </div>
                                </div>
                            </div>
                            <div className="related_pro">
                                <div className='caro_item_related'>
                                    <img src='../../../landing_page/assets/images/card/darkblue.png' />
                                </div>
                                <div className="under_item">
                                    <div className="black_metal_professional">
                                        <h5 className="black_metal_professional_txt">Darkblue <br/> ACW Card</h5>
                                        {/* <h6 className="rupees_card"><span className="ruppee_symbol"><BiDollar /></span> 30</h6> */}
                                    </div>
                                    <div className="related_btn">
                                        <Link to='/abouts/features'>
                                            <Button id="features-now">Features</Button>
                                        </Link>
                                        {/* <Button id="order-now">Enquiry</Button> */}
                                    </div>
                                </div>
                            </div>
                           
                        </Carousel>
                    </div>
                </Container>
            </div>
        </>
    );
}