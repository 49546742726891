"use client";
import React, { useEffect, useCallback, useState } from "react";
import { Navbar, Nav, NavDropdown, Container, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
const token = localStorage.getItem("acwtoken");
const type = localStorage.getItem("type");
export default function Header() {
    const scrollEnquiry = () => {
        let formEnquiry = document.getElementById("enquiry-form-data");
        if (formEnquiry) {
            formEnquiry.scrollIntoView()
        }
    }
    const location = useLocation();

    const [isscrolled, setIsScrolled] = useState("0");
    useEffect(() => {
        window.addEventListener("scroll", onScroll, { passive: true });
    }, []);
    const onScroll = useCallback((event) => {
        const { pageYOffset, scrollY } = window;
        if (scrollY == 0 && pageYOffset == 0) {
            setIsScrolled(0);
        } else {
            setIsScrolled(1);
        }
    }, []);

    const account = () => {
        if (type == 'admin' || type == 'company') {
            window.location.href = "/admin";
        } else {
            window.location.href = "/profile";
        }
    }
    const Scroll = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    return (
        <div>
            <header className='section navbar_sec' >
                {/* <Navbar collapseOnSelect expand="lg" className={
                    isscrolled == 1
                        ? "logo-ai"
                        : " hotel-ai-logo"
                } > */}
                <Navbar collapseOnSelect expand="lg">
                    <Container className='container-new-big'>
                        <Navbar.Brand href="/">
                            <img src="../../../landing_page/assets/images/logo.png" alt='logo' />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll" className='justify-content-end'>
                            <Nav className="ml-auto my-2 my-lg-0 acw_navbar" navbarScroll >
                                <Nav.Link href="javascript:void(0)" className="p-0 m-0"> <Link to="/" onClick={Scroll} className={location.pathname === '/' ? 'active_header nav-link' : 'nav-link'} title='Home'>Home</Link></Nav.Link>
                                <Nav.Link href="javascript:void(0)" className="p-0 m-0"> <Link to="/digitalcard" onClick={Scroll} className={location.pathname === '/digitalcard' ? 'active_header nav-link' : 'nav-link'} title='ACW Digital Card'>ACW Digital Card</Link></Nav.Link>
                                <Nav.Link href="javascript:void(0)" className="p-0 m-0"><Link to="/about" onClick={Scroll} className={(location.pathname === '/about' || location.pathname === '/abouts/features') ? 'active_header nav-link' : 'nav-link'} title='How it works'>How It Works</Link></Nav.Link>
                                <Nav.Link href="javascript:void(0)" className="p-0 m-0"><Link to="/pricing" onClick={Scroll} className={location.pathname === '/pricing' ? 'active_header nav-link' : 'nav-link'} title='Pricing'>
                                    Pricing
                                </Link></Nav.Link>
                                <Nav.Link href="javascript:void(0)" className="p-0 m-0"> <Link to="/contact" onClick={Scroll} className={location.pathname === '/contact' ? 'active_header nav-link' : 'nav-link'} title='Contact'>
                                    Contact
                                </Link></Nav.Link>
                                {
                                    (token && type)
                                        ?
                                        <div onClick={account} className={location.pathname === '/login' ? 'active_header login_btn nav-link' : 'login_btn nav-link'} title='Login'>
                                            Account
                                        </div>
                                        :
                                        <Nav.Link href="javascript:void(0)" className="p-0 m-0">
                                            <Link to="/login" onClick={Scroll} className={location.pathname === '/login' ? 'active_header login_btn nav-link' : 'login_btn nav-link'} title='Login'>
                                                Login
                                            </Link>
                                        </Nav.Link>
                                }

                                <Nav.Link href="javascript:void(0)" className="p-0 m-0">
                                    <Link to='/details/enquiry-form' className='order_card nav-link ' title='Enquiry'>
                                        <Button onClick={scrollEnquiry} className={location.pathname === '/details/enquiry-form' ? 'active_header' : ''}>Enquiry</Button>
                                    </Link>
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse >
                    </Container >
                </Navbar >
            </header >
        </div >
    )
}
