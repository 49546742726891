import React, { Component } from 'react'
import axios from 'axios';
import ImportedUrl from '../../../common/api';
import { Row, Container, Col, Spinner, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Success, Error } from '../../../common/swal';
import { Emailvalidate } from '../../../common/validate';
import { useParams } from "react-router";
import { Redirect } from 'react-router-dom';


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}
class ForgetPwd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            password: '',
            usernameError: false,
            emailError: false,
            passwordError: false,
            hidepassword: false,
            emailValidError: false,
            uniqueIdBack: false,
            unique: '',
            barCodeValue: '',
            strongPass: false,
        }
    }
    componentDidMount() {
        document.title = 'ACW CARD - Sign up'
        document.description = 'ACW Card enables you to share your business and personal profiles along with digital uploads of key documents to strengthen your portfolio.'
    }
    onChange = e => {
        const { name, value } = e.target;
        const Error = name + "Error";
        const ValidError = name + "ValidError";
        this.setState({ [name]: value, [Error]: false })
        if (name === 'email') {
            this.setState({ email: value });
            var email = value;
            if (email) {
                if (Emailvalidate(email)) {
                    this.setState({ [ValidError]: false, [Error]: false })
                } else {
                    this.setState({ [ValidError]: true })
                }
            }
            else {
                this.setState({ emailError: true, [ValidError]: false });
            }
        }
    }
    submit = (e) => {
        var { email, emailValidError } = this.state
        email = email ? email.trim() : ''
        let valid = true
        if (emailValidError) {
            valid = false
        }
        if (!email) {
            this.setState({ emailError: true, emailValidError: false })
            valid = false
        }
        if (valid) {
            this.setState({ updateSpinner: true })
            axios.post(ImportedUrl.API.forgotPassword, { email: email })
                .then((res) => {
                    if (res) {
                        this.setState({ updateSpinner: false, email: '' })
                        Success('Check mail for new password');
                        this.setState({ backLogin: true })
                    }
                }).catch(({ response }) => {
                    this.setState({ updateSpinner: false })
                    if (response) {
                        if (response.status == 401) {
                            Error('Something wrong, Retry again!')
                        } else if (response.status == 402) {
                            Error('Invalid password')
                        } else if (response.status == 510) {
                            Error('Email does not exit')
                        } else if (response.status == 502) {
                            Error(response.status + ' Bad Gateway')
                        } else if (response.status == 500) {
                            Error('Internal Server Error')
                        } else if (response.status == 400) {
                            Error('Bad request')
                        } else if (response.status == 409) {
                            Error('Block your account please contact admin')
                        }
                    }
                });
        }
    }
    render() {
        if (this.state.backLogin) {
            return <Redirect to={'/login'} />
        }
        return (
            <>
                <div className="login">
                    <Container className="container-new-big">
                        <div className="login_div">
                            <Row>
                                <Col lg={6}>
                                    <div className="left-login">
                                        <img src="./landing_page/assets/images/login.png" alt="login-img" />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="right-login">
                                        <h4 className="login_txt">Forgot Password</h4>
                                        <p className="accout_content">A password recovery link will be sent to this Email ID</p>
                                        <div className="mb-3">
                                            <input type="text" value={this.state.email} onChange={this.onChange} name='email' className="form-control" id="forgotemail" placeholder="Your Email Address*" />
                                            <div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Your Email Address is required</div>
                                            <div className="invalid-feedback" style={{ display: this.state.emailValidError ? 'block' : 'none' }}>Enter valid email</div>
                                        </div>
                                        <div className="login_btn">
                                            {
                                                this.state.spinner
                                                    ?
                                                    <Button className="login_txt">Forgot Password</Button>
                                                    :
                                                    <Button className="login_txt" onClick={this.submit}>Forgot Password</Button>
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}
export default connect(mapDispatchToProps)(withParams(ForgetPwd))