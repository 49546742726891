import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Container, Col, Spinner } from "react-bootstrap";
import { AiFillCamera, AiFillEye, AiOutlineSetting, AiOutlinePhone } from "react-icons/ai";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { MdFileOpen } from "react-icons/md";
import { BiEdit, BiMap } from 'react-icons/bi';
import Select from "react-select";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { RiImageAddFill } from "react-icons/ri";
import { HiRefresh } from "react-icons/hi";
import {
    AC_HANDLE_INPUT_CHANGE_PROFILE,
    AC_HANDLE_INPUT_CHANGE_SETTINGS,
    AC_LIST_PROFILE,
    AC_PROFILE_SPINNER,
    AC_VIEW_PROFILE,
} from "../../actions/profileAction";
import ImportedURL from "../../common/api";
import {
    Emailvalidate,
    FileAndImagevalidation,
    GetAge,
    Imagevalidation,
    Phonenumber,
    Urlvalidate,
    dataURLtoFile,
} from "../../common/validate";
import { Success, Error } from "../../common/swal";
import { AC_LIST_APP } from "../../actions/appAction";
import CountryCodeJson from "../../common/countrycode.json";
import DatePicker from "react-multi-date-picker";
import moment from "moment";
import { Modal } from "react-bootstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Buffer } from "buffer";
import bnotfound from "../images/v2/b-not-found.png";
import active_tick from "../images/v2/active_tick.png";
import disable_tick from "../images/v2/disable_tick.png";
import { BsPencilFill } from "react-icons/bs";
import {
    AC_HANDLE_INPUT_CHANGE_BUSINESS,
    AC_LIST_BUSINESSESS,
    AC_LIST_PERSONAL,
    AC_RUN_SPINNER_BUSINESS,
    AC_VIEW_BUSINESSESS,
    AC_VIEW_PERSONAL,
} from "../../actions/businessAction";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import profile from "../images/v2/profile.png";
import edit from "../images/v2/edit.png";
import { GoogleMap, LoadScript, Autocomplete } from '@react-google-maps/api';
import userlogo from '../images/v2/user.png';
import banner from '../images/v2/acwprofilebg.png';

const crop = {
    unit: "px",
    x: 130,
    y: 50,
};

class viewpreview extends Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
        this.fileInputBanner = React.createRef();
        this.fileInputBusinesssLogo = React.createRef();
        this.containerRef = React.createRef();
        this.modalContentRef = React.createRef();
        this.state = {
            bgimage: "",
            profileimg: "",
            skill: "",
            socialmediaSelect: {},
            socialmediaValue: [],
            urlError: false,
            emailError: false,
            updateSpinner: false,
            phoneNoPatternError: false,
            busPhoneNoPatternError: false,
            phoneNoPatternArrayError: false,
            name: "React",
            src: null,
            crop: {
                unit: "px",
                x: 130,
                y: 50,
                width: 200,
                height: 200,
            },
            croppedImageUrl: null,
            showModal: false,
            typeImage: "",
            onChangeHeight: false,
            profileImage: "",
            isUserdDisplayNameSaveShow: false,
            isHeadlineSaveShow: false,
            bussinesslogoSrc: "",
            bussinesslogoError: false,
            isBusinessEditedit: false,
            businessEditeditId: "",
            modelData: {},
            modelTitle: "",

            viewText: '',
            isWwwShow: false,

            streetBord: false,
            cityBord: false,
            stateBord: false,
            zipBord: false,
            countryBord: false,
            isClear: false,
            isClearUrl: false,
            isClearNumber: false,
            isClearEmail: false,
            isCoseModel: false,
            isCoseModel1: false,
            isShowSwal: true,
            apiKey: 'AIzaSyA2FJmWEkxtZDw3E8thlgXRQIU1sPMJwKU',
            isPreviewRedirect: false

        };
    }

    async componentDidMount() {
        document.title = "ACW CARD - Profile";
        document.description =
            "ACW Card enables you to share your business and personal profiles along with digital uploads of key documents to strengthen your portfolio.";
        this.props.RunSpinner()
        await this.props.ListPersonal();
        await this.props.ListUser();

        this.apiCall()

        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA2FJmWEkxtZDw3E8thlgXRQIU1sPMJwKU&libraries=places`;
        // script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA2FJmWEkxtZDw3E8thlgXRQIU1sPMJwKU&callback=initAutocomplete&libraries=places&v=weekly`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
            this.setState({ scriptLoaded: true });
        };
        document.body.appendChild(script);

    }

    apiCall = async () => {
        let id = this.props.match.params.id;
        const listPersonalData = this.props.BusinessState.listPersonal;
        var temp = false
        listPersonalData.filter(e => {
            if (e._id == id) {
                temp = true
            } else {
                temp = false
            }
        });
        if (temp) {
            await this.props.ViewPersonal(id);
        } else {
            await this.props.ViewBusiness(id);
        }
        await this.props.ViewProfile({ reverseStatus: true });
        await this.props.SpinnerProfile();
        await this.props.ListBusiness();
        await this.props.ListApp();
    }



    ModalImages = (e) => {
        this.setState({ showModal: false, collapsed: true });
        if (this.state.typeImage == "image") {
            this.fileInputRef.current.value = "";
        } else if (this.state.typeImage == "banner") {
            this.fileInputBanner.current.value = "";
        } else {
            this.fileInputBusinesssLogo.current.value = "";
        }
    };
    CropImages = (e) => {
        this.setState({ showModal1: true, collapsed: true });

        const data = this.props.ProfileState.profile;
        let value = this.state.croppedImageUrl;
        this.setState({
            croppedImageUrl: "",
            showModal: false,
            src: null,
            crop: {
                unit: "px",
                x: 130,
                y: 50,
                width: 200,
                height: 200,
            },
        });
        var formData = new FormData();
        if (this.state.typeImage == "image") {
            this.setState({ profileimg: URL.createObjectURL(value) });
            this.props.HandleInputChange("image", value);
            formData.append("image", value);
            this.fileInputRef.current.value = "";
        } else if (this.state.typeImage == "banner") {
            this.setState({ bgimage: value });
            formData.append("banner", value);
            this.props.HandleInputChange("banner", value);
            this.fileInputBanner.current.value = "";
        } else {
            this.props.HandleInputBusinessChange("image", value);
            this.fileInputBusinesssLogo.current.value = "";
        }

        if (this.state.typeImage == "image" || this.state.typeImage == "banner") {
            axios
                .post(ImportedURL.API.updateProfilePicture, formData)
                .then((res) => {
                    this.setState({ updateSpinner: false });
                    this.props.ViewProfile({ reverseStatus: true });
                    Success("Saved");
                    let btn = document.getElementById("personalDetailsModal");
                    btn.click();
                })
                .catch(({ response }) => {
                    this.setState({ updateSpinner: false });
                    if (response) {
                        if (response.status == 401) {
                            Error("Something wrong, Retry again!");
                        } else if (response.status == 510) {
                            Error("Email does not exit");
                        } else if (response.status == 502) {
                            Error(response.status + " Bad Gateway");
                        } else if (response.status == 500) {
                            Error("Internal Server Error");
                        } else if (response.status == 409) {
                            Error("Already exist");
                        } else if (response.status == 400) {
                            Error("Bad request");
                        }
                    }
                });
        }

    };
    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        const base64Image = canvas.toDataURL("image/png");
        if (base64Image) {
            const fileType = base64Image.split(";")[0].split(":")[1];
            const buffer = Buffer.from(
                base64Image.replace(/^data:image\/\w+;base64,/, ""),
                "base64"
            );
            const file = new File([buffer], fileName, { type: fileType });
            return file;
        } else {
            return "";
        }
    }

    sendModelView = async (app) => {
        this.setState({ modelId: app._id, modelTitle: app.name, viewText: '' });
        const { BusinessState } = this.props;
        const data = BusinessState.bussiness.applink;
        this.setState({ socialmediaValue: [] })

        let id = this.props.match.params.id;
        const listPersonalData = this.props.BusinessState.listPersonal;
        var temp = false
        listPersonalData.filter(e => {
            if (e._id == id) {
                temp = true
            } else {
                temp = false
            }
        });
        if (temp) {
            await this.props.ViewPersonal(id);
        } else {
            await this.props.ViewBusiness(id);
        }





        const filteredArray = data.filter((obj1) =>
            app.typesList.some((obj2) => obj2.id === obj1.id._id)
        );
        this.props.HandleInputBusinessChange("modelData", filteredArray);


        this.setState({
            urlError: false,
            emailError: false,
            phoneNoPatternError: false,
            busPhoneNoPatternError: false,
            phoneNoPatternArrayError: false,
            bussinesslogoError: false,
            isBusinessEditedit: false,
            designationError: false,
            workError: false,
            businessphonenumberError: false,
            showModal1: true,

            streetBord: false,
            cityBord: false,
            stateBord: false,
            zipBord: false,
            country: false,
            isCoseModel: false,
            isCoseModel1: false,
        });
    };

    // Headline Display Name Hiting Function
    headlineDisplayFun = (formData) => {
        axios
            .post(ImportedURL.API.updateProfileNameHeadline, formData)
            .then((res) => {
                this.setState({ updateSpinner: false });
                // this.props.ViewProfile({ reverseStatus: true });
                // Success("Saved");
            })
            .catch(({ response }) => {
                this.setState({ updateSpinner: false });
                if (response) {
                    if (response.status == 401) {
                        Error("Something wrong, Retry again!");
                    } else if (response.status == 510) {
                        Error("Email does not exit");
                    } else if (response.status == 502) {
                        Error(response.status + " Bad Gateway");
                    } else if (response.status == 500) {
                        Error("Internal Server Error");
                    } else if (response.status == 409) {
                        Error("Already exist");
                    } else if (response.status == 400) {
                        Error("Bad request");
                    }
                }
            });
    }

    redirectPreview = (e) => {
        this.setState({ isPreviewRedirect: true })
    }

    render() {
        const { isPreviewRedirect, croppedImageUrl, src, isUserdDisplayNameSaveShow, isUserdHeadlineSaveShow, modelTitle, viewText, isWwwShow } = this.state;
        const { socialmediaSelect } = this.state;
        const { ProfileState, appState, BusinessState } = this.props;
        const listapp = appState.listApp;
        const spinnerViewProfile = appState.spinnerViewProfile;
        const bData = BusinessState.bussiness;

        const listBusinessData = BusinessState.listBusiness;
        listapp?.sort((a, b) => Number(a.sortorder) - Number(b.sortorder));
        // const spinner = ProfileState.spinner;
        const data = ProfileState.profile;


        const dobDate = data.dob ? moment(data.dob).format("MM-DD-YYYY") : "";
        const { streetBord, cityBord, stateBord, zipBord, countryBord, isClear, isClearUrl, isClearNumber, isClearEmail, isCoseModel, isCoseModel1, Country, State, City, apiKey } = this.state;
        // ========== Preview changes 
        const listUser = ProfileState.listUser;
        var querString = ''
        if (listUser && listUser.length > 0) {
            listUser.map(data => {
                querString = `?cardType=${data.cardtype}&uId=${data.uniqueid}`
            })
        }
        if (isPreviewRedirect) return <Redirect to={`/preview/${querString}`} />

        let locationData = {}
        if (bData && bData != undefined) {
            locationData = bData && bData.address ? bData.address.address : ''
        }

        let phoneShowInPersonal = ''

        if (bData && bData != undefined) {
            let call = bData && bData.call
            if (call && call.length != 0) {
                phoneShowInPersonal = (call.cc && call.cc.value) + ' ' + call.value
            }
        }

        let displayLoc = ''
        if (locationData) {
            displayLoc = (locationData.street ? (locationData.street).replace(/,/g, "") + ',' : "") + (locationData.city ? (locationData.city).replace(/,/g, "") + ',' : "") + (locationData.state ? (locationData.state).replace(/,/g, "") + ',' : "") + (locationData.country ? (locationData.country).replace(/,/g, "") + ',' + ' ' : "") + (locationData.zip ? "- " + locationData.zip : "")
            var mapUrlDisplay = `https://www.google.com/maps/place/${encodeURIComponent(displayLoc)}`;
        }

        const linkStatus = BusinessState.bussiness && BusinessState.bussiness.applinkstatus;
        if (linkStatus && linkStatus.length > 0) {
            var listStatusVal = linkStatus.filter(e => e.status == true)
        }

        const matchedData = listapp.filter(obj1 =>
            (listStatusVal && listStatusVal.some(obj2 => obj2.appid === obj1._id))
        );
        const spinnerEdit = BusinessState.spinnerEdit;
        return (
            <>
                <div style={{ position: "relative" }}>
                    <div className="home_section profile_section gap_padding_space pro_bg">
                        <Container>
                            <Row className="justify-content-md-center">
                                <Col xs="12" lg="5" md="12" sm="12">
                                    <div className="acw_card_nav_images">
                                        <div className="acw_card_logo">
                                            <div className="acw_image1">
                                                <img src="../assets/images/acwlogo.png" />
                                            </div>
                                            <div className="acw_vertical_line"></div>
                                            <div className="acw_image2">
                                                <img src="../assets/images/nfclogo.png" />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="profile_header_text d-flex"
                                        style={{
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div className="d-flex align-items-center">
                                            <p className="back_text mb-0">View Profile</p>
                                        </div>
                                        <div className="profile_back_arrow_edit">
                                            <p onClick={this.redirectPreview} className="mb-0">
                                                <HiOutlineChevronLeft />
                                            </p>
                                        </div>
                                    </div>
                                    <div className="home_sec">
                                        <div className='account_profile_sec'>
                                            <div className='account_profile'>
                                                <div className='tap_banner_img'>
                                                    <div className='img_size'>
                                                        {
                                                            ((this.state.bgimage || data.banner) && data.isCoverStatus) ?
                                                                <img src={this.state.bgimage ? URL.createObjectURL(this.state.bgimage) : ImportedURL.LIVEURL + data.banner} alt='' />
                                                                : <img className="avatar" src={banner} data-toggle="tooltip" data-original-title="Avatar Name" alt="" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='address_img'>
                                                    <div className='row'>
                                                        <div className='col-8'>
                                                            <div className='address'>
                                                                <h6>{data.displayname ? data.displayname : '---'} </h6>
                                                                <span>{data.headline ? data.headline : ''}</span>
                                                                {
                                                                    bData.viewBusinessStatus
                                                                        ?
                                                                        <>
                                                                            {bData.work ? <span> {bData.work}</span> : ''}
                                                                            {bData.designation ? <span> {bData.designation}</span> : ''}
                                                                        </> : ''
                                                                }
                                                            </div>
                                                            <div className='d-flex '>
                                                                <div className='icon'>
                                                                    {
                                                                        displayLoc
                                                                            ?
                                                                            <a href={mapUrlDisplay} target="_blank" >
                                                                                <BiMap className='display_name_map' />
                                                                            </a>
                                                                            : ""
                                                                    }
                                                                </div>
                                                                <div className='content'>
                                                                    {
                                                                        displayLoc
                                                                            ?
                                                                            <a href={mapUrlDisplay} target="_blank" style={{ color: 'black', textDecoration: 'none' }}>
                                                                                <span>{locationData ? locationData.street : ''}</span><br></br>
                                                                                <span>{locationData ? locationData.city + ', ' + locationData.state + ' ' + locationData.zip : ''}</span><br></br>
                                                                                <span>{locationData ? locationData.country : ''}</span>
                                                                                {/* {displayLoc}     */}
                                                                            </a>
                                                                            : ""
                                                                    }
                                                                </div>
                                                            </div>


                                                            <div className='d-flex '>
                                                                {
                                                                    phoneShowInPersonal
                                                                    &&
                                                                    <>
                                                                        <a href={"tel:" + phoneShowInPersonal} target="_blank" >
                                                                            <AiOutlinePhone className='display_name_profile' />
                                                                        </a>
                                                                        <p>
                                                                            <a href={"tel:" + phoneShowInPersonal} target="_blank" className='viewunique_call'>
                                                                                {phoneShowInPersonal}
                                                                            </a>
                                                                        </p>
                                                                    </>

                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='col-4'>
                                                            <div className='right_img'>
                                                                {
                                                                    bData.viewBusinessStatus
                                                                        ?
                                                                        <>
                                                                            {
                                                                                bData.image
                                                                                    ?
                                                                                    <img src={ImportedURL.FILEURL + bData.image} />
                                                                                    :
                                                                                    <img src={userlogo} />
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {(this.state.profileimg || data.image) && data.isProfileStatus ?
                                                                                <>
                                                                                    <img src={this.state.profileimg ? URL.createObjectURL(this.state.profileimg) : ImportedURL.LIVEURL + data.image} />
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <img src={userlogo} />
                                                                                </>
                                                                            }
                                                                        </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='skill_section'>
                                                    {
                                                        (data.skill && data.skill.length > 0)
                                                        &&
                                                        data.skill.map((item, i) => {
                                                            return (
                                                                <>
                                                                    <div className='skill_btn'>
                                                                        {item}
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                        <div className='accordian_sec'>
                                            <div class="accordion mt-3" id="accordionExample">
                                                {
                                                    (matchedData != undefined && matchedData.length > 0)
                                                    &&
                                                    matchedData.filter(e => e.status == true).map((app, i) => {
                                                        let { socialmediaValue } = this.state
                                                        let toggleStatus = (bData.applinkstatus && bData.applinkstatus.length > 0) ? (bData.applinkstatus).find((e) => e.appid == app._id) : ""
                                                        let status = (socialmediaValue && socialmediaValue.length > 0) ? (socialmediaValue.reverse()).find((e) => e.Uid == app._id) : ''

                                                        let link = ''
                                                        let valueData = ''
                                                        if (status != undefined && status) {
                                                            link = status.link
                                                            valueData = status.value
                                                        }
                                                        return (
                                                            <>
                                                                <div className='profile_infosec accordion-item edit_profile_infosec tap_view_box' onClick={(e) => this.sendModelView(app)}>
                                                                    <button class="btn d-flex justify-content-between align-items-center" type="button">
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='tap_left_icon_list'>
                                                                                <div className='img_size'>
                                                                                    <img src={ImportedURL.LIVEURL + app.applogo} />
                                                                                </div>
                                                                            </div>
                                                                            <label className='form-label mb-0 px-0 ml-3' data-toggle="modal" data-target="#exampleModalCenter"> {app.name}</label>
                                                                        </div>
                                                                        <div className='file_open mr-2' data-toggle="modal" data-target="#exampleModalCenter" >
                                                                            <MdFileOpen />
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        {spinnerEdit &&
                                            <div className='common_loader_model'>
                                                <img className='loader_img_style_common' src='/assets/images/logo.jpg' />
                                                <Spinner className='spinner_load_common' animation="border" variant="info" >
                                                </Spinner>
                                            </div>}

                                        {/* =============Model xxxxxxxxxx  */}
                                        <div class="modal fade personal_details_model" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLongTitle">{modelTitle}</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body collapseSix modeleditopt">
                                                        {
                                                            (listapp != undefined && listapp.length > 0) && listapp.filter(e => e.status == true).map((app, i) => {
                                                                if (app._id == this.state.modelId) {
                                                                    let { socialmediaValue } = this.state
                                                                    let toggleStatus = (bData.applinkstatus && bData.applinkstatus.length > 0) ? (bData.applinkstatus).find((e) => e.appid == app._id) : ""
                                                                    let status = (socialmediaValue && socialmediaValue.length > 0) ? (socialmediaValue.reverse()).find((e) => e.Uid == app._id) : ''
                                                                    let link = ''
                                                                    let valueData = ''
                                                                    if (status != undefined && status) {
                                                                        link = status.link
                                                                        valueData = status.value
                                                                    }
                                                                    return (
                                                                        <div className='profile_images_box_shadow'>
                                                                            <div className='link_num_mail_show_desin '>
                                                                                {
                                                                                    (bData.applink && bData.applink.length > 0) &&
                                                                                    bData.applink.filter(e => e.appStatus == true).map((item, index) => {
                                                                                        if (item.parentid == app._id) {
                                                                                            return (
                                                                                                <>
                                                                                                    <div key={index} className="mb-2">
                                                                                                        {
                                                                                                            (item.inputtype == 'url')
                                                                                                            &&
                                                                                                            <a href={item.link.includes('https://') ? item.link : `https://${item.link}`} target="_blank" style={{ color: 'black', textDecoration: 'none' }}>
                                                                                                                <div className='img_size'>
                                                                                                                    <img src={ImportedURL.LIVEURL + item.logo} />
                                                                                                                </div>
                                                                                                                <span className='tap_view_appname'>{item.appname}</span>
                                                                                                            </a>
                                                                                                        }
                                                                                                        {
                                                                                                            (item.inputtype == 'number')
                                                                                                            &&
                                                                                                            <a href={"tel:" + ((item.cc.value != undefined && item.cc) ? item.cc.value + "" : "") + item.value} target="_blank" style={{ color: 'black', textDecoration: 'none' }}>
                                                                                                                <div className='img_size'>
                                                                                                                    <img src={ImportedURL.LIVEURL + item.logo} />
                                                                                                                </div>
                                                                                                                <span className='tap_view_appname'>{item.appname}</span>
                                                                                                            </a>
                                                                                                        }
                                                                                                        {
                                                                                                            (item.inputtype == 'email')
                                                                                                            &&
                                                                                                            <a href={"mailto:" + item.link} target="_blank" style={{ color: 'black', textDecoration: 'none' }}>
                                                                                                                <div className='img_size'>
                                                                                                                    <img src={ImportedURL.LIVEURL + item.logo} />
                                                                                                                </div>
                                                                                                                <span className='tap_view_appname'>{item.appname}</span>
                                                                                                            </a>
                                                                                                        }
                                                                                                        {
                                                                                                            (item.inputtype == 'document')
                                                                                                            &&
                                                                                                            <a href={ImportedURL.FILEURL + item.value} target="_blank" style={{ color: 'black', textDecoration: 'none' }}>
                                                                                                                <div className='img_size'>
                                                                                                                    <img src={ImportedURL.LIVEURL + item.logo} />
                                                                                                                </div>
                                                                                                                <span className='tap_view_appname'>{item.appname}</span>
                                                                                                            </a>
                                                                                                        }
                                                                                                        {
                                                                                                            (item.inputtype == 'none')
                                                                                                            &&
                                                                                                            <div className='img_size'>
                                                                                                                <img src={ImportedURL.LIVEURL + item.logo} />
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            {
                                                                                (bData.applink && bData.applink.length > 0)
                                                                                &&
                                                                                bData.applink.filter(e => e.appStatus == true).map((item, i) => {
                                                                                    let location = ((item.address && item.address.street) ? (item.address.street).replace(/,/g, "") + ',' : "") + ((item.address && item.address.city) ? (item.address.city).replace(/,/g, "") + "," : "") + ((item.address && item.address.state) ? (item.address.state).replace(/,/g, "") + ',' : "") + ((item.address && item.address.country) ? (item.address.country).replace(/,/g, "") : "")
                                                                                    const mapUrl = `https://www.google.com/maps/place/${encodeURIComponent(location)}`;
                                                                                    if (item.parentid == app._id) {
                                                                                        return (
                                                                                            <>
                                                                                                {
                                                                                                    (item.inputtype == 'address')
                                                                                                    &&
                                                                                                    <div className='tap_address_box'>
                                                                                                        <a href={mapUrl} target='_blank'>
                                                                                                            <div className='text_imgs'>
                                                                                                                <div className='img_size'>
                                                                                                                    <img src={ImportedURL.LIVEURL + item.logo} />
                                                                                                                </div>
                                                                                                                <div className='content'>
                                                                                                                    <h5>{item.appname}</h5>
                                                                                                                    <p>{item.address.street}, </p>
                                                                                                                    <p>{item.address.city}, </p>
                                                                                                                    <p>{item.address.state}, </p>
                                                                                                                    <p>{item.address.country}, </p>
                                                                                                                    <p>{item.address.zip}. </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                }

                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        </div>
                                                                    )
                                                                }

                                                            })
                                                        }
                                                        <div className='close_modal d-flex justify-content-center mt-3'>
                                                            <button type="button" class="btn " data-dismiss="modal">Close</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="crop_model_section">
                        <Modal
                            className={"info-modal crop_modal"}
                            show={this.state.showModal}
                        >
                            <div className="modal-header">
                                <Modal.Title>{"Crop Image"}</Modal.Title>
                                <button
                                    type="button"
                                    id="closeModal"
                                    onClick={this.ModalImages}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                        <img src="../../assets/images/cancel.png" />
                                    </span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="crop_Images">
                                    {this.state.src && (
                                        <ReactCrop
                                            src={this.state.src}
                                            crop={this.state.crop}
                                            onImageLoaded={this.onImageLoaded}
                                            onComplete={this.onCropComplete}
                                            onChange={this.onCropChange}
                                        />
                                    )}
                                </div>
                            </div>
                            <div class="modal-footer">
                                <div className="">
                                    <button
                                        type="button"
                                        class="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={this.ModalImages}
                                    >
                                        Close
                                    </button>
                                </div>
                                <div className="profile_save_btn">
                                    <button type="button" class="btn" onClick={this.CropImages}>
                                        Crop
                                    </button>
                                </div>
                            </div>
                        </Modal>
                    </div>

                    {this.state.updateSpinner ? (
                        <div
                            style={{
                                height: "100vh",
                                position: "fixed",
                                width: "100%",
                                top: "0px",
                            }}
                        >
                            <div style={{ position: "relative" }}></div>
                            <div className="common_loader">
                                <img
                                    className="loader_img_style_common"
                                    src="/assets/images/logo.jpg"
                                />
                                <Spinner
                                    className="spinner_load_common"
                                    animation="border"
                                    variant="info"
                                ></Spinner>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    ProfileState: state.profile,
    BusinessState: state.business,
    appState: state.app,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            HandleInputChange: AC_HANDLE_INPUT_CHANGE_PROFILE,
            HandleInputBusinessChange: AC_HANDLE_INPUT_CHANGE_BUSINESS,
            ListBusiness: AC_LIST_BUSINESSESS,
            ListApp: AC_LIST_APP,
            ViewProfile: AC_VIEW_PROFILE,
            ViewBusiness: AC_VIEW_BUSINESSESS,
            SpinnerProfile: AC_PROFILE_SPINNER,
            HandleChangesSetting: AC_HANDLE_INPUT_CHANGE_SETTINGS,
            ViewPersonal: AC_VIEW_PERSONAL,
            ListPersonal: AC_LIST_PERSONAL,
            ListUser: AC_LIST_PROFILE,
            RunSpinner: AC_RUN_SPINNER_BUSINESS
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(viewpreview);
