const CONSTANTS = {};

CONSTANTS.previleges = [
    { "name": "Hotel", "view": false, "add": false, "edit": false, "delete": false, "sidebarname": "Hotel" },
    { "name": "Company", "view": false, "add": false, "edit": false, "delete": false, "sidebarname": "Company" },
    { "name": "User", "view": false, "add": false, "edit": false, "delete": false, "sidebarname": "User" },
    { "name": "App", "view": false, "add": false, "edit": false, "delete": false, "sidebarname": "App" },
    { "name": "Card_Type", "view": false, "add": false, "edit": false, "delete": false, "sidebarname": "Card Type" },
    { "name": "Category", "view": false, "add": false, "edit": false, "delete": false, "sidebarname": "Category" },
    { "name": "Enquiry", "view": false, "add": false, "edit": false, "delete": false, "sidebarname": "Enquiry" },
    { "name": "Swap", "view": false, "add": false, "edit": false, "delete": false, "sidebarname": "Swap" },
    { "name": "Bloodgroup", "view": false, "add": false, "edit": false, "delete": false, "sidebarname": "Bloodgroup" },
    { "name": "Education", "view": false, "add": false, "edit": false, "delete": false, "sidebarname": "Education" },
    { "name": "Landing_Page_Enquiry", "view": false, "add": false, "edit": false, "delete": false, "sidebarname": "Landing Page Enquiry" },
]

CONSTANTS.allprevileges = [
    { "name": "Hotel", "view": true, "add": true, "edit": true, "delete": true, "sidebarname": "Hotel" },
    { "name": "Company", "view": true, "add": true, "edit": true, "delete": true, "sidebarname": "Company" },
    { "name": "User", "view": true, "add": true, "edit": true, "delete": true, "sidebarname": "User" },
    { "name": "App", "view": true, "add": true, "edit": true, "delete": true, "sidebarname": "App" },
    { "name": "Card_Type", "view": true, "add": true, "edit": true, "delete": true, "sidebarname": "Card Type" },
    { "name": "Category", "view": true, "add": true, "edit": true, "delete": true, "sidebarname": "Category" },
    { "name": "Enquiry", "view": true, "add": true, "edit": true, "delete": true, "sidebarname": "Enquiry" },
    { "name": "Swap", "view": true, "add": true, "edit": true, "delete": true, "sidebarname": "Swap" },
    { "name": "Bloodgroup", "view": true, "add": true, "edit": true, "delete": true, "sidebarname": "Bloodgroup" },
    { "name": "Education", "view": true, "add": true, "edit": true, "delete": true, "sidebarname": "Education" },
    { "name": "Landing_Page_Enquiry", "view": true, "add": true, "edit": true, "delete": true, "sidebarname": "Landing Page Enquiry" },
]

export default CONSTANTS;
