const initialState = {
    company: {
        image: '',
        name: '',
        email: '',
        phonenumber: '',
        address: '',
        username: '',
        password: '',
        bussinesstype: '',
        cc: {
            name: "US",
            value: "+1",
        },
    },
    listCompany: [],
    spinner: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_INPUT_CHANGE_COMPANY':
            return Object.assign({}, state, {
                company: {
                    ...state.company,
                    [action.name]: action.value
                }
            })
        case "VIEW_COMPANY":
            return Object.assign({}, state, {
                company: action.payload,
                spinner: action.spinner
            });
        case 'LIST_COMPANY':
            return {
                ...state,
                listCompany: action.payload,
                spinner: action.spinner
            }
        case 'EMPTY_COMPANY':
            return Object.assign({}, state, {
                company: {
                    image: '',
                    name: '',
                    email: '',
                    phonenumber: '',
                    address: '',
                    username: '',
                    password: '',
                    bussinesstype: '',
                    cc: {
                        name: "US",
                        value: "+1",
                    },
                }
            })
        case 'SPINNER_COMPANY':
            return Object.assign({}, state, {
                spinner: true
            })

        default:
            return state;
    }
}