// const LIVEURL = "http://localhost:9000/";
// const ROOTURL = LIVEURL + 'api/v1/admin/';

const LIVE = true;
const ENV = true ? 'prod' : 'dev';
const LIVEURL = LIVE ? "https://acwcard.com/" : "http://localhost:5005/"
const SITEURL = LIVE ? "https://acwcard.com/" : "http://localhost:3002/";
const ROOTURL = LIVEURL + 'api/v1/admin/';
const LOCALURL = LIVE ? "https://acwcard.com/" : "http://localhost:3000/";
const FILEURL = ENV == 'dev' ? "https://test-assets-myhotelai.s3.us-east-2.amazonaws.com/" : 'https://assets-myhotelai.s3.us-east-2.amazonaws.com/';


const API = {

  addLandingPageEnquiry: ROOTURL + 'landingpageenquiry/addLandingPageEnquiry',
  login: ROOTURL + 'account/login',
  signup: ROOTURL + 'user/signup',
  forgotPassword: ROOTURL + 'user/forgotPassword',

  // -------------Common Status Call URL 
  statusChange: ROOTURL + 'common/statusChange',

  accountDetails: ROOTURL + 'account/accountDetails',
  updateProfileAdmin: ROOTURL + 'account/updateProfileAdmin',

  login: ROOTURL + 'account/login',
  signup: ROOTURL + 'user/signup',
  updateProfilePicture: ROOTURL + 'user/updateProfilePicture',
  updateProfile: ROOTURL + 'user/updateProfile',
  updateProfileNameHeadline: ROOTURL + 'user/updateProfileNameHeadline',
  updateaccountsetting: ROOTURL + 'user/updateaccountsetting',
  viewProfile: ROOTURL + 'user/viewProfile',
  listSocialMediaDrag: ROOTURL + 'user/listSocialMediaDrag',
  listUser: ROOTURL + 'user/listUser',
  viewUser: ROOTURL + 'user/viewUser',
  updateUser: ROOTURL + 'user/updateUser',
  viewProfileUniqueId: ROOTURL + 'user/viewProfileUniqueId',
  vCardGenerate: ROOTURL + 'user/vCardGenerate',
  viewPrivateAccount: ROOTURL + 'user/viewPrivateAccount',
  viewProfileUserName: ROOTURL + 'user/viewProfileUserName',
  vCardGenerateOwner: ROOTURL + 'user/vCardGenerateOwner',
  forgotPassword: ROOTURL + 'user/forgotPassword',
  updateProfileAppLinks: ROOTURL + 'user/updateProfileAppLinks',
  updateProfileAppStatus: ROOTURL + 'user/updateProfileAppStatus',
  updateProfileOtherStatus: ROOTURL + 'user/updateProfileOtherStatus',
  updateInfoStatus: ROOTURL + 'user/updateInfoStatus',
  userCardCount: ROOTURL + 'user/userCardCount',
  updateTapCardStatus: ROOTURL + 'user/updateTapCardStatus',
  updateUserProfileStatus: ROOTURL + 'user/updateUserProfileStatus',
  updateUserCOverStatus: ROOTURL + 'user/updateUserCOverStatus',
  swapAccountIdInUser: ROOTURL + 'user/swapAccountIdInUser',
  findUniqueId: ROOTURL + 'user/findUniqueId',


  addBusiness: ROOTURL + 'business/addBusiness',
  listBusiness: ROOTURL + 'business/listBusiness',
  listBusinessUnique: ROOTURL + 'business/listBusinessUnique',
  viewBusiness: ROOTURL + 'business/viewBusiness',
  updateBusiness: ROOTURL + 'business/updateBusiness',
  updateBusinessStatus: ROOTURL + 'business/updateBusinessStatus',
  softDeleteBusinesses: ROOTURL + 'business/softDeleteBusinesses',

  addPersonel: ROOTURL + 'personal/addPersonel',
  listPersonel: ROOTURL + 'personal/listPersonel',
  listPersonelUnique: ROOTURL + 'personal/listPersonelUnique',
  viewPersonel: ROOTURL + 'personal/viewPersonel',
  updatePersonalStatus: ROOTURL + 'personal/updatePersonalStatus',
  softDeletePersonal: ROOTURL + 'personal/softDeletePersonal',


  addApp: ROOTURL + 'app/addApp',
  listAppUnique: ROOTURL + 'app/listAppUnique',
  listApp: ROOTURL + 'app/listApp',
  viewApp: ROOTURL + 'app/viewApp',
  updateApp: ROOTURL + 'app/updateApp',
  softDeleteApp: ROOTURL + 'app/softDeleteApp',


  getLoginHistory: ROOTURL + 'loginhistory/getLoginHistory',

  countSaveContactDashboard: ROOTURL + 'dashboard/countSaveContactDashboard',

  getSaveContactHistory: ROOTURL + 'savecontacthistory/getSaveContactHistory',
  ViewSaveContactHistory: ROOTURL + 'savecontacthistory/ViewSaveContactHistory',

  addEnquiry: ROOTURL + 'enquiry/addEnquiry',
  listEnquiry: ROOTURL + 'enquiry/listEnquiry',
  viewEnquiry: ROOTURL + 'enquiry/viewEnquiry',

  addTapSeen: ROOTURL + 'tapseen/addTapSeen',
  listTapSeen: ROOTURL + 'tapseen/listTapSeen',
  addUpdateSwap: ROOTURL + 'swap/addUpdateSwap',
  listSwaps: ROOTURL + 'swap/listSwaps',

  addBloodgroup: ROOTURL + 'bloodgroup/addBloodgroup',
  listBloodgroup: ROOTURL + 'bloodgroup/listBloodgroup',
  viewBloodgroup: ROOTURL + 'bloodgroup/viewBloodgroup',
  updateBloodgroup: ROOTURL + 'bloodgroup/updateBloodgroup',

  addEducation: ROOTURL + 'education/addEducation',
  listEducation: ROOTURL + 'education/listEducation',
  viewEducation: ROOTURL + 'education/viewEducation',
  updateEducation: ROOTURL + 'education/updateEducation',

  addCompany: ROOTURL + 'company/addCompany',
  listCompany: ROOTURL + 'company/listCompany',
  viewCompany: ROOTURL + 'company/viewCompany',
  updateCompany: ROOTURL + 'company/updateCompany',

  addCardtype: ROOTURL + 'cardtype/addCardtype',
  listCardtype: ROOTURL + 'cardtype/listCardtype',
  viewCardtype: ROOTURL + 'cardtype/viewCardtype',
  updateCardtype: ROOTURL + 'cardtype/updateCardtype',

  addCategory: ROOTURL + 'category/addCategory',
  listCategory: ROOTURL + 'category/listCategory',
  viewCategory: ROOTURL + 'category/viewCategory',
  updateCategory: ROOTURL + 'category/updateCategory',

  addHotel: ROOTURL + 'hotel/addHotel',
  listHotel: ROOTURL + 'hotel/listHotel',
  viewHotel: ROOTURL + 'hotel/viewHotel',
  updateHotel: ROOTURL + 'hotel/updateHotel',
  getUserViewReview: ROOTURL + 'hotel/getUserViewReview',

  addLandingPageEnquiry: ROOTURL + 'landingpageenquiry/addLandingPageEnquiry',
  listLandingPageEnquiry: ROOTURL + 'landingpageenquiry/listLandingPageEnquiry',
  viewLandingPageEnquiry: ROOTURL + 'landingpageenquiry/viewLandingPageEnquiry',
}

const ImportedURL = {
  API: API,
  LIVEURL: LIVEURL,
  LOCALURL: LOCALURL,
  FILEURL: FILEURL,
  SITEURL: SITEURL,
}
export default ImportedURL;
