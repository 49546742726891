import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Container, Col, Spinner, Dropdown, Button, ButtonGroup } from "react-bootstrap";
import { AiFillCamera, AiOutlinePlus, AiOutlineSetting, AiFillCloseCircle, AiFillSetting } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import { HiPhotograph } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import Select from "react-select";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { BsPersonVcard } from "react-icons/bs";
import { RiImageAddFill } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { FaHandPointer } from "react-icons/fa";
import {
  AC_HANDLE_INPUT_CHANGE_PROFILE,
  AC_HANDLE_INPUT_CHANGE_SETTINGS,
  AC_PROFILE_SPINNER,
  AC_RESET_BASIC_DETAILS,
  AC_VIEW_PROFILE,
} from "../../actions/profileAction";
import ImportedURL from "../../common/api";
import {
  Emailvalidate,
  FileAndImagevalidation,
  GetAge,
  Imagevalidation,
  Phonenumber,
  OnlyAplhabets,
  Urlvalidate,
  dataURLtoFile,
  OnlyAplhabetsSomeCharactor,
  OnlyAplhabetsNumberSomeCharactor,
} from "../../common/validate";
import { Success, Error } from "../../common/swal";
import { AC_LIST_APP } from "../../actions/appAction";
import CountryCodeJson from "../../common/countrycode.json";
// import DatePicker, { Calendar } from "react-multi-date-picker";
import { addYears } from 'date-fns';
import moment from "moment";
import { Modal } from "react-bootstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Buffer } from "buffer";
import active_tick from "../images/v2/active_tick.png";
import disable_tick from "../images/v2/disable_tick.png";
import { AC_EMPTY_BUSINESS, AC_HANDLE_INPUT_CHANGE_BUSINESS, AC_LIST_BUSINESSESS, AC_LIST_PERSONAL, AC_RUN_SPINNER_ADD, } from "../../actions/businessAction";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import profile from "../images/v2/profile.png";
import edit from "../images/v2/edit.png";
import bnotfound from "../images/v2/b-not-found.png";
import personal_log from "../images/v2/personal_log.png";
import { HiRefresh } from "react-icons/hi";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// Latest version - v3.0.0 with Tree Shaking to reduce bundle size
import { Country, State, City } from 'country-state-city';
import zipcodes from 'zipcodes';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'
import parsePhoneNumber from 'libphonenumber-js'
import { GoogleMap, LoadScript, Autocomplete } from '@react-google-maps/api';
import Autocomplete1 from 'react-autocomplete';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import education_json from './education.json';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AC_LIST_BLOODGROUP } from "../../actions/bloodgroupAction";
import { AC_LIST_EDUCATION } from "../../actions/educationAction";

const crop = {
  unit: "px",
  x: 130,
  y: 50,
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};

class Profile extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.dateInputRef = React.createRef();
    this.fileInputBanner = React.createRef();
    this.fileInputBusinesssLogo = React.createRef();
    this.carouselRef = React.createRef();
    this.state = {
      bgimage: "",
      profileimg: "",
      skill: "",
      socialmediaSelect: {},
      socialmediaValue: [],
      urlError: false,
      emailError: false,
      updateSpinner: false,
      phoneNoPatternError: false,
      busPhoneNoPatternError: false,
      phoneNoPatternArrayError: false,
      name: "React",
      src: null,
      crop: {
        unit: "px",
        x: 130,
        y: 50,
        width: 200,
        height: 200,
      },
      croppedImageUrl: null,
      showModal: false,
      typeImage: "",
      onChangeHeight: false,
      profileImage: "",
      isUserdDisplayNameSaveShow: false,
      isUserdHeadlineSaveShow: false,
      isHeadlineSaveShow: false,
      bussinesslogoSrc: "",
      bussinesslogoError: false,
      isBusinessEditedit: false,
      businessEditeditId: "",
      designationError: false,
      workError: false,
      businessphonenumberError: false,
      collapsed: false,
      showModal1: false,
      isWwwShow: false,
      streetBord: false,
      cityBord: false,
      stateBord: false,
      zipBord: false,
      countryBord: false,
      isClear: false,
      isClearUrl: false,
      isClearNumber: false,
      isClearEmail: false,
      isCoseModel: false,
      isCoseModel1: false,
      isShowSwal: true,
      apiKey: 'AIzaSyA2FJmWEkxtZDw3E8thlgXRQIU1sPMJwKU',
      scriptLoaded: false,
      no: '',
      street: '',
      city: '',
      state: '',
      country: '',
      zip: '',
      modalType: '',
      businessBtnChange: false,
      personalBtnChange: false,
      isEducation: false,
      isSkillColor: false,
      isBCamShow: false,
      isPCamShow: false,
      isOpen: false,
      isOpenTwo: false,

      Country: Country.getAllCountries(),
      State: State.getAllStates(),
      City: City.getAllCities(),
    };
    this.containerRef = React.createRef();
    this.dropdownRef = React.createRef();
    this.dropdownRefTwo = React.createRef();
  }

  async componentDidMount() {
    document.title = "ACW CARD - Profile";
    document.description =
      "ACW Card enables you to share your business and personal profiles along with digital uploads of key documents to strengthen your portfolio.";

    this.props.SpinnerProfile();
    this.props.ViewProfile({ reverseStatus: true });
    this.props.ListBusiness();
    this.props.ListPersonal();
    this.props.ListBloodgroup();
    this.props.ListEducation();
    await this.props.ListApp();
    this.insertAppLinkSatus();
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("mousedown", this.handleClickOutsideTwo);


    const script = document.createElement("script");
    // script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA2FJmWEkxtZDw3E8thlgXRQIU1sPMJwKU&libraries=places`;
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA2FJmWEkxtZDw3E8thlgXRQIU1sPMJwKU&callback=initAutocomplete&libraries=places&v=weekly`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      this.setState({ scriptLoaded: true });
    };
    document.body.appendChild(script);

    this.handleChangeQualification()
  }
  // This function using applinkstatus inserting
  insertAppLinkSatus = () => {
    const { appState } = this.props;
    const listapp = appState.listApp;
    const applinkstatus = [];
    listapp.filter((e) => e.status == true).map((app, i) => {
      let obj = {};
      obj["appid"] = app._id;
      obj["status"] = false;
      applinkstatus.push(obj);
    });
    this.props.HandleInputBusinessChange("applinkstatus", applinkstatus);
  };

  // Headline Display Name Hiting Function
  headlineDisplayFun = (formData) => {
    const data = { ...formData };
    for (let key in data) {
      if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
        data[key] = data[key].trim();
      }
    }
    axios
      .post(ImportedURL.API.updateProfileNameHeadline, data)
      .then((res) => {
        this.setState({ updateSpinner: false });
        // this.props.ViewProfile({ reverseStatus: true });
        // Success("Saved");
      })
      .catch(({ response }) => {
        this.setState({ updateSpinner: false });
        if (response) {
          if (response.status == 401) {
            Error("Something wrong, Retry again!");
          } else if (response.status == 510) {
            Error("Email does not exit");
          } else if (response.status == 502) {
            Error(response.status + " Bad Gateway");
          } else if (response.status == 500) {
            Error("Internal Server Error");
          } else if (response.status == 409) {
            Error("Already exist");
          } else if (response.status == 400) {
            Error("Bad request");
          }
        }
      });
  }

  // submitStatus = (e) => {
  //   this.setState({ updateSpinner: true });
  //   axios
  //     .post(ImportedURL.API.updateProfileAppStatus, { applinkstatus: e })
  //     .then((res) => {
  //       this.props.ViewProfile({ reverseStatus: true });
  //       this.setState({ updateSpinner: false });
  //       Success("Saved");
  //     })
  //     .catch(({ response }) => {
  //       this.setState({ updateSpinner: false });
  //       if (response) {
  //         if (response.status == 401) {
  //           Error("Something wrong, Retry again!");
  //         } else if (response.status == 510) {
  //           Error("Email does not exit");
  //         } else if (response.status == 502) {
  //           Error(response.status + " Bad Gateway");
  //         } else if (response.status == 500) {
  //           Error("Internal Server Error");
  //         } else if (response.status == 409) {
  //           Error("Already exist");
  //         } else if (response.status == 400) {
  //           Error("Bad request");
  //         }
  //       }
  //     });
  // };

  removeImages = (e) => {
    var formData = new FormData();
    formData.append("image", "");

    Swal.fire({
      title: "Do you want to remove your profile picture?",
      // showCancelButton: true,
      showDenyButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
      // imageUrl: "../../assets/images/delete.png",
      customClass: {
        container: "delete_swal_image",
        popup: "swal_pop",
        title: "swal_title",
        image: "swal_image",
        actions: "swal_action",
        confirmButton: "swal_confirm",
        cancelButton: "swal_close",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ profileimg: "" });
        this.props.HandleInputChange("image", "");
        axios
          .post(ImportedURL.API.updateProfilePicture, formData)
          .then((res) => {
            this.setState({ updateSpinner: false });
            Success("Data saved");
          })
          .catch(({ response }) => {
            this.setState({ updateSpinner: false });
            if (response) {
              if (response.status == 401) {
                Error("Something wrong, Retry again!");
              } else if (response.status == 510) {
                Error("Email does not exit");
              } else if (response.status == 502) {
                Error(response.status + " Bad Gateway");
              } else if (response.status == 500) {
                Error("Internal Server Error");
              } else if (response.status == 409) {
                Error("Already exist");
              } else if (response.status == 400) {
                Error("Bad request");
              }
            }
          });
      }
    })
  };
  removeBanner = (e) => {
    this.setState({ updateSpinner: false });
    var formData = new FormData();
    formData.append("banner", "");
    Swal.fire({
      title: "Do you want to remove your cover picture?",
      // showCancelButton: true,
      showDenyButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
      // imageUrl: "../../assets/images/delete.png",
      customClass: {
        container: "delete_swal_image",
        popup: "swal_pop",
        title: "swal_title",
        image: "swal_image",
        actions: "swal_action",
        confirmButton: "swal_confirm",
        cancelButton: "swal_close",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ bgimage: "" });
        this.props.HandleInputChange("banner", "");
        axios
          .post(ImportedURL.API.updateProfilePicture, formData)
          .then((res) => {
            this.setState({ updateSpinner: false });
            this.setState({ isBCamShow: false })
            Success("Data saved");
          })
          .catch(({ response }) => {
            if (response) {
              if (response.status == 401) {
                Error("Something wrong, Retry again!");
              } else if (response.status == 510) {
                Error("Email does not exit");
              } else if (response.status == 502) {
                Error(response.status + " Bad Gateway");
              } else if (response.status == 500) {
                Error("Internal Server Error");
              } else if (response.status == 409) {
                Error("Already exist");
              } else if (response.status == 400) {
                Error("Bad request");
              }
            }
          });
      }
    })
  };
  onChangeImage = (e) => {
    this.setState({ updateSpinner: false });
    const { name, value } = e.target;
    if (name == "image") {
      if (e.target.files[0] != undefined) {
        const imgvalidate = Imagevalidation(e.target.files[0]);
        if (imgvalidate) {
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            this.setState((prevState) => ({
              ...prevState,
              src: reader.result,
            }));
          });
          reader.readAsDataURL(e.target.files[0]);
          this.setState({ showModal: true, typeImage: "image" });
          // this.props.HandleInputChange(name, e.target.files[0]);
        } else {
          Error("Invalid file extension");
        }
      }
    } else if (name == "banner") {
      if (e.target.files[0] != undefined) {
        const imgvalidate = Imagevalidation(e.target.files[0]);
        if (imgvalidate) {
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            this.setState((prevState) => ({
              ...prevState,
              src: reader.result,
            }));
          });
          reader.readAsDataURL(e.target.files[0]);
          this.setState({ showModal: true, typeImage: "banner" });
          // this.setState({ bgimage: e.target.files[0] });
          // this.props.HandleInputChange(name, e.target.files[0]);
        } else {
          Error("Invalid file extension");
        }
      }
    }
  };

  Logout = (e) => {
    Swal.fire({
      title: "Are you sure want to logout ?",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
      imageUrl: "../../assets/images/signout.png",
      customClass: {
        popup: "swal_pop",
        title: "swal_title",
        image: "swal_image",
        actions: "swal_action",
        confirmButton: "swal_confirm",
        cancelButton: "swal_close",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        localStorage.removeItem("acwtoken");
        window.location.href = "/";
      }
    });
  };

  bloodgroupChange = e => {
    const { name, value } = e;
    const Error = name + "Error";
    this.setState({ [Error]: false });
    this.props.HandleInputChange('bloodgroup', value);
  }

  onChange = (e) => {
    const { name, value } = e.target;
    const Error1 = name + "Error";
    this.setState({ [Error1]: false });
    let formData = {};
    if (name === "phonenumber") {
      let val = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      if (val) {
        if (Phonenumber(val)) {
          this.setState({ phoneNoPatternError: false });
        } else {
          this.setState({ phoneNoPatternError: true });
        }
      } else {
        this.setState({ phoneNoPatternError: false });
      }
      this.props.HandleInputChange(name, val);
    } else if (name == "displayname") {
      if (value) {
        if (OnlyAplhabetsSomeCharactor(value)) {
          if (value.length < 100) {
            this.setState({ [Error]: false, isUserdDisplayNameSaveShow: true });
            this.props.HandleInputChange("displayname", value);
            formData["displayname"] = value;
            this.headlineDisplayFun(formData);
          } else {
            Error('Maximum limit reached')
          }
        } else {
          Error('Content can only include letters, commas, hyphen and spaces')
        }
      } else {
        this.props.HandleInputChange("displayname", value);
        this.setState({ [Error]: true });
        formData["displayname"] = value;
        this.headlineDisplayFun(formData);
      }
    } else if (name == "headline") {
      if (value) {
        this.setState({ [Error]: false, isUserdHeadlineSaveShow: true });
        this.props.HandleInputChange("headline", value);
        formData["headline"] = value;
        this.headlineDisplayFun(formData);
      } else {
        this.props.HandleInputChange("headline", value);
        this.setState({ [Error]: true });
        formData["headline"] = value;
        this.headlineDisplayFun(formData);
      }
    } else if (name == 'education') {
      if (value) {
        this.props.HandleInputChange('education', value);
        this.setState({ isEducation: true })
      } else {
        this.props.HandleInputChange('education', value);
        this.setState({ isEducation: false })
      }
    } else {
      this.props.HandleInputChange(name, value);
    }
  };

  skillChange = (val) => {
    if (val) {
      this.setState({ skill: val, isSkillColor: true })
    } else {
      this.setState({ skill: val, isSkillColor: false })
    }
  }

  onClickSkill = (e) => {
    const data = this.props.ProfileState.profile;
    var skillTrim = this.state.skill ? this.state.skill?.trim() : ''
    if (skillTrim) {
      let skill = [...data.skill];
      if (skill && skill.length > 0) {
        if (skill.includes(this.state.skill)) {
          Error('Your skill is already exists')
        } else {
          this.props.HandleInputChange("skill", [...skill, this.state.skill]);
          this.setState({ skill: "", isSkillColor: false });
        }
      } else {
        this.props.HandleInputChange("skill", [...skill, this.state.skill]);
        this.setState({ skill: "", isSkillColor: false });
      }
    } else {
      this.setState({ skill: '' })
    }
  };
  removeSkill = (id) => {
    const data = this.props.ProfileState.profile;
    let skill = [...data.skill];
    this.props.HandleInputChange("skill", [
      ...skill.filter((e, i) => i !== id),
    ]);
  };
  heightPerpose = () => {
    this.setState({ onChangeHeight: false });
  };
  socialmediaSelect = (e, parentId) => {
    this.setState({ modalType: '', zipBord: false, othersError: false, countryBord: false, isWwwShow: false, cityBord: false, streetBord: false, isClear: false, isClearUrl: false, isClearNumber: false, isClearEmail: false })
    this.setState({ othersError: false, urlError: false, phoneRequired: false, phoneNoPatternArrayError: false, emailError: false })
    const { socialmediaValue, onChangeHeight } = this.state;
    const { value, logo, id, inputtype } = e;
    if (value) {
      let index = socialmediaValue.findIndex((e) => e.Uid == parentId);
      if (index != -1) {
        let obj = {
          logo: logo,
          name: value,
          Uid: parentId,
          parentId: parentId,
          id: id,
          inputtype: inputtype,
          value: '',
          link: ''
        };
        if (inputtype == "number") {
          obj["cc"] = { name: "US", value: "+1" };
        }
        if (inputtype == "url") {
          obj["link"] = '';
        }
        socialmediaValue[index] = obj;
        this.setState({ socialmediaValue: socialmediaValue });
      } else {
        let obj = {
          logo: logo,
          name: value,
          Uid: parentId,
          parentId: parentId,
          id: id,
          inputtype: inputtype,
          value: '',
          link: ''
        };
        if (inputtype == "number") {
          obj["cc"] = { name: "US", value: "+1" };
        }
        if (inputtype == "url") {
          obj["link"] = '';
        }
        this.setState({ socialmediaValue: [...socialmediaValue, obj] });
      }
    }
    if (value === "") {
      let social = this.state.socialmediaValue.filter(
        (e) => e.parentId != parentId
      );
      this.setState({ socialmediaValue: social });
    }
  };
  onClickSocialMedia = (parentId, type) => {
    const { urlError, socialmediaValue, emailError, phoneNoPatternArrayError } = this.state;
    const data = this.props.BusinessState.bussiness;
    let status = socialmediaValue.find((e) => e.Uid == parentId);

    //App Link status
    let applinkstatus = [...data.applinkstatus];
    let linkStatus = applinkstatus.findIndex((r) => r.appid == parentId);

    if (type == "url") {
      if (status != undefined && status && !urlError) {
        if (status.name != undefined && status.link != undefined && (status.link && status.link.trim())) {
          let applink = [...data.applink];

          var form = status;
          form.index = Math.floor((Math.random() * 10000000000000) + 1);
          form.appStatus = true;
          // let urlval = form.link.includes('https://') ? form.link.slice(8) : form.link;
          // form.link = urlval.includes('www.') ? form.link : 'www.' + urlval
          var temp = status.link ? (status.link).trim() : ''
          if ((temp.includes('https://') && temp.includes('www.')) || (temp.includes('http://') && temp.includes('www.'))) {
            form.link = temp.replace('https://', '').replace('http://', '')
          } else {
            if (temp.includes('http://m.')) {
              form.link = temp.replace('http://m.', 'www.')
            } else if (temp.includes('https://m.')) {
              form.link = temp.replace('https://m.', 'www.')
            } else if (temp.includes('https://')) {
              form.link = temp.replace('https://', 'www.')
            } else if (temp.includes('http://')) {
              form.link = temp.replace('http://', 'www.')
            } else if (temp.includes('www.')) {
              form.link = temp
            } else {
              form.link = 'www.' + temp
            }
          }
          if ((applink && applink.length > 0) && (socialmediaValue && socialmediaValue.length > 0)) {
            const isAlreadyExists = socialmediaValue.some(item1 => applink.some(item2 => (item1.link === item2.link)))
            if (isAlreadyExists) {
              Error("This data is already exists")
            } else {
              this.props.HandleInputBusinessChange("applink", [...applink, form]);
              this.setState({
                socialmediaValue: [
                  ...socialmediaValue.filter((e) => e.Uid != parentId),
                ],
              });
              // App Link Status update
              if (linkStatus !== -1) {
                applinkstatus[linkStatus] = { appid: parentId, status: true };
                this.props.HandleInputBusinessChange("applinkstatus", applinkstatus);
                // this.submitStatus(applinkstatus)
              }
            }
          } else {
            this.props.HandleInputBusinessChange("applink", [...applink, form]);
            this.setState({
              socialmediaValue: [
                ...socialmediaValue.filter((e) => e.Uid != parentId),
              ],
              modalType: ''
            });
            // App Link Status update
            if (linkStatus !== -1) {
              applinkstatus[linkStatus] = { appid: parentId, status: true };
              this.props.HandleInputBusinessChange("applinkstatus", applinkstatus);
              // this.submitStatus(applinkstatus)
            }
          }
        }
      }
      if (status.link == undefined || status.link == '') {
        this.setState({ urlError: true, isClearUrl: false });
      }
    } else if (type == "email") {
      if (status != undefined && status && !emailError) {
        if (status.name != undefined && status.value != undefined && status.value) {
          let applink = [...data.applink];
          var form = status;
          form.appStatus = true;
          form.index = Math.floor((Math.random() * 10000000000000) + 1);
          if ((applink && applink.length > 0) && (socialmediaValue && socialmediaValue.length > 0)) {
            const isAlreadyExists = socialmediaValue.some(item1 => applink.some(item2 => (item1.value === item2.value && item1.id === item2.id)))
            if (isAlreadyExists) {
              Error("This data is already exists")
            } else {
              this.props.HandleInputBusinessChange("applink", [...applink, form]);
              this.setState({
                socialmediaValue: [
                  ...socialmediaValue.filter((e) => e.Uid != parentId),
                ],
                modalType: ''
              });
              // App Link Status update
              if (linkStatus !== -1) {
                applinkstatus[linkStatus] = { appid: parentId, status: true };
                this.props.HandleInputBusinessChange("applinkstatus", applinkstatus);
                // this.submitStatus(applinkstatus)
              }
            }
          } else {
            this.props.HandleInputBusinessChange("applink", [...applink, form]);
            this.setState({
              socialmediaValue: [
                ...socialmediaValue.filter((e) => e.Uid != parentId),
              ],
              modalType: ''
            });
            // App Link Status update
            if (linkStatus !== -1) {
              applinkstatus[linkStatus] = { appid: parentId, status: true };
              this.props.HandleInputBusinessChange("applinkstatus", applinkstatus);
              // this.submitStatus(applinkstatus)
            }
          }
        }
      }
      if (status.value == undefined || status.value == '') {
        this.setState({ emailError: true, isClearEmail: false });
      }
    } else if (type == "number") {
      if (status != undefined && status && !phoneNoPatternArrayError) {
        if (status.name != undefined && status.value != undefined && status.value) {
          let applink = [...data.applink];
          var form = status;
          form.appStatus = true;
          form.index = Math.floor(Math.random() * 10000000000000 + 1);
          if ((applink && applink.length > 0) && (socialmediaValue && socialmediaValue.length > 0)) {
            const isAlreadyExists = socialmediaValue.some(item1 => applink.some(item2 => ((item1.value == item2.value) && (item1.cc?.value == item2.cc?.value) && (item1.name == item2.name))))
            if (isAlreadyExists) {
              Error("This data is already exists")
            } else {
              this.props.HandleInputBusinessChange("applink", [...applink, form]);
              this.setState({
                socialmediaValue: [
                  ...socialmediaValue.filter((e) => e.Uid != parentId),
                ],
                modalType: ''
              });
              // App Link Status update
              if (linkStatus !== -1) {
                applinkstatus[linkStatus] = { appid: parentId, status: true };
                this.props.HandleInputBusinessChange("applinkstatus", applinkstatus);
                // this.submitStatus(applinkstatus)
              }
            }
          } else {
            this.props.HandleInputBusinessChange("applink", [...applink, form]);
            this.setState({
              socialmediaValue: [
                ...socialmediaValue.filter((e) => e.Uid != parentId),
              ],
              modalType: ''
            });
            // App Link Status update
            if (linkStatus !== -1) {
              applinkstatus[linkStatus] = { appid: parentId, status: true };
              this.props.HandleInputBusinessChange("applinkstatus", applinkstatus);
              // this.submitStatus(applinkstatus)
            }
          }
        }
      }
      if (!status.value) {
        this.setState({ phoneRequired: true, isClearNumber: false });
      }
    } else {
      if (status != undefined && status) {
        if (status.name != undefined && status.value != undefined && status.value) {
          let applink = [...data.applink];
          var form = status;
          form.appStatus = true;
          form.index = Math.floor((Math.random() * 10000000000000) + 1);
          this.props.HandleInputBusinessChange("applink", [...applink, form]);
          this.setState({
            socialmediaValue: [
              ...socialmediaValue.filter((e) => e.Uid != parentId),
            ],
            modalType: ''
          });
          let sendData = [...applink, form];
          // this.submitApp(sendData)

          // App Link Status update
          if (linkStatus !== -1) {
            applinkstatus[linkStatus] = { appid: parentId, status: true };
            this.props.HandleInputBusinessChange("applinkstatus", applinkstatus);
            // this.submitStatus(applinkstatus)
          }
        }
      }
    }
  };
  documentStatus = (e, item, id, type) => {
    const { socialmediaValue } = this.state;
    const { value, name, checked } = e.target;

    const data = this.props.BusinessState.bussiness;
    let applink = [...data.applink];

    let index = applink.findIndex((e) => e.Uid == item.Uid);
    if (index != -1) {
      applink[index] = {
        ...applink[index],
        ["documentstatus"]: checked,
      };
      this.props.HandleInputBusinessChange("applink", applink);
      // this.submitApp(applink)
    }
  };
  onChangeImageArray = (e, parentId, type) => {

    const { socialmediaValue } = this.state;
    const { name, value } = e.target;
    // if (name == "others") {
    //   var { value } = e.target;
    // }
    var valx = '';
    if (name == 'others') {
      this.setState({ others: value })
      this.setState({ othersError: false })

      // if (value) {
      //   valx = value
      //   this.setState({ others: value })
      // } else {
      //   valx = value
      //   this.setState({ others: value })
      // }
    }
    let files = e.target.files ? e.target.files[0] : undefined;
    let previewType = "doc";
    if (files != undefined) {
      let data = FileAndImagevalidation(files);
      if (data.allow != undefined && data.allow) {
        if (data.type == "jpg" || data.type == "jpeg" || data.type == "png") {
          previewType = "image";
        }
        if (data.type == "pdf") {
          previewType = "pdf";
        }
      } else {
        Error("Invalid file extension");
      }
    }
    let index = socialmediaValue.findIndex((e) => e.Uid == parentId);
    if (index != -1) {
      socialmediaValue[index] = {
        ...socialmediaValue[index],
        ["address"]: "",
        ["link"]: "",
        // ["value"]: files,
        // ["othersdoc"]: value,
        ["documentstatus"]: true,
      };
      if (name == "others") {
        socialmediaValue[index]["othersdoc"] = value;
      }
      if (name == "image") {
        socialmediaValue[index]["value"] = files;
        socialmediaValue[index]["previewtype"] = previewType;
      }
      this.setState({ socialmediaValue: socialmediaValue });
    }
  };
  onClickSocialMediaAddress = (parentId) => {
    const { socialmediaValue } = this.state;
    const data = this.props.BusinessState.bussiness;
    //App Link status
    let applinkstatus = [...data.applinkstatus];
    let linkStatus = applinkstatus.findIndex((r) => r.appid == parentId);
    let status = socialmediaValue.find((e) => e.Uid == parentId);

    if (status != undefined && status) {
      if ((status.name != undefined) && (status.street != undefined && status.street) && (status.city != undefined && status.city) && (status.state != undefined && status.state) && (status.zip != undefined && status.zip) && (status.country != undefined && status.country)) {
        let applink = [...data.applink];
        var form = status;
        form.address = {
          street: status.street ? status.street.trim() : '',
          city: status.city ? status.city.trim() : '',
          state: status.state ? status.state.trim() : '',
          zip: status.zip ? status.zip.trim() : '',
          country: status.country ? status.country.trim() : '',
        };
        form.appStatus = true;
        form.index = Math.floor((Math.random() * 10000000000000) + 1);
        this.props.HandleInputBusinessChange("applink", [...applink, form]);
        this.setState({ socialmediaValue: [...socialmediaValue.filter((e) => e.Uid != parentId),], });

        // App Link Status update
        if (linkStatus !== -1) {
          applinkstatus[linkStatus] = { appid: parentId, status: true };
          this.props.HandleInputBusinessChange("applinkstatus", applinkstatus);
          // this.submitStatus(applinkstatus)
        }

        let sendData = [...applink, form];
        // this.submitApp(sendData)
      }

      if (status.street == undefined || status.street == '') {
        this.setState({ streetBord: true })
      }
      if (status.city == undefined || status.city == '') {
        this.setState({ cityBord: true })
      }
      if (status.state == undefined || status.state == '') {
        this.setState({ stateBord: true })
      }
      if (status.zip == undefined || status.zip == '') {
        this.setState({ zipBord: true })
      }
      if (status.country == undefined || status.country == '') {
        this.setState({ countryBord: true })
      }

    }
  };

  onClickIAddImageArray = (parentId) => {
    const { urlError, socialmediaValue } = this.state;
    const data = this.props.BusinessState.bussiness;
    let status = socialmediaValue.find((e) => e.Uid == parentId);

    //App Link status
    let applinkstatus = [...data.applinkstatus];
    let linkStatus = applinkstatus.findIndex((r) => r.appid == parentId);

    if (status != undefined && status) {
      let valid = 1
      status.othersdoc?.trim()
      if (!status.othersdoc && status.name == "Others") {
        this.setState({ othersError: true })
        valid = 0
      }

      if (status.value != undefined && status.value) {
        // App Link Status update
        if (valid) {
          let applink = [...data.applink];
          var form = status;
          form.appStatus = true;
          form.index = Math.floor((Math.random() * 10000000000000) + 1);
          this.props.HandleInputBusinessChange("applink", [...applink, form]);

          let sendData = [...applink, form];
          this.setState({
            socialmediaValue: [
              ...socialmediaValue.filter((e) => e.Uid != parentId),
            ],
          });
          if (linkStatus !== -1) {
            applinkstatus[linkStatus] = { appid: parentId, status: true };
            this.props.HandleInputBusinessChange("applinkstatus", applinkstatus);
            // this.submitStatus(applinkstatus)
          }
        }
      } else {
        Error("Choose file")
      }
    }
  };

  onChangeAddress = (e, parentId, type) => {
    const { socialmediaValue, no, street, city, state, country, zip } = this.state;
    const { value, name } = e.target;
    // let streetVal;
    // let cityVal;
    // let stateVal;
    // let countryVal;
    // let zipVal;
    // if (name == "street") {
    //   streetVal = value
    // }
    // else if (name == "city") {
    //   cityVal = value
    // }
    // else if (name == "state") {
    //   stateVal = value
    // }
    // else if (name == "country") {
    //   countryVal = value
    // }
    // else if (name == "zip") {
    //   zipVal = value
    // }

    let index = socialmediaValue.findIndex((e) => e.Uid == parentId);
    if (index != -1) {
      socialmediaValue[index] = {
        ...socialmediaValue[index],
        [name]: value,
        // ["street"]: streetVal,
        // ["city"]: cityVal,
        // ["state"]: stateVal,
        // ["country"]: countryVal,
        // ["zip"]: zipVal,
        ["value"]: "",
        ["link"]: "",
      };
      if (socialmediaValue && socialmediaValue.length > 0) {
        socialmediaValue.map(data => {
          if (data.street) {
            this.setState({ streetBord: false, })
          }
          if (data.city) {
            this.setState({ cityBord: false })
          }
          if (data.state) {
            this.setState({ stateBord: false })
          }
          if (data.zip) {
            this.setState({ zipBord: false })
          } if (data.country) {
            this.setState({ countryBord: false })
          }
          if (!data.street && !data.city && !data.state && !data.zip) {
            this.setState({ isClear: false })
          } else {
            this.setState({ isClear: true })
          }
        })
      }
      this.setState({ socialmediaValue: socialmediaValue });
    }
  };


  removeSocailMedia = (id, parentId, length) => {
    Swal.fire({
      title: "Are you sure you want to delete the data?",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
      // imageUrl: "../../assets/images/delete.png",
      customClass: {
        container: "delete_swal_image",
        popup: "swal_pop",
        title: "swal_title",
        image: "swal_image",
        actions: "swal_action",
        confirmButton: "swal_confirm",
        cancelButton: "swal_close",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const data = this.props.BusinessState.bussiness;
        let applink = [...data.applink];
        let app = [...applink.filter((e, i) => e.index !== id)];
        this.props.HandleInputBusinessChange("applink", app);
        // this.submitApp(app)
        this.setState({ collapsed: true })
        // let appstatus = data.applink.filter((e, i) => e.index == id);

        this.appStatusChangeRemove(parentId)
        if (this.carouselRef.current) {
          this.carouselRef.current.previous(); // Trigger the "previous" method
        }
      } else {
        this.setState({ collapsed: true })
      }
    });
  };
  // AppStatusChangeRemove Function
  appStatusChangeRemove = (parentId) => {
    const { socialmediaValue } = this.state;
    const data = this.props.BusinessState.bussiness;
    let applink = [...data.applink];
    const findInd = (applink && applink.length > 0) ? applink.findIndex(e => e.parentId === parentId) : -1

    //App Link status
    let applinkstatus = [...data.applinkstatus];
    let linkStatus = applinkstatus.findIndex((r) => r.appid == parentId);

    let checkDeleteArray = socialmediaValue.filter((e) => e.parentId == parentId)

    if (findInd == -1 && checkDeleteArray.length == 0) {
      applinkstatus[linkStatus] = { appid: parentId, status: false };
      this.props.HandleInputBusinessChange("applinkstatus", applinkstatus);
    }
  }



  editSocailMedia = (e, id, type) => {
    this.setState({ modalType: 'edit' })
    const { socialmediaValue } = this.state;
    this.setState({ isWwwShow: true })
    const data = this.props.BusinessState.bussiness;
    let applink = [...data.applink];
    if (type == "address") {
      const form = {
        id: e.id,
        logo: e.logo,
        name: e.name,
        parentId: e.parentId,
        address: e.address,
        street: e.address.street,
        city: e.address.city,
        state: e.address.state,
        zip: e.address.zip,
        country: e.address.country,
        Uid: e.parentId,
        inputtype: e.inputtype,
      };
      this.setState({ socialmediaValue: [...socialmediaValue, form] });
      let app = [...applink.filter((a, i) => a.index !== e.index)];
      this.props.HandleInputBusinessChange("applink", app);
    } else if (type == "document") {

      let previewType = "doc";
      if (typeof e.value == "string") {
        previewType = "alldata";
      } else {
        let data = FileAndImagevalidation(e.value);
        if (data.allow != undefined && data.allow) {
          if (data.type == "jpg" || data.type == "jpeg" || data.type == "png") {
            previewType = "image";
          }
          if (data.type == "pdf") {
            previewType = "pdf";
          }
        }
      }
      const form = {
        id: e.id,
        logo: e.logo,
        othersdoc: e.othersdoc,
        name: e.name,
        parentId: e.parentId,
        Uid: e.parentId,
        link: e.link,
        value: e.value,
        othersdoc: e.othersdoc,
        inputtype: e.inputtype,
        previewtype: previewType,
      };
      this.setState({ socialmediaValue: [...socialmediaValue, form] });
      let app = [...applink.filter((a, i) => a.index !== e.index)];
      this.props.HandleInputBusinessChange("applink", app);
    } else {
      this.setState({
        socialmediaValue: [
          ...socialmediaValue,
          {
            id: e.id,
            logo: e.logo,
            name: e.name,
            parentId: e.parentId,
            link: e.link,
            Uid: e.parentId,
            value: e.value,
            inputtype: e.inputtype,
            cc: e.cc,
          },
        ],
      });
      let app = [...applink.filter((a, i) => a.index !== e.index)];
      this.props.HandleInputBusinessChange("applink", app);
    }
  };
  onChangeLinkCC = (e, parentId, type) => {
    const { socialmediaValue } = this.state;
    const { value, name, label } = e;
    let data = {
      name: value,
      value: label,
    };
    let index = socialmediaValue.findIndex((e) => e.Uid == parentId);
    if (index != -1) {
      socialmediaValue[index] = {
        ...socialmediaValue[index],
        ["address"]: "",
        ["link"]: "",
        ["cc"]: data,
      };
      this.setState({ socialmediaValue: socialmediaValue });
    }
  };

  onChangeLink = (e, parentId, type) => {
    const { socialmediaValue } = this.state;
    const { value, name } = e.target;
    if (type == "url") {
      if (value) {
        this.setState({ urlError: false, isClearUrl: true });
      } else {
        this.setState({ isClearUrl: false });
      }

      let index = socialmediaValue.findIndex((e) => e.Uid == parentId);
      if (index != -1) {
        socialmediaValue[index] = {
          ...socialmediaValue[index],
          ["address"]: "",
          ["value"]: "",
          ["link"]: value,
        };
        this.setState({ socialmediaValue: socialmediaValue, isClear: true });
      }
    } else if (type == "number") {
      let val = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      if (val) {
        this.setState({ phoneRequired: false })
        if (Phonenumber(val)) {
          this.setState({ phoneNoPatternArrayError: false });
        } else {
          this.setState({ phoneNoPatternArrayError: true });
        }
        this.setState({ isClearNumber: true });
      } else {
        this.setState({ phoneNoPatternArrayError: false, isClearNumber: false });
      }
      let index = socialmediaValue.findIndex((e) => e.Uid == parentId);
      if (index != -1) {
        socialmediaValue[index] = {
          ...socialmediaValue[index],
          ["address"]: "",
          ["value"]: val,
          ["link"]: "",
        };
        this.setState({ socialmediaValue: socialmediaValue });
      }
    } else if (type == "email") {
      let val = value.toLowerCase()
      if (val) {
        if (Emailvalidate(val)) {
          this.setState({ emailError: false });
        } else {
          this.setState({ emailError: true });
        }
        this.setState({ isClearEmail: true });
      } else {
        this.setState({ emailError: false, isClearEmail: false });
      }
      let index = socialmediaValue.findIndex((e) => e.Uid == parentId);
      if (index != -1) {
        socialmediaValue[index] = {
          ...socialmediaValue[index],
          ["address"]: "",
          ["link"]: "",
          ["value"]: val,
        };
        this.setState({ socialmediaValue: socialmediaValue });
      }
    } else if (type == "none") {
      let index = socialmediaValue.findIndex((e) => e.Uid == parentId);
      if (index != -1) {
        socialmediaValue[index] = {
          ...socialmediaValue[index],
          ["address"]: "",
          ["link"]: "",
          ["value"]: value,
        };
        this.setState({ socialmediaValue: socialmediaValue });
      }
    }
  };
  appLinkStatus = (e, app, index) => {
    const data = this.props.BusinessState.bussiness;
    let applinkstatus = [...data.applinkstatus];
    let status = applinkstatus.findIndex((r) => r.appid == app._id);
    if (status !== -1) {
      applinkstatus[status] = { appid: app._id, status: e.target.checked };
      this.props.HandleInputBusinessChange("applinkstatus", applinkstatus);
      // this.submitStatus(applinkstatus)
    } else {
      let value = [...applinkstatus, { appid: app._id, status: e.target.checked },];
      this.props.HandleInputBusinessChange("applinkstatus", value);
      // this.submitStatus(value)
    }
  };
  onSelectFlag = (e) => {
    const { name, value, label } = e;
    let data = {
      name: value,
      value: label,
    };
    this.props.HandleInputChange("cc", data);
  };
  onSelectFlagBusiness = (e) => {
    const { name, value, label } = e;
    let data = {
      name: value,
      value: label,
    };
    this.props.HandleInputBusinessChange("businesscc", data);
  };
  onChangeDate = (e) => {
    this.props.HandleInputChange("dob", e);
    // let age = GetAge(date);
    // if (age >= 15) {
    //   this.setState({ ageError: false });
    // } else {
    //   this.setState({ ageError: true });
    // }
  };

  taggleStatusPersonal = (e, id, type) => {
    const data = this.props.ProfileState.profile;
    const { value, name, checked } = e.target;
    const formData = {};
    if (name == "personalstatus") {
      this.props.HandleInputBusinessChange("status", checked);
      formData["status"] = checked;
    } else {
      formData["status"] = checked;
    }
    this.setState({ updateSpinner: true });
    if (type != undefined) {
      axios
        .post(ImportedURL.API.updatePersonalStatus + '/' + id, formData)
        .then((res) => {
          this.props.ViewProfile({ reverseStatus: true });
          this.setState({ updateSpinner: false });
          Success("Data saved");
          this.props.ListPersonal();
        })
        .catch(({ response }) => {
          this.setState({ updateSpinner: false });
          if (response) {
            if (response.status == 401) {
              Error("Something wrong, Retry again!");
            } else if (response.status == 510) {
              Error("Email does not exit");
            } else if (response.status == 502) {
              Error(response.status + " Bad Gateway");
            } else if (response.status == 500) {
              Error("Internal Server Error");
            } else if (response.status == 409) {
              Error("Already exist");
            } else if (response.status == 400) {
              Error("Bad request");
            }
          }
        });
    } else {
      axios
        .post(ImportedURL.API.updateBusinessStatus + '/' + id, formData)
        .then((res) => {
          this.props.ViewProfile({ reverseStatus: true });
          this.setState({ updateSpinner: false });
          Success("Data saved");
          this.props.ListBusiness();
        })
        .catch(({ response }) => {
          this.setState({ updateSpinner: false });
          if (response) {
            if (response.status == 401) {
              Error("Something wrong, Retry again!");
            } else if (response.status == 510) {
              Error("Email does not exit");
            } else if (response.status == 502) {
              Error(response.status + " Bad Gateway");
            } else if (response.status == 500) {
              Error("Internal Server Error");
            } else if (response.status == 409) {
              Error("Already exist");
            } else if (response.status == 400) {
              Error("Bad request");
            }
          }
        });
    }

  }
  taggleStatus = (e) => {
    const data = this.props.ProfileState.profile;
    const { value, name, checked } = e.target;
    const formData = {};
    if (data.headline && data.displayname) {
      if (name == "ispublicprofile") {
        this.props.HandleInputChange("ispublicprofile", checked);
        formData["ispublicprofile"] = checked;
        formData["ispersonaldetails"] = data.ispersonaldetails ? data.ispersonaldetails : false;
        formData["isbusinessdetails"] = data.isbusinessdetails ? data.isbusinessdetails : false;
      }
    }
    if (name == "ispersonaldetails") {
      this.props.HandleInputChange("ispersonaldetails", e.target.checked);
      formData["ispublicprofile"] = data.ispublicprofile ? data.ispublicprofile : false;
      formData["ispersonaldetails"] = checked;
      formData["isbusinessdetails"] = data.isbusinessdetails ? data.isbusinessdetails : false;
    }
    if (name == "isbusinessdetails") {
      this.props.HandleInputChange("isbusinessdetails", e.target.checked);
      formData["ispublicprofile"] = data.ispublicprofile ? data.ispublicprofile : false;
      formData["ispersonaldetails"] = data.ispersonaldetails ? data.ispersonaldetails : false;
      formData["isbusinessdetails"] = checked;
    }
    if (data.headline && data.displayname) {
      this.setState({ updateSpinner: true });
      axios
        .post(ImportedURL.API.updateProfileOtherStatus, formData)
        .then((res) => {
          this.props.ViewProfile({ reverseStatus: true });
          this.setState({ updateSpinner: false });
          Success("Data saved");
        })
        .catch(({ response }) => {
          this.setState({ updateSpinner: false });
          if (response) {
            if (response.status == 401) {
              Error("Something wrong, Retry again!");
            } else if (response.status == 510) {
              Error("Email does not exit");
            } else if (response.status == 502) {
              Error(response.status + " Bad Gateway");
            } else if (response.status == 500) {
              Error("Internal Server Error");
            } else if (response.status == 409) {
              Error("Already exist");
            } else if (response.status == 400) {
              Error("Bad request");
            }
          }
        });
    } else {
      Error('Could you please add your "Full name" and "headline"?')
    }
  };
  ModalImages = (e) => {
    this.setState({ showModal: false, collapsed: true });
    if (this.state.typeImage == "image") {
      this.fileInputRef.current.value = "";
    } else if (this.state.typeImage == "banner") {
      this.fileInputBanner.current.value = "";
    } else {
      this.fileInputBusinesssLogo.current.value = "";
    }
  };
  CropImages = (e) => {
    this.setState({ showModal1: true, collapsed: true, isOpen: false, isOpenTwo: false });

    const data = this.props.ProfileState.profile;
    let value = this.state.croppedImageUrl;
    this.setState({
      croppedImageUrl: "",
      showModal: false,
      src: null,
      crop: {
        unit: "px",
        x: 130,
        y: 50,
        width: 200,
        height: 200,
      },
    });
    var formData = new FormData();
    if (this.state.typeImage == "image") {
      this.setState({ profileimg: URL.createObjectURL(value) });
      this.props.HandleInputChange("image", value);
      formData.append("image", value);
      this.fileInputRef.current.value = "";
    } else if (this.state.typeImage == "banner") {
      this.setState({ bgimage: value });
      formData.append("banner", value);
      this.props.HandleInputChange("banner", value);
      this.fileInputBanner.current.value = "";
    } else {
      this.props.HandleInputBusinessChange("image", value);
      this.fileInputBusinesssLogo.current.value = "";
    }

    if (this.state.typeImage == "image" || this.state.typeImage == "banner") {
      axios
        .post(ImportedURL.API.updateProfilePicture, formData)
        .then((res) => {
          this.setState({ updateSpinner: false });
          this.props.ViewProfile({ reverseStatus: true });
          Success("Data saved");
          let btn = document.getElementById("personalDetailsModal");
          btn.click();
        })
        .catch(({ response }) => {
          this.setState({ updateSpinner: false });
          if (response) {
            if (response.status == 401) {
              Error("Something wrong, Retry again!");
            } else if (response.status == 510) {
              Error("Email does not exit");
            } else if (response.status == 502) {
              Error(response.status + " Bad Gateway");
            } else if (response.status == 500) {
              Error("Internal Server Error");
            } else if (response.status == 409) {
              Error("Already exist");
            } else if (response.status == 400) {
              Error("Bad request");
            }
          }
        });
    }

  };
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    const base64Image = canvas.toDataURL("image/png");
    if (base64Image) {
      const fileType = base64Image.split(";")[0].split(":")[1];
      const buffer = Buffer.from(
        base64Image.replace(/^data:image\/\w+;base64,/, ""),
        "base64"
      );
      const file = new File([buffer], fileName, { type: fileType });
      return file;
    } else {
      return "";
    }
  }

  taggleStatusFiles = (e) => {
    const data = this.props.ProfileState.profile;
    const { value, name, checked } = e.target;
    const formData = {
      isinfostatus: checked,
    };
    this.setState({ updateSpinner: true });
    axios
      .post(ImportedURL.API.updateInfoStatus, formData)
      .then((res) => {
        this.props.ViewProfile({ reverseStatus: true });
        this.setState({ updateSpinner: false });
        Success("Data saved");
      })
      .catch(({ response }) => {
        this.setState({ updateSpinner: false });
        if (response) {
          if (response.status == 401) {
            Error("Something wrong, Retry again!");
          } else if (response.status == 510) {
            Error("Email does not exit");
          } else if (response.status == 502) {
            Error(response.status + " Bad Gateway");
          } else if (response.status == 500) {
            Error("Internal Server Error");
          } else if (response.status == 409) {
            Error("Already exist");
          } else if (response.status == 400) {
            Error("Bad request");
          }
        }
      });
  };
  onChangeBusiness = (e) => {
    const data = this.props.BusinessState.bussiness;

    const { name, value } = e.target;
    const Errors = name + "Error";
    this.setState({ [Errors]: false });

    if (name === "businessphonenumber") {
      let val = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      if (val) {
        if (Phonenumber(val)) {
          this.setState({ busPhoneNoPatternError: false });
          this.props.HandleInputBusinessChange(name, val);
        } else {
          this.setState({ busPhoneNoPatternError: true });
        }
      } else {
        this.setState({ busPhoneNoPatternError: false });
      }
      this.props.HandleInputBusinessChange(name, val);
    } else if (name == 'designation') {
      if (value) {
        this.setState({ [Errors]: false });
        this.props.HandleInputBusinessChange('designation', value.toUpperCase());
      } else {
        this.setState({ [Errors]: true });
        this.props.HandleInputBusinessChange('designation', value.toUpperCase());
      }
    } else if (name == 'work') {
      if (OnlyAplhabetsNumberSomeCharactor(value)) {
        if (value) {
          this.setState({ [Errors]: false });
          this.props.HandleInputBusinessChange('work', value);
        } else {
          this.setState({ [Errors]: true });
          this.props.HandleInputBusinessChange('work', value);
        }
      }
    }
    else {
      this.props.HandleInputBusinessChange(name, value);
    }
  };
  businessLogoChange = (e) => {
    this.setState({ showModal1: false });
    const { name, value } = e.target;
    const Error = name + "Error";
    if (name == "businesslogo") {
      this.setState({ bussinesslogoSrc: "" });
      const imgvalidate = Imagevalidation(e.target.files[0]);
      if (imgvalidate) {
        this.setState({ bussinesslogoError: false, invalidFileError: false });
        this.setState({ categorylogo: e.target.files[0], validImages: false });
        var reader = new FileReader();
        var url = reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = function (e) {
          this.setState({
            bussinesslogoSrc: [reader.result],
          });
          this.setState((prevState) => ({
            ...prevState,
            src: reader.result,
          }));
        }.bind(this);
        // this.props.HandleInputBusinessChange("image", e.target.files[0]);
        this.setState({ showModal: true, typeImage: "businesslogo" });

      } else {
        this.setState({
          invalidFileError: true,
          bussinesslogoError: false,
          validImages: true,
        });
        this.props.HandleInputBusinessChange("image", "");
        // Error('Invalid file extension');
      }
    }
  };
  businessStatusChange = (e) => {
    const { checked } = e.target;
    this.props.HandleInputBusinessChange("status", checked);
  };
  businessSubmit = (e, type) => {
    const { busPhoneNoPatternError, phoneNoPatternError, ageError } = this.state;
    const { BusinessState, ProfileState } = this.props;
    const Pdata = ProfileState.profile;
    const dataBusiness = BusinessState.bussiness;
    const data = { ...dataBusiness };
    for (let key in data) {
      if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
        data[key] = data[key].trim();
      }
    }
    let valid = true;
    // if (!data.image) {
    //   this.setState({ bussinesslogoError: true });
    //   valid = false;
    // }
    if (!data.designation) {
      this.setState({ designationError: true });
      valid = false;
    }
    if (!data.work) {
      this.setState({ workError: true });
      valid = false;
    }
    // if (!data.businessphonenumber) {
    //   this.setState({ businessphonenumberError: true });
    //   valid = false;
    // }

    //changes
    let listAppLink = [...data.applink];
    const formData = new FormData();

    formData.append("userid", Pdata._id);

    for (let key in data) {
      if (key == "applink") continue;
      else if (key == "applinkstatus")
        formData.append(key, JSON.stringify(data[key]));
      else if (key == "businesscc")
        formData.append(key, JSON.stringify(data[key]));
      else formData.append(key, data[key]);
    }

    let othersArr = [];
    let documentArr = [];
    let existImageArr = [];
    let imagesDataArr = [];
    if (listAppLink.length > 0) {
      for (let i = 0; i < listAppLink.length; i++) {
        if (listAppLink[i].inputtype != "document") {
          othersArr.push(listAppLink[i]);
        }
      }
      for (let i = 0; i < listAppLink.length; i++) {
        if (listAppLink[i].inputtype == "document") {
          documentArr.push(listAppLink[i]);
        }
      }
    }
    if (documentArr.length > 0) {
      for (let i = 0; i < documentArr.length; i++) {
        if (typeof documentArr[i].value == "string") {
          existImageArr.push(documentArr[i]);
        } else {
          formData.append("documents", documentArr[i].value);
          imagesDataArr.push(documentArr[i]);
        }
      }
    }
    formData.append("othersArr", JSON.stringify(othersArr));
    formData.append("existImageArr", JSON.stringify(existImageArr));
    formData.append("imagesDataArr", JSON.stringify(imagesDataArr));
    if (type && type != undefined) {
      if (listAppLink && listAppLink.length > 0) {
        this.setState({ personalBtnChange: true })
        this.props.RunSpinnerAdd()
        axios
          .post(ImportedURL.API.addPersonel, formData)
          .then((res) => {
            this.setState({ updateSpinner: false, isShowSwal: false, isCoseModel1: true, personalBtnChange: false, collapsed: false });
            this.props.ViewProfile({ reverseStatus: true });
            Success("Data saved");
            this.props.ListPersonal();
            this.props.EmptyBusiness();
            let btn = document.getElementById("closeModal1");
            btn.click();
          })
          .catch(({ response }) => {
            this.setState({ updateSpinner: false, });
            if (response) {
              if (response.status == 401) {
                Error("Something wrong, Retry again!");
              } else if (response.status == 510) {
                Error("Email does not exit");
              } else if (response.status == 502) {
                Error(response.status + " Bad Gateway");
              } else if (response.status == 500) {
                Error("Internal Server Error");
              } else if (response.status == 409) {
                Error("Already exist");
              } else if (response.status == 400) {
                Error("Bad request");
              }
            }
          });
      } else {
        this.setState({ collapsed: false })
        this.props.ListPersonal();
        this.props.EmptyBusiness();
        let btn = document.getElementById("closePersonal");
        btn.click();
      }
    } else {
      if (valid) {
        this.setState({ businessBtnChange: true })
        this.props.RunSpinnerAdd()
        axios
          .post(ImportedURL.API.addBusiness, formData)
          .then((res) => {
            this.setState({ updateSpinner: false, isShowSwal: false, isCoseModel: true, businessBtnChange: false, collapsed: false });
            this.props.ViewProfile({ reverseStatus: true });
            Success("Data saved");
            this.props.ListBusiness();
            this.props.EmptyBusiness();
            let btn = document.getElementById("closeModal");
            btn.click();
          })
          .catch(({ response }) => {
            this.setState({ updateSpinner: false });
            if (response) {
              if (response.status == 401) {
                Error("Something wrong, Retry again!");
              } else if (response.status == 510) {
                Error("Email does not exit");
              } else if (response.status == 502) {
                Error(response.status + " Bad Gateway");
              } else if (response.status == 500) {
                Error("Internal Server Error");
              } else if (response.status == 409) {
                Error("Already exist");
              } else if (response.status == 400) {
                Error("Bad request");
              }
            }
          });
      } else {
        Error("Sorry. Fill the mandatory fields");
        this.setState({ isCoseModel: false, collapsed: true, })
      }
    }
  };

  businessEdit = (id) => {
    this.setState({ isBusinessEditedit: true, businessEditeditId: id });
  };
  submit = (e) => {
    const { busPhoneNoPatternError, phoneNoPatternError, ageError } = this.state;
    const { ProfileState } = this.props;
    const data = ProfileState.profile;
    for (let key in data) {
      if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
        data[key] = data[key].trim();
      }
    }
    this.setState({ isEducation: false })
    let listAppLink = [...data.applink];

    let valid = true;
    if (phoneNoPatternError) {
      valid = false;
    }
    if (ageError) {
      valid = false;
    }
    if (valid) {
      this.setState({ updateSpinner: true });
      const formData = new FormData();
      for (let key in data) {
        if (key == "skill") formData.append(key, JSON.stringify(data[key]));
        else if (key == "applink") continue;
        else if (key == "applinkstatus")
          formData.append(key, JSON.stringify(data[key]));
        else if (key == "cc") formData.append(key, JSON.stringify(data[key]));
        else if (key == "businesscc")
          formData.append(key, JSON.stringify(data[key]));
        else formData.append(key, data[key]);
      }
      this.props.ResetBasicDetails()
      axios
        .post(ImportedURL.API.updateProfile, formData)
        .then((res) => {
          this.setState({ updateSpinner: false });
          this.props.ViewProfile({ reverseStatus: true });
          Success("Data saved");
          let btn = document.getElementById("personalDetailsModal");
          btn.click();
        })
        .catch(({ response }) => {
          this.setState({ updateSpinner: false });
          if (response) {
            if (response.status == 401) {
              Error("Something wrong, Retry again!");
            } else if (response.status == 510) {
              Error("Email does not exit");
            } else if (response.status == 502) {
              Error(response.status + " Bad Gateway");
            } else if (response.status == 500) {
              Error("Internal Server Error");
            } else if (response.status == 409) {
              Error("Already exist");
            } else if (response.status == 400) {
              Error("Bad request");
            }
          }
        });
    }
  };
  openModelClick = (e) => {
    this.props.EmptyBusiness();
    this.setState({ socialmediaValue: [] });
    this.insertAppLinkSatus();
    this.setState({
      urlError: false,
      emailError: false,
      phoneNoPatternError: false,
      busPhoneNoPatternError: false,
      phoneNoPatternArrayError: false,
      bussinesslogoError: false,
      isBusinessEditedit: false,
      designationError: false,
      workError: false,
      businessphonenumberError: false,
      showModal1: true,

      streetBord: false,
      cityBord: false,
      stateBord: false,
      zipBord: false,
      country: false,
      isCoseModel: false,
      isCoseModel1: false,
    });
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };

  trigerModelAccord = (e) => {
    this.setState({ socialmedialink: "", socialmediaSelect: "" });
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("mousedown", this.handleClickOutsideTwo);
  }
  handleClickOutside = (e) => {
    if (this.state.isOpen && this.dropdownRef.current && !this.dropdownRef.current.contains(e.target)) {
      this.setState({ isOpen: false });
    }
  };
  handleClickOutsideTwo = (e) => {
    if (this.state.isOpenTwo && this.dropdownRefTwo.current && !this.dropdownRefTwo.current.contains(e.target)) {
      this.setState({ isOpenTwo: false });
    }
  };
  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
  };
  toggleDropdownTwo = () => {
    this.setState((prevState) => ({
      isOpenTwo: !prevState.isOpenTwo
    }));
  };
  // Clear function 
  clearValFun = (e, app_id, type) => {
    const { socialmediaValue } = this.state;
    let index = socialmediaValue.findIndex((e) => e.Uid == app_id);
    this.setState({ isClear: false })
    if (type == 'address') {
      if (index != -1) {
        socialmediaValue[index] = {
          ...socialmediaValue[index],
          ["zip"]: "",
          ["street"]: "",
          ["state"]: "",
          ["country"]: "",
          ["city"]: "",
          ["value"]: "",
          ["link"]: "",
        };
        this.setState({ socialmediaValue: socialmediaValue, isClear: false });
      }
    }

    if (type == 'url') {
      if (index != -1) {
        socialmediaValue[index] = {
          ...socialmediaValue[index],
          ["address"]: "",
          ["value"]: "",
          ["link"]: "",
        };
        this.setState({ socialmediaValue: socialmediaValue, isClearUrl: false });
      }
    }

    if (type == 'number') {
      if (index != -1) {
        socialmediaValue[index] = {
          ...socialmediaValue[index],
          ["address"]: "",
          ["value"]: "",
          ["link"]: "",
        };
        this.setState({ socialmediaValue: socialmediaValue, isClearNumber: false, phoneNoPatternArrayError: false });
      }
    }
    if (type == 'email') {
      if (index != -1) {
        socialmediaValue[index] = {
          ...socialmediaValue[index],
          ["address"]: "",
          ["link"]: "",
          ["value"]: "",
        };
        this.setState({ socialmediaValue: socialmediaValue, isClearEmail: false, emailError: false });
      }
    }



  }
  closeBusinessModel = e => {
    this.setState({ isShowSwal: true })
    if (this.state.isShowSwal) {
      Swal.fire({
        title: "Do you want to update your business information?",
        // showCancelButton: true,
        showDenyButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: 'Yes',
        denyButtonText: `No`,
        // confirmButtonText: "Yes",
        // imageUrl: "../../assets/images/delete.png",
        customClass: {
          container: "delete_swal_image",
          popup: "swal_pop",
          title: "swal_title",
          image: "swal_image",
          actions: "swal_action",
          confirmButton: "swal_confirm",
          cancelButton: "swal_close",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({ collapsed: false, isCoseModel: true, isShowSwal: false });
          this.businessSubmit();
        }
        if (result.isDenied) {
          this.setState({ isCoseModel: true, isShowSwal: false, collapsed: false, });
          let btn = document.getElementById("closeModal");
          btn.click();
        }
      })
    }
  }
  // Personal 
  closeBusinessModel1 = e => {
    this.setState({ isShowSwal: true })
    const bData = this.props.BusinessState.bussiness;
    if (bData && bData.applink && bData.applink.length > 0) {
      if (this.state.isShowSwal) {
        Swal.fire({
          title: "Update your personal information if you haven't already?",
          // showCancelButton: true,
          showDenyButton: true,
          cancelButtonColor: "#d33",
          confirmButtonText: 'Yes',
          denyButtonText: `No`,
          // imageUrl: "../../assets/images/delete.png",
          customClass: {
            container: "delete_swal_image",
            popup: "swal_pop",
            title: "swal_title",
            image: "swal_image",
            actions: "swal_action",
            confirmButton: "swal_confirm",
            cancelButton: "swal_close",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.businessSubmit(e, 'personal')
            this.setState({ collapsed: false, isCoseModel1: true, isShowSwal: false });
            let btn = document.getElementById("closeModal1");
            btn.click();
          }
          if (result.isDenied) {
            let btn = document.getElementById("closePersonal");
            btn.click();
            this.setState({ isCoseModel1: false, collapsed: false, });
          }
        })
      }
    } else {
      let btn = document.getElementById("closePersonal");
      btn.click();
      this.setState({ isCoseModel1: false, collapsed: false, });
    }
  }

  //Business Google API
  handlePlaceSelect = (place, parentId) => {
    const addressComponents = place.address_components;
    let no = '';
    let street = '';
    let city = '';
    let state = '';
    let country = '';
    let zip = '';

    for (const component of addressComponents) {
      const types = component.types;

      if (types.includes('street_number')) {
        no = component.long_name;
      }

      if (types.includes('route')) {
        street = component.long_name;
      }

      if (types.includes('locality')) {
        city = component.long_name;
      }

      if (types.includes('administrative_area_level_1')) {
        state = component.long_name;
      }

      if (types.includes('country')) {
        country = component.long_name;
      }

      if (types.includes('postal_code')) {
        zip = component.long_name;
      }
    }
    const { socialmediaValue } = this.state;
    let index = socialmediaValue.findIndex((e) => e.Uid == parentId);
    if (index != -1) {
      socialmediaValue[index] = {
        ...socialmediaValue[index],
        ["street"]: no ? no + ' ' + street : street ? street : '',
        ["city"]: city ? city : '',
        ["state"]: state ? state : '',
        ["country"]: country ? country : '',
        ["zip"]: zip ? zip : '',
        ["value"]: "",
        ["link"]: "",
      };
      if (socialmediaValue && socialmediaValue.length > 0) {
        socialmediaValue.map(data => {
          if (data.street) {
            this.setState({ streetBord: false, })
          }
          if (data.city) {
            this.setState({ cityBord: false })
          }
          if (data.state) {
            this.setState({ stateBord: false })
          }
          if (data.zip) {
            this.setState({ zipBord: false })
          } if (data.country) {
            this.setState({ countryBord: false })
          }
          if (!data.street && !data.city && !data.state && !data.zip) {
            this.setState({ isClear: false })
          } else {
            this.setState({ isClear: true })
          }
        })
      }
      this.setState({ socialmediaValue: socialmediaValue });
    }
  }
  //Personal Google API
  handlePlaceSelect1 = (place, parentId) => {
    const addressComponents = place.address_components;
    let no = '';
    let street = '';
    let city = '';
    let state = '';
    let country = '';
    let zip = '';

    for (const component of addressComponents) {
      const types = component.types;

      if (types.includes('street_number')) {
        no = component.long_name;
      }

      if (types.includes('route')) {
        street = component.long_name;
      }

      if (types.includes('locality')) {
        city = component.long_name;
      }

      if (types.includes('administrative_area_level_1')) {
        state = component.long_name;
      }

      if (types.includes('country')) {
        country = component.long_name;
      }

      if (types.includes('postal_code')) {
        zip = component.long_name;
      }
    }
    const { socialmediaValue } = this.state;
    let index = socialmediaValue.findIndex((e) => e.Uid == parentId);
    if (index != -1) {
      socialmediaValue[index] = {
        ...socialmediaValue[index],
        ["street"]: no ? no + ' ' + street : street ? street : '',
        ["city"]: city ? city : '',
        ["state"]: state ? state : '',
        ["country"]: country ? country : '',
        ["zip"]: zip ? zip : '',
        ["value"]: "",
        ["link"]: "",
      };
      if (socialmediaValue && socialmediaValue.length > 0) {
        socialmediaValue.map(data => {
          if (data.street) {
            this.setState({ streetBord: false, })
          }
          if (data.city) {
            this.setState({ cityBord: false })
          }
          if (data.state) {
            this.setState({ stateBord: false })
          }
          if (data.zip) {
            this.setState({ zipBord: false })
          } if (data.country) {
            this.setState({ countryBord: false })
          }
          if (!data.street && !data.city && !data.state && !data.zip) {
            this.setState({ isClear: false })
          } else {
            this.setState({ isClear: true })
          }
        })
      }
      this.setState({ socialmediaValue: socialmediaValue });
    }
  }

  selectEducation = (value) => {
    this.props.HandleInputChange("education", value);
    this.setState({ isEducation: false })
  }

  handleSelectQualification = (item) => {
    this.props.HandleInputChange("education", item.name);
  };

  // Function to handle input changes
  handleChangeQualification = (value) => {
    if (value != undefined) {
      this.props.HandleInputChange("education", value);
    }
  };
  // Delete Business Function 
  deleteBusiness = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete the data?",
      // showCancelButton: true,
      showDenyButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
      // imageUrl: "../../assets/images/delete.png",
      customClass: {
        container: "delete_swal_image",
        popup: "swal_pop",
        title: "swal_title",
        image: "swal_image",
        actions: "swal_action",
        confirmButton: "swal_confirm",
        cancelButton: "swal_close",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(ImportedURL.API.softDeleteBusinesses + '/' + id)
          .then((res) => {
            // this.setState({ updateSpinner: false, isShowSwal: false, isCoseModel: true });
            this.props.ViewProfile({ reverseStatus: true });
            Success("Your business info has been deleted ");
            this.props.ListBusiness();
            this.props.EmptyBusiness();

          })
          .catch(({ response }) => {
            this.setState({ updateSpinner: false });
            if (response) {
              if (response.status == 401) {
                Error("Something wrong, Retry again!");
              } else if (response.status == 510) {
                Error("Email does not exit");
              } else if (response.status == 502) {
                Error(response.status + " Bad Gateway");
              } else if (response.status == 500) {
                Error("Internal Server Error");
              } else if (response.status == 409) {
                Error("Already exist");
              } else if (response.status == 400) {
                Error("Bad request");
              }
            }
          });
      }
      if (result.isDenied) {

      }
    })
  }
  // Delete Personal Function 
  deletePersonal = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete the data?",
      // showCancelButton: true,
      showDenyButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
      // imageUrl: "../../assets/images/delete.png",
      customClass: {
        container: "delete_swal_image",
        popup: "swal_pop",
        title: "swal_title",
        image: "swal_image",
        actions: "swal_action",
        confirmButton: "swal_confirm",
        cancelButton: "swal_close",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(ImportedURL.API.softDeletePersonal + '/' + id)
          .then((res) => {
            // this.setState({ updateSpinner: false, isShowSwal: false, isCoseModel: true });
            this.props.ViewProfile({ reverseStatus: true });
            Success("Your personal info has been deleted");
            this.props.ListBusiness();
            this.props.EmptyBusiness();
            this.props.ListPersonal();

          })
          .catch(({ response }) => {
            this.setState({ updateSpinner: false });
            if (response) {
              if (response.status == 401) {
                Error("Something wrong, Retry again!");
              } else if (response.status == 510) {
                Error("Email does not exit");
              } else if (response.status == 502) {
                Error(response.status + " Bad Gateway");
              } else if (response.status == 500) {
                Error("Internal Server Error");
              } else if (response.status == 409) {
                Error("Already exist");
              } else if (response.status == 400) {
                Error("Bad request");
              }
            }
          });
      }
      if (result.isDenied) {

      }
    })
  }

  onOpenBasicModal = () => {
    this.setState({ isEducation: false, skill: '' })
    this.props.ResetBasicDetails()
    this.props.ViewProfile({ reverseStatus: true });
  }
  render() {
    if (this.state.isBusinessEditedit)
      return <Redirect to={"/editprofile/" + this.state.businessEditeditId} />;
    const { crop, croppedImageUrl, src, isUserdDisplayNameSaveShow, isUserdHeadlineSaveShow, } = this.state;
    const { socialmediaSelect } = this.state;
    const { ProfileState, appState, BusinessState, bloodgroupState, educationState } = this.props;
    const listapp = appState.listApp;
    const bData = BusinessState.bussiness;
    const listBusinessData = BusinessState.listBusiness;
    const listPersonalData = BusinessState.listPersonal;
    listapp?.sort((a, b) => Number(a.sortorder) - Number(b.sortorder));
    const spinner = ProfileState.spinner;
    const spinnerAdd = BusinessState.spinnerAdd;
    const spinnerBasicDetail = ProfileState.spinnerBasicDetail;
    const data = ProfileState.profile;
    // const dobDate = data.dob ? moment(data.dob).format("MM-DD-YYYY") : "";
    const selectOption = [];
    if (listapp && listapp.length > 0) {
      listapp.filter((e) => e.status == true).map((app) => {
        app.typesList && app.typesList.length > 0 &&
          app.typesList.map((item) => {
            selectOption.push({
              label: item.appname,
              value: item.appname,
              logo: item.logo,
              id: item.id,
              inputtype: item.inputtype,
            });
          });
      });
    }
    const bloodgroupoptions = bloodgroupState.listBloodgroup;
    let bloodgroupVal;
    if (bloodgroupoptions && bloodgroupoptions.length > 0) {
      bloodgroupoptions.map((item => {
        if (data.bloodgroup == item._id) {
          bloodgroupVal = { value: item._id, label: item.name, name: 'bloodgroup' }
        }
      }))
    }
    const { streetBord, cityBord, stateBord, zipBord, countryBord, isClear, isClearUrl, isClearNumber, isClearEmail, isCoseModel, isCoseModel1, modalType, Country, State, City, apiKey, scriptLoaded, businessBtnChange, personalBtnChange, isEducation, isSkillColor, isWwwShow } = this.state;





    // const jsonArr = education_json.data;
    // const ids = jsonArr.map(({ name }) => name);
    // const filtered = jsonArr.filter(({ name }, index) =>
    //   !ids.includes(name, index + 1));


    const educationArr = educationState.listEducation;
    const filteredData = educationArr.filter((item) =>
      item.name.toLowerCase().includes(data.education && data.education.toLowerCase())
    );


    const fifteenYearsAgo = new Date();
    fifteenYearsAgo.setFullYear(fifteenYearsAgo.getFullYear() - 15);
    const DatepickerInput = ({ ...props }) => (
      <input type="text" {...props} readOnly />
    );
    return (
      <>
        <div style={{ position: "relative" }}>
          <div className="home_section profile_section gap_padding_space pro_bg">
            <Container >
              <Row className="justify-content-md-center">
                <Col xs="12" lg="5" md="12" sm="12">
                  <div className="acw_card_nav_images">
                    <div className="acw_card_logo">
                      <div className="acw_image1">
                        {/* <Link to="/"> */}
                        <img src="../assets/images/acwlogo.png" />
                        {/* </Link> */}
                      </div>
                      <div className="acw_vertical_line"></div>
                      <div className="acw_image2">
                        {/* <Link to="/"> */}
                        <img src="../assets/images/nfclogo.png" />
                        {/* </Link> */}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="acw_card_setting">
                        <Link
                          style={{ color: "#fff" }}
                          to="/accountsetting"
                          onClick={(e) =>
                            this.props.HandleChangesSetting("profile")
                          }
                        >
                          <AiFillSetting className="acoount_setting" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="profile_header_text d-flex"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <p className="back_text mb-0">Edit Profile</p>
                    </div>
                  </div>
                  <div className="public_private_sec">
                    <div class="profile_toggle  d-flex justify-content-between">
                      <p>Make your profile visible Yes/No</p>
                      <label class="toggle">
                        <input
                          type="checkbox"
                          name="ispublicprofile"
                          checked={data.ispublicprofile}
                          onClick={this.taggleStatus}
                        />
                        <span class="slider"></span>
                        <span
                          class="labels"
                          data-on="YES"
                          data-off="NO"
                        ></span>
                      </label>
                    </div>
                    {/* <div class="profile_toggle_Account d-flex justify-content-between">
                        <p>Hide or show the files users</p>
                        <label class="toggle">
                            <input type="checkbox" name="ispublicprofile" checked={data.isinfostatus} onClick={this.taggleStatusFiles} />
                            <span class="slider"></span>
                            <span class="labels" data-on="Files On" data-off="Files Off"></span>
                        </label>
                    </div> */}
                  </div>
                  <div className="home_sec">
                    <div className="progfile_images">
                      <div class="image-upload">
                        <div className="pro_bg_image">
                          {(this.state.bgimage || data.banner) ? (
                            <img
                              src={this.state.bgimage ? URL.createObjectURL(this.state.bgimage) : ImportedURL.LIVEURL + data.banner} alt="" />
                          ) : (
                            <img className="avatar" src="../assets/images/acwprofilebg.png" data-toggle="tooltip" data-original-title="Avatar Name" alt="" />
                          )}
                          <div className="response_ban_cam">
                            <label class="dropdown " ref={this.dropdownRef}>
                              <div class="dd-button" onClick={this.toggleDropdown}>
                                <AiFillCamera />
                              </div>
                              {/* <input type="checkbox" class="dd-input" id="test" /> */}
                              {this.state.isOpen && (
                                <ul class="dd-menu">
                                  <li onChange={this.onChangeImage}><label for="file-input" >Gallery </label></li>
                                  {this.state.bgimage || data.banner ? <li onClick={this.removeBanner} >Remove</li> : ''}

                                </ul>
                              )}
                            </label>
                          </div>







                          {/* <label for="file-input" className="profile_camera_banner">
                            <span> <AiFillCamera onChange={this.onChangeImage} /></span>
                          </label>
                          {(data.banner) && (
                            <label for="file-input-0" className="profile_camera_banner_Cancel">
                              <span>
                                <i class="fa fa-times-circle" aria-hidden="true" onClick={this.removeBanner}></i>
                              </span>
                            </label>
                          )} */}
                        </div>
                        <input id="file-input" ref={this.fileInputBanner} accept="image/jpg, image/jpeg, image/png" type="file" name="banner" onChange={this.onChangeImage} />
                      </div>
                      <div class="image-upload">
                        <div className="round_profile">
                          {(this.state.profileimg || data.image) ? (
                            <>
                              <img src={this.state.profileimg ? this.state.profileimg : ImportedURL.LIVEURL + data.image} />
                            </>
                          ) : (
                            <>
                              <img src="../assets/images/user.png" />
                            </>
                          )}


                          <div className="response_pro_cam">
                            <label class="dropdown " ref={this.dropdownRefTwo}>
                              <div class="dd-button" onClick={this.toggleDropdownTwo}>
                                <AiFillCamera />
                              </div>
                              {/* <input type="checkbox" class="dd-input" id="test" /> */}
                              {this.state.isOpenTwo && (
                                <ul class="dd-menu">
                                  <li onChange={this.onChangeImage}><label for="file-input1" >Gallery</label></li>
                                  {this.state.profileimg || data.image ? <li onClick={this.removeImages} >Remove</li> : ''}
                                </ul>
                              )}
                            </label>
                          </div>


                          {/* <label for="file-input1" className="profile_camera" name="circle_image" >
                            <span>
                              <AiFillCamera onChange={this.onChangeImage} />
                            </span>
                          </label>
                          {(data.image) && (
                            <label
                              for="file-input2"
                              className="profile_camera_cancel"
                              name="circle_image"
                            >
                              <span>
                                <i class="fa fa-times-circle" aria-hidden="true" onClick={this.removeImages} ></i>
                              </span>
                            </label>
                          )} */}

                          <input
                            id="file-input1"
                            ref={this.fileInputRef}
                            accept="image/jpg, image/jpeg, image/png"
                            type="file"
                            style={{ display: "none" }}
                            name="image"
                            onChange={this.onChangeImage}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="profile_images_box_shadow prof_dis_name">
                      <div className="input_tick">
                        <div className="left_form">
                          <div class="input_design">
                            <label class="form-label"> Full Name</label>
                            <input
                              type="text"
                              class="form-control"
                              id="profiledisplayname"
                              placeholder="Full Name"
                              name="displayname"
                              value={data.displayname}
                              onChange={this.onChange}
                              spellCheck="false"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="input_tick">
                        <div className="left_form">
                          <div class="input_design">
                            <label class="form-label" placeholder="Password"> Headline </label>
                            <input
                              type="text"
                              class="form-control"
                              id="profileheadline"
                              placeholder="Headline"
                              name="headline"
                              value={data.headline}
                              onChange={this.onChange}
                              spellCheck="false"
                            />
                          </div>
                        </div>
                        {/* <div className="right_tick">
                          {!isUserdHeadlineSaveShow ? (
                            <div className="img_size">
                              <img src={disable_tick} alt="Not Found" />
                            </div>
                          ) : (
                            <div
                              className="img_size"
                              onClick={this.headlineClick}
                            >
                              <img src={active_tick} alt="Not Found" />
                            </div>
                          )}
                        </div> */}
                      </div>
                    </div>
                    {/* ============= Basic Detaile ===================== */}
                    <div className="profile_detail_button">
                      {/* <div className='status_btn'>
                            <label class="switch">
                                <input type="checkbox" checked={data.ispersonaldetails} name='ispersonaldetails' onClick={this.taggleStatus} />
                                <div class="slider round"></div>
                            </label>
                        </div> */}
                      <div className="pe_img">
                        <div className="img_size">
                          <img src={profile} />
                        </div>
                        {/* <BsPersonVcard /> */}
                      </div>
                      <div className="content" data-toggle="modal" onClick={this.onOpenBasicModal} data-target="#exampleModalCenter" >
                        <span>Basic Details</span>
                      </div>
                      <div
                        className="edit_icon"
                        data-toggle="modal"
                        data-target="#exampleModalCenter"
                      >
                        <div className="img_size">
                          <img src={edit} />
                        </div>
                      </div>
                    </div>
                    <div
                      class="modal fade personal_details_model"
                      id="exampleModalCenter"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalCenterTitle"
                      aria-hidden="true"
                    >
                      <div
                        class="modal-dialog modal-dialog-centered"
                        role="document"
                      >
                        <div class="modal-content">
                          <div class="modal-header" style={{ padding: '1rem 1rem 1rem 1.6rem' }}>
                            <h5 class="modal-title" id="exampleModalLongTitle">
                              Basic Details
                            </h5>
                            <button type="button" class="close" id="personalDetailsModal" data-dismiss="modal" aria-label="Close" >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div className="profile_images_box_shadow">
                              <div class=" input_design mb-3">
                                <label
                                  class="form-label"
                                >
                                  Date of Birth
                                </label>
                                <div className="form-control  input_date_field w-100 d-flex align-items-center">
                                  <DatePicker
                                    selected={data.dob && data.dob != "null" ? new Date(data.dob) : fifteenYearsAgo}
                                    value={new Date(data.dob)}
                                    onChange={this.onChangeDate}
                                    maxDate={fifteenYearsAgo}
                                    placeholderText="Date of Birth"
                                    dateFormat='MM/dd/yyyy'
                                    showMonthYearDropdowns={false}
                                    showYearDropdown
                                    showMonthDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={100}
                                    onKeyDown={e => e.preventDefault()}
                                    customInput={<DatepickerInput />}
                                    ref={this.dateInputRef}
                                  />
                                </div>
                                <span className="disclimer">
                                  Note: The age must be at least 15.</span>
                                <div
                                  className="invalid-feedback"
                                  style={{
                                    display: this.state.ageError
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  Age should be greater than 15
                                </div>
                              </div>
                              <div class="mb-3 input_design">
                                <label class="form-label">Blood Group</label>
                                <Select
                                  value={bloodgroupVal != undefined ? bloodgroupVal : ""}
                                  onChange={this.bloodgroupChange}
                                  options={bloodgroupoptions && bloodgroupoptions.length > 0 && bloodgroupoptions.filter(e => e.status === true).map(data => {
                                    return { value: data._id, label: data.name, name: 'bloodgroup' }
                                  })}
                                  styles={{
                                    menu: provided => ({ ...provided, zIndex: 9999, textAlign: 'left' })
                                  }}
                                />
                              </div>

                              <div class="mb-3 input_design " >
                                <label for="exampleFormControlInput1" class="form-label" >Educational Qualification </label>
                                <input type="text" class="form-control" name="education" onChange={this.onChange} value={data.education} id="floatingPassword" placeholder="Educational Qualification" spellCheck="false" />
                                {(isEducation) &&
                                  <div className="qual_xldj_012_box" style={filteredData.length > 5 ? { height: '300px' } : filteredData.length != 0 ? { height: "auto" } : { display: 'none' }}>
                                    <ul>
                                      {filteredData && filteredData.filter(e => e.status === true).map(data => {
                                        return <li onClick={(e) => this.selectEducation(data.name)}>{data.name}</li>
                                      })}
                                    </ul>
                                  </div>
                                }
                              </div>


                              <div className="input_design">
                                <label class="form-label" > Skills</label>
                                {(data.skill && data.skill.length > 0) && (
                                  <div className="skill_badge_group input_design mt-2">
                                    {data.skill &&
                                      data.skill.length > 0 &&
                                      data.skill.map((data, i) => {
                                        return (
                                          <>
                                            <span className="skill_badges mb-3" onClick={(e) => this.removeSkill(i)} >
                                              {data} <AiFillCloseCircle className="close_icon" />
                                            </span>
                                          </>
                                        );
                                      })}
                                  </div>
                                )}
                                <div class="input_design skill_plus"
                                  style={{
                                    marginBottom: "35px",
                                    marginLeft: "0px !important",
                                    marginRight: "0px !important",
                                  }}
                                >
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="floatingInput"
                                    value={this.state.skill}
                                    onChange={(e) => this.skillChange(e.target.value)}
                                    spellCheck="false"
                                    placeholder="Skills"
                                  />
                                  <AiOutlinePlus onClick={this.onClickSkill} style={{ backgroundColor: isSkillColor ? "#150B4F" : '#ccc' }} />
                                </div>
                              </div>
                              {((data.skill != undefined && data.skill && data.skill.length == 0) && data.dob == '' && data.bloodgroup == '' && data.education == '') ?
                                <div className="profile_save_btn" style={{ marginTop: "35px" }}>
                                  <button type="button" class="btn btn-primary" id="personalDetailsModal" data-dismiss="modal" aria-label="Close">close</button>
                                </div>
                                :
                                <div className="profile_save_btn" style={{ marginTop: "35px" }}>
                                  <button type="button" class="btn btn-primary" onClick={this.submit} >Save</button>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      {spinnerBasicDetail &&
                        <div className='common_loader_model'>
                          <img className='loader_img_style_common' src='/assets/images/logo.jpg' />
                          <Spinner className='spinner_load_common' animation="border" variant="info" >
                          </Spinner>
                        </div>}
                    </div>

                    {/* ============== Personal Detaile =========== */}
                    <div className="our_budiness_part">
                      <h5>Personal Details</h5>
                      {listPersonalData && listPersonalData.length > 0 ?
                        listPersonalData.map(personal => {
                          return <>
                            <div className="buss_btns">
                              <div className="img_size" >
                                <img src={personal_log} alt="" />
                              </div>
                              <div className="d-flex align-items-center content" onClick={() => this.businessEdit(personal._id)} style={{ cursor: "pointer" }}>
                                <span>Personal Details</span>
                              </div>
                              <div className="d-flex">
                                <div className="profile_infosec d-flex align-items-center mt-0" style={{ boxShadow: "none" }} >
                                  <label class="switch">
                                    <input type="checkbox" name="personalstatus" checked={personal.status} onClick={(e) => this.taggleStatusPersonal(e, personal._id, 'personal')} />
                                    <div class="slider round"></div>
                                  </label>
                                </div>
                                <div className="delete_list_pro_012" onClick={(e) => this.deletePersonal(personal._id)}>
                                  <MdDeleteOutline />
                                </div>
                              </div>
                            </div >
                            <span className="note_style"><strong>Note :</strong><FaHandPointer className="hand_symbole" />Click Personal details to add more information</span>
                          </>
                        }) : []}
                      {listPersonalData && listPersonalData.length == [] ?
                        <div className="add_btn">
                          <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop1" onClick={this.openModelClick} >
                            Add
                          </button>
                        </div>
                        : ''}
                    </div>
                    {/* =============== Personal Model  */}
                    <div class={`modal fade business_model personal_details_model`} id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" ref={this.containerRef}>
                      <div class="modal-dialog modal-dialog-scrollable" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel"> Add Personal Details</h5>
                            <button type="button" id="closeModal1" data-bs-dismiss={isCoseModel1 ? "modal" : ''} aria-label="Close" class="close" onClick={this.closeBusinessModel1}  >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div className="">
                              <div className="profile_images_box_shadow">
                                {/* <div class="my-2 input_design">
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <label for="exampleFormControlInput1" class="form-label pt-0">
                                        Business logo
                                      </label><br></br>
                                      <label for="imageSet" className="businesslogo" name="circle_image" >
                                        <span><RiImageAddFill onChange={this.onChangeImage} /></span>
                                      </label>
                                      <input ref={this.fileInputBusinesssLogo} type="file" accept="image/jpg, image/jpeg, image/png" class="form-control" placeholder="Icon" name="businesslogo" id="imageSet" onChange={this.businessLogoChange} style={{ display: 'none' }} />
                                    </div>
                                    {bData.image ?
                                      <div className="buss_img_size">
                                        {this.state.bussinesslogoSrc ? <img src={this.state.bussinesslogoSrc} width="100px" alt='' /> : <img src={bData.image ? ImportedURL.FILEURL + bData.image : ''} width="100px" alt='' />}
                                      </div>
                                      : ""}
                                  </div>
                                  <div class="invalid-feedback" style={{ display: this.state.bussinesslogoError ? "block" : "none", }} >
                                    Logo is required
                                  </div>
                                </div> */}

                                {/* <div class="mb-2 input_design">
                                  <label for="exampleFormControlInput1" class="form-label">Designation</label>
                                  <input type="text" class="form-control" name="designation" onChange={this.onChangeBusiness} value={bData.designation} id="floatingInput" placeholder="Designation" />
                                  <div class="invalid-feedback" style={{ display: this.state.designationError ? "block" : "none", }} >
                                    Designation is required
                                  </div>
                                </div> */}

                                {/* <div class="mb-2 input_design">
                                  <label for="exampleFormControlInput1" class="form-label">Name of the company </label>
                                  <input type="text" class="form-control" name="work" onChange={this.onChangeBusiness} value={bData.work} id="floatingInput" placeholder="Name of the company" />
                                  <div class="invalid-feedback" style={{ display: this.state.workError ? "block" : "none", }}>
                                    Name of the company is required
                                  </div>
                                </div> */}

                                {/* <div class="form-floating-cc mb-2">
                                  <label for="exampleFormControlInput1" class="form-label" >Contact Number</label>
                                  <div className="row">
                                    <div className="col-5 ">
                                      <Select
                                        value={bData.businesscc && bData.businesscc.value != undefined ? { label: bData.businesscc.value, value: bData.businesscc.name, flag: bData.businesscc.name.toLowerCase(), } : ""}
                                        onChange={this.onSelectFlagBusiness}
                                        options={CountryCodeJson &&
                                          CountryCodeJson.map((item) => {
                                            return {
                                              label: item.dial_code,
                                              flag: item.code.toLowerCase(),
                                              value: item.code,
                                              name: "businesscc",
                                            };
                                          })
                                        }
                                        formatOptionLabel={(e) => {
                                          return (
                                            <div
                                              style={{
                                                display: "block",
                                                alignItems: "center",
                                              }}
                                            >
                                              {e.flag ? (
                                                <img
                                                  src={
                                                    "../assets/images/flags/" +
                                                    e.flag +
                                                    ".svg"
                                                  }
                                                  style={{
                                                    height: "20px",
                                                    width: "25px",
                                                    objectFit: "contain",
                                                  }}
                                                  className="contain_image"
                                                />
                                              ) : (
                                                ""
                                              )}
                                              <span style={{ marginLeft: 5 }}>
                                                {e.label}
                                              </span>
                                            </div>
                                          );
                                        }}
                                        menuPortalTarget={document.body}
                                        styles={{
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                        }}
                                      />
                                    </div>
                                    <div className="col-7">
                                      <div class="mb-3 input_design form-floating-phone-number">
                                        <input type="text" class="form-control" name="businessphonenumber" maxLength={10}
                                          onChange={this.onChangeBusiness} value={bData.businessphonenumber} id="floatingPassword" placeholder="Contact Number"
                                        />
                                        <div className="invalid-feedback" style={{ display: this.state.busPhoneNoPatternError ? "block" : "none", }}>
                                          Contact Number should contain 10 digits
                                        </div>
                                        <div
                                          className="invalid-feedback"
                                          style={{
                                            display: this.state
                                              .businessphonenumberError
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          Contact Number is required
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                                <div id='accordionExample' class="accordion">
                                  {listapp != undefined && listapp.length > 0 &&
                                    listapp.filter((e) => e.status == true).map((app, i) => {
                                      let carouselData = bData.applink.filter((e) => e.parentId == app._id)
                                      let { socialmediaValue } = this.state;
                                      let toggleStatus = bData.applinkstatus && bData.applinkstatus.length > 0 ? bData.applinkstatus.find((e) => e.appid == app._id) : "";
                                      let status = socialmediaValue && socialmediaValue.length > 0 ? socialmediaValue.reverse().find((e) => e.Uid == app._id) : "";
                                      let selectOption = [];
                                      if (app.typesList && app.typesList.length > 0) {
                                        selectOption.push({ label: "Deselect", value: "", });
                                        app.typesList.map((item) => {
                                          selectOption.push({ label: item.appname, value: item.appname, logo: item.logo, id: item.id, inputtype: item.inputtype, });
                                        });
                                      }

                                      if (status != undefined) {
                                        var countryVal = Country && Country.length > 0 && Country.find(e => e.name == status.country);
                                      }
                                      if (countryVal != undefined) {
                                        var stateVal = State && State.length > 0 && State.filter(e => e.countryCode == countryVal.isoCode)
                                      }
                                      if (status != undefined) {
                                        var stateFindVal = State && State.length > 0 && State.find(e =>
                                          e.name == status.state
                                        )
                                      }

                                      if (stateFindVal != undefined) {
                                        var cityVal = City && City.length > 0 && City.filter(e => e.stateCode == stateFindVal.isoCode)
                                      }
                                      //State Option
                                      const StateOption = [];
                                      if (stateVal && stateVal.length > 0) {
                                        stateVal.map(state => {
                                          StateOption.push({ value: state.name, label: state.name, name: 'state' })
                                        })
                                      }

                                      //City Option
                                      const CityOption = [];
                                      if (cityVal && cityVal.length > 0) {
                                        cityVal.map(city => {
                                          CityOption.push({ value: city.name, label: city.name, name: 'city' })
                                        })
                                      }

                                      let link = "";
                                      let valueData = "";
                                      if (status != undefined && status) {
                                        link = status.link;
                                        valueData = status.value;
                                      }
                                      return (
                                        <>
                                          <div class="accordion-item mt-3" key={i}>
                                            <h2 class="accordion-header" id={"headingSix" + (i + 100)} >
                                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseSix" + (i + 100)} aria-expanded="false" aria-controls="collapseSix" onClick={this.trigerModelAccord} >
                                                <label className="form-label mb-0 px-0 ml-0 mb-0"> {app.name}</label>
                                              </button>
                                            </h2>
                                            {this.state.collapsed && (
                                              <div id={"collapseSix" + (i + 100)} class="accordion-collapse collapse collapseSix" aria-labelledby={"headingSix" + (i + 100)} data-bs-parent="#accordionExample">
                                                <div class="accordion-body" >
                                                  <div className="profile_images_box_shadow">
                                                    <div className="row social_media_field  social_media_field_insert">
                                                      <div className="col-12">
                                                        <div className="form-group">
                                                          <Select
                                                            onClick={this.heightPerpose}
                                                            value={status != undefined && status ? { label: status.name, logo: status.logo, } : ""}
                                                            options={selectOption}
                                                            onChange={(e) => this.socialmediaSelect(e, app._id)}
                                                            formatOptionLabel={(e) => (
                                                              <div style={{ display: "block", alignItems: "center", zIndex: 2, }}>
                                                                {e.logo ? (
                                                                  <img src={ImportedURL.LIVEURL + e.logo}
                                                                    style={{ height: "25px", width: "30px", objectFit: "contain", }}
                                                                    className="contain_image"
                                                                  />
                                                                ) : ("")}
                                                                <span style={{ marginLeft: 5, }}>{e.label}</span>
                                                              </div>
                                                            )}
                                                            styles={{
                                                              menu: provided => ({ ...provided, zIndex: 9999, textAlign: 'left' })
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                      {status != undefined && status && (
                                                        <>
                                                          {status.inputtype == "address" && (
                                                            <>
                                                              {isClear &&
                                                                <span className="clear_val" onClick={(e) => this.clearValFun(e, app._id, "address")}>
                                                                  <HiRefresh />
                                                                  <label class="form-check-label" for="exampleCheck1">Clear</label>
                                                                </span>
                                                              }
                                                              <Autocomplete
                                                                apiKey={apiKey}
                                                                onLoad={(autocomplete) => (this.autocomplete1 = autocomplete)}
                                                                onPlaceChanged={() => this.handlePlaceSelect1(this.autocomplete1.getPlace(), app._id)}
                                                              >
                                                                <div className="col-12">
                                                                  <div className="form-group px-1">
                                                                    <label for="exampleFormControlInput1" class="form-label" > Street</label>
                                                                    <input type="text" class="form-control" name="street" style={{ borderColor: streetBord ? 'red' : '' }}
                                                                      onChange={(e) => this.onChangeAddress(e, app._id, "address")} value={status.street ? status.street : ""} id="floatingInput" placeholder="Street" spellCheck="false" />
                                                                  </div>
                                                                </div>
                                                              </Autocomplete>

                                                              <div className="col-12">
                                                                <div className="form-group">
                                                                  <label for="exampleFormControlInput1" class="form-label"  >City</label>
                                                                  <input type="text" class="form-control" name="city" style={{ borderColor: cityBord ? 'red' : '' }}
                                                                    onChange={(e) => this.onChangeAddress(e, app._id, "address")}
                                                                    value={status.city ? status.city : ""} id="floatingInput" placeholder="City"
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div className="col-12">
                                                                <div className="form-group">
                                                                  <label for="exampleFormControlInput1" class="form-label" > State</label>
                                                                  <input type="text" class="form-control" onChange={(e) => this.onChangeAddress(e, app._id, "address")} name="state" value={status.state ? status.state : ""} id="floatingInput" placeholder="State" style={{ borderColor: stateBord ? 'red' : '' }} spellCheck="false" />
                                                                </div>
                                                              </div>

                                                              <div className="col-12">
                                                                <div className="form-group">
                                                                  <label for="exampleFormControlInput1" class="form-label">
                                                                    Country
                                                                  </label>
                                                                  <input type="text" class="form-control" name="country"
                                                                    onChange={(e) => this.onChangeAddress(e, app._id, "address")} style={{ borderColor: countryBord ? 'red' : '' }}
                                                                    value={status.country ? status.country : ""} id="floatingInput" placeholder="Country" spellCheck="false"
                                                                  />
                                                                </div>
                                                              </div>

                                                              <div className="col-12">
                                                                <div className="form-group">
                                                                  <label for="exampleFormControlInput1" class="form-label">ZIP Code </label>
                                                                  <input type="text" class="form-control" name="zip"
                                                                    onChange={(e) => this.onChangeAddress(e, app._id, "address")} style={{ borderColor: zipBord ? 'red' : '' }}
                                                                    value={status.zip ? status.zip : ""} id="floatingInput" placeholder="ZIP Code" spellCheck="false"
                                                                  />
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                          {status.inputtype == "url" && (
                                                            <>
                                                              {isClearUrl &&
                                                                <div className="clear_val" onClick={(e) => this.clearValFun(e, app._id, "url")}>
                                                                  <HiRefresh />
                                                                  <label class="form-check-label" for="exampleCheck1">Clear</label>
                                                                </div>}
                                                              <div className="col-12">
                                                                <div className="form-group" style={{ marginTop: "10px", position: 'relative' }} >
                                                                  <input type="text" class="form-control " id="floatingInput" style={{ paddingLeft: '60px' }}
                                                                    value={link} name="socialmedialink"
                                                                    onChange={(e) => this.onChangeLink(e, app._id, "url")}
                                                                    placeholder={status.name}
                                                                  />
                                                                  {!isWwwShow &&
                                                                    <span className="www_012x3">www.</span>
                                                                  }
                                                                  <div className="invalid-feedback" style={{ display: this.state.urlError ? "block" : "none", }}> URL is required</div>
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                          {status.inputtype == "number" && (
                                                            <>
                                                              {isClearNumber &&
                                                                <div className="clear_val" onClick={(e) => this.clearValFun(e, app._id, "number")}>
                                                                  <HiRefresh />
                                                                  <label class="form-check-label" for="exampleCheck1">Clear</label>
                                                                </div>}
                                                              <div className="col-12">
                                                                <div className="form-group" style={{ marginTop: "10px", }} >
                                                                  <div className="row">
                                                                    <div className="col-5 ">
                                                                      <Select
                                                                        value={status.cc && status.cc.value != undefined ? { label: status.cc.value, value: status.cc.name, flag: status.cc.name.toLowerCase(), } : ""}
                                                                        onChange={(e) => this.onChangeLinkCC(e, app._id, "cc")}
                                                                        options={CountryCodeJson &&
                                                                          CountryCodeJson.map((item) => {
                                                                            return {
                                                                              label: item.dial_code,
                                                                              flag: item.code.toLowerCase(),
                                                                              value: item.code,
                                                                              name: "cc",
                                                                            };
                                                                          }
                                                                          )
                                                                        }
                                                                        formatOptionLabel={(e) => {
                                                                          return (
                                                                            <div style={{ display: "block", alignItems: "center", }}>
                                                                              {e.flag ? (
                                                                                <img src={"../assets/images/flags/" + e.flag + ".svg"}
                                                                                  style={{ height: "20px", width: "25px", objectFit: "contain", }}
                                                                                  className="contain_image"
                                                                                />
                                                                              ) : ("")}
                                                                              <span style={{ marginLeft: 5, }}>
                                                                                {e.label}
                                                                              </span>
                                                                            </div>
                                                                          );
                                                                        }}
                                                                        styles={{
                                                                          menu: provided => ({ ...provided, zIndex: 9999, textAlign: 'left' })
                                                                        }}
                                                                      />
                                                                    </div>
                                                                    <div className="col-7">
                                                                      <div class="mb-3 input_design form-floating-phone-number">
                                                                        <input type="text" class="form-control " id="floatingInput"
                                                                          maxLength={10}
                                                                          value={status.value}
                                                                          pattern="[0-9]*" inputmode="numeric"
                                                                          name="socialmedialink"
                                                                          onChange={(e) => this.onChangeLink(e, app._id, "number")}
                                                                          placeholder={status.name}
                                                                          spellCheck="false"
                                                                        />
                                                                        <div
                                                                          className="invalid-feedback"
                                                                          style={{ display: this.state.phoneNoPatternArrayError ? "block" : "none", }} >
                                                                          Number should contain 10 digits
                                                                        </div>
                                                                        <div className="invalid-feedback" style={{ display: this.state.phoneRequired ? "block" : "none" }}>
                                                                          Number is required
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                          {status.inputtype == "email" && (
                                                            <>
                                                              {isClearEmail &&
                                                                <div className="clear_val" onClick={(e) => this.clearValFun(e, app._id, "email")}>
                                                                  <HiRefresh />
                                                                  <label class="form-check-label" for="exampleCheck1">Clear</label>
                                                                </div>
                                                              }
                                                              <div className="col-12">
                                                                <div className="form-group"
                                                                  style={{ marginTop: "10px", }}>
                                                                  <input type="text" class="form-control "
                                                                    spellCheck="false"
                                                                    value={status.value} name="socialmedialink"
                                                                    onChange={(e) => this.onChangeLink(e, app._id, "email")}
                                                                    placeholder={status.name} />
                                                                  <div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : "none", }} > Email is  not valid</div>
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                          {status.inputtype == "document" && (
                                                            <>
                                                              {status.name == "Others" && (
                                                                <div className="form-group" style={{ marginTop: "10px", }}>
                                                                  <input type="text" class="form-control " onChange={(e) => this.onChangeImageArray(e, app._id, "document")}
                                                                    value={status.othersdoc} name="others" placeholder={status.name} spellCheck="false" />
                                                                  <div className="invalid-feedback" style={{ display: this.state.othersError ? "block" : "none", }} >Others is required</div>
                                                                </div>
                                                              )}
                                                              <div className="col-12">
                                                                <div class="mt-3 input_design  skill_plus social_Select_Media">
                                                                  <div class="doc_img">
                                                                    <div className="document_upload">
                                                                      <div className="row">
                                                                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                                                                          <div className="document_img">
                                                                            {status.value != undefined && status.value ? (
                                                                              <>
                                                                                {typeof status.value == "string" ? (
                                                                                  <>
                                                                                    <a href={ImportedURL.FILEURL + status.value} target="_blank" style={{ color: "black", textDecoration: "none", objectFit: "contain", }}>
                                                                                      <img src={"../assets/images/all_images.jpg"} />
                                                                                    </a>
                                                                                  </>
                                                                                ) : (
                                                                                  <>
                                                                                    {status.previewtype == "image" && (
                                                                                      <img src={URL.createObjectURL(status.value)} />
                                                                                    )}
                                                                                    {status.previewtype == "pdf" && (

                                                                                      <a href={URL.createObjectURL(status.value)} target="_blank" style={{ color: "black", textDecoration: "none", }} >
                                                                                        <embed src={URL.createObjectURL(status.value)}></embed>
                                                                                      </a>
                                                                                    )}
                                                                                    {status.previewtype == "doc" && (
                                                                                      <a href={URL.createObjectURL(status.value)} target="_blank" style={{ color: "black", textDecoration: "none", objectFit: "contain", }} >
                                                                                        <img src={"../assets/images/doc_img.png"} />
                                                                                      </a>
                                                                                    )}
                                                                                  </>
                                                                                )}
                                                                              </>
                                                                            ) : (
                                                                              <>
                                                                                <img src="../assets/images/doucumet_dummy.png" />
                                                                              </>
                                                                            )}
                                                                          </div>
                                                                        </div>
                                                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                                          <div className="document_icon">
                                                                            <label for="file-input2" className="document_camera" name="circle_image" >
                                                                              <span style={{ color: "#fff", }}>
                                                                                <AiFillCamera
                                                                                  onChange={(e) => this.onChangeImageArray(e, app._id, "document")}
                                                                                  style={{ color: "#fff", }}
                                                                                />
                                                                              </span>
                                                                            </label>
                                                                            <input id="file-input2"
                                                                              accept="application/pdf,application/doc,application/docx,application/csv,application/msword,image/jpg, image/jpeg, image/png"
                                                                              type="file" style={{ display: "none", }} name="image" onChange={(e) => this.onChangeImageArray(e, app._id, "document")}
                                                                            />
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                          {status.inputtype == "none" && (
                                                            <>
                                                              <div className="col-12">
                                                                <div className="form-group" style={{ marginTop: "10px", }}>
                                                                  <input type="text" class="form-control " id="floatingInput" spellCheck="false" value={status.value} name="socialmedialink" onChange={(e) => this.onChangeLink(e, app._id, "none")} placeholder={status.name} />
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </div>

                                                    {status != undefined && status.inputtype == "address" &&
                                                      (
                                                        <div className="form-group">
                                                          <div className="profile_save_btn" style={{ marginTop: "10px", }}>
                                                            <button type="button" class="btn btn-primary " onClick={(e) => this.onClickSocialMediaAddress(app._id)} >
                                                              {modalType == 'edit' ? "Update" : "Add"}
                                                            </button>
                                                          </div>
                                                        </div>
                                                      )}
                                                    {status != undefined && status.inputtype == "document" &&
                                                      (
                                                        <div className="form-group">
                                                          <div className="profile_save_btn" style={{ marginTop: "25px", }}>
                                                            <button type="button" class="btn btn-primary " onClick={(e) => this.onClickIAddImageArray(app._id)}>
                                                              {modalType == 'edit' ? "Update" : "Add"}
                                                            </button>
                                                          </div>
                                                        </div>
                                                      )}
                                                    {status != undefined && status.inputtype == "none" &&
                                                      (
                                                        <div className="form-group">
                                                          <div
                                                            className="profile_save_btn"
                                                            style={{
                                                              marginTop: "10px",
                                                            }}
                                                          >
                                                            <button
                                                              type="button"
                                                              class="btn btn-primary "
                                                              onClick={(e) =>
                                                                this.onClickSocialMedia(
                                                                  app._id,
                                                                  "none"
                                                                )
                                                              }
                                                            >
                                                              {modalType == 'edit' ? "Update" : "Add"}
                                                            </button>
                                                          </div>
                                                        </div>
                                                      )}
                                                    {status != undefined && status.inputtype == "number" &&
                                                      (
                                                        <div className="form-group">
                                                          <div className="profile_save_btn" style={{ marginTop: "10px", }}>
                                                            <button type="button" class="btn btn-primary " onClick={(e) => this.onClickSocialMedia(app._id, "number")}>
                                                              {modalType == 'edit' ? "Update" : "Add"}
                                                            </button>
                                                          </div>
                                                        </div>
                                                      )}
                                                    {status != undefined && status.inputtype == "email" &&
                                                      (
                                                        <div className="form-group">
                                                          <div
                                                            className="profile_save_btn"
                                                            style={{
                                                              marginTop: "10px",
                                                            }}
                                                          >
                                                            <button
                                                              type="button"
                                                              class="btn btn-primary "
                                                              onClick={(e) =>
                                                                this.onClickSocialMedia(
                                                                  app._id,
                                                                  "email"
                                                                )
                                                              }
                                                            >
                                                              {modalType == 'edit' ? "Update" : "Add"}
                                                            </button>
                                                          </div>
                                                        </div>
                                                      )}
                                                    {status != undefined && status.inputtype == "url" &&
                                                      (
                                                        <div className="form-group">
                                                          <div
                                                            className="profile_save_btn"
                                                            style={{
                                                              marginTop: "10px",
                                                            }}
                                                          >
                                                            <button
                                                              type="button"
                                                              class="btn btn-primary "
                                                              onClick={(e) =>
                                                                this.onClickSocialMedia(
                                                                  app._id,
                                                                  "url"
                                                                )
                                                              }
                                                            >
                                                              {modalType == 'edit' ? "Update" : "Add"}
                                                            </button>
                                                          </div>
                                                        </div>
                                                      )}

                                                    <>
                                                      {
                                                        (carouselData && carouselData.length > 0)
                                                        &&
                                                        <>
                                                          {(carouselData.length == 1)
                                                            ?
                                                            <>
                                                              <div className="row">
                                                                <div className="col-6">
                                                                  <div className="px-2">
                                                                    <div className="carousel_list_data">
                                                                      <Row>
                                                                        <div className="col-9 pl-1 border_right" >
                                                                          <div className="pl-2">
                                                                            <div className="img_size">
                                                                              <img src={ImportedURL.LIVEURL + carouselData[0].logo} />
                                                                            </div>
                                                                            <span className="item_val">{carouselData[0].name}</span>
                                                                          </div>
                                                                        </div>
                                                                        <div className="col-3">
                                                                          {/* <p className="mb-1 view"><AiFillEye /></p> */}
                                                                          <p className="mb-3 edit"><BiEdit onClick={(e) => this.editSocailMedia(carouselData[0], carouselData[0].id, carouselData[0].inputtype)} /></p>
                                                                          <p className="mb-0 delete"><MdDeleteOutline onClick={(e) => this.removeSocailMedia(carouselData[0].index, carouselData[0].parentId)} /></p>
                                                                        </div>
                                                                      </Row>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </>
                                                            :
                                                            <>
                                                              <div className="carousel_sec">
                                                                <Carousel responsive={responsive}>
                                                                  {
                                                                    carouselData.map((item, i) => {
                                                                      return (
                                                                        <div className="px-2">
                                                                          <div className="carousel_list_data">
                                                                            <Row>
                                                                              <div className="col-9 pl-1 border_right" >
                                                                                <div className="pl-2">
                                                                                  <div className="img_size">
                                                                                    <img src={ImportedURL.LIVEURL + item.logo} />
                                                                                  </div>
                                                                                  <span className="item_val">{item.name}</span>
                                                                                </div>
                                                                              </div>
                                                                              <div className="col-3">
                                                                                <p className="mb-3 edit"><BiEdit onClick={(e) => this.editSocailMedia(item, item.id, item.inputtype)} /></p>
                                                                                <p className="mb-0 delete"><MdDeleteOutline onClick={(e) => this.removeSocailMedia(item.index, item.parentId)} /></p>
                                                                              </div>
                                                                            </Row>
                                                                          </div>
                                                                        </div>
                                                                      );
                                                                    }
                                                                    )}
                                                                </Carousel>
                                                              </div>
                                                            </>
                                                          }
                                                        </>
                                                      }
                                                    </>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div >
                                        </>
                                      );
                                    })}
                                </div>

                              </div>
                            </div>
                          </div>
                          {bData && bData.applink && bData.applink.length > 0 ?
                            <div class="modal-footer justify-content-center">
                              <div className="profile_save_btn my-0" style={{ marginTop: "35px" }}>
                                {!personalBtnChange ?
                                  <button type="button" class="btn btn-primary" onClick={(e) => this.businessSubmit(e, 'personal')} >
                                    Save
                                  </button>
                                  :
                                  <button type="button" class="btn btn-primary" >
                                    Saving...
                                  </button>
                                }
                              </div>
                              <button type="button" id="closePersonal" class="btn btn-secondary" data-bs-dismiss="modal" style={{ display: 'none' }}>Close</button>
                            </div>
                            :
                            <div class="modal-footer justify-content-center">
                              <div className="profile_save_btn my-0" style={{ marginTop: "35px" }}>
                                <button type="button" id="closePersonal" class="btn btn-secondary" data-bs-dismiss="modal" style={{ display: 'none' }}>Close</button>
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={(e) => this.setState({ collapsed: false })}>Close</button>
                              </div>
                            </div>
                          }
                        </div>
                        {spinnerAdd &&
                          <div className='common_loader_model'>
                            <img className='loader_img_style_common' src='/assets/images/logo.jpg' />
                            <Spinner className='spinner_load_common' animation="border" variant="info" >
                            </Spinner>
                          </div>}
                      </div>
                    </div>

                    {/* ============= Our Business ===================== */}
                    <div className="our_budiness_part">
                      <h5>Our Business</h5>
                      {listBusinessData && listBusinessData.length > 0
                        ? listBusinessData.map((business) => {
                          return (<div className="buss_btns">
                            <div className="img_size" onClick={() => this.businessEdit(business._id)} >
                              <img src={business.image ? ImportedURL.FILEURL + business.image : bnotfound} alt="" />
                            </div>
                            <div className="d-flex align-items-center content" onClick={() => this.businessEdit(business._id)} style={{ cursor: "pointer" }}>
                              <span>{business.work}</span>
                            </div>
                            <div className="d-flex">
                              <div className="profile_infosec d-flex align-items-center mt-0" style={{ boxShadow: "none" }} >
                                <label class="switch">
                                  <input type="checkbox" checked={business.status} name="ispersonaldetails" onClick={(e) => this.taggleStatusPersonal(e, business._id)} />
                                  <div class="slider round"></div>
                                </label>
                              </div>
                              <div className="delete_list_pro_012" onClick={(e) => this.deleteBusiness(business._id)}>
                                <MdDeleteOutline />
                              </div>
                            </div>
                          </div>
                          );
                        })
                        : []}
                      <div className="add_btn">
                        <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={this.openModelClick} >
                          Add
                        </button>
                      </div>
                    </div>
                    {/* =============== Business Model  */}
                    <div class={`modal fade business_model personal_details_model`} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" ref={this.containerRef}>
                      <div class="modal-dialog modal-dialog-scrollable" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel"> Add new business</h5>
                            <button type="button" id="closeModal" data-bs-dismiss={isCoseModel ? "modal" : ''} aria-label="Close" class="close" onClick={this.closeBusinessModel}  >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div className="">
                              <div className="business_infosec d-flex justify-content-between mb-4">
                                <span>Is a default business </span>
                                <label class="switch">
                                  <input type="checkbox" checked={bData.status} name="ispersonaldetails" onChange={this.businessStatusChange} />
                                  <div class="slider round"></div>
                                </label>
                              </div>
                              <div className="profile_images_box_shadow">
                                <div class="my-2 input_design">
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <label for="exampleFormControlInput1" class="form-label pt-0">
                                        Business logo
                                      </label><br></br>
                                      <label for="imageSet" className="businesslogo" name="circle_image" >
                                        <span><RiImageAddFill onChange={this.onChangeImage} /></span>
                                      </label>
                                      <input ref={this.fileInputBusinesssLogo} type="file" accept="image/jpg, image/jpeg, image/png" class="form-control" placeholder="Icon" name="businesslogo" id="imageSet" onChange={this.businessLogoChange} style={{ display: 'none' }} />
                                    </div>
                                    {bData.image ?
                                      <label for="imageSet">
                                        <div className="buss_img_size">
                                          <img src={(typeof bData.image == 'string') ? ImportedURL.FILEURL + bData.image : window.URL.createObjectURL(bData.image)} width="100px" alt='' />
                                        </div>
                                      </label>
                                      : ""}
                                  </div>
                                  <div class="invalid-feedback" style={{ display: this.state.bussinesslogoError ? "block" : "none", }} >
                                    Logo is required
                                  </div>
                                </div>

                                <div class="mb-2 input_design">
                                  <label for="exampleFormControlInput1" class="form-label">Designation<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                  <input type="text" class="form-control" name="designation" onChange={this.onChangeBusiness} value={bData.designation} id="floatingInput" placeholder="Designation" spellCheck="false" />
                                  <div class="invalid-feedback" style={{ display: this.state.designationError ? "block" : "none", }} >
                                    Designation is required
                                  </div>
                                </div>

                                <div class="mb-2 input_design">
                                  <label for="exampleFormControlInput1" class="form-label">Name of the company<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                  <input type="text" class="form-control" name="work" onChange={this.onChangeBusiness} value={bData.work} id="floatingInput" placeholder="Name of the company" spellCheck="false" />
                                  <div class="invalid-feedback" style={{ display: this.state.workError ? "block" : "none", }}>
                                    Name of the company is required
                                  </div>
                                </div>
                                <div id='accordionExample1' class="accordion">
                                  {listapp != undefined && listapp.length > 0 &&
                                    listapp.filter((e) => e.status == true).map((app, i) => {
                                      let carouselData = bData.applink.filter((e) => e.parentId == app._id)
                                      let { socialmediaValue } = this.state;
                                      let toggleStatus = bData.applinkstatus && bData.applinkstatus.length > 0 ? bData.applinkstatus.find((e) => e.appid == app._id) : "";
                                      let status = socialmediaValue && socialmediaValue.length > 0 ? socialmediaValue.reverse().find((e) => e.Uid == app._id) : "";
                                      let selectOption = [];
                                      if (app.typesList && app.typesList.length > 0) {
                                        selectOption.push({ label: "Deselect", value: "", });
                                        app.typesList.map((item) => {
                                          selectOption.push({ label: item.appname, value: item.appname, logo: item.logo, id: item.id, inputtype: item.inputtype, });
                                        });
                                      }

                                      if (status != undefined) {
                                        var countryVal = Country && Country.length > 0 && Country.find(e => e.name == status.country);
                                      }
                                      if (countryVal != undefined) {
                                        var stateVal = State && State.length > 0 && State.filter(e => e.countryCode == countryVal.isoCode)
                                      }
                                      if (status != undefined) {
                                        var stateFindVal = State && State.length > 0 && State.find(e =>
                                          e.name == status.state
                                        )
                                      }

                                      if (stateFindVal != undefined) {
                                        var cityVal = City && City.length > 0 && City.filter(e => e.stateCode == stateFindVal.isoCode)
                                      }
                                      //State Option
                                      const StateOption = [];
                                      if (stateVal && stateVal.length > 0) {
                                        stateVal.map(state => {
                                          StateOption.push({ value: state.name, label: state.name, name: 'state' })
                                        })
                                      }

                                      //City Option
                                      const CityOption = [];
                                      if (cityVal && cityVal.length > 0) {
                                        cityVal.map(city => {
                                          CityOption.push({ value: city.name, label: city.name, name: 'city' })
                                        })
                                      }

                                      let link = "";
                                      let valueData = "";
                                      if (status != undefined && status) {
                                        link = status.link;
                                        valueData = status.value;
                                      }

                                      let wwwCheck = link && link.includes('www.')

                                      return (
                                        <>
                                          <div class="accordion-item mt-3" key={i}>
                                            <h2 class="accordion-header" id={"headingSix" + (i + 10)} >
                                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseSix" + (i + 10)} aria-expanded="false" aria-controls="collapseSix" onClick={this.trigerModelAccord} >
                                                <label className="form-label mb-0 px-0 ml-0 mb-0"> {app.name}</label>
                                              </button>
                                            </h2>
                                            {this.state.collapsed && (
                                              <div id={"collapseSix" + (i + 10)} class="accordion-collapse collapse collapseSix" aria-labelledby={"headingSix" + (i + 10)} data-bs-parent="#accordionExample1">
                                                <div class="accordion-body" >
                                                  <div className="profile_images_box_shadow">
                                                    <div className="row social_media_field  social_media_field_insert">
                                                      <div className="col-12">
                                                        <div className="form-group">
                                                          <Select
                                                            onClick={this.heightPerpose}
                                                            value={status != undefined && status ? { label: status.name, logo: status.logo, } : ""}
                                                            options={selectOption}
                                                            onChange={(e) => this.socialmediaSelect(e, app._id)}
                                                            formatOptionLabel={(e) => (
                                                              <div style={{ display: "block", alignItems: "center", zIndex: 2, }}>
                                                                {e.logo ? (
                                                                  <img src={ImportedURL.LIVEURL + e.logo}
                                                                    style={{ height: "25px", width: "30px", objectFit: "contain", }}
                                                                    className="contain_image"
                                                                  />
                                                                ) : ("")}
                                                                <span style={{ marginLeft: 5, }}>{e.label}</span>
                                                              </div>
                                                            )}
                                                            styles={{
                                                              menu: provided => ({ ...provided, zIndex: 9999, textAlign: 'left' })
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                      {status != undefined && status && (
                                                        <>
                                                          {status.inputtype == "address" && (
                                                            <>
                                                              {isClear &&
                                                                <span className="clear_val" onClick={(e) => this.clearValFun(e, app._id, "address")}>
                                                                  <HiRefresh />
                                                                  <label class="form-check-label" for="exampleCheck1">Clear</label>
                                                                </span>
                                                              }
                                                              <Autocomplete
                                                                apiKey={apiKey}
                                                                onLoad={(autocomplete) => (this.autocomplete = autocomplete)}
                                                                onPlaceChanged={() => this.handlePlaceSelect(this.autocomplete.getPlace(), app._id)}
                                                              >
                                                                <div className="col-12">
                                                                  <div className="form-group px-1">
                                                                    <label for="exampleFormControlInput1" class="form-label" > Street</label>
                                                                    <input type="text" class="form-control" name="street" style={{ borderColor: streetBord ? 'red' : '' }}
                                                                      onChange={(e) => this.onChangeAddress(e, app._id, "address")} value={status.street ? status.street : ""} id="floatingInput" placeholder="Street" spellCheck="false" />
                                                                  </div>
                                                                </div>
                                                              </Autocomplete>

                                                              <div className="col-12">
                                                                <div className="form-group">
                                                                  <label for="exampleFormControlInput1" class="form-label"  >City</label>
                                                                  <input type="text" class="form-control" name="city" style={{ borderColor: cityBord ? 'red' : '' }}
                                                                    onChange={(e) => this.onChangeAddress(e, app._id, "address")}
                                                                    value={status.city ? status.city : ""} id="floatingInput" placeholder="City" spellCheck="false"
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div className="col-12">
                                                                <div className="form-group">
                                                                  <label for="exampleFormControlInput1" class="form-label" > State</label>
                                                                  <input type="text" class="form-control" onChange={(e) => this.onChangeAddress(e, app._id, "address")} name="state" value={status.state ? status.state : ""} id="floatingInput" placeholder="State" style={{ borderColor: stateBord ? 'red' : '' }} spellCheck="false" />
                                                                </div>
                                                              </div>

                                                              <div className="col-12">
                                                                <div className="form-group">
                                                                  <label for="exampleFormControlInput1" class="form-label">
                                                                    Country
                                                                  </label>
                                                                  <input type="text" class="form-control" name="country"
                                                                    onChange={(e) => this.onChangeAddress(e, app._id, "address")} style={{ borderColor: countryBord ? 'red' : '' }}
                                                                    value={status.country ? status.country : ""} id="floatingInput" placeholder="Country"
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div className="col-12">
                                                                <div className="form-group">
                                                                  <label for="exampleFormControlInput1" class="form-label">ZIP Code </label>
                                                                  <input type="text" class="form-control" name="zip" spellCheck="false"
                                                                    onChange={(e) => this.onChangeAddress(e, app._id, "address")} style={{ borderColor: zipBord ? 'red' : '' }}
                                                                    value={status.zip ? status.zip : ""} id="floatingInput" placeholder="ZIP Code"
                                                                  />
                                                                </div>
                                                              </div>

                                                            </>
                                                          )}
                                                          {status.inputtype == "url" && (
                                                            <>
                                                              {isClearUrl &&
                                                                <div className="clear_val" onClick={(e) => this.clearValFun(e, app._id, "url")}>
                                                                  <HiRefresh />
                                                                  <label class="form-check-label" for="exampleCheck1">Clear</label>
                                                                </div>}
                                                              <div className="col-12">
                                                                <div className="form-group" style={{ marginTop: "10px", position: 'relative' }} >
                                                                  <input type="text" class="form-control " id="floatingInput" style={{ paddingLeft: '60px' }}
                                                                    value={link} name="socialmedialink"
                                                                    onChange={(e) => this.onChangeLink(e, app._id, "url")}
                                                                    placeholder={status.name} spellCheck="false"
                                                                  />

                                                                  {!isWwwShow &&
                                                                    <span className="www_012x3">www.</span>
                                                                  }
                                                                  <div className="invalid-feedback" style={{ display: this.state.urlError ? "block" : "none", }}> URL is required</div>
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                          {status.inputtype == "number" && (
                                                            <>
                                                              {isClearNumber &&
                                                                <div className="clear_val" onClick={(e) => this.clearValFun(e, app._id, "number")}>
                                                                  <HiRefresh />
                                                                  <label class="form-check-label" for="exampleCheck1">Clear</label>
                                                                </div>}
                                                              <div className="col-12">
                                                                <div className="form-group" style={{ marginTop: "10px", }} >
                                                                  <div className="row">
                                                                    <div className="col-5 ">
                                                                      <Select
                                                                        value={status.cc && status.cc.value != undefined ? { label: status.cc.value, value: status.cc.name, flag: status.cc.name.toLowerCase(), } : ""}
                                                                        onChange={(e) => this.onChangeLinkCC(e, app._id, "cc")}
                                                                        options={CountryCodeJson &&
                                                                          CountryCodeJson.map((item) => {
                                                                            return {
                                                                              label: item.dial_code,
                                                                              flag: item.code.toLowerCase(),
                                                                              value: item.code,
                                                                              name: "cc",
                                                                            };
                                                                          }
                                                                          )
                                                                        }
                                                                        formatOptionLabel={(e) => {
                                                                          return (
                                                                            <div style={{ display: "block", alignItems: "center", }}>
                                                                              {e.flag ? (
                                                                                <img src={"../assets/images/flags/" + e.flag + ".svg"}
                                                                                  style={{ height: "20px", width: "25px", objectFit: "contain", }}
                                                                                  className="contain_image"
                                                                                />
                                                                              ) : ("")}
                                                                              <span style={{ marginLeft: 5, }}>
                                                                                {e.label}
                                                                              </span>
                                                                            </div>
                                                                          );
                                                                        }}
                                                                        styles={{
                                                                          menu: provided => ({ ...provided, zIndex: 9999, textAlign: 'left' })
                                                                        }}
                                                                      />
                                                                    </div>
                                                                    <div className="col-7">
                                                                      <div class="mb-3 input_design form-floating-phone-number">
                                                                        <input type="text" class="form-control " id="floatingInput"
                                                                          maxLength={10}
                                                                          value={status.value}
                                                                          pattern="[0-9]*" inputmode="numeric"
                                                                          name="socialmedialink"
                                                                          onChange={(e) => this.onChangeLink(e, app._id, "number")}
                                                                          placeholder={status.name}
                                                                          spellCheck="false"
                                                                        />
                                                                        <div
                                                                          className="invalid-feedback"
                                                                          style={{ display: this.state.phoneNoPatternArrayError ? "block" : "none", }} >
                                                                          Number should contain 10 digits
                                                                        </div>
                                                                        <div className="invalid-feedback" style={{ display: this.state.phoneRequired ? "block" : "none" }}>
                                                                          Number is required
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                          {status.inputtype == "email" && (
                                                            <>
                                                              {isClearEmail &&
                                                                <div className="clear_val" onClick={(e) => this.clearValFun(e, app._id, "email")}>
                                                                  <HiRefresh />
                                                                  <label class="form-check-label" for="exampleCheck1">Clear</label>
                                                                </div>}
                                                              <div className="col-12">
                                                                <div className="form-group"
                                                                  style={{ marginTop: "10px", }}>
                                                                  <input type="text" class="form-control "
                                                                    value={status.value} name="socialmedialink"
                                                                    onChange={(e) => this.onChangeLink(e, app._id, "email")}
                                                                    placeholder={status.name} spellCheck="false" />
                                                                  <div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : "none", }} > Email is  not valid</div>
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                          {status.inputtype == "document" && (
                                                            <>
                                                              {status.name == "Others" && (
                                                                <div className="form-group" style={{ marginTop: "10px", }}>
                                                                  <input type="text" class="form-control " onChange={(e) => this.onChangeImageArray(e, app._id, "document")}
                                                                    value={status.othersdoc} name="others" placeholder={status.name} spellCheck="false" />
                                                                  <div className="invalid-feedback" style={{ display: this.state.othersError ? "block" : "none", }} >Others is required</div>
                                                                </div>
                                                              )}
                                                              <div className="col-12">
                                                                <div class="mt-3 input_design  skill_plus social_Select_Media">
                                                                  <div class="doc_img">
                                                                    <div className="document_upload">
                                                                      <div className="row">
                                                                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                                                                          <div className="document_img">
                                                                            {status.value != undefined && status.value ? (
                                                                              <>
                                                                                {typeof status.value == "string" ? (
                                                                                  <>
                                                                                    <a href={ImportedURL.FILEURL + status.value} target="_blank" style={{ color: "black", textDecoration: "none", objectFit: "contain", }}>
                                                                                      <img src={"../assets/images/all_images.jpg"} />
                                                                                    </a>
                                                                                  </>
                                                                                ) : (
                                                                                  <>
                                                                                    {status.previewtype == "image" && (
                                                                                      <img src={URL.createObjectURL(status.value)} />
                                                                                    )}
                                                                                    {status.previewtype == "pdf" && (

                                                                                      <a href={URL.createObjectURL(status.value)} target="_blank" style={{ color: "black", textDecoration: "none", }} >
                                                                                        <embed src={URL.createObjectURL(status.value)}></embed>
                                                                                      </a>
                                                                                    )}
                                                                                    {status.previewtype == "doc" && (
                                                                                      <a href={URL.createObjectURL(status.value)} target="_blank" style={{ color: "black", textDecoration: "none", objectFit: "contain", }} >
                                                                                        <img src={"../assets/images/doc_img.png"} />
                                                                                      </a>
                                                                                    )}
                                                                                  </>
                                                                                )}
                                                                              </>
                                                                            ) : (
                                                                              <>
                                                                                <img src="../assets/images/doucumet_dummy.png" />
                                                                              </>
                                                                            )}
                                                                          </div>
                                                                        </div>
                                                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                                          <div className="document_icon">
                                                                            <label for="file-input2" className="document_camera" name="circle_image" >
                                                                              <span style={{ color: "#fff", }}>
                                                                                <AiFillCamera
                                                                                  onChange={(e) => this.onChangeImageArray(e, app._id, "document")}
                                                                                  style={{ color: "#fff", }}
                                                                                />
                                                                              </span>
                                                                            </label>
                                                                            <input id="file-input2"
                                                                              accept="application/pdf,application/doc,application/docx,application/csv,application/msword,image/jpg, image/jpeg, image/png"
                                                                              type="file" style={{ display: "none", }} name="docimage" onChange={(e) => this.onChangeImageArray(e, app._id, "document")}
                                                                            />
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                          {status.inputtype == "none" && (
                                                            <>
                                                              <div className="col-12">
                                                                <div className="form-group" style={{ marginTop: "10px", }}>
                                                                  <input type="text" class="form-control " id="floatingInput" value={status.value} name="socialmedialink" onChange={(e) => this.onChangeLink(e, app._id, "none")} placeholder={status.name} spellCheck="false" />
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </div>

                                                    {status != undefined && status.inputtype == "address" &&
                                                      (
                                                        <div className="form-group">
                                                          <div className="profile_save_btn" style={{ marginTop: "10px", }}>
                                                            <button type="button" class="btn btn-primary " onClick={(e) => this.onClickSocialMediaAddress(app._id)} >
                                                              {modalType == 'edit' ? "Update" : "Add"}
                                                            </button>
                                                          </div>
                                                        </div>
                                                      )}
                                                    {status != undefined && status.inputtype == "document" &&
                                                      (
                                                        <div className="form-group">
                                                          <div className="profile_save_btn" style={{ marginTop: "25px", }}>
                                                            <button type="button" class="btn btn-primary " onClick={(e) => this.onClickIAddImageArray(app._id)}>
                                                              {modalType == 'edit' ? "Update" : "Add"}
                                                            </button>
                                                          </div>
                                                        </div>
                                                      )}
                                                    {status != undefined && status.inputtype == "none" &&
                                                      (
                                                        <div className="form-group">
                                                          <div
                                                            className="profile_save_btn"
                                                            style={{
                                                              marginTop: "10px",
                                                            }}
                                                          >
                                                            <button
                                                              type="button"
                                                              class="btn btn-primary "
                                                              onClick={(e) =>
                                                                this.onClickSocialMedia(
                                                                  app._id,
                                                                  "none"
                                                                )
                                                              }
                                                            >
                                                              {modalType == 'edit' ? "Update" : "Add"}
                                                            </button>
                                                          </div>
                                                        </div>
                                                      )}
                                                    {status != undefined && status.inputtype == "number" &&
                                                      (
                                                        <div className="form-group">
                                                          <div className="profile_save_btn" style={{ marginTop: "10px", }}>
                                                            <button type="button" class="btn btn-primary " onClick={(e) => this.onClickSocialMedia(app._id, "number")}>
                                                              {modalType == 'edit' ? "Update" : "Add"}
                                                            </button>
                                                          </div>
                                                        </div>
                                                      )}
                                                    {status != undefined && status.inputtype == "email" &&
                                                      (
                                                        <div className="form-group">
                                                          <div
                                                            className="profile_save_btn"
                                                            style={{
                                                              marginTop: "10px",
                                                            }}
                                                          >
                                                            <button
                                                              type="button"
                                                              class="btn btn-primary "
                                                              onClick={(e) =>
                                                                this.onClickSocialMedia(
                                                                  app._id,
                                                                  "email"
                                                                )
                                                              }
                                                            >
                                                              {modalType == 'edit' ? "Update" : "Add"}
                                                            </button>
                                                          </div>
                                                        </div>
                                                      )}
                                                    {status != undefined && status.inputtype == "url" &&
                                                      (
                                                        <div className="form-group">
                                                          <div
                                                            className="profile_save_btn"
                                                            style={{
                                                              marginTop: "10px",
                                                            }}
                                                          >
                                                            <button
                                                              type="button"
                                                              class="btn btn-primary "
                                                              onClick={(e) =>
                                                                this.onClickSocialMedia(
                                                                  app._id,
                                                                  "url"
                                                                )
                                                              }
                                                            >
                                                              {modalType == 'edit' ? "Update" : "Add"}
                                                            </button>
                                                          </div>
                                                        </div>
                                                      )}

                                                    <>
                                                      {
                                                        (carouselData && carouselData.length > 0)
                                                        &&
                                                        <>
                                                          {(carouselData.length == 1)
                                                            ?
                                                            <>
                                                              <div className="row">
                                                                <div className="col-6">
                                                                  <div className="px-2">
                                                                    <div className="carousel_list_data">
                                                                      <Row>
                                                                        <div className="col-9 pl-1 border_right" >
                                                                          <div className="pl-2">
                                                                            <div className="img_size">
                                                                              <img src={ImportedURL.LIVEURL + carouselData[0].logo} />
                                                                            </div>
                                                                            <span className="item_val">{carouselData[0].name}</span>
                                                                          </div>
                                                                        </div>
                                                                        <div className="col-3">
                                                                          {/* <p className="mb-1 view"><AiFillEye /></p> */}
                                                                          <p className="mb-3 edit"><BiEdit onClick={(e) => this.editSocailMedia(carouselData[0], carouselData[0].id, carouselData[0].inputtype)} /></p>
                                                                          <p className="mb-0 delete"><MdDeleteOutline onClick={(e) => this.removeSocailMedia(carouselData[0].index, carouselData[0].parentId)} /></p>
                                                                        </div>
                                                                      </Row>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </>
                                                            :
                                                            <>
                                                              <div className="carousel_sec">
                                                                <Carousel responsive={responsive} ref={this.carouselRef}>
                                                                  {
                                                                    carouselData.map((item, i) => {
                                                                      return (
                                                                        <div className="px-2" key={i}>
                                                                          <div className="carousel_list_data">
                                                                            <Row>
                                                                              <div className="col-9 pl-1 border_right" >
                                                                                <div className="pl-2">
                                                                                  <div className="img_size">
                                                                                    <img src={ImportedURL.LIVEURL + item.logo} />
                                                                                  </div>
                                                                                  <span className="item_val">{item.name}</span>
                                                                                </div>
                                                                              </div>
                                                                              <div className="col-3">
                                                                                {/* <p className="mb-1 view"><AiFillEye /></p> */}
                                                                                <p className="mb-3 edit"><BiEdit onClick={(e) => this.editSocailMedia(item, item.id, item.inputtype)} /></p>
                                                                                <p className="mb-0 delete"><MdDeleteOutline onClick={(e) => this.removeSocailMedia(item.index, item.parentId, carouselData && carouselData.length)} /></p>
                                                                              </div>
                                                                            </Row>
                                                                          </div>
                                                                        </div>
                                                                      );
                                                                    }
                                                                    )}
                                                                </Carousel>
                                                              </div>
                                                            </>
                                                          }
                                                        </>
                                                      }

                                                    </>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer justify-content-center">
                            <div className="profile_save_btn my-0" style={{ marginTop: "35px" }}>
                              {!businessBtnChange ?
                                <button type="button" class="btn btn-primary" onClick={this.businessSubmit} >
                                  Save
                                </button>
                                :
                                <button type="button" class="btn btn-primary">
                                  Saving...
                                </button>
                              }
                            </div>
                          </div>
                        </div>
                        {spinnerAdd &&
                          <div className='common_loader_model'>
                            <img className='loader_img_style_common' src='/assets/images/logo.jpg' />
                            <Spinner className='spinner_load_common' animation="border" variant="info" >
                            </Spinner>
                          </div>}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="crop_model_section">
                <Modal className={"info-modal crop_modal"} show={this.state.showModal} style={{ display: this.state.showModal ? 'block' : 'none' }}>
                  <div className="modal-header">
                    <Modal.Title>{"Crop Image"}</Modal.Title>
                    <div>
                      <button type="button" id="closeModal" onClick={this.ModalImages} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" style={{ fontSize: "23px" }}>
                          <img src="../../assets/images/cancel.png" />
                        </span>
                      </button>
                      <button type="button" className="close" onClick={this.CropImages}>
                        <span aria-hidden="true" style={{ fontSize: "23px" }}>
                          <img src="../../assets/images/tick2.png" />
                        </span>
                      </button>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div class="crop_Images">
                      {this.state.src && (
                        <ReactCrop
                          src={this.state.src}
                          crop={this.state.crop}
                          onImageLoaded={this.onImageLoaded}
                          onComplete={this.onCropComplete}
                          onChange={this.onCropChange}
                        />
                      )}
                    </div>
                  </div>
                </Modal>
              </div>
            </Container>
          </div>

          {spinner || this.state.updateSpinner ? (
            <div style={{ position: 'relative', zIndex: '2' }}>
              <div
                style={{
                  height: "100vh",
                  position: "fixed",
                  width: "100%",
                  top: "0px",
                }}
              >
                <div style={{ position: "relative" }}></div>
                <div className="common_loader">
                  <img
                    className="loader_img_style_common"
                    src="/assets/images/logo.jpg"
                  />
                  <Spinner
                    className="spinner_load_common"
                    animation="border"
                    variant="info"
                  ></Spinner>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div >
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ProfileState: state.profile,
  BusinessState: state.business,
  appState: state.app,
  bloodgroupState: state.bloodgroup,
  educationState: state.education,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      HandleInputChange: AC_HANDLE_INPUT_CHANGE_PROFILE,
      HandleInputBusinessChange: AC_HANDLE_INPUT_CHANGE_BUSINESS,
      ListBusiness: AC_LIST_BUSINESSESS,
      ListApp: AC_LIST_APP,
      ViewProfile: AC_VIEW_PROFILE,
      SpinnerProfile: AC_PROFILE_SPINNER,
      HandleChangesSetting: AC_HANDLE_INPUT_CHANGE_SETTINGS,
      EmptyBusiness: AC_EMPTY_BUSINESS,
      // === PERSONAL
      ListPersonal: AC_LIST_PERSONAL,
      ResetBasicDetails: AC_RESET_BASIC_DETAILS,
      RunSpinnerAdd: AC_RUN_SPINNER_ADD,
      ListBloodgroup: AC_LIST_BLOODGROUP,
      ListEducation: AC_LIST_EDUCATION,

    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Profile);




// Full Name Click Fun
// fullNameClick = (e) => {
//   const { isHeadlineSaveShow, isUserdDisplayNameSaveShow } = this.state;
//   this.setState({ isUserdDisplayNameSaveShow: false });
//   const data = this.props.ProfileState.profile;
//   let formData = {};

//   formData["displayname"] = data.displayname;
//   axios
//     .post(ImportedURL.API.updateProfileNameHeadline, formData)
//     .then((res) => {
//       this.setState({ updateSpinner: false });
//       // this.props.ViewProfile({ reverseStatus: true });
//       Success("Saved");
//     })
//     .catch(({ response }) => {
//       this.setState({ updateSpinner: false });
//       if (response) {
//         if (response.status == 401) {
//           Error("Something wrong, Retry again!");
//         } else if (response.status == 510) {
//           Error("Email does not exit");
//         } else if (response.status == 502) {
//           Error(response.status + " Bad Gateway");
//         } else if (response.status == 500) {
//           Error("Internal Server Error");
//         } else if (response.status == 409) {
//           Error("Already exist");
//         } else if (response.status == 400) {
//           Error("Bad request");
//         }
//       }
//     });
// };

// Head Line Click Fun
// headlineClick = (e) => {
//   this.setState({ isUserdHeadlineSaveShow: false });
//   const data = this.props.ProfileState.profile;
//   let formData = {};
//   formData["headline"] = data.headline;
//   axios
//     .post(ImportedURL.API.updateProfileNameHeadline, formData)
//     .then((res) => {
//       this.setState({ updateSpinner: false });
//       // this.props.ViewProfile({ reverseStatus: true });
//       Success("Saved");
//     })
//     .catch(({ response }) => {
//       this.setState({ updateSpinner: false });
//       if (response) {
//         if (response.status == 401) {
//           Error("Something wrong, Retry again!");
//         } else if (response.status == 510) {
//           Error("Email does not exit");
//         } else if (response.status == 502) {
//           Error(response.status + " Bad Gateway");
//         } else if (response.status == 500) {
//           Error("Internal Server Error");
//         } else if (response.status == 409) {
//           Error("Already exist");
//         } else if (response.status == 400) {
//           Error("Bad request");
//         }
//       }
//     });
// };

//Submit App Function
// submitApp = (e) => {
//   const { busPhoneNoPatternError, phoneNoPatternError } = this.state;
//   const { ProfileState } = this.props;
//   const data = ProfileState.profile;

//   let listAppLink = [...e];
//   let valid = true;
//   if (busPhoneNoPatternError) {
//     valid = false;
//   }
//   if (phoneNoPatternError) {
//     valid = false;
//   }
//   if (valid) {
//     this.setState({ updateSpinner: true });
//     const formDataS3 = new FormData();
//     let othersArr = [];
//     let documentArr = [];
//     let existImageArr = [];
//     let imagesDataArr = [];
//     if (listAppLink.length > 0) {
//       for (let i = 0; i < listAppLink.length; i++) {
//         if (listAppLink[i].inputtype != "document") {
//           othersArr.push(listAppLink[i]);
//         }
//       }
//       for (let i = 0; i < listAppLink.length; i++) {
//         if (listAppLink[i].inputtype == "document") {
//           documentArr.push(listAppLink[i]);
//         }
//       }
//     }
//     if (documentArr.length > 0) {
//       for (let i = 0; i < documentArr.length; i++) {
//         if (typeof documentArr[i].value == "string") {
//           existImageArr.push(documentArr[i]);
//         } else {
//           formDataS3.append("documents", documentArr[i].value);
//           imagesDataArr.push(documentArr[i]);
//         }
//       }
//     }
//     formDataS3.append("othersArr", JSON.stringify(othersArr));
//     formDataS3.append("existImageArr", JSON.stringify(existImageArr));
//     formDataS3.append("imagesDataArr", JSON.stringify(imagesDataArr));
//     axios
//       .post(ImportedURL.API.updateProfileAppLinks, formDataS3)
//       .then((res) => {
//         this.props.ViewProfile({ reverseStatus: true });
//         this.setState({ updateSpinner: false });
//         Success("Saved");
//       })
//       .catch(({ response }) => {
//         this.setState({ updateSpinner: false });
//         if (response) {
//           if (response.status == 401) {
//             Error("Something wrong, Retry again!");
//           } else if (response.status == 510) {
//             Error("Email does not exit");
//           } else if (response.status == 502) {
//             Error(response.status + " Bad Gateway");
//           } else if (response.status == 500) {
//             Error("Internal Server Error");
//           } else if (response.status == 409) {
//             Error("Already exist");
//           } else if (response.status == 400) {
//             Error("Bad request");
//           }
//         }
//       });
//   }
// };