const initialState = {
    bussiness: {
        image: '',
        banner: '',
        workcompany: '',
        worktitle: '',
        applink: [],
        applinkstatus: [],
        iswork: false,
        issociallinks: false,
        status: true,
        modelData: [],
        businesscc: {
            name: "US",
            value: "+1",
        },
    },
    listBusiness: [],
    listPersonal: [],
    listPersonalUnique: [],
    listBusinessUnique: [],
    socailmedialist: [],
    spinner: true,
    spinnerAdd: false,
    spinnerEdit: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_INPUT_CHANGE_BUSINESS':
            return Object.assign({}, state, {
                bussiness: {
                    ...state.bussiness,
                    [action.name]: action.value
                }
            })
        case 'LIST_BUSINESS':
            return {
                ...state,
                listBusiness: action.payload,
                spinner: action.spinner,
                spinnerAdd: action.spinner
            }
        case 'LIST_BUSINESS_UNIQUE':
            return {
                ...state,
                listBusinessUnique: action.payload,
                spinner: action.spinner
            }

        case "VIEW_BUSINESSESS":
            return Object.assign({}, state, {
                bussiness: action.payload,
                socailmedialist: action.socialmedia,
                spinner: action.spinner,
                spinnerEdit: action.spinner
            });
        case 'LIST_PERSONAL_UNIQUE':
            return {
                ...state,
                listPersonalUnique: action.payload,
                spinner: action.spinner
            }
        case 'LIST_PERSONAL':
            return {
                ...state,
                listPersonal: action.payload,
                spinner: action.spinner,
                spinnerAdd: action.spinner
            }
        case "VIEW_PERSONAL":
            return Object.assign({}, state, {
                bussiness: action.payload,
                socailmedialist: action.socialmedia,
                spinner: action.spinner,
                spinnerEdit: action.spinner
            });
        case "RUN_SPINNER_EDIT":
            return Object.assign({}, state, {
                spinnerEdit: true
            });
        case "RUN_SPINNER_ADD":
            return Object.assign({}, state, {
                spinnerAdd: true
            });
        // case 'HANDLE_INPUT_CHANGE_SETTINGS':
        //     return Object.assign({}, state, {
        //         settingOption: action.value
        //     })
        // case 'HANDLE_INPUT_CHANGE_BROCHURE':
        //     return Object.assign({}, state, {
        //         brochure: action.value
        //     })

        // case "VIEW_PROFILE_UNIQUEID":
        //     return Object.assign({}, state, {
        //         bussiness: action.payload,
        //         spinner: false
        //     });
        // case "VIEW_PROFILE_USERNAME":
        //     return Object.assign({}, state, {
        //         bussiness: action.payload,
        //         spinner: false
        //     });
        // case "VIEW_USER":
        //     return Object.assign({}, state, {
        //         bussiness: action.payload,
        //         spinner: false
        //     });
        // case "DRAG_SOCIALMEDIA_LIST":
        //     return Object.assign({}, state, {
        //         socailmedialist: action.payload,
        //         spinner: false
        //     });

        // case 'USER_SPINNER':
        //     return Object.assign({}, state, {
        //         spinner: true
        //     })
        // case 'PROFILE_SPINNER':
        //     return Object.assign({}, state, {
        //         spinner: true
        //     })
        case 'EMPTY_BUSINESS':
            return Object.assign({}, state, {
                bussiness: {
                    designation: '',
                    businessphonenumber: '',
                    work: '',
                    image: '',
                    banner: '',
                    workcompany: '',
                    worktitle: '',
                    applink: [],
                    iswork: false,
                    issociallinks: false,
                    status: true,
                    modelData: [],
                    businesscc: {
                        name: "US",
                        value: "+1",
                    },
                },
                spinner: false
            })

        default:
            return state;
    }
}