// "use client";
import axios from "axios";
import React, { useState } from "react";
import { Row, Tab, Tabs, Col, Container, Button } from 'react-bootstrap';
import { BiDollar } from 'react-icons/bi';
import { Success } from "../../../common/swal";
import ImportedURL from "../../../common/api";
import { Emailvalidate, Phonenumber } from "../../../common/validate";
// import Image from '../../../acwcard_landing/'

export default function Blackmetal({ targetDivRef }) {

    const [data, setData] = useState({});
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phonenumberError, setPhonenumberError] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [designationError, setDesignationError] = useState(false);
    const [bookingtype, setBookingtype] = useState(true);
    const [emailValidError, setEmailValidError] = useState(false);
    const [phonenumberValidError, setPhonenumberValidError] = useState(false);
    const [backStatus, setBackStatus] = useState(true);

    const onChange = (e) => {
        const { name, value } = (e.target != undefined && e.target) ? e.target : e;
        SaveData(name, value);
        if (name == 'name') {
            setNameError(false);
        }
        if (name == 'email') {
            if (value) setEmailValidError(!Emailvalidate(value)); else setEmailValidError(false);
            setEmailError(false);
        }
        if (name == 'phonenumber') {
            setPhonenumberError(false);
        }
        if (name == 'company') {
            setCompanyError(false);
        }
        if (name == 'designation') {
            setDesignationError(false);
        }
    };

    const SaveData = (name, value) => {
        if (name == 'phonenumber') {
            let s1 = value.replace(/[^a-z\d\s]+/gi, "");
            let s2 = s1.replace(/[- )(]/g, '')
            var s3 = s2.slice(0, 10);
            var val = s3.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
            if (val) setPhonenumberValidError(!Phonenumber(val)); else setPhonenumberValidError(false);
            const getData = {
                ...data,
                [name]: val
            }
            setData(getData);
        } else {
            const getData = {
                ...data,
                [name]: value
            }
            setData(getData);
        }
    }

    const submit = (e) => {
        const newData = { ...data };
        for (let key in newData) {
            if (newData.hasOwnProperty(key) && typeof newData[key] === 'string') {
                newData[key] = newData[key].trim();
            }
        }
        let valid = 1;
        if (!newData.name) {
            valid = 0;
            setNameError(true);
        }
        if (!newData.email) {
            valid = 0;
            setEmailError(true);
        }
        if (!newData.phonenumber) {
            valid = 0;
            setPhonenumberError(true);
        }
        if (!newData.company) {
            valid = 0;
            setCompanyError(true);
        }
        if (!newData.designation) {
            valid = 0;
            setDesignationError(true);
        }
        if (emailValidError) {
            valid = 0;
        }
        if (phonenumberValidError) {
            valid = 0;
        }
        if (valid) {
            newData['type'] = 'Enquiry'
            newData['bookingtype'] = bookingtype ? 'Individual' : 'Team'
            axios.post(ImportedURL.API.addLandingPageEnquiry, newData)
                .then((res) => {
                    setBackStatus(false);
                    reset();
                }).catch((err) => {
                    console.log(err)
                });
        }
    }
    const reset = () => {
        setData({ name: '', email: '', phonenumber: '', company: '', designation: '' });
        setNameError(false);
        setEmailError(false);
        setPhonenumberError(false);
        setCompanyError(false);
        setDesignationError(false);
        setEmailValidError(false);
        setPhonenumberValidError(false);
    }
    const onChangeTab = () => {
        setBookingtype(!bookingtype)
        reset();
        setBackStatus(true);
    }


    return (
        <>
            <div className="black_metal_section" ref={targetDivRef} id='enquiry-form-data'>
                <Container className='container-new-big'>
                    <Tabs
                        defaultActiveKey="Metalme"
                        id="fill-tab-example"
                        className="mb-3"
                        fill
                        onClick={onChangeTab}
                    >
                        <Tab eventKey="Metalme" title="For Individual" >
                            <div className="for_me">
                                <Row>
                                    <Col lg={6}>
                                        <div className="blac_metal">
                                            <div className="black_meatl_first">
                                                <div className="card_img_left">
                                                    <img src="../../../landing_page/assets/images/card/digitalcard09.png" alt="card-img" />
                                                </div>
                                                <div className="black-metal-professional">
                                                    <h5 className="black-metal-txt">Black<br />
                                                        ACW Card</h5>
                                                    <h6 className="black-metal-txt2">Card Type : PVC Glossy</h6>
                                                    <p className="black-metal-para">Get your card customized according to your needs </p>
                                                    <span className="all-tax">Inclusive of all taxes</span>
                                                </div>
                                            </div>
                                            <div className="under_black_metal mt-3">
                                                <div className="left_under_black">
                                                    <img src="../../../landing_page/assets/images/card/digitaliacard.png" alt="card-img" />
                                                </div>
                                                <div className="left_under_black enquiry_image_right">
                                                    <img src=".../../../landing_page/assets/images/card/black-metal.png" alt="card-img" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    {
                                        backStatus
                                            ?
                                            <>
                                                <Col lg={6}>
                                                    <div className="card-detail">
                                                        <p className="printed-para">Please Enter The Details To Be Printed On The Card.</p>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <div className="input-group mb-1 input-contact_modal">
                                                                    <input id='enquiryname' type="text" value={data.name} onChange={onChange} name='name' className={"form-control " + (nameError ? 'red_bdr' : '')} placeholder="Full Name*" aria-label="Username" aria-describedby="basic-addon1" />
                                                                </div>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <div className={"input-group input-contact_modal " + (!emailValidError && 'mb-1')}>
                                                                    <input id='enquiryemail' type="text" style={emailValidError ? { margin: '0px' } : {}} value={data.email} onChange={onChange} name='email' className={"form-control " + ((emailError || emailValidError) ? 'red_bdr' : '')} placeholder="Email*" aria-label="Username" aria-describedby="basic-addon1" />
                                                                </div>
                                                                {emailValidError ? <span className='error-message'>Enter valid email</span> : ''}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <div className={"input-group input-contact_modal " + (!phonenumberValidError ? 'mb-1' : '')}>
                                                                    <input id='enquiryphonenumber' type="text" style={phonenumberValidError ? { margin: '0px' } : {}} value={data.phonenumber} onChange={onChange} name='phonenumber' className={"form-control " + ((phonenumberError) ? 'red_bdr' : '') + ((phonenumberValidError) ? 'red_bdr' : '')} placeholder="Enter Your Phone Number*" aria-label="Username" aria-describedby="basic-addon1" />
                                                                </div>
                                                                {phonenumberValidError ? <span className='error-message'>Phone Number should contain 10 digits</span> : ''}
                                                            </Col>
                                                            <Col lg={6}>
                                                                <div className="input-group mb-1 input-contact_modal">
                                                                    <input id='enquirycompany' type="text" value={data.company} onChange={onChange} name='company' className={"form-control " + (companyError ? 'red_bdr' : '')} placeholder="Company Name*" aria-label="Username" aria-describedby="basic-addon1" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <div className="input-group mb-1 input-contact_modal">
                                                                    <input id='enquirydesignation' type="text" value={data.designation} onChange={onChange} name='designation' className={"form-control " + (designationError ? 'red_bdr' : '')} placeholder="Your Designation*" aria-label="Username" aria-describedby="basic-addon1" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <div className="buy_now_btbn">
                                                            <Button id="buy-now" onClick={submit}>Enquiry</Button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </>
                                            :
                                            <>
                                                <div className="col-lg-6 d-flex justify-content-center align-items-center">
                                                    <div className="form-group">
                                                        <p style={{ color: '#2f2f2f', fontWeight: '600', fontSize: '35px', textAlign: 'center' }}>Thank you for your submission!</p>
                                                        <div className="buy_now_btbn" style={{ textAlign: 'center' }}>
                                                            <Button id="buy-now" onClick={(e) => setBackStatus(true)}>Back</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </Row>
                            </div>
                        </Tab>
                        <Tab eventKey="profPVCileteam" title="For Team">
                            <div className="for_me">
                                <Row>
                                    <Col lg={6}>
                                        <div className="blac_metal">
                                            <div className="black_meatl_first">
                                                <div className="card_img_left">
                                                    <img src="../../../landing_page/assets/images/card/digitaliacard.png" alt="card-img" />
                                                </div>
                                                <div className="black-metal-professional">
                                                    <h5 className="black-metal-txt">Black<br />
                                                        ACW Card</h5>
                                                    <h6 className="black-metal-txt2">Card Type : PVC Glossy</h6>
                                                    <p className="black-metal-para">Get your card customized according to your needs </p>
                                                    <span className="all-tax">Inclusive of all taxes</span>
                                                </div>
                                            </div>
                                            <div className="under_black_metal mt-3">
                                                <div className="left_under_black">
                                                    <img src="../../../landing_page/assets/images/card/digitaliacard.png" alt="card-img" />
                                                </div>
                                                <div className="left_under_black enquiry_image_right">
                                                    <img src="../../../landing_page/assets/images/card/black-metal.png" alt="card-img" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    {
                                        backStatus
                                            ?
                                            <>
                                                <Col lg={6}>
                                                    <div className="card-detail">
                                                        <p className="printed-para">Please Enter The Details To Be Printed On The Card.</p>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <div className="input-group mb-1 input-contact_modal">
                                                                    <input id='enquiryname' type="text" value={data.name} onChange={onChange} name='name' className={"form-control " + (nameError ? 'red_bdr' : '')} placeholder="Full Name*" aria-label="Username" aria-describedby="basic-addon1" />
                                                                </div>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <div className={"input-group input-contact_modal " + (!emailValidError && 'mb-1')}>
                                                                    <input id='enquiryemail' type="text" style={emailValidError ? { margin: '0px' } : {}} value={data.email} onChange={onChange} name='email' className={"form-control " + ((emailError || emailValidError) ? 'red_bdr' : '')} placeholder="Email*" aria-label="Username" aria-describedby="basic-addon1" />
                                                                </div>
                                                                {emailValidError ? <span className='error-message'>Enter valid email</span> : ''}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <div className={"input-group input-contact_modal " + (!phonenumberValidError ? 'mb-1' : '')}>
                                                                    <input id='enquiryphonenumber' type="text" style={phonenumberValidError ? { margin: '0px' } : {}} value={data.phonenumber} onChange={onChange} name='phonenumber' className={"form-control " + ((phonenumberError || phonenumberValidError) ? 'red_bdr' : '')} placeholder="Enter Your Phone Number*" aria-label="Username" aria-describedby="basic-addon1" />
                                                                </div>
                                                                {phonenumberValidError ? <span className='error-message'>Phone Number should contain 10 digits</span> : ''}
                                                            </Col>
                                                            <Col lg={6}>
                                                                <div className="input-group mb-1 input-contact_modal">
                                                                    <input id='enquirycompany' type="text" value={data.company} onChange={onChange} name='company' className={"form-control " + (companyError ? 'red_bdr' : '')} placeholder="Company Name*" aria-label="Username" aria-describedby="basic-addon1" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <div className="input-group mb-1 input-contact_modal">
                                                                    <input id='enquirydesignation' type="text" value={data.designation} onChange={onChange} name='designation' className={"form-control " + (designationError ? 'red_bdr' : '')} placeholder="Your Designation*" aria-label="Username" aria-describedby="basic-addon1" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <div className="buy_now_btbn">
                                                            <Button id="buy-now" onClick={submit}>Enquiry</Button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </>
                                            :
                                            <>
                                                <div className="col-lg-6 d-flex justify-content-center align-items-center">
                                                    <div className="form-group">
                                                        <p style={{ color: '#2f2f2f', fontWeight: '600', fontSize: '35px', textAlign: 'center' }}>Thank you for your submission!</p>
                                                        <div className="buy_now_btbn" style={{ textAlign: 'center' }}>
                                                            <Button id="buy-now" onClick={(e) => setBackStatus(true)}>Back</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </Row>
                            </div>
                        </Tab>
                    </Tabs>
                </Container>
            </div >
        </>
    );
}