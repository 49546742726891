import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Container, Col, Spinner } from "react-bootstrap";
import { AiFillCamera, AiFillEye, AiOutlineEye, AiFillSetting } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { MdDeleteOutline } from "react-icons/md";
import Select from "react-select";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { RiImageAddFill } from "react-icons/ri";
import { HiRefresh, HiPhotograph } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import {
  AC_HANDLE_INPUT_CHANGE_PROFILE,
  AC_HANDLE_INPUT_CHANGE_SETTINGS,
  AC_PROFILE_SPINNER,
  AC_VIEW_PROFILE,
} from "../../actions/profileAction";
import ImportedURL from "../../common/api";
import {
  Emailvalidate,
  FileAndImagevalidation,
  GetAge,
  Imagevalidation,
  Phonenumber,
  OnlyAplhabets,
  Urlvalidate,
  dataURLtoFile,
  OnlyAplhabetsSomeCharactor,
  OnlyAplhabetsNumberSomeCharactor,
} from "../../common/validate";
import { Success, Error } from "../../common/swal";
import { AC_LIST_APP } from "../../actions/appAction";
import CountryCodeJson from "../../common/countrycode.json";
import moment from "moment";
import { Modal } from "react-bootstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Buffer } from "buffer";
import bnotfound from "../images/v2/b-not-found.png";
import delete_img from "../images/v2/delete.png";

import {
  AC_HANDLE_INPUT_CHANGE_BUSINESS,
  AC_LIST_BUSINESSESS,
  AC_LIST_PERSONAL,
  AC_RUN_SPINNER_BUSINESS,
  AC_VIEW_BUSINESSESS,
  AC_VIEW_PERSONAL,
} from "../../actions/businessAction";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import profile from "../images/v2/profile.png";
import edit from "../images/v2/edit.png";
import { GoogleMap, LoadScript, Autocomplete } from '@react-google-maps/api';

const crop = {
  unit: "px",
  x: 130,
  y: 50,
};

class Profile extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.dateInputRef = React.createRef();
    this.fileInputBanner = React.createRef();
    this.fileInputBusinesssLogo = React.createRef();
    this.containerRef = React.createRef();
    this.modalContentRef = React.createRef();
    this.state = {
      bgimage: "",
      profileimg: "",
      skill: "",
      socialmediaSelect: {},
      socialmediaValue: [],
      urlError: false,
      emailError: false,
      updateSpinner: false,
      phoneNoPatternError: false,
      busPhoneNoPatternError: false,
      phoneNoPatternArrayError: false,
      name: "React",
      src: null,
      crop: {
        unit: "px",
        x: 130,
        y: 50,
        width: 200,
        height: 200,
      },
      croppedImageUrl: null,
      showModal: false,
      typeImage: "",
      onChangeHeight: false,
      profileImage: "",
      isUserdDisplayNameSaveShow: false,
      isHeadlineSaveShow: false,
      bussinesslogoSrc: "",
      bussinesslogoError: false,
      isBusinessEditedit: false,
      businessEditeditId: "",
      modelData: {},
      modelTitle: "",

      viewText: '',
      isWwwShow: false,

      streetBord: false,
      cityBord: false,
      stateBord: false,
      zipBord: false,
      countryBord: false,
      isClear: false,
      isClearUrl: false,
      isClearNumber: false,
      isClearEmail: false,
      isCoseModel: false,
      isCoseModel1: false,
      isShowSwal: true,
      apiKey: 'AIzaSyA2FJmWEkxtZDw3E8thlgXRQIU1sPMJwKU',

    };
    this.dropdownRef = React.createRef();
    this.dropdownRefTwo = React.createRef();
  }

  async componentDidMount() {
    document.title = "ACW CARD - Profile";
    document.description =
      "ACW Card enables you to share your business and personal profiles along with digital uploads of key documents to strengthen your portfolio.";

    await this.props.ListPersonal();
    this.apiCall()

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA2FJmWEkxtZDw3E8thlgXRQIU1sPMJwKU&libraries=places`;
    // script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA2FJmWEkxtZDw3E8thlgXRQIU1sPMJwKU&callback=initAutocomplete&libraries=places&v=weekly`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      this.setState({ scriptLoaded: true });
    };
    document.body.appendChild(script);

    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("mousedown", this.handleClickOutsideTwo);

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("mousedown", this.handleClickOutsideTwo);
  }
  handleClickOutside = (e) => {
    if (this.state.isOpen && this.dropdownRef.current && !this.dropdownRef.current.contains(e.target)) {
      this.setState({ isOpen: false });
    }
  };
  handleClickOutsideTwo = (e) => {
    if (this.state.isOpenTwo && this.dropdownRefTwo.current && !this.dropdownRefTwo.current.contains(e.target)) {
      this.setState({ isOpenTwo: false });
    }
  };
  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
  };
  toggleDropdownTwo = () => {
    this.setState((prevState) => ({
      isOpenTwo: !prevState.isOpenTwo
    }));
  };

  apiCall = async () => {
    let id = this.props.match.params.id;
    const listPersonalData = this.props.BusinessState.listPersonal;
    var temp = false
    listPersonalData.filter(e => {
      if (e._id == id) {
        temp = true
      } else {
        temp = false
      }
    });
    this.setState({ viewPersonalStatus: temp })

    this.props.RunSpinner()
    if (temp) {
      await this.props.ViewPersonal(id);
    } else {
      await this.props.ViewBusiness(id);
    }
    await this.props.ViewProfile({ reverseStatus: true });
    await this.props.SpinnerProfile();
    await this.props.ListBusiness();
    await this.props.ListApp();
  }

  reRenderFun = async (e) => {
    let id = this.props.match.params.id;
    const listPersonalData = this.props.BusinessState.listPersonal;
    var temp = false
    listPersonalData.filter(e => {
      if (e._id == id) {
        temp = true
      } else {
        temp = false
      }
    });
    if (temp) {
      await this.props.ViewPersonal(id);
    } else {
      await this.props.ViewBusiness(id);
    }
    await this.props.SpinnerProfile();
    await this.props.ViewProfile({ reverseStatus: true });
    await this.props.ListBusiness();
    await this.props.ListApp();
  };

  submitApp = (e, status) => {
    const { busPhoneNoPatternError, phoneNoPatternError } = this.state;
    const { ProfileState } = this.props;
    const data = ProfileState.profile;

    let listAppLink = [...e];
    let valid = true;
    if (busPhoneNoPatternError) {
      valid = false;
    }
    if (phoneNoPatternError) {
      valid = false;
    }
    if (valid) {
      this.setState({ updateSpinner: true });
      const formDataS3 = new FormData();
      let othersArr = [];
      let documentArr = [];
      let existImageArr = [];
      let imagesDataArr = [];
      if (listAppLink.length > 0) {
        for (let i = 0; i < listAppLink.length; i++) {
          if (listAppLink[i].inputtype != "document") {
            othersArr.push(listAppLink[i]);
          }
        }
        for (let i = 0; i < listAppLink.length; i++) {
          if (listAppLink[i].inputtype == "document") {
            documentArr.push(listAppLink[i]);
          }
        }
      }
      if (documentArr.length > 0) {
        for (let i = 0; i < documentArr.length; i++) {
          if (typeof documentArr[i].value == "string") {
            existImageArr.push(documentArr[i]);
          } else {
            formDataS3.append("documents", documentArr[i].value);
            imagesDataArr.push(documentArr[i]);
          }
        }
      }
      formDataS3.append("othersArr", JSON.stringify(othersArr));
      formDataS3.append("existImageArr", JSON.stringify(existImageArr));
      formDataS3.append("imagesDataArr", JSON.stringify(imagesDataArr));
      let id = this.props.match.params.id;
      axios
        .post(ImportedURL.API.updateProfileAppLinks + "/" + id, formDataS3)
        .then((res) => {
          this.props.ViewProfile({ reverseStatus: true });
          this.setState({ updateSpinner: false });
          Success(status);
          this.props.ListBusiness();
          // let btn = document.getElementById("closeModalEdit");
          // btn.click();
          this.reRenderFun();
        })
        .catch(({ response }) => {
          this.setState({ updateSpinner: false });
          if (response) {
            if (response.status == 401) {
              Error("Something wrong, Retry again!");
            } else if (response.status == 510) {
              Error("Email does not exit");
            } else if (response.status == 502) {
              Error(response.status + " Bad Gateway");
            } else if (response.status == 500) {
              Error("Internal Server Error");
            } else if (response.status == 409) {
              Error("Already exist");
            } else if (response.status == 400) {
              Error("Bad request");
            }
          }
        });
    }
  };

  submitStatus = (e, status) => {
    this.setState({ updateSpinner: true });
    let id = this.props.match.params.id;
    axios.post(ImportedURL.API.updateProfileAppStatus + "/" + id, { applinkstatus: e, })
      .then(async (res) => {
        this.apiCall()
        this.setState({ updateSpinner: false });
        Success(status);
      })
      .catch(({ response }) => {
        this.setState({ updateSpinner: false });
        if (response) {
          if (response.status == 401) {
            Error("Something wrong, Retry again!");
          } else if (response.status == 510) {
            Error("Email does not exit");
          } else if (response.status == 502) {
            Error(response.status + " Bad Gateway");
          } else if (response.status == 500) {
            Error("Internal Server Error");
          } else if (response.status == 409) {
            Error("Already exist");
          } else if (response.status == 400) {
            Error("Bad request");
          }
        }
      });
  };
  removeImages = (e) => {
    var formData = new FormData();
    formData.append("image", "");

    Swal.fire({
      title: "Do you want to remove your profile picture?",
      // showCancelButton: true,
      showDenyButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
      // imageUrl: "../../assets/images/delete.png",
      customClass: {
        container: "delete_swal_image",
        popup: "swal_pop",
        title: "swal_title",
        image: "swal_image",
        actions: "swal_action",
        confirmButton: "swal_confirm",
        cancelButton: "swal_close",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ profileimg: "" });
        this.props.HandleInputChange("image", "");
        axios
          .post(ImportedURL.API.updateProfilePicture, formData)
          .then((res) => {
            this.setState({ updateSpinner: false });
            Success("Saved");
          })
          .catch(({ response }) => {
            this.setState({ updateSpinner: false });
            if (response) {
              if (response.status == 401) {
                Error("Something wrong, Retry again!");
              } else if (response.status == 510) {
                Error("Email does not exit");
              } else if (response.status == 502) {
                Error(response.status + " Bad Gateway");
              } else if (response.status == 500) {
                Error("Internal Server Error");
              } else if (response.status == 409) {
                Error("Already exist");
              } else if (response.status == 400) {
                Error("Bad request");
              }
            }
          });
      }
    })
  };
  removeBanner = (e) => {
    var formData = new FormData();
    formData.append("banner", "");
    Swal.fire({
      title: "Do you want to remove your cover picture?",
      // showCancelButton: true,
      showDenyButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
      // imageUrl: "../../assets/images/delete.png",
      customClass: {
        container: "delete_swal_image",
        popup: "swal_pop",
        title: "swal_title",
        image: "swal_image",
        actions: "swal_action",
        confirmButton: "swal_confirm",
        cancelButton: "swal_close",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ bgimage: "" });
        this.props.HandleInputChange("banner", "");
        axios
          .post(ImportedURL.API.updateProfilePicture, formData)
          .then((res) => {
            this.setState({ updateSpinner: false });
            Success("Data saved");
          })
          .catch(({ response }) => {
            this.setState({ updateSpinner: false });
            if (response) {
              if (response.status == 401) {
                Error("Something wrong, Retry again!");
              } else if (response.status == 510) {
                Error("Email does not exit");
              } else if (response.status == 502) {
                Error(response.status + " Bad Gateway");
              } else if (response.status == 500) {
                Error("Internal Server Error");
              } else if (response.status == 409) {
                Error("Already exist");
              } else if (response.status == 400) {
                Error("Bad request");
              }
            }
          });
      }
    })
  };
  onChangeImage = (e) => {
    const { name, value } = e.target;
    if (name == "image") {
      if (e.target.files[0] != undefined) {
        const imgvalidate = Imagevalidation(e.target.files[0]);
        if (imgvalidate) {
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            this.setState((prevState) => ({
              ...prevState,
              src: reader.result,
            }));
          });
          reader.readAsDataURL(e.target.files[0]);
          this.setState({ showModal: true, typeImage: "image" });
          // this.props.HandleInputChange(name, e.target.files[0]);
        } else {
          Error("Invalid file extension");
        }
      }
    } else if (name == "banner") {
      if (e.target.files[0] != undefined) {
        const imgvalidate = Imagevalidation(e.target.files[0]);
        if (imgvalidate) {
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            this.setState((prevState) => ({
              ...prevState,
              src: reader.result,
            }));
          });
          reader.readAsDataURL(e.target.files[0]);
          this.setState({ showModal: true, typeImage: "banner" });
          // this.setState({ bgimage: e.target.files[0] });
          // this.props.HandleInputChange(name, e.target.files[0]);
        } else {
          Error("Invalid file extension");
        }
      }
    }
  };

  Logout = (e) => {
    Swal.fire({
      title: "Are you sure want to logout ?",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
      imageUrl: "../../assets/images/signout.png",
      customClass: {
        popup: "swal_pop",
        title: "swal_title",
        image: "swal_image",
        actions: "swal_action",
        confirmButton: "swal_confirm",
        cancelButton: "swal_close",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        localStorage.removeItem("acwtoken");
        window.location.href = "/";
      }
    });
  };
  onChange = (e) => {
    const { name, value } = e.target;
    const Error1 = name + "Error";
    let formData = {};
    if (name === "phonenumber") {
      let val = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      if (val) {
        if (Phonenumber(val)) {
          this.setState({ phoneNoPatternError: false });
        } else {
          this.setState({ phoneNoPatternError: true });
        }
      } else {
        this.setState({ phoneNoPatternError: false });
      }
      this.props.HandleInputChange(name, val);
    } else if (name == "displayname") {
      if (value) {
        if (OnlyAplhabetsSomeCharactor(value)) {
          if (value.length < 100) {
            this.setState({ [Error]: false, isUserdDisplayNameSaveShow: true });
            this.props.HandleInputChange("displayname", value);
            formData["displayname"] = value;
            this.headlineDisplayFun(formData);
          } else {
            Error('Maximum limit reached')
          }
        } else {
          Error('Content can only include letters, commas, hyphen and spaces')
        }
      } else {
        this.props.HandleInputChange("displayname", value);
        this.setState({ [Error]: true });
        formData["displayname"] = value;
        this.headlineDisplayFun(formData);
      }
    } else if (name == "headline") {
      if (value) {
        this.setState({ [Error]: false, isUserdHeadlineSaveShow: true });
        this.props.HandleInputChange("headline", value);
        formData["headline"] = value;
        this.headlineDisplayFun(formData);
      } else {
        this.props.HandleInputChange("headline", value);
        this.setState({ [Error]: true });
        formData["headline"] = value;
        this.headlineDisplayFun(formData);
      }
    } else {
      this.props.HandleInputChange(name, value);
    }
  };
  onClickSkill = (e) => {
    const data = this.props.ProfileState.profile;
    var skillTrim = this.state.skill ? this.state.skill?.trim() : ''
    if (skillTrim) {
      let skill = [...data.skill];
      this.props.HandleInputChange("skill", [...skill, skillTrim]);
      this.setState({ skill: "" });
    }
  };
  removeSkill = (id) => {
    const data = this.props.ProfileState.profile;
    let skill = [...data.skill];
    this.props.HandleInputChange("skill", [
      ...skill.filter((e, i) => i !== id),
    ]);
  };
  heightPerpose = () => {
    this.setState({ onChangeHeight: false });
  };
  socialmediaSelect = (e, parentId) => {
    this.setState({ isClear: false, isClearUrl: false, isClearNumber: false, isClearEmail: false, isWwwShow: false, othersError: false })
    this.setState({ othersError: false, urlError: false, phoneRequired: false, phoneNoPatternArrayError: false, emailError: false })
    const { socialmediaValue, onChangeHeight } = this.state;
    const { value, logo, id, inputtype } = e;
    if (value) {
      let index = socialmediaValue.findIndex((e) => e.Uid == parentId);
      if (index != -1) {
        let obj = {
          logo: logo,
          name: value,
          Uid: parentId,
          parentId: parentId,
          id: id,
          inputtype: inputtype,
          value: ''
        };
        if (inputtype == "number") {
          obj["cc"] = { name: "US", value: "+1" };
          obj["value"] = '';
        }
        if (inputtype == "url") {
          obj["link"] = '';
        }
        socialmediaValue[index] = obj;
        this.setState({ socialmediaValue: socialmediaValue });
      } else {
        let obj = {
          logo: logo,
          name: value,
          Uid: parentId,
          parentId: parentId,
          id: id,
          inputtype: inputtype,
          value: ''
        };
        if (inputtype == "number") {
          obj["cc"] = { name: "US", value: "+1" };
          obj["value"] = '';
        }
        if (inputtype == "url") {
          obj["link"] = '';
        }
        this.setState({ socialmediaValue: [...socialmediaValue, obj] });
      }
    }
    if (value == "") {
      let social = this.state.socialmediaValue.filter(
        (e) => e.parentId != parentId
      );
      this.setState({ socialmediaValue: social });
    }
  };
  onClickSocialMedia = (parentId, type, appval) => {
    const { urlError, socialmediaValue, emailError, phoneNoPatternArrayError, viewText } = this.state;
    const data = this.props.BusinessState.bussiness;
    let status = socialmediaValue.find((e) => e.Uid == parentId);
    if (type == "url") {
      if (status != undefined && status && !urlError) {
        if (
          status.name != undefined &&
          status.link != undefined &&
          (status.link && status.link.trim())
        ) {
          let applink = [...data.applink];
          var form = status;
          var temp = (status.link ? status.link.trim() : '')
          form.appStatus = form.appStatus == undefined ? true : form.appStatus
          // let urlval = form.link.includes('https://') ? form.link.slice(8) : form.link;

          // form.link = urlval.includes('www.') ? form.link : 'www.' + urlval
          // form.index = Math.floor((Math.random() * 10000000000000) + 1);
          // this.props.HandleInputBusinessChange("applink", [...applink, form]);
          if ((temp.includes('https://') && temp.includes('www.')) || (temp.includes('http://') && temp.includes('www.'))) {
            form.link = temp.replace('https://', '').replace('http://', '')
          } else {
            if (temp.includes('http://m.')) {
              form.link = temp.replace('http://m.', 'www.')
            } else if (temp.includes('https://m.')) {
              form.link = temp.replace('https://m.', 'www.')
            } else if (temp.includes('https://')) {
              form.link = temp.replace('https://', 'www.')
            } else if (temp.includes('http://')) {
              form.link = temp.replace('http://', 'www.')
            } else if (temp.includes('www.')) {
              form.link = temp
            } else {
              form.link = 'www.' + temp
            }
          }

          if ((applink && applink.length > 0) && (socialmediaValue && socialmediaValue.length > 0) && viewText != 'edit') {
            const isAlreadyExists = socialmediaValue.some(item1 => applink.some(item2 => (item1.link === item2.link && (item1.name == item2.appname) && (item1._id != item2._id))))
            if (isAlreadyExists) {
              Error("This data is already exists")
            } else {
              let index = applink.findIndex((e) => e._id == form._id)
              let sendData = []
              if (index != -1) {
                sendData = [...applink.slice(0, index), form, ...applink.slice(index + 1),]
              } else {
                sendData = [...applink, form]
              }
              this.props.HandleInputBusinessChange("applink", sendData);
              this.props.RunSpinner()
              this.submitApp(sendData, this.state.viewText == 'edit' ? "Data updated" : "Data saved");
              this.setState({
                socialmediaValue: [
                  ...socialmediaValue.filter((e) => e.Uid != parentId),
                ],
              });
            }
          } else {
            let alreadyExist = applink.find((e) => e.link == appval && e._id != status._id && (e.appname == status.name));
            if (!alreadyExist) {
              let index = applink.findIndex((e) => e._id == form._id)
              let sendData = []
              if (index != -1) {
                sendData = [...applink.slice(0, index), form, ...applink.slice(index + 1),]
              } else {
                sendData = [...applink, form]
              }
              this.props.HandleInputBusinessChange("applink", sendData);
              this.props.RunSpinner()
              this.submitApp(sendData, this.state.viewText == 'edit' ? "Data updated" : "Data saved");
              this.setState({
                socialmediaValue: [
                  ...socialmediaValue.filter((e) => e.Uid != parentId),
                ],
                viewText: ''
              });
            } else {
              Error("This data is already exists")
            }
          }
        }
      }
      if (!status.link) {
        this.setState({ urlError: true, isClearUrl: false })
      }
    } else if (type == "email") {
      if (status != undefined && status && !emailError) {
        if (
          status.name != undefined &&
          status.value != undefined &&
          status.value
        ) {
          let applink = [...data.applink];
          var form = status;
          form.appStatus = form.appStatus == undefined ? true : form.appStatus

          // form.index = Math.floor((Math.random() * 10000000000000) + 1);
          // this.props.HandleInputBusinessChange("applink", [...applink, form]);


          if ((applink && applink.length > 0) && (socialmediaValue && socialmediaValue.length > 0) && viewText != 'edit') {
            const isAlreadyExists = socialmediaValue.some(item1 => applink.some(item2 => (item1.value === item2.value && (item1.name == item2.appname) && (item1._id != item2._id))))
            if (isAlreadyExists) {
              Error("This data is already exists")
            } else {
              let index = applink.findIndex((e) => e._id == form._id)
              let sendData = []
              if (index != -1) {
                sendData = [...applink.slice(0, index), form, ...applink.slice(index + 1),]
              } else {
                sendData = [...applink, form]
              }
              this.props.HandleInputBusinessChange("applink", sendData);
              this.props.RunSpinner()
              this.submitApp(sendData, this.state.viewText == 'edit' ? "Data updated" : "Data saved");
              this.setState({
                socialmediaValue: [
                  ...socialmediaValue.filter((e) => e.Uid != parentId),
                ],
              });
            }
          } else {
            let alreadyExist = applink.find((e) => (e.value == appval && e._id != status._id && (e.appname == status.name)));
            if (!alreadyExist) {
              let index = applink.findIndex((e) => e._id == form._id)
              let sendData = []
              if (index != -1) {
                sendData = [...applink.slice(0, index), form, ...applink.slice(index + 1),]
              } else {
                sendData = [...applink, form]
              }
              this.props.HandleInputBusinessChange("applink", sendData);
              this.props.RunSpinner()
              this.submitApp(sendData, this.state.viewText == 'edit' ? "Data updated" : "Data saved");
              this.setState({
                socialmediaValue: [
                  ...socialmediaValue.filter((e) => e.Uid != parentId),
                ],
                viewText: ''
              });
            } else {
              Error("This data is already exists")
            }
          }
        }
      }
      if (!status.value) {
        this.setState({ emailRequired: true, isClearEmail: false })
      }
    } else if (type == "number") {
      if (status != undefined && status && !phoneNoPatternArrayError) {
        if (
          status.name != undefined &&
          status.value != undefined &&
          status.value
        ) {
          let applink = [...data.applink];
          var form = status;
          form.appStatus = form.appStatus == undefined ? true : form.appStatus
          // form.index = Math.floor((Math.random() * 10000000000000) + 1);
          // this.props.HandleInputBusinessChange("applink", [...applink, form]);
          if ((applink && applink.length > 0) && (status && socialmediaValue.length > 0) && viewText != 'edit') {
            const isAlreadyExists = socialmediaValue.some(item1 => applink.some(item2 => ((item1.value == item2.value) && (item1.cc?.value == item2.cc?.value) && (item1.name == item2.appname) && (item1._id != item2._id))))
            if (isAlreadyExists) {
              Error("This data is already exists")
            } else {
              let index = applink.findIndex((e) => e._id == form._id)
              let sendData = []
              if (index != -1) {
                sendData = [...applink.slice(0, index), form, ...applink.slice(index + 1),]
              } else {
                sendData = [...applink, form]
              }
              this.props.HandleInputBusinessChange("applink", sendData);
              this.props.RunSpinner()
              this.submitApp(sendData, this.state.viewText == 'edit' ? "Data updated" : "Data saved");
              this.setState({
                socialmediaValue: [
                  ...socialmediaValue.filter((e) => e.Uid != parentId),
                ],
              });
            }
          } else {
            let alreadyExist = applink.find((e) => ((e.value == appval.value) && (e._id != appval._id) && (e.cc?.value == appval.cc?.value) && (e.appname == appval.name)));
            if (!alreadyExist) {
              let index = applink.findIndex((e) => e._id == form._id)
              let sendData = []
              if (index != -1) {
                sendData = [...applink.slice(0, index), form, ...applink.slice(index + 1),]
              } else {
                sendData = [...applink, form]
              }
              this.props.HandleInputBusinessChange("applink", sendData);
              this.props.RunSpinner()
              this.submitApp(sendData, this.state.viewText == 'edit' ? "Data updated" : "Data saved");
              this.setState({
                socialmediaValue: [
                  ...socialmediaValue.filter((e) => e.Uid != parentId),
                ],
                viewText: ''
              });
            } else {
              Error("This data is already exists")
            }
          }
        }
      }
      if (!status.value) {
        this.setState({ phoneRequired: true, isClearNumber: false })
      }
    } else {
      if (status != undefined && status) {
        if (
          status.name != undefined &&
          status.value != undefined &&
          status.value
        ) {
          let applink = [...data.applink];
          var form = status;
          form.appStatus = form.appStatus == undefined ? true : form.appStatus
          // form.index = Math.floor((Math.random() * 10000000000000) + 1);
          // this.props.HandleInputBusinessChange("applink", [...applink, form]);
          this.setState({
            socialmediaValue: [
              ...socialmediaValue.filter((e) => e.Uid != parentId),
            ],
          });
          let index = applink.findIndex((e) => e._id == form._id)
          let sendData = []
          if (index != -1) {
            sendData = [
              ...applink.slice(0, index),
              form,
              ...applink.slice(index + 1),
            ]
          } else {
            sendData = [...applink, form]
          }

          this.props.HandleInputBusinessChange("applink", sendData);
          this.props.RunSpinner()
          this.submitApp(sendData, this.state.viewText == 'edit' ? "Data updated" : "Data saved");
        }
      }
    }
  };

  documentStatus = (e, item, id, type) => {
    const { socialmediaValue } = this.state;
    const { value, name, checked } = e.target;

    const data = this.props.BusinessState.bussiness;
    let applink = [...data.applink];
    let applinkstatus = [...data.applinkstatus];

    let index = applink.findIndex((e) => e._id == item._id);
    if (index != -1) {
      applink[index] = {
        ...applink[index],
        ["appStatus"]: checked,
      };

      this.props.HandleInputBusinessChange("applink", applink);
      let newapplinkstatus = []
      if (applinkstatus && applinkstatus.length > 0) {
        applinkstatus.map((item) => {
          let arr = (applink && applink.length > 0) ? applink.filter((e) => e.parentid == item.appid) : []
          let status = arr.length > 0 ? arr.find((e) => e.appStatus == true) : false
          let obj = item
          obj['status'] = status ? item.status : false
          newapplinkstatus.push(obj)
        })
      }
      this.props.HandleInputBusinessChange("applinkstatus", newapplinkstatus);
      this.props.RunSpinner()
      this.submitApp(applink, "Saved");
      this.submitStatus(newapplinkstatus, 'Saved');

    }
  };

  onChangeImageArray = (e, parentId, type) => {
    const { socialmediaValue } = this.state;
    const { value, name } = e.target;
    let files = e.target.files ? e.target.files[0] : undefined;
    if (files != undefined) {
      let data = FileAndImagevalidation(files);
      if (data.allow != undefined && data.allow) {
        var previewType = "doc";
        if (data.type == "jpg" || data.type == "jpeg" || data.type == "png") {
          previewType = "image";
        }
        if (data.type == "pdf") {
          previewType = "pdf";
        }

      } else {
        Error("Invalid file extension");
      }
    }

    let index = socialmediaValue.findIndex((e) => e.Uid == parentId);
    if (index != -1) {
      socialmediaValue[index] = {
        ...socialmediaValue[index],
        ["address"]: "",
        ["link"]: "",
        ["documentstatus"]: true,
      };
      if (name == "others") {
        socialmediaValue[index]["othersdoc"] = value;
        if (value) {
          this.setState({ othersError: false })
        }
      }
      if (name == "image") {
        socialmediaValue[index]["value"] = files;
        socialmediaValue[index]["previewtype"] = previewType;
      }
      this.setState({ socialmediaValue: socialmediaValue });
    }
  };
  onClickSocialMediaAddress = (parentId) => {
    const { urlError, socialmediaValue } = this.state;
    this.setState({ viewText: '' })
    const data = this.props.BusinessState.bussiness;
    let status = socialmediaValue.find((e) => e.Uid == parentId);

    if (status != undefined && status && !urlError) {
      if ((status.name != undefined) && (status.street != undefined && status.street) && (status.city != undefined && status.city) && (status.state != undefined && status.state) && (status.zip != undefined && status.zip) && (status.country != undefined && status.country)) {
        let applink = [...data.applink];
        var form = status;
        form.address = {
          street: status.street ? status.street.trim() : '',
          city: status.city ? status.city.trim() : '',
          state: status.state ? status.state.trim() : '',
          zip: status.zip ? status.zip.trim() : '',
          country: status.country ? status.country.trim() : '',
        };
        form.appStatus = form.appStatus == undefined ? true : form.appStatus

        // form.index = Math.floor((Math.random() * 10000000000000) + 1);
        // this.props.HandleInputBusinessChange("applink", [...applink, form]);
        this.setState({
          socialmediaValue: [
            ...socialmediaValue.filter((e) => e.Uid != parentId),
          ],
        });
        let index = applink.findIndex((e) => e._id == form._id)
        let sendData = []
        if (index != -1) {
          sendData = [
            ...applink.slice(0, index),
            form,
            ...applink.slice(index + 1),
          ]
        } else {
          sendData = [...applink, form]
        }

        this.props.HandleInputBusinessChange("applink", sendData);
        this.props.RunSpinner()
        this.submitApp(sendData, this.state.viewText == 'edit' ? "Data updated" : "Data saved");
      }

      if (status.street == undefined || status.street == '') {
        this.setState({ streetBord: true })
      }
      if (status.city == undefined || status.city == '') {
        this.setState({ cityBord: true })
      }
      if (status.state == undefined || status.state == '') {
        this.setState({ stateBord: true })
      }
      if (status.zip == undefined || status.zip == '') {
        this.setState({ zipBord: true })
      }
      if (status.country == undefined || status.country == '') {
        this.setState({ countryBord: true })
      }
    }
  };

  onClickIAddImageArray = (parentId) => {
    const { urlError, socialmediaValue } = this.state;
    this.setState({ viewText: '' })
    const data = this.props.BusinessState.bussiness;
    let status = socialmediaValue.find((e) => e.Uid == parentId);
    if (status != undefined && status) {
      let valid = 1
      status.othersdoc?.trim()
      if (!status.othersdoc && status.name == "Others") {
        this.setState({ othersError: true })
        valid = 0
      }

      if (status.value != undefined && status.value) {
        if (valid) {
          let applink = [...data.applink];
          var form = status;
          let index = applink.findIndex((e) => e._id == form._id)
          let sendData = []
          if (index != -1) {
            sendData = [
              ...applink.slice(0, index),
              form,
              ...applink.slice(index + 1),
            ]
          } else {
            sendData = [...applink, form]
          }
          this.setState({
            socialmediaValue: [
              ...socialmediaValue.filter((e) => e.Uid != parentId),
            ],
          });
          this.props.HandleInputBusinessChange("applink", sendData);
          this.props.RunSpinner()
          this.submitApp(sendData, this.state.viewText == 'edit' ? "Data updated" : "Data saved");
        }
      } else {
        Error("Choose file")
      }
    }
  };

  onChangeAddress = (e, parentId, type) => {
    const { socialmediaValue } = this.state;
    const { value, name } = e.target;
    let index = socialmediaValue.findIndex((e) => e.Uid == parentId);
    if (index != -1) {
      socialmediaValue[index] = {
        ...socialmediaValue[index],
        [name]: value,
        ["value"]: "",
        ["link"]: "",
      };
      if (socialmediaValue && socialmediaValue.length > 0) {
        socialmediaValue.map(data => {
          if (data.street) {
            this.setState({ streetBord: false, })
          }
          if (data.city) {
            this.setState({ cityBord: false })
          }
          if (data.state) {
            this.setState({ stateBord: false })
          }
          if (data.zip) {
            this.setState({ zipBord: false })
          } if (data.country) {
            this.setState({ countryBord: false })
          }
          if (!data.street && !data.city && !data.state && !data.zip) {
            this.setState({ isClear: false })
          } else {
            this.setState({ isClear: true })
          }
        })
      }
      this.setState({ socialmediaValue: socialmediaValue });
    }
  };

  editSocailMedia = (e, id, type) => {
    this.setState({ viewText: 'edit', isWwwShow: true, isClear: true, isClearEmail: true, isClearNumber: true, isClearUrl: true })
    const { socialmediaValue } = this.state;
    const data = this.props.BusinessState.bussiness;
    let alreadyExist = socialmediaValue.find((e) => e.id == e.id);
    let applink = [...data.applink];
    if (type == "address") {
      this.setState({ zipBord: false, countryBord: false, cityBord: false, streetBord: false, })
      const form = {
        id: e.id,
        logo: e.logo,
        name: e.appname,
        parentId: e.parentid,
        address: e.address,
        street: e.address.street,
        city: e.address.city,
        state: e.address.state,
        zip: e.address.zip,
        country: e.address.country,
        Uid: e.parentid,
        inputtype: e.inputtype,
        _id: e._id,
      };
      form['appStatus'] = e.appStatus
      if (alreadyExist) {
        const updatedArray = socialmediaValue.map(obj => {
          if (obj.id === e.id) {
            return { ...obj, ...form }; // Update the object with new data
          } else {
            return obj; // Return the original object
          }
        });
        this.setState({ socialmediaValue: updatedArray });
      } else {
        this.setState({ socialmediaValue: [...socialmediaValue, form] });
      }
      // let app = [...applink.filter((a, i) => a._id !== e._id)];
      // this.props.HandleInputBusinessChange("applink", app);
    } else if (type == "document") {
      let previewType = "doc";
      if (typeof e.value == "string") {
        previewType = "alldata";
      } else {
        let data = FileAndImagevalidation(e.value);
        if (data.allow != undefined && data.allow) {
          if (data.type == "jpg" || data.type == "jpeg" || data.type == "png") {
            previewType = "image";
          }
          if (data.type == "pdf") {
            previewType = "pdf";
          }
        }
      }
      const form = {
        id: e.id,
        logo: e.logo,
        name: e.appname,
        parentId: e.parentid,
        Uid: e.parentid,
        link: e.link,
        othersdoc: e.othersdoc,
        value: e.value,
        inputtype: e.inputtype,
        previewtype: previewType,
        _id: e._id,
      };
      form['appStatus'] = e.appStatus
      if (alreadyExist) {
        const updatedArray = socialmediaValue.map(obj => {
          if (obj.id === e.id) {
            return { ...obj, ...form }; // Update the object with new data
          } else {
            return obj; // Return the original object
          }
        });
        this.setState({ socialmediaValue: updatedArray });
      } else {
        this.setState({ socialmediaValue: [...socialmediaValue, form] });
      }

      // let app = [...applink.filter((a, i) => a._id !== e._id)];
      // this.props.HandleInputBusinessChange("applink", app);
    } else {
      const form = {
        id: e.id,
        logo: e.logo,
        name: e.appname,
        parentId: e.parentid,
        link: e.link,
        Uid: e.parentid,
        value: e.value,
        inputtype: e.inputtype,
        cc: e.cc,
        appStatus: e.appStatus,
        _id: e._id,
      };
      if (alreadyExist) {
        const updatedArray = socialmediaValue.map(obj => {
          if (obj.id === e.id) {
            return { ...obj, ...form }; // Update the object with new data
          } else {
            return obj; // Return the original object
          }
        });
        this.setState({ socialmediaValue: updatedArray });
      } else {
        this.setState({ socialmediaValue: [...socialmediaValue, form] });
      }

      // let app = [...applink.filter((a, i) => a._id !== e._id)];
      // this.props.HandleInputBusinessChange("applink", app);
    }
  };

  viewSocailMedia = (e, id, type, text) => {
    this.setState({ viewText: text })
    const { socialmediaValue } = this.state;
    let alreadyExist = socialmediaValue.find((e) => e.id == e.id);
    const data = this.props.BusinessState.bussiness;
    let applink = [...data.applink];
    if (type == "address") {
      const form = {
        id: e.id,
        logo: e.logo,
        name: e.appname,
        parentId: e.parentid,
        address: e.address,
        street: e.address.street,
        city: e.address.city,
        state: e.address.state,
        zip: e.address.zip,
        country: e.address.country,
        Uid: e.parentid,
        inputtype: e.inputtype,
      };
      form['appStatus'] = e.appStatus
      if (alreadyExist) {
        const updatedArray = socialmediaValue.map(obj => {
          if (obj.id === e.id) {
            return { ...obj, ...form }; // Update the object with new data
          } else {
            return obj; // Return the original object
          }
        });
        this.setState({ socialmediaValue: updatedArray });
      } else {
        this.setState({ socialmediaValue: [...socialmediaValue, form] });
      }


      // let app = [...applink.filter((a, i) => a._id !== e._id)];
      // this.props.HandleInputBusinessChange("applink", app);
    } else if (type == "document") {
      let previewType = "doc";
      if (typeof e.value == "string") {
        previewType = "alldata";
      } else {
        let data = FileAndImagevalidation(e.value);
        if (data.allow != undefined && data.allow) {
          if (data.type == "jpg" || data.type == "jpeg" || data.type == "png") {
            previewType = "image";
          }
          if (data.type == "pdf") {
            previewType = "pdf";
          }
        }
      }
      const form = {
        id: e.id,
        logo: e.logo,
        name: e.appname,
        parentId: e.parentid,
        Uid: e.parentid,
        link: e.link,
        value: e.value,
        inputtype: e.inputtype,
        othersdoc: e.othersdoc ? e.othersdoc : '',
        previewtype: previewType,
      };
      form['appStatus'] = e.appStatus
      if (alreadyExist) {
        const updatedArray = socialmediaValue.map(obj => {
          if (obj.id === e.id) {
            return { ...obj, ...form }; // Update the object with new data
          } else {
            return obj; // Return the original object
          }
        });
        this.setState({ socialmediaValue: updatedArray });
      } else {
        this.setState({ socialmediaValue: [...socialmediaValue, form] });
      }
      // let app = [...applink.filter((a, i) => a._id !== e._id)];
      // this.props.HandleInputBusinessChange("applink", app);
    } else {
      const form = {
        id: e.id,
        logo: e.logo,
        name: e.appname,
        parentId: e.parentid,
        link: e.link,
        Uid: e.parentid,
        value: e.value,
        inputtype: e.inputtype,
        cc: e.cc,
        appStatus: e.appStatus
      };
      if (alreadyExist) {
        const updatedArray = socialmediaValue.map(obj => {
          if (obj.id === e.id) {
            return { ...obj, ...form }; // Update the object with new data
          } else {
            return obj; // Return the original object
          }
        });
        this.setState({ socialmediaValue: updatedArray });
      } else {
        this.setState({ socialmediaValue: [...socialmediaValue, form] });
      }
      // let app = [...applink.filter((a, i) => a._id !== e._id)];
      // this.props.HandleInputBusinessChange("applink", app);
    }
  };

  onChangeLinkCC = (e, parentId, type) => {
    const { socialmediaValue } = this.state;
    const { value, name, label } = e;
    let data = {
      name: value,
      value: label,
    };
    let index = socialmediaValue.findIndex((e) => e.Uid == parentId);
    if (index != -1) {
      socialmediaValue[index] = {
        ...socialmediaValue[index],
        ["address"]: "",
        ["link"]: "",
        ["cc"]: data,
      };
      this.setState({ socialmediaValue: socialmediaValue });
    }
  };
  onChangeLink = (e, parentId, type) => {
    const { socialmediaValue } = this.state;
    const { value, name } = e.target;

    if (type == "url") {
      if (value) {
        // if (!Urlvalidate(value)) {
        //   this.setState({ urlError: true });
        // } else {
        //   this.setState({ urlError: false });
        // }
        this.setState({ urlError: false, isClearUrl: true });

      } else {
        this.setState({ isClearUrl: false });
      }
      let index = socialmediaValue.findIndex((e) => e.Uid == parentId);
      if (index != -1) {
        socialmediaValue[index] = {
          ...socialmediaValue[index],
          ["address"]: "",
          ["value"]: "",
          ["link"]: value,
        };
        this.setState({ socialmediaValue: socialmediaValue, isClear: true });
      }
    } else if (type == "number") {
      let val = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      if (val) {
        this.setState({ phoneRequired: false })
        if (Phonenumber(val)) {
          this.setState({ phoneNoPatternArrayError: false });
        } else {
          this.setState({ phoneNoPatternArrayError: true });
        }
        this.setState({ isClearNumber: true });
      } else {
        this.setState({ phoneNoPatternArrayError: false, isClearNumber: false });
      }
      let index = socialmediaValue.findIndex((e) => e.Uid == parentId);
      if (index != -1) {
        socialmediaValue[index] = {
          ...socialmediaValue[index],
          ["address"]: "",
          ["value"]: val,
          ["link"]: "",
        };
        this.setState({ socialmediaValue: socialmediaValue });
      }
    } else if (type == "email") {
      let val = value.toLowerCase()
      if (val) {
        this.setState({ isClearEmail: true, emailRequired: false });
        if (Emailvalidate(val)) {
          this.setState({ emailError: false });
        } else {
          this.setState({ emailError: true });
        }
      } else {
        this.setState({ emailError: false, isClearEmail: false });
      }
      let index = socialmediaValue.findIndex((e) => e.Uid == parentId);
      if (index != -1) {
        socialmediaValue[index] = {
          ...socialmediaValue[index],
          ["address"]: "",
          ["link"]: "",
          ["value"]: val,
        };
        this.setState({ socialmediaValue: socialmediaValue });
      }
    } else if (type == "none") {
      let index = socialmediaValue.findIndex((e) => e.Uid == parentId);
      if (index != -1) {
        socialmediaValue[index] = {
          ...socialmediaValue[index],
          ["address"]: "",
          ["link"]: "",
          ["value"]: value,
        };
        this.setState({ socialmediaValue: socialmediaValue });
      }
    }
  };
  appLinkStatus = (e, app, index) => {
    const data = this.props.BusinessState.bussiness;
    let applinkstatus = [...data.applinkstatus];
    let status = applinkstatus.findIndex((r) => r.appid == app._id);
    const statusEnable = data.applink && data.applink.length > 0 ? data.applink.find(s => s.parentid == app._id) : null;
    if (statusEnable != null) {
      if (status !== -1) {
        applinkstatus[status] = { appid: app._id, status: e.target.checked };
        // this.props.HandleInputBusinessChange("applinkstatus", applinkstatus);
        this.submitStatus(applinkstatus, 'Saved');
      } else {
        let value = [
          ...applinkstatus,
          { appid: app._id, status: e.target.checked },
        ];
        // this.props.HandleInputBusinessChange("applinkstatus", value);
        this.submitStatus(value, 'Saved');
      }
    } else {
      Error('No data inside to turn on')
    }
  };

  autoUpdateStatus = (parentId, status) => {
    const data = this.props.BusinessState.bussiness;
    const findInd = (data && data.applink && data.applink.length > 0) ? data.applink.findIndex(e => e.parentid == parentId) : -1
    const findVal = (data && data.applink && data.applink.length > 0) ? data.applink.find(e => e.parentid == parentId) : undefined
    //App Link status
    let applinkstatus = [...data.applinkstatus];
    let linkStatus = applinkstatus.findIndex((r) => r.appid == parentId);
    if (findInd == -1) {
      applinkstatus[linkStatus] = { appid: parentId, status: false };
      this.props.HandleInputBusinessChange("applinkstatus", applinkstatus);
      this.submitStatus(applinkstatus, status);
    }
  }
  removeSocailMedia = (id, parentId) => {
    Swal.fire({
      title: "Are you sure you want to delete the data?",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
      // imageUrl: "../../../../assets/images/delete.png",
      customClass: {
        container: "delete_swal_image",
        popup: "swal_pop",
        title: "swal_title",
        image: "swal_image",
        actions: "swal_action",
        confirmButton: "swal_confirm",
        cancelButton: "swal_close",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const data = this.props.BusinessState.bussiness;
        let applink = [...data.applink];
        let app = [...applink.filter((e, i) => e._id !== id)];
        this.props.HandleInputBusinessChange("applink", app);
        this.props.RunSpinner()
        this.submitApp(app, 'Deleted');
        this.autoUpdateStatus(parentId, 'Deleted');

        if (this.state.viewText == 'view' || this.state.viewText == 'edit') {
          this.viewBackFun(id)
        }
      }
    });
  };

  onSelectFlag = (e) => {
    const { name, value, label } = e;
    let data = {
      name: value,
      value: label,
    };
    this.props.HandleInputChange("cc", data);
  };
  onSelectFlagBusiness = (e) => {
    const { name, value, label } = e;
    let data = {
      name: value,
      value: label,
    };
    this.props.HandleInputBusinessChange("businesscc", data);
  };
  onChangeDate = (e) => {
    let date = moment(e?.toDate?.().toString()).format("YYYY-MM-DD");
    this.props.HandleInputChange("dob", date);
    let age = GetAge(date);
    if (age >= 15) {
      this.setState({ ageError: false });
    } else {
      this.setState({ ageError: true });
    }
  };

  taggleStatus = (e) => {
    const data = this.props.ProfileState.profile;
    const { value, name, checked } = e.target;
    const formData = {};
    if (data.headline && data.displayname) {
      if (name == "ispublicprofile") {
        this.props.HandleInputChange("ispublicprofile", checked);
        formData["ispublicprofile"] = checked;
        formData["ispersonaldetails"] = data.ispersonaldetails ? data.ispersonaldetails : false;
        formData["isbusinessdetails"] = data.isbusinessdetails ? data.isbusinessdetails : false;
      }
    }
    if (name == "ispersonaldetails") {
      this.props.HandleInputChange("ispersonaldetails", e.target.checked);
      formData["ispublicprofile"] = data.ispublicprofile
        ? data.ispublicprofile
        : false;
      formData["ispersonaldetails"] = checked;
      formData["isbusinessdetails"] = data.isbusinessdetails
        ? data.isbusinessdetails
        : false;
    }
    if (name == "isbusinessdetails") {
      this.props.HandleInputChange("isbusinessdetails", e.target.checked);
      formData["ispublicprofile"] = data.ispublicprofile
        ? data.ispublicprofile
        : false;
      formData["ispersonaldetails"] = data.ispersonaldetails
        ? data.ispersonaldetails
        : false;
      formData["isbusinessdetails"] = checked;
    }

    if (data.headline && data.displayname) {
      this.setState({ updateSpinner: true });
      axios
        .post(ImportedURL.API.updateProfileOtherStatus, formData)
        .then((res) => {
          this.props.ViewProfile({ reverseStatus: true });
          this.setState({ updateSpinner: false });
          Success("Data saved");
        })
        .catch(({ response }) => {
          this.setState({ updateSpinner: false });
          if (response) {
            if (response.status == 401) {
              Error("Something wrong, Retry again!");
            } else if (response.status == 510) {
              Error("Email does not exit");
            } else if (response.status == 502) {
              Error(response.status + " Bad Gateway");
            } else if (response.status == 500) {
              Error("Internal Server Error");
            } else if (response.status == 409) {
              Error("Already exist");
            } else if (response.status == 400) {
              Error("Bad request");
            }
          }
        });
    } else {
      Error('Could you please add your "Full name" and "headline" ')
    }
  };
  ModalImages = (e) => {
    this.setState({ showModal: false, collapsed: true });
    if (this.state.typeImage == "image") {
      this.fileInputRef.current.value = "";
    } else if (this.state.typeImage == "banner") {
      this.fileInputBanner.current.value = "";
    } else {
      this.fileInputBusinesssLogo.current.value = "";
    }
  };
  CropImages = (e) => {
    this.setState({ showModal1: true, collapsed: true });

    const data = this.props.ProfileState.profile;
    let value = this.state.croppedImageUrl;
    this.setState({
      croppedImageUrl: "",
      showModal: false,
      src: null,
      crop: {
        unit: "px",
        x: 130,
        y: 50,
        width: 200,
        height: 200,
      },
    });
    var formData = new FormData();
    if (this.state.typeImage == "image") {
      this.setState({ profileimg: URL.createObjectURL(value) });
      this.props.HandleInputChange("image", value);
      formData.append("image", value);
      this.fileInputRef.current.value = "";
    } else if (this.state.typeImage == "banner") {
      this.setState({ bgimage: value });
      formData.append("banner", value);
      this.props.HandleInputChange("banner", value);
      this.fileInputBanner.current.value = "";
    } else {
      this.props.HandleInputBusinessChange("image", value);
      this.fileInputBusinesssLogo.current.value = "";
    }

    if (this.state.typeImage == "image" || this.state.typeImage == "banner") {
      axios
        .post(ImportedURL.API.updateProfilePicture, formData)
        .then((res) => {
          this.setState({ updateSpinner: false });
          this.props.ViewProfile({ reverseStatus: true });
          Success("Data saved");
          let btn = document.getElementById("personalDetailsModal");
          btn.click();
        })
        .catch(({ response }) => {
          this.setState({ updateSpinner: false });
          if (response) {
            if (response.status == 401) {
              Error("Something wrong, Retry again!");
            } else if (response.status == 510) {
              Error("Email does not exit");
            } else if (response.status == 502) {
              Error(response.status + " Bad Gateway");
            } else if (response.status == 500) {
              Error("Internal Server Error");
            } else if (response.status == 409) {
              Error("Already exist");
            } else if (response.status == 400) {
              Error("Bad request");
            }
          }
        });
    }

  };
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    const base64Image = canvas.toDataURL("image/png");
    if (base64Image) {
      const fileType = base64Image.split(";")[0].split(":")[1];
      const buffer = Buffer.from(
        base64Image.replace(/^data:image\/\w+;base64,/, ""),
        "base64"
      );
      const file = new File([buffer], fileName, { type: fileType });
      return file;
    } else {
      return "";
    }
  }

  taggleStatusFiles = (e) => {
    const data = this.props.ProfileState.profile;
    const { value, name, checked } = e.target;
    const formData = {
      isinfostatus: checked,
    };
    this.setState({ updateSpinner: true });
    axios
      .post(ImportedURL.API.updateInfoStatus, formData)
      .then((res) => {
        this.props.ViewProfile({ reverseStatus: true });
        this.setState({ updateSpinner: false });
        Success("Data saved");
      })
      .catch(({ response }) => {
        this.setState({ updateSpinner: false });
        if (response) {
          if (response.status == 401) {
            Error("Something wrong, Retry again!");
          } else if (response.status == 510) {
            Error("Email does not exit");
          } else if (response.status == 502) {
            Error(response.status + " Bad Gateway");
          } else if (response.status == 500) {
            Error("Internal Server Error");
          } else if (response.status == 409) {
            Error("Already exist");
          } else if (response.status == 400) {
            Error("Bad request");
          }
        }
      });
  };
  onChangeBusiness = (e) => {
    const data = this.props.BusinessState.bussiness;

    const { name, value } = e.target;
    const Errors = name + "Error";
    this.setState({ [Errors]: false });
    if (name === "businessphonenumber") {
      let val = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      if (val) {
        if (Phonenumber(val)) {
          this.setState({ busPhoneNoPatternError: false });
          this.props.HandleInputBusinessChange(name, val);
        } else {
          this.setState({ busPhoneNoPatternError: true });
        }
      } else {
        this.setState({ busPhoneNoPatternError: false });
      }
      this.props.HandleInputBusinessChange(name, val);
    } else if (name == 'designation') {
      if (value) {
        this.setState({ [Errors]: false });
        this.props.HandleInputBusinessChange('designation', value.toUpperCase());
      } else {
        this.setState({ [Errors]: true });
        this.props.HandleInputBusinessChange('designation', value.toUpperCase());
      }
    } else if (name == 'work') {
      if (OnlyAplhabetsNumberSomeCharactor(value)) {
        if (value) {
          this.setState({ [Errors]: false });
          this.props.HandleInputBusinessChange('work', value);
        } else {
          this.setState({ [Errors]: true });
          this.props.HandleInputBusinessChange('work', value);
        }
      }
    }
    else {
      this.props.HandleInputBusinessChange(name, value);
    }
  };
  businessLogoChange = (e) => {
    this.setState({ showModal1: false });
    const { name, value } = e.target;
    const Error = name + "Error";
    if (name == "businesslogo") {
      this.setState({ bussinesslogoSrc: "" });
      const imgvalidate = Imagevalidation(e.target.files[0]);
      if (imgvalidate) {
        this.setState({ bussinesslogoError: false, invalidFileError: false });
        this.setState({ categorylogo: e.target.files[0], validImages: false });
        var reader = new FileReader();
        var url = reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = function (e) {
          this.setState({
            bussinesslogoSrc: [reader.result],
          });
          this.setState((prevState) => ({
            ...prevState,
            src: reader.result,
          }));
        }.bind(this);
        // this.props.HandleInputBusinessChange("image", e.target.files[0]);
        this.setState({ showModal: true, typeImage: "businesslogo" });
      } else {
        this.setState({
          invalidFileError: true,
          bussinesslogoError: false,
          validImages: true,
        });
        this.props.HandleInputBusinessChange("image", "");
        // Error('Invalid file extension');
      }
    }
  };
  businessStatusChange = (e) => {
    const { checked } = e.target;
    this.props.HandleInputBusinessChange("status", checked);
  };
  businessSubmit = (e) => {
    const { busPhoneNoPatternError, phoneNoPatternError, ageError } =
      this.state;
    const { BusinessState, ProfileState } = this.props;
    const Pdata = ProfileState.profile;
    const data = BusinessState.bussiness;

    let valid = true;
    if (busPhoneNoPatternError) {
      valid = false;
    }
    if (valid) {
      let listAppLink = [...data.applink];
      const formData = new FormData();

      formData.append("userid", Pdata._id);

      for (let key in data) {
        if (key == "applink") continue;
        else if (key == "applinkstatus")
          formData.append(key, JSON.stringify(data[key]));
        else if (key == "businesscc")
          formData.append(key, JSON.stringify(data[key]));
        else formData.append(key, data[key]);
      }

      let othersArr = [];
      let documentArr = [];
      let existImageArr = [];
      let imagesDataArr = [];
      if (listAppLink.length > 0) {
        for (let i = 0; i < listAppLink.length; i++) {
          if (listAppLink[i].inputtype != "document") {
            othersArr.push(listAppLink[i]);
          }
        }
        for (let i = 0; i < listAppLink.length; i++) {
          if (listAppLink[i].inputtype == "document") {
            documentArr.push(listAppLink[i]);
          }
        }
      }
      if (documentArr.length > 0) {
        for (let i = 0; i < documentArr.length; i++) {
          if (typeof documentArr[i].value == "string") {
            existImageArr.push(documentArr[i]);
          } else {
            formData.append("documents", documentArr[i].value);
            imagesDataArr.push(documentArr[i]);
          }
        }
      }
      formData.append("othersArr", JSON.stringify(othersArr));
      formData.append("existImageArr", JSON.stringify(existImageArr));
      formData.append("imagesDataArr", JSON.stringify(imagesDataArr));
      axios
        .post(ImportedURL.API.addBusiness, formData)
        .then((res) => {
          this.setState({ updateSpinner: false });
          this.props.ViewProfile({ reverseStatus: true });
          Success("Data saved");
          let btn = document.getElementById("closeModal");
          btn.click();
        })
        .catch(({ response }) => {
          this.setState({ updateSpinner: false });
          if (response) {
            if (response.status == 401) {
              Error("Something wrong, Retry again!");
            } else if (response.status == 510) {
              Error("Email does not exit");
            } else if (response.status == 502) {
              Error(response.status + " Bad Gateway");
            } else if (response.status == 500) {
              Error("Internal Server Error");
            } else if (response.status == 409) {
              Error("Already exist");
            } else if (response.status == 400) {
              Error("Bad request");
            }
          }
        });
    }
  };

  businessEdit = (id) => {
    this.setState({ isBusinessEditedit: true, businessEditeditId: id });
  };

  submit = (e) => {
    const { busPhoneNoPatternError, phoneNoPatternError, ageError } =
      this.state;
    const { ProfileState } = this.props;
    const data = ProfileState.profile;
    let listAppLink = [...data.applink];

    let valid = true;
    if (busPhoneNoPatternError) {
      valid = false;
    }
    if (phoneNoPatternError) {
      valid = false;
    }
    if (ageError) {
      valid = false;
    }
    if (valid) {
      this.setState({ updateSpinner: true });
      const formData = new FormData();
      for (let key in data) {
        if (key == "skill") formData.append(key, JSON.stringify(data[key]));
        else if (key == "applink") continue;
        else if (key == "applinkstatus")
          formData.append(key, JSON.stringify(data[key]));
        else if (key == "cc") formData.append(key, JSON.stringify(data[key]));
        else if (key == "businesscc")
          formData.append(key, JSON.stringify(data[key]));
        else formData.append(key, data[key]);
      }
      axios
        .post(ImportedURL.API.updateProfile, formData)
        .then((res) => {
          this.setState({ updateSpinner: false });
          this.props.ViewProfile({ reverseStatus: true });
          Success("Data saved");
        })
        .catch(({ response }) => {
          this.setState({ updateSpinner: false });
          if (response) {
            if (response.status == 401) {
              Error("Something wrong, Retry again!");
            } else if (response.status == 510) {
              Error("Email does not exit");
            } else if (response.status == 502) {
              Error(response.status + " Bad Gateway");
            } else if (response.status == 500) {
              Error("Internal Server Error");
            } else if (response.status == 409) {
              Error("Already exist");
            } else if (response.status == 400) {
              Error("Bad request");
            }
          }
        });
    }
  };

  sendModelView = async (app) => {
    this.setState({ zipBord: false, countryBord: false, cityBord: false, streetBord: false, phoneRequired: false, emailRequired: false, emailError: false, phoneNoPatternArrayError: false, urlError: false })
    this.setState({ modelId: app._id, modelTitle: app.name, viewText: '' });
    const { BusinessState } = this.props;
    const data = BusinessState.bussiness.applink;
    this.setState({ socialmediaValue: [] })

    let id = this.props.match.params.id;
    const listPersonalData = this.props.BusinessState.listPersonal;
    var temp = false
    listPersonalData.filter(e => {
      if (e._id == id) {
        temp = true
      } else {
        temp = false
      }
    });
    if (temp) {
      await this.props.ViewPersonal(id);
    } else {
      await this.props.ViewBusiness(id);
    }





    const filteredArray = data.filter((obj1) =>
      app.typesList.some((obj2) => obj2.id === obj1.id._id)
    );
    this.props.HandleInputBusinessChange("modelData", filteredArray);


    this.setState({
      urlError: false,
      emailError: false,
      phoneNoPatternError: false,
      busPhoneNoPatternError: false,
      phoneNoPatternArrayError: false,
      bussinesslogoError: false,
      isBusinessEditedit: false,
      designationError: false,
      workError: false,
      businessphonenumberError: false,
      showModal1: true,

      streetBord: false,
      cityBord: false,
      stateBord: false,
      zipBord: false,
      country: false,
      isCoseModel: false,
      isCoseModel1: false,
    });
  };
  businessEditSubmit = (e, type) => {
    const { busPhoneNoPatternError, phoneNoPatternError, ageError } = this.state;
    const { BusinessState, ProfileState } = this.props;
    const Pdata = ProfileState.profile;
    const dataBusiness = BusinessState.bussiness;
    const data = { ...dataBusiness };
    for (let key in data) {
      if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
        data[key] = data[key].trim();
      }
    }
    let id = this.props.match.params.id;
    let valid = true;
    // if (!data.image) {
    //   this.setState({ bussinesslogoError: true });
    //   valid = false;
    // }
    if (!data.designation) {
      this.setState({ designationError: true });
      valid = false;
    }
    if (!data.work) {
      this.setState({ workError: true });
      valid = false;
    }
    // if (!data.businessphonenumber) {
    //   this.setState({ businessphonenumberError: true });
    //   valid = false;
    // }
    const formData = new FormData();
    formData.append("image", data.image);
    formData.append("designation", data.designation);
    formData.append("work", data.work);
    formData.append("businessphonenumber", data.businessphonenumber);
    formData.append("businesscc", JSON.stringify(data["businesscc"]));

    // for (let key in data) {
    //   if (key == "applink") continue;
    //   else if (key == "applinkstatus")
    //     formData.append(key, JSON.stringify(data[key]));
    //   else if (key == "businesscc")
    //     formData.append(key, JSON.stringify(data[key]));
    //   else formData.append(key, data[key]);
    // }

    if (valid) {
      // this.setState({ businessBtnChange: true })
      axios
        .post(ImportedURL.API.updateBusiness + '/' + id, formData)
        .then((res) => {
          this.setState({ updateSpinner: false, isShowSwal: false, isCoseModel: true, businessBtnChange: false, collapsed: false });
          this.props.ViewProfile({ reverseStatus: true });
          Success("Updated");
          this.props.ListBusiness();
          this.props.EmptyBusiness();
        })
        .catch(({ response }) => {
          this.setState({ updateSpinner: false });
          if (response) {
            if (response.status == 401) {
              Error("Something wrong, Retry again!");
            } else if (response.status == 510) {
              Error("Email does not exit");
            } else if (response.status == 502) {
              Error(response.status + " Bad Gateway");
            } else if (response.status == 500) {
              Error("Internal Server Error");
            } else if (response.status == 409) {
              Error("Already exist");
            } else if (response.status == 400) {
              Error("Bad request");
            }
          }
        });
    }
  };

  // Headline Display Name Hiting Function
  headlineDisplayFun = (formData) => {
    const data = { ...formData };
    for (let key in data) {
      if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
        data[key] = data[key].trim();
      }
    }
    axios
      .post(ImportedURL.API.updateProfileNameHeadline, data)
      .then((res) => {
        this.setState({ updateSpinner: false });
        // this.props.ViewProfile({ reverseStatus: true });
        // Success("Saved");
      })
      .catch(({ response }) => {
        this.setState({ updateSpinner: false });
        if (response) {
          if (response.status == 401) {
            Error("Something wrong, Retry again!");
          } else if (response.status == 510) {
            Error("Email does not exit");
          } else if (response.status == 502) {
            Error(response.status + " Bad Gateway");
          } else if (response.status == 500) {
            Error("Internal Server Error");
          } else if (response.status == 409) {
            Error("Already exist");
          } else if (response.status == 400) {
            Error("Bad request");
          }
        }
      });
  }

  viewBackFun = (e) => {
    this.setState({ viewText: '', socialmediaValue: [] });
    this.setState({ updateSpinner: false });
    this.props.ListBusiness();
    this.reRenderFun();
  }

  // Clear function 
  clearValFun = (e, app_id, type) => {
    const { socialmediaValue } = this.state;
    let index = socialmediaValue.findIndex((e) => e.Uid == app_id);
    this.setState({ isClear: false })
    if (type == 'address') {
      if (index != -1) {
        socialmediaValue[index] = {
          ...socialmediaValue[index],
          ["zip"]: "",
          ["street"]: "",
          ["state"]: "",
          ["country"]: "",
          ["city"]: "",
          ["value"]: "",
          ["link"]: "",
        };
        this.setState({ socialmediaValue: socialmediaValue, isClear: false });
      }
    }

    if (type == 'url') {
      if (index != -1) {
        socialmediaValue[index] = {
          ...socialmediaValue[index],
          ["address"]: "",
          ["value"]: "",
          ["link"]: "",
        };
        this.setState({ socialmediaValue: socialmediaValue, isClearUrl: false });
      }
    }

    if (type == 'number') {
      if (index != -1) {
        socialmediaValue[index] = {
          ...socialmediaValue[index],
          ["address"]: "",
          ["value"]: "",
          ["link"]: "",
        };
        this.setState({ socialmediaValue: socialmediaValue, isClearNumber: false, phoneNoPatternArrayError: false });
      }
    }
    if (type == 'email') {
      if (index != -1) {
        socialmediaValue[index] = {
          ...socialmediaValue[index],
          ["address"]: "",
          ["link"]: "",
          ["value"]: "",
        };
        this.setState({ socialmediaValue: socialmediaValue, isClearEmail: false, emailError: false });
      }
    }
  }

  handlePlaceSelect = (place, parentId) => {
    const addressComponents = place.address_components;
    let no = '';
    let street = '';
    let city = '';
    let state = '';
    let country = '';
    let zip = '';

    for (const component of addressComponents) {
      const types = component.types;

      if (types.includes('street_number')) {
        no = component.long_name;
      }

      if (types.includes('route')) {
        street = component.long_name;
      }

      if (types.includes('locality')) {
        city = component.long_name;
      }

      if (types.includes('administrative_area_level_1')) {
        state = component.long_name;
      }

      if (types.includes('country')) {
        country = component.long_name;
      }

      if (types.includes('postal_code')) {
        zip = component.long_name;
      }
    }
    const { socialmediaValue } = this.state;
    let index = socialmediaValue.findIndex((e) => e.Uid == parentId);
    if (index != -1) {
      socialmediaValue[index] = {
        ...socialmediaValue[index],
        ["street"]: no ? no + ' ' + street : street ? street : '',
        ["city"]: city ? city : '',
        ["state"]: state ? state : '',
        ["country"]: country ? country : '',
        ["zip"]: zip ? zip : '',
        ["value"]: "",
        ["link"]: "",
      };
      if (socialmediaValue && socialmediaValue.length > 0) {
        socialmediaValue.map(data => {
          if (data.street) {
            this.setState({ streetBord: false, })
          }
          if (data.city) {
            this.setState({ cityBord: false })
          }
          if (data.state) {
            this.setState({ stateBord: false })
          }
          if (data.zip) {
            this.setState({ zipBord: false })
          } if (data.country) {
            this.setState({ countryBord: false })
          }
          if (!data.street && !data.city && !data.state && !data.zip) {
            this.setState({ isClear: false })
          } else {
            this.setState({ isClear: true })
          }
        })
      }
      this.setState({ socialmediaValue: socialmediaValue });
    }
  }
  // Scroll  top 
  scrollToTop = () => {
    if (this.modalContentRef.current) {
      // this.modalContentRef.current.scrollTo({
      //   top: 0,
      //   behavior: 'smooth',
      // });

      this.modalContentRef.current.scrollTop = 0;
      this.modalContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

      // this.modalContentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  render() {
    const { crop, croppedImageUrl, src, isUserdDisplayNameSaveShow, isUserdHeadlineSaveShow, modelTitle, viewText, isWwwShow } = this.state;
    const { socialmediaSelect } = this.state;
    const { ProfileState, appState, BusinessState } = this.props;
    const listapp = appState.listApp;
    const bData = BusinessState.bussiness;

    const listBusinessData = BusinessState.listBusiness;
    listapp?.sort((a, b) => Number(a.sortorder) - Number(b.sortorder));
    // const spinner = ProfileState.spinner;
    const data = ProfileState.profile;
    const dobDate = data.dob ? moment(data.dob).format("MM-DD-YYYY") : "";
    const { streetBord, cityBord, stateBord, zipBord, countryBord, isClear, isClearUrl, isClearNumber, isClearEmail, isCoseModel, isCoseModel1, Country, State, City, apiKey } = this.state;
    const spinnerEdit = BusinessState.spinnerEdit;
    return (
      <>
        <div style={{ position: "relative" }}>
          <div className="home_section profile_section gap_padding_space pro_bg">
            <Container>
              <Row className="justify-content-md-center">
                <Col xs="12" lg="5" md="12" sm="12">
                  <div className="acw_card_nav_images">
                    <div className="acw_card_logo">
                      <div className="acw_image1">
                        <img src="../assets/images/acwlogo.png" />
                      </div>
                      <div className="acw_vertical_line"></div>
                      <div className="acw_image2">
                        <img src="../assets/images/nfclogo.png" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="acw_card_setting">
                        <Link
                          style={{ color: "#fff" }}
                          to="/accountsetting"
                          onClick={(e) =>
                            this.props.HandleChangesSetting("profile")
                          }
                        >
                          <AiFillSetting className="acoount_setting" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="profile_header_text d-flex"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <p className="back_text mb-0">Edit Profile</p>
                    </div>
                    <div className="profile_back_arrow_edit">
                      <Link to="/profile">
                        <HiOutlineChevronLeft />
                      </Link>
                    </div>
                  </div>
                  <div className="public_private_sec">
                    <div class="profile_toggle  d-flex justify-content-between">
                      <p>Make your profile visible Yes/No</p>
                      <label class="toggle">
                        <input
                          type="checkbox"
                          name="ispublicprofile"
                          checked={data.ispublicprofile}
                          onClick={this.taggleStatus}
                        />
                        <span class="slider"></span>
                        <span
                          class="labels"
                          data-on="YES"
                          data-off="NO"
                        ></span>
                      </label>
                    </div>
                  </div>
                  <div className="home_sec">
                    {/* {this.state.designation ? */}
                    {(bData.viewBusinessStatus != undefined && bData.viewBusinessStatus) ?
                      <div className="edit_bussi_personal">
                        <div className="profile_images_box_shadow">
                          <div class="my-2 input_design">
                            <div className="d-flex justify-content-between">
                              <div className="ml-3">
                                <label for="exampleFormControlInput1" class="form-label pl-0 pt-0">
                                  Business logo
                                </label><br></br>
                                <label for="imageSet" className="businesslogo" name="circle_image" >
                                  <span><RiImageAddFill onChange={this.onChangeImage} /></span>
                                </label>
                                <input ref={this.fileInputBusinesssLogo} type="file" accept="image/jpg, image/jpeg, image/png" class="form-control" placeholder="Icon" name="businesslogo" id="imageSet" onChange={this.businessLogoChange} style={{ display: 'none' }} />
                              </div>
                              {bData.image ?
                                <label for="imageSet">
                                  <div className="buss_img_size">
                                    <img src={(typeof bData.image == 'string') ? ImportedURL.FILEURL + bData.image : window.URL.createObjectURL(bData.image)} width="100px" alt='' />
                                  </div>
                                </label>
                                : <label for="imageSet">
                                  <div className="buss_img_size">
                                    <img src={bnotfound} width="100px" alt='' />
                                  </div>
                                </label>}
                            </div>
                            <div class="invalid-feedback" style={{ display: this.state.bussinesslogoError ? "block" : "none", }} >
                              Logo is required
                            </div>
                          </div>

                          <div class="mb-2 input_design">
                            <label for="exampleFormControlInput1" class="form-label">Designation</label>
                            <input type="text" class="form-control" name="designation" onChange={this.onChangeBusiness} value={bData.designation} id="profiledesignation" placeholder="Designation" spellCheck="false" />
                            <div class="invalid-feedback" style={{ display: this.state.designationError ? "block" : "none", }} >
                              Designation is required
                            </div>
                          </div>

                          <div class="mb-2 input_design">
                            <label for="exampleFormControlInput1" class="form-label">Name of the company </label>
                            <input type="text" class="form-control" name="work" onChange={this.onChangeBusiness} value={bData.work} id="profilework" placeholder="Name of the company" />
                            <div class="invalid-feedback" style={{ display: this.state.workError ? "block" : "none", }}>
                              Name of the company is required
                            </div>
                          </div>
                          <div className="Update_edit_btn">
                            <button type="button" class="btn" onClick={this.businessEditSubmit}>Update </button>
                          </div>
                        </div>
                      </div>
                      :
                      <>
                        <div className="progfile_images">
                          <div class="image-upload">
                            <div className="pro_bg_image">
                              {(this.state.bgimage || data.banner) ? (
                                <img src={this.state.bgimage ? URL.createObjectURL(this.state.bgimage) : ImportedURL.LIVEURL + data.banner} alt="" />
                              ) : (
                                <img
                                  className="avatar"
                                  src="../assets/images/acwprofilebg.png"
                                  data-toggle="tooltip"
                                  data-original-title="Avatar Name"
                                  alt=""
                                />
                              )}


                              <div className="response_ban_cam">
                                <label class="dropdown " ref={this.dropdownRef}>
                                  <div class="dd-button" onClick={this.toggleDropdown}>
                                    <AiFillCamera />
                                  </div>
                                  {/* <input type="checkbox" class="dd-input" id="test" /> */}
                                  {this.state.isOpen && (
                                    <ul class="dd-menu">
                                      <li onChange={this.onChangeImage}><label for="file-input" >Gallery</label></li>
                                      {this.state.bgimage || data.banner ? <li onClick={this.removeBanner} >Remove</li> : ''}
                                    </ul>
                                  )}
                                </label>
                              </div>

                              {/* <label
                                for="file-input"
                                className="profile_camera_banner"
                              >
                                <span>
                                  <AiFillCamera onChange={this.onChangeImage} />
                                </span>
                              </label>
                              {(data.banner) && (
                                <label
                                  for="file-input-0"
                                  className="profile_camera_banner_Cancel"
                                >
                                  <span>
                                    <i
                                      class="fa fa-times-circle"
                                      aria-hidden="true"
                                      onClick={this.removeBanner}
                                    ></i>
                                  </span>
                                </label>
                              )} */}
                            </div>
                            <input
                              id="file-input"
                              ref={this.fileInputBanner}
                              accept="image/jpg, image/jpeg, image/png"
                              type="file"
                              name="banner"
                              onChange={this.onChangeImage}
                            />
                          </div>
                          <div class="image-upload">
                            <div className="round_profile">
                              {(this.state.profileimg || data.image) ? (
                                <>
                                  <img
                                    src={
                                      this.state.profileimg
                                        ? this.state.profileimg
                                        : ImportedURL.LIVEURL + data.image
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <img src="../assets/images/user.png" />
                                </>
                              )}

                              <div className="response_pro_cam">
                                <label class="dropdown " ref={this.dropdownRefTwo}>
                                  <div class="dd-button" onClick={this.toggleDropdownTwo}>
                                    <AiFillCamera />
                                  </div>
                                  {/* <input type="checkbox" class="dd-input" id="test" /> */}
                                  {this.state.isOpenTwo && (
                                    <ul class="dd-menu">
                                      <li onChange={this.onChangeImage}><label for="file-input1" >Gallery</label></li>
                                      {this.state.profileimg || data.image ? <li onClick={this.removeImages} >Remove</li> : ''}
                                    </ul>
                                  )}
                                </label>
                              </div>

                              {/* <label
                                for="file-input1"
                                className="profile_camera"
                                name="circle_image"
                              >
                                <span>
                                  <AiFillCamera onChange={this.onChangeImage} />
                                </span>
                              </label>
                              {(data.image) && (
                                <label
                                  for="file-input2"
                                  className="profile_camera_cancel"
                                  name="circle_image"
                                >
                                  <span>
                                    <i
                                      class="fa fa-times-circle"
                                      aria-hidden="true"
                                      onClick={this.removeImages}
                                    ></i>
                                  </span>
                                </label>
                              )} */}

                              <input
                                id="file-input1"
                                ref={this.fileInputRef}
                                accept="image/jpg, image/jpeg, image/png"
                                type="file"
                                style={{ display: "none" }}
                                name="image"
                                onChange={this.onChangeImage}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="profile_images_box_shadow prof_dis_name">
                          <div className="input_tick">
                            <div className="left_form">
                              <div class="input_design">
                                <label
                                  for="exampleFormControlInput1"
                                  class="form-label"
                                >
                                  Full Name
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="profiledisplayname"
                                  placeholder="Full Name"
                                  name="displayname"
                                  value={data.displayname}
                                  onChange={this.onChange}
                                  spellCheck="false"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="input_tick">
                            <div className="left_form">
                              <div class="input_design">
                                <label
                                  for="exampleFormControlInput1"
                                  class="form-label"
                                  placeholder="Password"
                                >
                                  Headline
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="profileheadline"
                                  placeholder="Headline"
                                  name="headline"
                                  value={data.headline}
                                  onChange={this.onChange}
                                  spellCheck="false"
                                />
                              </div>
                            </div>

                          </div>
                        </div>
                      </>
                    }
                    <div className="accordian_sec">
                      <div class="accordion mt-3" id="accordionExample">
                        {listapp != undefined && listapp.length > 0 &&
                          listapp
                            .filter((e) => e.status == true)
                            .map((app, i) => {
                              let { socialmediaValue } = this.state;
                              let toggleStatus =
                                bData.applinkstatus &&
                                  bData.applinkstatus.length > 0
                                  ? bData.applinkstatus.find(
                                    (e) => e.appid == app._id
                                  )
                                  : "";
                              let status =
                                socialmediaValue && socialmediaValue.length > 0
                                  ? socialmediaValue
                                    .reverse()
                                    .find((e) => e.Uid == app._id)
                                  : "";

                              let link = "";
                              let valueData = "";
                              if (status != undefined && status) {
                                link = status.link;
                                valueData = status.value;
                              }
                              return (
                                <>
                                  <div
                                    className="profile_infosec accordion-item edit_profile_infosec"
                                    onClick={(e) => this.sendModelView(app)}
                                  >
                                    <button
                                      class="btn d-flex justify-content-between align-items-center"
                                      type="button"
                                    >
                                      <div className="d-flex align-items-center">
                                        <label class="switch">
                                          <input type="checkbox" checked={toggleStatus ? toggleStatus.status : false}
                                            onClick={(e) => { this.appLinkStatus(e, app, i); }}
                                            name="ispersonaldetails"
                                          />
                                          <div class="slider round"></div>
                                        </label>
                                        <label className="form-label mb-0 px-0 ml-3" data-toggle="modal" data-target="#exampleModalCenter">
                                          {app.name}
                                        </label>
                                      </div>
                                      <div className="edit_icon" data-toggle="modal" data-target="#exampleModalCenter">
                                        <div className="img_size">
                                          <img src={edit} />
                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                </>
                              );
                            })}
                      </div>
                      {spinnerEdit &&
                        <div className='common_loader_model'>
                          <img className='loader_img_style_common' src='/assets/images/logo.jpg' />
                          <Spinner className='spinner_load_common' animation="border" variant="info" >
                          </Spinner>
                        </div>}
                    </div>

                    {/* =============Model xxxxxxxxxx  */}
                    <div ref={this.containerRef} >
                      <div class="modal fade personal_details_model" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" >
                        <div class="modal-dialog modal-dialog-scrollable" role="document"  >
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLongTitle">
                                {modelTitle}
                              </h5>
                              <button type="button" id="closeModalEdit" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body collapseSix modeleditopt" ref={this.modalContentRef}>
                              {listapp != undefined && listapp.length > 0 &&
                                listapp.filter((e) => e.status == true).map((app, i) => {
                                  if (app._id == this.state.modelId) {
                                    let { socialmediaValue } = this.state;
                                    let toggleStatus = bData.applinkstatus && bData.applinkstatus.length > 0 ? bData.applinkstatus.find((e) => e.appid == app._id) : "";
                                    let status = socialmediaValue && socialmediaValue.length > 0 ? socialmediaValue.reverse().find((e) => e.Uid == app._id) : "";
                                    let link = "";
                                    let valueData = "";
                                    if (status != undefined && status) {
                                      link = status.link;
                                      valueData = status.value;
                                    }
                                    let selectOption = [];
                                    if (app.typesList && app.typesList.length > 0) {
                                      selectOption.push({ label: "Deselect", value: "", });
                                      app.typesList.map((item) => {
                                        selectOption.push({ label: item.appname, value: item.appname, logo: item.logo, id: item.id, inputtype: item.inputtype, });
                                      });
                                    }

                                    let displayLoc = ''
                                    if (status) {
                                      displayLoc = (status.street ? (status.street).replace(/,/g, "") + ',' : "") + (status.city ? (status.city).replace(/,/g, "") + ',' : "") + (status.state ? (status.state).replace(/,/g, "") + ',' : "") + (status.country ? (status.country).replace(/,/g, "") + ',' + ' ' : "") + (status.zip ? "- " + status.zip : "")
                                      var mapUrlDisplay = `https://www.google.com/maps/place/${encodeURIComponent(displayLoc)}`;
                                    }
                                    return (
                                      <div className="profile_images_box_shadow">
                                        <div className="social_media_field">
                                          {(viewText == 'edit' || viewText == '') &&
                                            <Select
                                              onClick={this.heightPerpose}
                                              value={status != undefined && status ? { label: status.name, logo: status.logo, } : ""}
                                              options={selectOption}
                                              onChange={(e) =>
                                                this.socialmediaSelect(e, app._id)
                                              }
                                              formatOptionLabel={(e) => (
                                                <div style={{ display: "block", alignItems: "center", zIndex: 2, }} >
                                                  {e.logo ? (
                                                    <img src={ImportedURL.LIVEURL + e.logo}
                                                      style={{ height: "25px", width: "30px", objectFit: "contain", }}
                                                      className="contain_image"
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                  <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                </div>
                                              )}
                                              styles={{
                                                menu: provided => ({ ...provided, zIndex: 9999, textAlign: 'left' })
                                              }}
                                            />
                                          }
                                          {status != undefined && status && (viewText == 'edit' || viewText == '') && (
                                            <>
                                              {status.inputtype == "address" && (
                                                <>
                                                  {isClear &&
                                                    <span className="clear_val" onClick={(e) => this.clearValFun(e, app._id, "address")}>
                                                      <HiRefresh />
                                                      <label class="form-check-label" for="exampleCheck1">Clear</label>
                                                    </span>
                                                  }
                                                  <Autocomplete
                                                    apiKey={apiKey}
                                                    onLoad={(autocomplete) => (this.autocomplete = autocomplete)}
                                                    onPlaceChanged={() => this.handlePlaceSelect(this.autocomplete.getPlace(), app._id)}
                                                  >
                                                    <div className="col-12 mt-2">
                                                      <div className="form-group px-1">
                                                        <label for="exampleFormControlInput1" class="form-label" > Street</label>
                                                        <input type="text" class="form-control" name="street" style={{ borderColor: streetBord ? 'red' : '' }}
                                                          onChange={(e) => this.onChangeAddress(e, app._id, "address")} value={status.street ? status.street : ""} id="floatingInput" placeholder="Street" spellCheck="false" />
                                                      </div>
                                                    </div>
                                                  </Autocomplete>
                                                  <div className="col-12">
                                                    <div className="form-group">
                                                      <label
                                                        for="exampleFormControlInput1"
                                                        class="form-label"
                                                      >
                                                        City
                                                      </label>
                                                      <input
                                                        type="text"
                                                        class="form-control"
                                                        name="city"
                                                        onChange={(e) => this.onChangeAddress(e, app._id, "address")}
                                                        value={status.city ? status.city : ""}
                                                        id="floatingInput"
                                                        placeholder="City"
                                                        style={{ borderColor: cityBord ? 'red' : '' }}
                                                        spellCheck="false"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-12">
                                                    <div className="form-group">
                                                      <label
                                                        for="exampleFormControlInput1"
                                                        class="form-label"
                                                      >
                                                        State
                                                      </label>
                                                      <input
                                                        type="text"
                                                        class="form-control"
                                                        name="state"
                                                        onChange={(e) => this.onChangeAddress(e, app._id, "address")}
                                                        value={status.state ? status.state : ""}
                                                        id="floatingInput"
                                                        placeholder="State"
                                                        style={{ borderColor: stateBord ? 'red' : '' }}
                                                        spellCheck="false"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-12">
                                                    <div className="form-group">
                                                      <label
                                                        for="exampleFormControlInput1"
                                                        class="form-label"
                                                      >
                                                        Country
                                                      </label>
                                                      <input
                                                        type="text"
                                                        class="form-control"
                                                        name="country"
                                                        onChange={(e) =>
                                                          this.onChangeAddress(
                                                            e,
                                                            app._id,
                                                            "address"
                                                          )
                                                        }
                                                        value={
                                                          status.country
                                                            ? status.country
                                                            : ""
                                                        }
                                                        id="floatingInput"
                                                        placeholder="Country"
                                                        style={{ borderColor: countryBord ? 'red' : '' }}
                                                        spellCheck="false"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-12">
                                                    <div className="form-group">
                                                      <label
                                                        for="exampleFormControlInput1"
                                                        class="form-label"
                                                      >
                                                        ZIP Code
                                                      </label>
                                                      <input
                                                        type="text"
                                                        class="form-control"
                                                        name="zip"
                                                        onChange={(e) =>
                                                          this.onChangeAddress(
                                                            e,
                                                            app._id,
                                                            "address"
                                                          )
                                                        }
                                                        value={
                                                          status.zip
                                                            ? status.zip
                                                            : ""
                                                        }
                                                        id="floatingInput"
                                                        placeholder="ZIP Code"
                                                        style={{ borderColor: zipBord ? 'red' : '' }}
                                                        spellCheck="false"
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              )
                                              }
                                              {
                                                status.inputtype == "url" && (
                                                  <>
                                                    {isClearUrl &&
                                                      <div className="clear_val" onClick={(e) => this.clearValFun(e, app._id, "url")}>
                                                        <HiRefresh />
                                                        <label class="form-check-label" for="exampleCheck1">Clear</label>
                                                      </div>}
                                                    <div className="col-12">
                                                      <div
                                                        className="form-group"
                                                        style={{
                                                          marginTop: "10px", position: 'relative'
                                                        }}
                                                      >
                                                        <input
                                                          type="text"
                                                          class="form-control "
                                                          id="floatingInput"
                                                          value={link}
                                                          name="socialmedialink"
                                                          onChange={(e) =>
                                                            this.onChangeLink(
                                                              e,
                                                              app._id,
                                                              "url"
                                                            )
                                                          }
                                                          placeholder={
                                                            status.name
                                                          }
                                                          style={{ paddingLeft: '60px' }}
                                                          spellCheck="false"
                                                        />
                                                        {!isWwwShow &&
                                                          <span className="www_012x4">www.</span>
                                                        }
                                                        <div
                                                          className="invalid-feedback"
                                                          style={{
                                                            display: this.state
                                                              .urlError
                                                              ? "block"
                                                              : "none",
                                                          }}
                                                        >
                                                          URL is required
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                )
                                              }
                                              {
                                                status.inputtype == "number" && (
                                                  <>
                                                    {isClearNumber &&
                                                      <div className="clear_val" onClick={(e) => this.clearValFun(e, app._id, "number")}>
                                                        <HiRefresh />
                                                        <label class="form-check-label" for="exampleCheck1">Clear</label>
                                                      </div>}
                                                    <div className="col-12">
                                                      <div
                                                        className="form-group"
                                                        style={{
                                                          marginTop: "10px",
                                                        }}
                                                      >
                                                        <div className="phonres">
                                                          <div className="cucode">
                                                            <Select
                                                              value={status.cc && status.cc.value != undefined ? { label: status.cc.value, value: status.cc.name, flag: status.cc.name.toLowerCase(), } : ""}
                                                              onChange={(e) => this.onChangeLinkCC(e, app._id, "cc")}
                                                              options={CountryCodeJson && CountryCodeJson.map((item) => {
                                                                return { label: item.dial_code, flag: item.code.toLowerCase(), value: item.code, name: "cc", name: "cc", };
                                                              })}
                                                              formatOptionLabel={(e) => {
                                                                return (
                                                                  <div style={{ display: "block", alignItems: "center", }}>
                                                                    {e.flag ? (
                                                                      <img src={"../assets/images/flags/" + e.flag + ".svg"}
                                                                        style={{ height: "20px", width: "25px", objectFit: "contain", }} className="contain_image" />
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                    <span
                                                                      style={{
                                                                        marginLeft: 5,
                                                                      }}
                                                                    >
                                                                      {e.label}
                                                                    </span>
                                                                  </div>
                                                                );
                                                              }}
                                                              styles={{
                                                                menu: provided => ({ ...provided, zIndex: 9999, textAlign: 'left' })
                                                              }}
                                                            />
                                                          </div>
                                                          <div className="number">
                                                            <div class="mb-3 input_design form-floating-phone-number">
                                                              <input
                                                                type="text"
                                                                pattern="[0-9]*" inputmode="numeric"
                                                                class="form-control "
                                                                id="floatingInput"
                                                                maxLength={10}
                                                                value={
                                                                  status.value
                                                                }
                                                                name="socialmedialink"
                                                                onChange={(e) =>
                                                                  this.onChangeLink(
                                                                    e,
                                                                    app._id,
                                                                    "number"
                                                                  )
                                                                }
                                                                placeholder={
                                                                  status.name
                                                                }
                                                                spellCheck="false"
                                                              />
                                                              <div
                                                                className="invalid-feedback"
                                                                style={{
                                                                  display: this
                                                                    .state
                                                                    .phoneNoPatternArrayError
                                                                    ? "block"
                                                                    : "none",
                                                                }}
                                                              >
                                                                Number
                                                                should contain 10
                                                                digits
                                                              </div>
                                                              <div className="invalid-feedback" style={{ display: this.state.phoneRequired ? "block" : "none" }}>
                                                                Number is required
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                )
                                              }
                                              {
                                                status.inputtype == "email" && (
                                                  <>
                                                    {isClearEmail &&
                                                      <div className="clear_val" onClick={(e) => this.clearValFun(e, app._id, "email")}>
                                                        <HiRefresh />
                                                        <label class="form-check-label" for="exampleCheck1">Clear</label>
                                                      </div>}
                                                    <div className="col-12">
                                                      <div
                                                        className="form-group"
                                                        style={{
                                                          marginTop: "10px",
                                                        }}
                                                      >
                                                        <input
                                                          type="text"
                                                          class="form-control "
                                                          id="floatingInput"
                                                          value={status.value}
                                                          name="socialmedialink"
                                                          onChange={(e) =>
                                                            this.onChangeLink(
                                                              e,
                                                              app._id,
                                                              "email"
                                                            )
                                                          }
                                                          placeholder={
                                                            status.name
                                                          }
                                                          spellCheck="false"
                                                        />
                                                        <div
                                                          className="invalid-feedback"
                                                          style={{
                                                            display: this.state
                                                              .emailError
                                                              ? "block"
                                                              : "none",
                                                          }}
                                                        >
                                                          Email is not valid
                                                        </div>
                                                        <div className="invalid-feedback" style={{ display: this.state.emailRequired ? "block" : "none" }}>
                                                          Email is required
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                )
                                              }
                                              {
                                                status.inputtype == "document" && (
                                                  <>
                                                    {status.name == "Others" && (
                                                      <div className="form-group" style={{ marginTop: "10px", }}>
                                                        <input type="text" class="form-control " onChange={(e) => this.onChangeImageArray(e, app._id, "document")}
                                                          value={status.othersdoc} name="others" placeholder={status.name} spellCheck="false" />
                                                        <div className="invalid-feedback" style={{ display: this.state.othersError ? "block" : "none", }} >Others is required</div>
                                                      </div>
                                                    )}
                                                    <div className="col-12">
                                                      <div class="mt-3 input_design  skill_plus social_Select_Media">
                                                        <div class="doc_img">
                                                          <div className="document_upload">
                                                            <div className="row">
                                                              <div className="col-lg-8">
                                                                <div className="document_img">
                                                                  {status.value !=
                                                                    undefined &&
                                                                    status.value ? (
                                                                    <>
                                                                      {typeof status.value == "string" ? (
                                                                        <>
                                                                          <a href={ImportedURL.FILEURL + status.value} target="_blank"
                                                                            style={{
                                                                              color:
                                                                                "black",
                                                                              textDecoration:
                                                                                "none",
                                                                              objectFit:
                                                                                "contain",
                                                                            }}
                                                                          >
                                                                            <img
                                                                              src={
                                                                                "../assets/images/all_images.jpg"
                                                                              }
                                                                            />
                                                                          </a>
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          {status.previewtype ==
                                                                            "image" && (
                                                                              <img
                                                                                src={URL.createObjectURL(
                                                                                  status.value
                                                                                )}
                                                                              />
                                                                            )}
                                                                          {status.previewtype ==
                                                                            "pdf" && (
                                                                              <a
                                                                                href={URL.createObjectURL(
                                                                                  status.value
                                                                                )}
                                                                                target="_blank"
                                                                                style={{
                                                                                  color:
                                                                                    "black",
                                                                                  textDecoration:
                                                                                    "none",
                                                                                }}
                                                                              >
                                                                                <embed
                                                                                  src={URL.createObjectURL(
                                                                                    status.value
                                                                                  )}
                                                                                ></embed>
                                                                              </a>
                                                                            )}
                                                                          {status.previewtype ==
                                                                            "doc" && (
                                                                              <a
                                                                                href={URL.createObjectURL(
                                                                                  status.value
                                                                                )}
                                                                                target="_blank"
                                                                                style={{
                                                                                  color:
                                                                                    "black",
                                                                                  textDecoration:
                                                                                    "none",
                                                                                  objectFit:
                                                                                    "contain",
                                                                                }}
                                                                              >
                                                                                <img
                                                                                  src={
                                                                                    "../assets/images/doc_img.png"
                                                                                  }
                                                                                />
                                                                              </a>
                                                                            )}
                                                                        </>
                                                                      )}
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      <img src="../assets/images/doucumet_dummy.png" />
                                                                    </>
                                                                  )}
                                                                </div>
                                                              </div>
                                                              <div className="col-lg-4">
                                                                <div className="document_icon">
                                                                  <label
                                                                    for="file-input2"
                                                                    className="document_camera"
                                                                    name="circle_image"
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "#fff",
                                                                      }}
                                                                    >
                                                                      <AiFillCamera
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          this.onChangeImageArray(
                                                                            e,
                                                                            app._id,
                                                                            "document"
                                                                          )
                                                                        }
                                                                        style={{
                                                                          color:
                                                                            "#fff",
                                                                        }}
                                                                      />
                                                                    </span>
                                                                  </label>
                                                                  <input
                                                                    id="file-input2"
                                                                    accept="application/pdf,application/doc,application/docx,application/csv,application/msword,image/jpg, image/jpeg, image/png"
                                                                    type="file"
                                                                    style={{
                                                                      display:
                                                                        "none",
                                                                    }}
                                                                    name="image"
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      this.onChangeImageArray(
                                                                        e,
                                                                        app._id,
                                                                        "document"
                                                                      )
                                                                    }
                                                                  />
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                )
                                              }
                                              {
                                                status.inputtype == "none" && (
                                                  <>
                                                    <div className="col-12">
                                                      <div
                                                        className="form-group"
                                                        style={{
                                                          marginTop: "10px",
                                                        }}
                                                      >
                                                        <input
                                                          type="text"
                                                          class="form-control "
                                                          id="floatingInput"
                                                          value={status.value}
                                                          name="socialmedialink"
                                                          onChange={(e) =>
                                                            this.onChangeLink(
                                                              e,
                                                              app._id,
                                                              "none"
                                                            )
                                                          }
                                                          placeholder={
                                                            status.name
                                                          }
                                                          spellCheck="false"
                                                        />
                                                      </div>
                                                    </div>
                                                  </>
                                                )
                                              }
                                            </>
                                          )}
                                        </div >

                                        {/* Show View Details */}
                                        {
                                          status != undefined && status && viewText == 'view' && (
                                            <div className="view_details">
                                              <div className="logo_title">
                                                <div className="logo_title_image">
                                                  <div className="img_size">
                                                    <img src={ImportedURL.LIVEURL + status.logo} />
                                                  </div>
                                                </div>
                                                <div className="title logo_title_name">
                                                  <span>{status.name}{status.othersdoc ? (" - " + status.othersdoc) : ''}</span>
                                                </div>
                                              </div>
                                              <div className="content">
                                                <p>
                                                  {status.inputtype == "address" && (
                                                    <>
                                                      <a href={mapUrlDisplay} target="_blank" >
                                                        {displayLoc}
                                                      </a>
                                                    </>
                                                  )}
                                                  {status.inputtype == "url" && (
                                                    <>
                                                      <a href={link.includes('https://') ? link : `https://${link}`} target="_blank" >
                                                        {link}
                                                      </a>
                                                    </>
                                                  )}
                                                  {status.inputtype == "number" && (
                                                    <>
                                                      <a href={"tel:" + status.cc.value + " " + status.value} target="_blank" >
                                                        {status.cc.value + " " + status.value}
                                                      </a>
                                                    </>
                                                  )}
                                                  {status.inputtype == "email" && (
                                                    <>
                                                      {status.value}
                                                    </>
                                                  )}
                                                  {status.inputtype == "document" && (
                                                    <>
                                                      <div className="document_img">
                                                        {status.value != undefined && status.value ? (
                                                          <>
                                                            {typeof status.value == "string" ? (
                                                              <>
                                                                <a href={ImportedURL.FILEURL + status.value} target="_blank" style={{ color: "black", textDecoration: "none", objectFit: "contain", }} >
                                                                  <img src={"../assets/images/all_images.jpg"} />
                                                                </a>
                                                              </>
                                                            ) : (
                                                              <>
                                                                {status.previewtype == "image" && (
                                                                  <img
                                                                    src={URL.createObjectURL(
                                                                      status.value
                                                                    )}
                                                                  />
                                                                )}
                                                                {status.previewtype == "pdf" && (
                                                                  <a
                                                                    href={URL.createObjectURL(
                                                                      status.value
                                                                    )}
                                                                    target="_blank"
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                      textDecoration:
                                                                        "none",
                                                                    }}
                                                                  >
                                                                    <embed
                                                                      src={URL.createObjectURL(
                                                                        status.value
                                                                      )}
                                                                    ></embed>
                                                                  </a>
                                                                )}
                                                                {status.previewtype == "doc" && (
                                                                  <a
                                                                    href={URL.createObjectURL(
                                                                      status.value
                                                                    )}
                                                                    target="_blank"
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                      textDecoration:
                                                                        "none",
                                                                      objectFit:
                                                                        "contain",
                                                                    }}
                                                                  >
                                                                    <img src={"../assets/images/doc_img.png"} />
                                                                  </a>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        ) : (
                                                          <>
                                                            <img src="../assets/images/doucumet_dummy.png" />
                                                          </>
                                                        )}
                                                      </div>
                                                    </>
                                                  )}
                                                  {status.inputtype == "none" && (
                                                    <>
                                                      {status.value}
                                                    </>
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                          )
                                        }
                                        {/* View Buttons */}
                                        {
                                          status != undefined && status.inputtype == "address" && viewText == 'view' && (
                                            <div className="form-group">
                                              <div
                                                className="profile_save_btn"
                                                style={{ marginTop: "10px" }}
                                              >
                                                <button type="button" class="btn btn-primary "
                                                  onClick={(e) => this.viewBackFun(app._id)} >
                                                  Close
                                                </button>
                                              </div>
                                            </div>
                                          )
                                        }
                                        {
                                          status != undefined && status.inputtype == "document" && viewText == 'view' && (
                                            <div className="form-group">
                                              <div
                                                className="profile_save_btn"
                                                style={{ marginTop: "25px" }}
                                              >
                                                <button
                                                  type="button"
                                                  class="btn btn-primary "
                                                  onClick={(e) =>
                                                    this.viewBackFun(
                                                      app._id
                                                    )
                                                  }
                                                >
                                                  Close
                                                </button>
                                              </div>
                                            </div>
                                          )
                                        }
                                        {
                                          status != undefined && status.inputtype == "none" && viewText == 'view' && (
                                            <div className="form-group">
                                              <div
                                                className="profile_save_btn"
                                                style={{ marginTop: "10px" }}
                                              >
                                                <button
                                                  type="button"
                                                  class="btn btn-primary "
                                                  onClick={(e) =>
                                                    this.viewBackFun(
                                                      app._id,
                                                      "none"
                                                    )
                                                  }
                                                >
                                                  Close
                                                </button>
                                              </div>
                                            </div>
                                          )
                                        }
                                        {
                                          status != undefined && status.inputtype == "number" && viewText == 'view' && (
                                            <div className="form-group">
                                              <div
                                                className="profile_save_btn"
                                                style={{ marginTop: "10px" }}
                                              >
                                                <button
                                                  type="button"
                                                  class="btn btn-primary "
                                                  onClick={(e) =>
                                                    this.viewBackFun(
                                                      app._id,
                                                      "number"
                                                    )
                                                  }
                                                >
                                                  Close
                                                </button>
                                              </div>
                                            </div>
                                          )
                                        }
                                        {
                                          status != undefined && status.inputtype == "email" && viewText == 'view' && (
                                            <div className="form-group">
                                              <div
                                                className="profile_save_btn"
                                                style={{ marginTop: "10px" }}
                                              >
                                                <button
                                                  type="button"
                                                  class="btn btn-primary "
                                                  onClick={(e) =>
                                                    this.viewBackFun(
                                                      app._id,
                                                      "email"
                                                    )
                                                  }
                                                >
                                                  Close
                                                </button>
                                              </div>
                                            </div>
                                          )
                                        }
                                        {
                                          status != undefined && status.inputtype == "url" && viewText == 'view' && (
                                            <div className="form-group">
                                              <div
                                                className="profile_save_btn"
                                                style={{ marginTop: "10px" }}
                                              >
                                                <button
                                                  type="button"
                                                  class="btn btn-primary "
                                                  onClick={(e) =>
                                                    this.viewBackFun(
                                                      app._id,
                                                      "url"
                                                    )
                                                  }
                                                >
                                                  Close
                                                </button>
                                              </div>
                                            </div>
                                          )
                                        }
                                        {/* Edit Buttons */}
                                        {
                                          status != undefined && status.inputtype == "address" && (viewText == 'edit' || viewText == '') && (
                                            <div className="form-group">
                                              <div
                                                className="profile_save_btn"
                                                style={{ marginTop: "10px" }}
                                              >
                                                <button type="button" class="btn btn-primary "
                                                  onClick={(e) => this.onClickSocialMediaAddress(app._id)} >
                                                  {viewText == 'edit' ? "Update" : "Add"}
                                                </button>
                                              </div>
                                            </div>
                                          )
                                        }
                                        {
                                          status != undefined && status.inputtype == "document" && (viewText == 'edit' || viewText == '') && (
                                            <div className="form-group">
                                              <div
                                                className="profile_save_btn"
                                                style={{ marginTop: "25px" }}
                                              >
                                                <button
                                                  type="button"
                                                  class="btn btn-primary "
                                                  onClick={(e) =>
                                                    this.onClickIAddImageArray(
                                                      app._id
                                                    )
                                                  }
                                                >
                                                  {viewText == 'edit' ? "Update" : "Add"}
                                                </button>
                                              </div>
                                            </div>
                                          )
                                        }
                                        {
                                          status != undefined && status.inputtype == "none" && (viewText == 'edit' || viewText == '') && (
                                            <div className="form-group">
                                              <div
                                                className="profile_save_btn"
                                                style={{ marginTop: "10px" }}
                                              >
                                                <button
                                                  type="button"
                                                  class="btn btn-primary "
                                                  onClick={(e) =>
                                                    this.onClickSocialMedia(
                                                      app._id,
                                                      "none"
                                                    )
                                                  }
                                                >
                                                  {viewText == 'edit' ? "Update" : "Add"}
                                                </button>
                                              </div>
                                            </div>
                                          )
                                        }
                                        {
                                          status != undefined && status.inputtype == "number" && (viewText == 'edit' || viewText == '') && (
                                            <div className="form-group">
                                              <div
                                                className="profile_save_btn"
                                                style={{ marginTop: "10px" }}
                                              >
                                                <button
                                                  type="button"
                                                  class="btn btn-primary "
                                                  onClick={(e) =>
                                                    this.onClickSocialMedia(
                                                      app._id,
                                                      "number", status
                                                    )
                                                  }
                                                >
                                                  {viewText == 'edit' ? "Update" : "Add"}
                                                </button>
                                              </div>
                                            </div>
                                          )
                                        }
                                        {
                                          status != undefined && status.inputtype == "email" && (viewText == 'edit' || viewText == '') && (
                                            <div className="form-group">
                                              <div
                                                className="profile_save_btn"
                                                style={{ marginTop: "10px" }}
                                              >
                                                <button
                                                  type="button"
                                                  class="btn btn-primary "
                                                  onClick={(e) =>
                                                    this.onClickSocialMedia(
                                                      app._id,
                                                      "email", valueData
                                                    )
                                                  }
                                                >
                                                  {viewText == 'edit' ? "Update" : "Add"}
                                                </button>
                                              </div>
                                            </div>
                                          )
                                        }
                                        {
                                          status != undefined && status.inputtype == "url" && (viewText == 'edit' || viewText == '') && (
                                            <div className="form-group">
                                              <div
                                                className="profile_save_btn"
                                                style={{ marginTop: "10px" }}
                                              >
                                                <button
                                                  type="button"
                                                  class="btn btn-primary "
                                                  onClick={(e) =>
                                                    this.onClickSocialMedia(
                                                      app._id,
                                                      "url", link
                                                    )
                                                  }
                                                >
                                                  {viewText == 'edit' ? "Update" : "Add"}
                                                </button>
                                              </div>
                                            </div>
                                          )
                                        }
                                        <div className="edit_list_02 ">
                                          <div className="row">
                                            {bData.applink && bData.applink.length > 0
                                              ?
                                              bData.applink.map((item, i, array) => {
                                                if (item.parentid == app._id) {
                                                  let displayLoc = ''
                                                  if (item && item.address) {
                                                    displayLoc = (item.address && item.address.street ? (item.address.street).replace(/,/g, "") + ',' : "") + (item.address && item.address.city ? (item.address.city).replace(/,/g, "") + ',' : "") + (item.address && item.address.state ? (item.address.state).replace(/,/g, "") + ',' : "") + (item.address && item.address.country ? (item.address.country).replace(/,/g, "") + ',' + ' ' : "") + (item.address && item.address.zip ? "- " + item.address.zip : "")
                                                    var mapUrlDisplay = `https://www.google.com/maps/place/${encodeURIComponent(displayLoc)}`;
                                                  }
                                                  return (
                                                    <>
                                                      <div className="col-6">
                                                        <div className="box">
                                                          <div className="d-flex justify-content-between">
                                                            <div className="img_size">
                                                              <img src={ImportedURL.LIVEURL + item.logo} alt="" />
                                                            </div>
                                                            <div>
                                                              <div className="status">
                                                                <label class="switch">
                                                                  <input type="checkbox" id="togBtn"
                                                                    checked={item.appStatus != undefined && item.appStatus ? item.appStatus : false}
                                                                    name="isbusinessdetails"
                                                                    onClick={(e) => this.documentStatus(e, item, item.id, item.inputtype)}
                                                                  />
                                                                  <div class="slider round"></div>
                                                                </label>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="content">
                                                            <span>
                                                              {item.inputtype == "address" && (
                                                                <>
                                                                  <a href={mapUrlDisplay} target="_blank" >
                                                                    {displayLoc}
                                                                  </a>
                                                                </>
                                                              )}
                                                              {item.inputtype == "url" && (<>
                                                                <a href={item.link.includes('https://') ? item.link : `https://${item.link}`} target="_blank" >
                                                                  {item.link}
                                                                </a>
                                                              </>)}
                                                              {item.inputtype == "number" && (
                                                                <>
                                                                  <a href={"tel:" + (item.cc && item.cc.value != undefined && item.cc ? item.cc.value + " " : "") + " " + item.value} target="_blank" >
                                                                    {(item.cc && item.cc.value != undefined && item.cc ? item.cc.value + " " : "") + item.value}
                                                                  </a>
                                                                </>
                                                              )}
                                                              {item.inputtype == "email" && (
                                                                <>
                                                                  <a href={"mailto:" + item.value} target="_blank" style={{ color: 'black', textDecoration: 'none' }}>
                                                                    {item.value}
                                                                  </a>
                                                                </>
                                                              )}
                                                              {item.inputtype == "none" && (
                                                                <>{item.value}</>
                                                              )}
                                                              {item.inputtype == "document" && (
                                                                <>{item.appname}</>
                                                              )}
                                                            </span>
                                                          </div>
                                                          <div className="icon_list">
                                                            <p className="mb-0 view" onClick={this.scrollToTop}><AiOutlineEye onClick={(e) => this.viewSocailMedia(item, item.id, item.inputtype, 'view')} /></p>
                                                            <p className="mb-0 edit" onClick={this.scrollToTop}><BiEdit onClick={(e) => this.editSocailMedia(item, item.id, item.inputtype)} /></p>
                                                            <p className="mb-0 delete"><MdDeleteOutline onClick={(e) => this.removeSocailMedia(item._id, item.parentid)} /></p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              })
                                              : " "
                                            }
                                          </div>
                                        </div>
                                      </div >
                                    );
                                  }
                                })}
                            </div >
                          </div >
                          {spinnerEdit &&
                            <div className='common_loader_model'>
                              <img className='loader_img_style_common' src='/assets/images/logo.jpg' />
                              <Spinner className='spinner_load_common' animation="border" variant="info" >
                              </Spinner>
                            </div>}
                        </div >
                      </div >
                    </div >
                  </div >
                </Col >
              </Row >
            </Container >
          </div >
          <div className="crop_model_section">
            <Modal
              className={"info-modal crop_modal"}
              show={this.state.showModal}
            >
              <div className="modal-header">
                <Modal.Title>{"Crop Image"}</Modal.Title>
                <div>
                  <button type="button" id="closeModal" onClick={this.ModalImages} className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                      <img src="../../assets/images/cancel.png" />
                    </span>
                  </button>
                  <button type="button" className="close" onClick={this.CropImages}>
                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                      <img src="../../assets/images/tick2.png" />
                    </span>
                  </button>
                </div>
              </div>
              <div class="modal-body">
                <div class="crop_Images">
                  {this.state.src && (
                    <ReactCrop
                      src={this.state.src}
                      crop={this.state.crop}
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                    />
                  )}
                </div>
              </div>
            </Modal>
          </div>

          {
            this.state.updateSpinner ? (
              <div style={{ position: 'relative', zIndex: '2' }}>
                <div
                  style={{ height: "100vh", position: "fixed", width: "100%", top: "0px", }}>
                  <div style={{ position: "relative" }}></div>
                  <div className="common_loader">
                    <img
                      className="loader_img_style_common"
                      src="/assets/images/logo.jpg"
                    />
                    <Spinner
                      className="spinner_load_common"
                      animation="border"
                      variant="info"
                    ></Spinner>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )
          }
        </div >
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ProfileState: state.profile,
  BusinessState: state.business,
  appState: state.app,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      HandleInputChange: AC_HANDLE_INPUT_CHANGE_PROFILE,
      HandleInputBusinessChange: AC_HANDLE_INPUT_CHANGE_BUSINESS,
      ListBusiness: AC_LIST_BUSINESSESS,
      ListApp: AC_LIST_APP,
      ViewProfile: AC_VIEW_PROFILE,
      ViewBusiness: AC_VIEW_BUSINESSESS,
      SpinnerProfile: AC_PROFILE_SPINNER,
      HandleChangesSetting: AC_HANDLE_INPUT_CHANGE_SETTINGS,
      ViewPersonal: AC_VIEW_PERSONAL,
      ListPersonal: AC_LIST_PERSONAL,
      RunSpinner: AC_RUN_SPINNER_BUSINESS
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Profile);